import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DataTable, DataColumn } from '../../controls/dataTable/DataTable';
import { OrContext } from './OrContext';
import CaseContext from '../../controls/caseContext/CaseContext';
import { useHistory } from 'react-router-dom';
import { useORRequests } from '../../hooks/orPortal/useORRequests';
import { useCaseData } from '../../hooks/orPortal/useCaseData';

import moment from 'moment';
import { dateTimeFormat } from '../../utilities/dateTime';

function RequestItems(props) {
    if (props.requestItems.length > 0) {
        return (
            <span>
                {
                    props.requestItems.map((item, i) => {
                        return (<span key={i}>{item.quantityNeeded} - {item.name}<br /></span>);
                    })
                }
            </span>
        );
    }
    else {
        return null;
    }
}

function tempBuildOrRequest(props) {
    var orRequest = {
        id: props.id,
        requestedBy: props.requestedBy,
        assignedTo: props.assignedTo,
        requestStatusText: props.requestStatusText,
        etaText: props.etaText,
        notes: props.notes,
        products: props.requestItems.map((item, i) => { return { name: item.name, quantityNeeded: item.quantityNeeded }; })
    };

    return orRequest;
}

const OrRequests = (props) => {
    const { t } = useTranslation();
    const caseData = useCaseData();
    const orRequests = useORRequests();
    const history = useHistory();

    return (
        <>
            <CaseContext caseInfo={caseData} className="context-container-or-portal bg-light" />
            <Card className="mt-30">
                <Card.Header className="d-flex align-items-center">
                    <div className="mr-auto card-button-group-item">{t('OR Requests')}</div>
                    <div className="card-btn-group form-inline">
                        <Button variant="primary" size="sm"
                            onClick={() => history.push('/or/case/request/create')}
                        >
                            <FontAwesomeIcon icon={faPlus} size="lg" className="show-hand" /> {t('Create new OR request')}
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body className="card-body-table">
                    <DataTable striped hover responsive size="sm" dataSource={orRequests}>
                        <DataColumn dataField="id" className="table-fit cellCenterContent">{t('Id')}</DataColumn>
                        <DataColumn dataField="items"
                            formattedColumn={(row, i) => {
                                return <RequestItems {...row} />
                            }}
                        >{t('Item(s)')}</DataColumn>
                        <DataColumn dataField="requestedBy" >{t('Req. by')}</DataColumn>
                        <DataColumn dataField="assignedTo" >{t('Assigned')}</DataColumn>
                        <DataColumn dataField="requestStatusText">{t('Status')}</DataColumn>
                        <DataColumn
                            dataField="etaText"
                            formattedColumn={(row, i) => {
                                if (row.eta !== null) {
                                    var formattedETA = moment.utc(row.eta).local().format(dateTimeFormat)
                                    return formattedETA;
                                }

                                return "";
                            }}
                            className="table-fit">{t('ETA')}</DataColumn>
                        <DataColumn
                            dataField="id"
                            className="table-fit"
                            formattedColumn={(row, i) => {
                                return <>
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        className="text-primary"
                                        style={{
                                            fontSize: '1.0rem',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => history.push('/or/case/request/' + row.id, { mode: "edit", data: row })} />
                                </>
                            }}
                            className="table-fit cellCenterContent"></DataColumn>
                    </DataTable>
                </Card.Body>
            </Card>
        </>
    )
}

export default OrRequests;