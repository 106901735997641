import Checkbox from './Checkbox';
import ConditionalRender from './ConditionalRender';
import Feedback from './Feedback';
//import TextArea from './TextArea';
import Selectbox from './Selectbox';
import Textbox from './Textbox';

const FormControls = {
    Checkbox,
    ConditionalRender,
    Feedback,
    Selectbox,
    //TextArea,
    Textbox
}
export {
    Checkbox,
    ConditionalRender,
    Feedback,
    Selectbox,
    //TextArea,
    Textbox
};
export default FormControls;