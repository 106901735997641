
import * as actionTypes from '../actions/actionTypes';

// actionTypes.
const initialState = {
    caseInfo: {
        stillNeeded: [],
        assignedItems: [],
        caseCarts: [],
        requests: []
    },
    isLoading: false,
    requestCaseNumber: ''
};

export default (state, action) => {
    state = state || initialState;

    if (action.type === actionTypes.REQUEST_OR_CASE_INFO) {
        return {
            ...state,
            requestCaseNumber: action.requestCaseNumber,
            isLoading: true
        };
    }

    if (action.type === actionTypes.RECEIVE_OR_CASE_INFO) {
        const fetchedCaseInfo = action.caseInfo;
        return {
            ...state,
            requestCaseNumber: action.requestCaseNumber,
            caseInfo: fetchedCaseInfo,
            isLoading: false
        };
    }

    if (action.type === actionTypes.UPDATE_CASE_INFO) {
        const updatedCaseInfo = action.updatedCaseInfo;
        return {
            ...state,
            caseInfo: updatedCaseInfo
        };
    }

    if (action.type === actionTypes.ADD_ITEM_TO_CASE) {
        const itemAddedToCase = action.caseAssignedItems;
        const caseInfoState = { ...state.caseInfo };

        caseInfoState.assignedItems = itemAddedToCase;

        const newState = { ...state, caseInfo: caseInfoState };

        return newState;
    }

    return state;
};