import React, { Component } from 'react';
import { Row, Col, Form, Container, FormCheck, FormGroup, FormLabel, FormControl, Badge, Table, HelpBlock, Button, Alert, DropdownButton, MenuItem, Tooltip, OverlayTrigger, Modal, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import OrganizationService from '../../services/organizationService';

class MakeChildModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            filteredOrganizations: [],
            confirmedMove: false
        };

        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.handleParentOrgSelection = this.handleParentOrgSelection.bind(this);
    }

    componentDidMount() {
        OrganizationService.getAllOrganizations()
        .then(response => {
            if(response.data.success)
            {
                let orgResult = response.data.payload;
                const orgs = orgResult.filter(p => p.organizationTypeId === 2).sort((a, b) => a.name > b.name);
                this.setState({ filteredOrganizations: orgs });     
            }
            else 
            {
                this.setState({ returnError: response.data.message});
            }
        })
        .catch(error => {
            this.setState({ returnError: error.message });
        });
        // OLD
        // let apiAddress = window.location.protocol + "//" + window.location.host + "/api/organization/";
        // fetch(apiAddress)
        //     .then(response => {
        //         if (response.redirected === true) {
        //             this.props.history.push("/auth/login");
        //         }
        //         if (response.ok) {
        //             return response;
        //         }
        //         else if (response.status === 401) {
        //             // redirect to authentication
        //             this.props.history.push("/auth/login");
        //         }
        //         else if (response.status === 403) {
        //             // redirect to unauthorized
        //             this.props.history.push("../auth/unauthorized");
        //         }
        //         else {
        //             throw new Error(response.status + ": " + response.statusText);
        //         }
        //     })
        //     .then(response => {
        //         return response.json();
        //     })
        //     .then(json => {
        //         const orgs = json.filter(p => p.organizationTypeId === 2).sort((a, b) => a.name > b.name);
        //         this.setState({ filteredOrganizations: orgs });
        //     })
        //     .catch(error => {
        //         this.setState({ returnError: error.message });
        //     });
    }

    handleParentOrgSelection(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ newOrgParentOrgId: value });
    }

    handleConfirmation(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ confirmedMove: value });
    }

    render() {
        return (
            <>
                <Modal.Header>
                    <h4>
                        Make Child Under Parent
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid >
                    <Row>
                        <Col>
                            <p>Please be aware!  You are attempting to make this current organization a child of another parent organization.  All existing server instances and client/server registrations will be moved to the parent organization.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group ">
                                <label htmlFor="newOrgParentOrgId">Parent Organization</label>
                                    <select className="form-control" name="newOrgParentOrgId" id="newOrgParentOrgId" aria-describedby="newOrgParentOrgIdHelp" disabled={this.state.filteredOrganizations.length === 0} value={this.state.newOrgParentOrgId} required onChange={this.handleParentOrgSelection}>
                                    <option value={0} selected>Please choose a parent organization}</option>
                                    {
                                        //var parentOrgs = this.state.filteredOrganizations.filter(function(org) { return org.organizationTypeId === 2; });
                                        this.state.filteredOrganizations.map((item, i) => {

                                            return (
                                                <option key={i} value={item.id}>{item.name}</option>
                                            );
                                        })
                                    }
                                </select>
                                <small id="newOrgParentOrgIdHelp" className="form-text text-muted">Please choose the parent org for this new child organization.</small><br />
                                {this.state.newOrgParentOrgId}
                            </div>
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormCheck type="checkbox" checked={this.state.confirmedMove} onChange={this.handleConfirmation}>I confirm that I am moving this organization as a child under the parent chosen above and that this process is not reversable.</FormCheck>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" onClick={this.props.closeModal}>Cancel</Button> 
                    <Button bsStyle="success" type="submit" disabled={!this.state.confirmedMove} onClick={() => this.props.saveModel(this.state.newOrgParentOrgId)}>Move</Button>
                </Modal.Footer>
            </>
        );
    }
}
export default MakeChildModal;