import axios from "axios";

const MeditechMappingService = {
    async deleteMapping(mappingId) {
        const response = axios.delete(`api/meditech/mappings/${mappingId}`);
        return response;
    },

    async saveMapping(mapping) {
        const response = axios.post(`/api/meditech/mappings`, mapping);
        return response;
    }
}

export default MeditechMappingService;