import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormControls from '../formControls';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { passwordRegexString } from '../../utilities/authConstants';
import userService from '../../services/userService';
import LoadingButton from '../buttons/LoadingButton';


class CreateNewUserModal extends Component {
    
    constructor(props, context) {
        super(props, context);
        this.state = {
            user: {
                organizationId: 0,
                userType: 0,
                firstName: "",
                lastName: "",
                emailAddress: "",
                password: "",
                confirmPassword: "",
                roleIds: []
            },
            roles: []
        };
    }

    componentDidMount() {
        const user = this.state.user;
        if (this.props.model.organizationTypeId !== 4) {
            // if it isn't a steris org then normal user type
            user.userType = 1;
        } else {
            // if it is steris - then steris user type
            user.userType = 3;
        }
        user.organizationId = this.props.model.id;

        this.setState({ user: user}, () => this.getRoleTypes());
    }

    getRoleTypes() {
        userService.getRoles(this.state.user.userType)
            .then(response => {
                if (response.data.success) {
                    var result = response.data.payload;
                    this.setState({ roles: result });
                }
            });
    }

    render() {
        const t = this.props.t;
        const initialValues = { ...this.state.user };
        
        const createNewUserSchema = Yup.object().shape({
            firstName: Yup.string()
                .required(t("First name is required.")),
            lastName: Yup.string()
                .required(t("Last name is required.")),
            emailAddress: Yup.string()
                .email(t("Invalid email address format"))
                .required(t("Email address is required")),
            password: Yup
                .string()
                .required(t("Please enter a password."))
                .matches(
                    new RegExp(passwordRegexString),
                    t("Requires at least 8 characters including: uppercase, lowercase, number, and at least one special character @$!%*?&")
                ),
            confirmPassword: Yup
                .string()
                .required(t("Confirmation of password is required."))
                .oneOf([Yup.ref("password"), null], t("Passwords must match")),

            roleIds: Yup.array().min(1, t("A web security role must be selected for the user."))
        })

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={createNewUserSchema}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    const tmpValues = { ...values };
                    tmpValues.roleIds = values.roleIds.map(v => parseInt(v));
                    this.props.saveModel(tmpValues);
                    actions.setSubmitting(false);
                }}>
                {
                    formik => 
                        (
                            <Form onSubmit={formik.handleSubmit}>
                                <Modal.Header>
                                    <Modal.Title>{t("Create New User")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group>
                                                <Form.Label>{t("Web Security Roles")}</Form.Label>
                                                {
                                                    this.state.roles.map((item, i) => {
                                                        return (
                                                            <FormControls.Checkbox key={i}
                                                                id={"roleIdCheck" + i}
                                                                label={item.name}
                                                                name="roleIds"
                                                                feedback=""
                                                                value={item.id}
                                                                formHandler={formik} />
                                                        )
                                                    })
                                                }
                                                <FormControls.Feedback 
                                                    name="roleIds"
                                                    formHandler={formik} />
                                            </Form.Group>
                                            <Form.Group>
                                                <FormControls.Textbox
                                                    label={t("First Name")}
                                                    name="firstName"
                                                    formHandler={formik} />
                                            </Form.Group>
                                            <Form.Group>
                                                <FormControls.Textbox
                                                    label={t("Last Name")}
                                                    name="lastName"
                                                    formHandler={formik} />
                                            </Form.Group>
                                            <Form.Group>
                                                <FormControls.Textbox
                                                    type="email"
                                                    label={t("Email Address")}
                                                    name="emailAddress"
                                                    formHandler={formik} />
                                            </Form.Group>
                                            <Form.Group>
                                                <FormControls.Textbox
                                                    type="password"
                                                    label={t("Password")}
                                                    name="password"
                                                    formHandler={formik} />
                                            </Form.Group>
                                            <Form.Group>
                                                <FormControls.Textbox
                                                    type="password"
                                                    label={t("Confirm Password")}
                                                    name="confirmPassword"
                                                    formHandler={formik} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button disabled={formik.isSubmitting} onClick={() => this.props.closeModal()} variant="warning" type="button">{t("Cancel")}</Button>
                                    <LoadingButton
                                        loading={formik.isSubmitting}
                                        disabled={!formik.dirty}
                                        variant="primary"
                                        type="submit">{t("Create")}
                                    </LoadingButton>
                                </Modal.Footer>
                            </Form>

                        )
                }
            </Formik>
        );
    }
}

export default withTranslation()(CreateNewUserModal);