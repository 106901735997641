import { Modal, Button} from "react-bootstrap";
import React, { useState} from 'react';

const ReasonForModification = (props) => {
    const [reasonNote, setReasonNote] = useState("");

    return (
        <div role="dialog" aria-modal="true" style={{ height: "10000px", width: "20px", display: "block;" }}>
            <Modal show={props.show} cancel={props.close} style={{ width: "2000px" }} onHide={props.close} class="modal-dialog modal-me" autoFocus={false} backdrop="static"  >
                <Modal.Header closeButton>
                    <Modal.Title>Reason for OR Request Modification</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please enter a reason  for these changes
                    <div className="form-group">
                        <textarea className="form-control" autoFocus={true} name="ReasonNotes" id="ReasonNotes" value={reasonNote} onChange={(e) => setReasonNote(e.target.value)} rows="2" style={{ overflowY: "scroll", height: "150px", resize: "none" }} placeholder="Add new note"></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={() => props.close(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={reasonNote.length < 1} onClick={() => props.close(true, reasonNote)}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
}

export default ReasonForModification;