import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { default as modalTypes } from '../modals';
import AlertModal from './AlertModal';
import CreateNewOrganizationModal from './CreateNewOrganizationModal';
import MarkUsedModal from './MarkUsedModal';
import ScanItemToCaseModal from './ScanItemToCaseModal';
import ServerInstanceModal from './ServerInstanceModal';
import MakeChildModal from './MakeChildModal';
import CreateApiKeyModal from './CreateApiKeyModal';
import ImageViewModal from './ImageViewModal';
import ErrorModal from './ErrorModal';
import CreateNewUserModal from './CreateNewUserModal';
import ChangeUserPasswordModal from './ChangeUserPasswordModal';
import WebcamCaptureModal from './WebcamCaptureModal';
import ConfirmationModal from './ConfirmationModal';
import VerifyTraysModal from './VerifyTraysModal';
import ClientSettingsModal from './ClientSettingsModal';
import SiteSettingsModal from './SiteSettingsModal';
import AddNewTrayModal from './AddNewTrayModal';
import SingleTextEntryModal from './SingleTextEntryModal';
import TagLoanersModal from './TagLoanersModal';
import DelivererSelectorModal from './DelivererSelectorModal';
import ProductIndexModal from './ProductIndexModal';
import VendorSelectionModal from "./VendorSelectionModal";

const MODAL_TYPES = {
    'alertModal': AlertModal,
    'createNewUserModal': CreateNewUserModal,
    'createNewOrganizationModal': CreateNewOrganizationModal,
    'markUsed': MarkUsedModal,
    'scanItemToCase': ScanItemToCaseModal,
    'serverInstance': ServerInstanceModal,
    'makeChild': MakeChildModal,
    'createApiKeyModal': CreateApiKeyModal,
    'imageViewModal': ImageViewModal,
    'errorModal': ErrorModal,
    'changeUserPasswordModal': ChangeUserPasswordModal,
    'verifyLoanerModal': VerifyTraysModal,
    'webcamCaptureModal': WebcamCaptureModal,
    'confirm': ConfirmationModal,
    'clientSettingsModal': ClientSettingsModal,
    'siteSettingsModal': SiteSettingsModal,
    'addNewTray': AddNewTrayModal,
    'singleTextEntry': SingleTextEntryModal,
    'tagLoanersModal': TagLoanersModal,
    'delivererSelectorModal': DelivererSelectorModal,
    'productIndexModal': ProductIndexModal,
    'vendorSelectionModal': VendorSelectionModal
};

const mapStateToProps = state => ({
    ...state.modal
});

class ModalRoot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            modalProps: {}
        };
        this.closeModal = this.closeModal.bind(this);
        //this.checkIfEscapeFunction = this.checkIfEscapeFunction.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.modalProps.size !== prevProps.modalProps.size) {
            const currentState = { ...this.state };
            if (this.props.modalProps.size !== undefined) {
                currentState.modalProps.size = this.props.modalProps.size;
                this.setState({ currentState });
            }
        }

        // Commenting this out for now - but leaving it because we will use it soon.
        //if (this.props.modalType) {
        //    // register
        //    window.addEventListener("keydown", this.checkIfEscapeFunction, false);
        //} else {
        //    // unregister
        //    window.removeEventListener("keydown", this.checkIfEscapeFunction, false);
        //}
    }

    // Commenting this out for now - but leaving it because we will use it soon.
    //checkIfEscapeFunction(event) {
    //    if (this.props.modalType && event.key === 27) {
    //        this.closeModal()
    //    }
    //}

    componentWillUnmount() {
        // Commenting this out for now - but leaving it because we will use it soon.
        // just making sure to delete the listener in case the update didn't do it
        //window.removeEventListener("keydown", this.checkIfEscapeFunction, false);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                modalIsOpen: nextProps.modalProps.open
            });
        }
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    render() {
        if (!this.props.modalType || this.state.modalIsOpen !== true) {
            return null;
        }
        const SpecifiedModal = MODAL_TYPES[this.props.modalType];
        return (
            <div>

                <Modal
                    show={this.props.modalProps.open}
                    onHide={this.props.modalProps.closeModal}
                    size={this.props.modalProps.size}
                    keyboard={this.props.modalProps.escClose}
                    backdrop={this.props.modalProps.focus ? "static" : true}>
                    <SpecifiedModal
                        closeModal={this.closeModal}
                        {...this.props.modalProps}
                    />
                </Modal>
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(ModalRoot);