import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { OrContext } from '../OrContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { DataTable, DataColumn } from '../../../controls/dataTable/DataTable';
import CaseContext from '../../../controls/caseContext/CaseContext';
import { dateTimeFormat } from "../../../utilities/dateTime";
import qualityEventStatusEnum from '../../../utilities/enums/qualityEventStatusEnum';
import requirementTypeEnum from '../../../utilities/enums/requirementTypeEnum';

const QualityEvents = (props) => {
    const orContext = useContext(OrContext);
    const history = useHistory();
    const [eventStatus, setEventStatus] = useState([]);
    const [requirementTypes, setRequirementTypes] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        orContext.getQualityEvents();
        
        var status = []; 
        for(var key in qualityEventStatusEnum) {
            status.push({...qualityEventStatusEnum[key]});
        }

        setEventStatus(status);
        
        var reqs = [];
        for(var key in requirementTypeEnum) {
            reqs.push({...requirementTypeEnum[key]});
        }

        setRequirementTypes(reqs);

    }, [orContext.caseData.id]);
    
    return (
        <>
            <CaseContext caseInfo={orContext.caseData} className="context-container-or-portal bg-light" />
            <Card className="mt-30">
                <Card.Header className="d-flex align-items-center">
                    <div className="mr-auto card-button-group-item">Quality Events</div>
                    <div className="card-btn-group form-inline">
                        <Button variant="primary" size="sm" onClick={() => history.push('/or/case/quality/create', {}) }> {t('Add Quality Event')}</Button>
                    </div>
                </Card.Header>
                <Card.Body  className="card-body-table">
                    <DataTable striped hover responsive size="sm" dataSource={orContext.qualityEvents}>
                        <DataColumn dataField="eventDate" 
                            sortable 
                            formattedColumn={(row, i) => {
                                    return <div>{moment(row.eventDate).format(dateTimeFormat)}</div>;
                                }
                            }
                        >{t('Created')}</DataColumn>
                        <DataColumn dataField="reportedBy" sortable >{t('Reported by')}</DataColumn>
                        <DataColumn dataField="eventStatus" 
                            sortable
                            formattedColumn={(row, i) => {
                                var evs = parseInt(row.eventStatus);
                                var status = eventStatus.find(es => es.value === evs);
                                
                                return <div>{status.name}</div>
                            }}
                            >{t('Status')}</DataColumn>
                        <DataColumn dataField="eventType" 
                            sortable
                            formattedColumn={(row, i) => {
                                var type = parseInt(row.eventType);
                                var reqType = requirementTypes.find(rt => rt.value === type);
                                return <div>{reqType.name}</div>
                            }}
                        >{t('Type')}</DataColumn>
                        <DataColumn dataField="eventDetails" className="table-fit">{t('Details')}</DataColumn>
                    </DataTable> 
                </Card.Body>
            </Card>
        </>
    )
}

export default QualityEvents;