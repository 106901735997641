import {DataColumn, DataTable} from "../../../controls/dataTable/DataTable";
import Paginator from "../../../controls/paging/Paginator";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Table} from "react-bootstrap";
import {useHistory} from "react-router-dom";

export function InstrumentResultsTable(props) {
    const {t} = useTranslation();
    const history = useHistory();

    const [selectedInstrumentPage, setSelectedInstrumentPage] = useState([]);

    const pageSize = 20;

    const createOrRequest = (itemDetail, isProduct) => {
        itemDetail.isProduct = isProduct;
        history.push('/or/case/request/create', {mode: "create", data: itemDetail});
    }

    const instrumentRowDetail = (row) => {
        if (row.productsForInstrument && row.productsForInstrument.length > 0) {
            return (<div className="finditem-datatable-info-instrument-subtable">
                <h6>{t('Products containing')} {row.name}:</h6>
                <Table stripped condensed hover responsive>
                    <thead>
                    <tr>
                        <th>Product name</th>
                        <th>Site</th>
                        <th>Most available location</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {row.productsForInstrument.map((product, i) => {
                        return (<tr key={i}>
                            <td>{product.name}</td>
                            <td>{product.site}</td>
                            <td>{product.mostAvailableLocation}</td>
                            <td><Button variant="primary" size="sm" onClick={() => createOrRequest(product, true)}>Create
                                Request</Button></td>
                        </tr>)
                    })}
                    </tbody>
                </Table>

            </div>);
        } else {
            return (<div className="finditem-datatable-info-instrument-subtable">
                <h6>{t('No products containing')} {row.name}</h6>
            </div>)
        }
    }

    return <>
        <DataTable id="instrumentResultsTable"
                   striped hover responsive size="sm"
                   dataSource={selectedInstrumentPage}
                   rowDetail={instrumentRowDetail}
                   onRowDetailExpanded={(row) => props.onRowDetailExpanded(row)}
                   alwaysShowChevrons={true}>
            <DataColumn dataField="name">{t('Instrument name')}</DataColumn>
            <DataColumn dataField="productNumber">{t('Product number 1')}</DataColumn>
            <DataColumn dataField="manufacturerName">{t('Manufacturer')}</DataColumn>
            <DataColumn dataField="category">{t('Category')}</DataColumn>
            <DataColumn dataField="actionColumn"
                        formattedColumn={props.formattedColumn}
            >{t('Action')}</DataColumn>
        </DataTable>
        {props.dataSource.length > 0 ? (<Paginator
            data={props.dataSource}
            maxPerPage={pageSize}
            maxPageItems={5}
            dataSliceChanged={setSelectedInstrumentPage}
        />) : null

        }
    </>;
}