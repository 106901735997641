import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const NotFound = (props) => {

    const { t, i18n } = useTranslation();
    return (
        <div class="py-5">
            <div class="container py-5">
                <div class="row">
                    <div class="col-md-2 text-center">
                        <p><FontAwesomeIcon icon={faExclamationTriangle} className="fa-5x text-danger" /><br />{t('Status Code')}: 404</p>
                    </div>
                    <div class="col-md-10">
                        <h3><b>404</b> {t('Not Found')}</h3>
                        <p>{t('The resource requested could not be found.')}</p>
                        <a class="btn btn-secondary" href="/auth/logout">{t('Logout')}</a>&nbsp;
                                < a class="btn btn-primary" href="javascript:history.back()" > {t('Go back')}</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFound;