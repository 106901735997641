import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import loanerOrderService from '../../../services/loanerOrderService';
import { selectDefaultLoanerSites } from '../../../store/selectors/settingsSelectors';
import { selectSavedSearchTerms } from '../../../store/selectors/loanerAppSettingsSelector';

import { filterCases } from "../../../utilities/filters";

export const useCaseQuery = (params) => {
    const { t } = useTranslation();
    const sites = useSelector(state => state.loanerOrder.sites);
    const defaultSites = useSelector(state => selectDefaultLoanerSites(state));

    const [casesData, setCasesData] = useState([]);
    const [allowedToSearchForPi, setAllowedToSearchForPi ] = useState(null);
    const [message, setRecordLimitExceededMessage] = useState(null);

    const savedSearchTerms = useSelector(state => selectSavedSearchTerms(state));

    useEffect(() => {
        const useSavedTermsOnInitialLoad = Object.keys(savedSearchTerms).length > 0;
        if (useSavedTermsOnInitialLoad) {
            params = savedSearchTerms;
        }
        loanerOrderService.caseSearch(params)
            .then(response => {
                if (response.data.success) {
                    const payload = response.data.payload;
                    if (payload.totalResults > payload.maxResults) {
                        const limitExceededMessage = t("Warning: record limit of ") +
                            payload.maxResults +
                            t(" exceeded. ") +
                            (payload.totalResults - payload.maxResults) +
                            t(" records not shown. Please narrow search terms.");
                        setRecordLimitExceededMessage(limitExceededMessage);
                    } else {
                        setRecordLimitExceededMessage(null);
                    }
                    const orders = filterCases(payload.caseOrders, params.from, params.to);
                    setCasesData(orders);
                } else {
                    setAllowedToSearchForPi(false);

                }
                if (response.data != null && response.data.payload != null) {
                    setAllowedToSearchForPi(response.data.payload.allowedToSearchForPi);
                }
            });
    }, [params]);

    return { casesData, sites, defaultSites, message, allowedToSearchForPi  };
}

export default useCaseQuery;