import React, { Component } from 'react';

class SpmFooter extends Component {
    render() {

        return (
            <footer id="mainfooter" className="py-3 bg-light text-black-50">
                <div className="container text-center">
                    <small>Copyright &copy; { new Date().getUTCFullYear() } STERIS</small>
                </div>
            </footer>
        );
    }
}

export default SpmFooter;