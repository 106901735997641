import React, { Component } from 'react';
import { Row, Col, Modal, Button, Card } from 'react-bootstrap';
import socketService from '../../services/socketAdminService';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { modalActionCreator } from '../../store/actions/modalActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { DataTable, DataColumn } from '../../controls/dataTable/DataTable';
import { Table } from 'react-bootstrap';
import { applicationMessagingActionCreator } from '../../store/actions/applicationMessagingActions';


class ActiveSockets extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = { activeSockets: [], returnError: '' };
        this.getSockets = this.getSockets.bind(this);
        this.handleDeleteSocket = this.handleDeleteSocket.bind(this);
    }

    componentDidMount() {
        this.getSockets();
    }

    getSockets() {
        socketService.getAllSockets()
            .then(response => {
                if(response.data.success) 
                {
                    var sockets = response.data.payload;
                    this.setState({ activeSockets: sockets });
                }
            });
    }

    handleDeleteSocket(socket) {
        const t = this.props.t;
        this.props.hideModal();
        // remove it from the list        
        socketService.deleteSocket(socket.channelId)
            .then(response => {
                if (response.data.success) {
                    var current = this.state.activeSockets.filter(x => x.channelId !== socket.channelId);
                    this.setState({ activeSockets: current });
                    setTimeout(function () { }, 2000);

                    //Create a Toast Notification:
                    this.props.createSuccessNotification(t("Success"), t("Socket for " + socket.name +  " was stopped") );
                    this.getSockets();
                }
            });
    }

    render() {

        const t = this.props.t;
        return (
        <>    
                <Card className="mt-30">
                    <Card.Header className="d-flex align-items-center">
                        <div className="mr-auto card-button-group-item">{t('Active Sockets')}</div>
                    </Card.Header>
                    <DataTable id="socketResultsTable" striped hover responsive size="sm" dataSource={this.state.activeSockets}>
                        <DataColumn sortable dataField="name">{t('Name')}</DataColumn>
                        <DataColumn dataField="id">{t('Org Id')}</DataColumn>
                        <DataColumn dataField="channelId">{t('Channel ID')}</DataColumn>

                        <DataColumn dataField=""
                            formattedColumn={(row, i) => {
                                return (
                                    <div >
                                        <Link to={'/admin/organizations/' + row.id} className="float-left primary-text"><Button Variant="primary" >Go to Organization</Button></Link>
                                    </div>
                                )
                            }
                            }
                        >{t('Action')}

                        </DataColumn>

                        <DataColumn dataField=""
                            formattedColumn={(row, i) => {
                                return (
                                <div key={i} className="panel panel-default">
                                    <div className="panel-heading"><Button variant="btn btn-danger" onClick={() => this.props.showModal({
                                        open: true,
                                        title: t("Disconnect client?"),
                                        message: t("Are you sure you want to disconnect the client for {{clientName}}?", {"clientName":row.name}),
                                        confirm: () => this.handleDeleteSocket(row),
                                        confirmText: t("Disconnect"),
                                        cancel: this.props.hideModal
                                    }, 'confirm')} >Disconnect Socket</Button></div>
                                </div>
                                    )
                                }
                            }
                            >{}
                            </DataColumn>
                    </DataTable>
                </Card>
        </>
        );
    }
}

export default withTranslation()(connect(null, dispatch => bindActionCreators({ ...modalActionCreator, ...applicationMessagingActionCreator }, dispatch))(ActiveSockets));