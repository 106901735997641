const clientSettingsEnum = {
    "filteredLoanerSites": {
        "name": "FilteredLoanerSites", 
        "value": 100
    },
    "selectedLabelPrinter": {
        "name": "SelectedLabelPrinter",
        "value": 101
    }
};

export default clientSettingsEnum;