import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../../assets/img/brand/spm.png';
import ls360Logo from '../../assets/img/brand/spm-ls-360.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import { withTranslation } from 'react-i18next';

const environments = ["-dev", "-test", "-staging", "localdev"];

const allowsUtilities = () => {
    const url = window.location.host;
    const showUtilities = environments.some((element) => {
        return url.includes(element);
    });
    return showUtilities;
};

const getHomeScreenUrl = (user) => {
    const url = window.location.href;
    if (url.includes("/or/")) {
        return null;
    } else return user.defaultHomeScreen;
}

//import '../Shared/scss/Navbar';
class SpmSidebar extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const { t } = this.props;
        return (
            <div className="sidebar bg-dark" id="sideNavBar" name="sideNavBar">
                <div className="sidebar-header bg-light">
                    <a className="sidebar-brand navbar-brand" href={getHomeScreenUrl(this.props.user)}>
                        <img
                            src={logo}
                            width="46"
                            height="46"
                            className="d-inline-block align-top"
                            alt="SPM Logo" />
                    </a>
                </div>
                <ul className="nav">
                    {this.props.nav.map((navitem, idx) => {
                        return (<li className="nav-item" key={idx}>
                            {navitem.reactRoute === false
                                ? <a className="nav-link" href={navitem.url}>
                                    <FontAwesomeIcon icon={navitem.icon}
                                        className="nav-icon" /> {t(navitem.name)}
                                </a>
                                : ((navitem.name === "Utilities" && allowsUtilities()) || navitem.name !== "Utilities") ?
                                    <LinkContainer to={navitem.url}>
                                        <a className="nav-link">
                                            <FontAwesomeIcon icon={navitem.icon}
                                                className="nav-icon" /> {t(navitem.name)}
                                        </a>
                                    </LinkContainer> : null}
                        </li>);
                    })}
                </ul>
                {this.props.nav.some(item => {
                    return item.url.includes('loaners');
                }) ?
                    <div className="logo-border">
                        <div className="bg-light">
                            <img
                                className="ls360Logo"
                                src={ls360Logo}
                                width="100%"
                                height="100%"
                                alt="LS360 Logo" />
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.applicationContext.applicationContext.user
});
export default withTranslation()(connect(mapStateToProps, dispatch => bindActionCreators({ ...applicationContextActionCreator }, dispatch))(SpmSidebar));