import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Select from 'react-select';

import { selectCasesPlusOrders } from '../../../store/selectors/loanerOrderSelectors';

import SiteMultiSelect from './SiteMultiSelect';
import { byName, byCountDescThenByName } from '../../../utilities/sorts'
import moment from 'moment';
import { dateFormat } from "../../../utilities/dateTime";
import { filterCasesFrom } from "../../../utilities/filters";

const VendorSelector = (props) => {
    const dateFrom = moment(new Date()).format(dateFormat);

    const cases = useSelector(state => selectCasesPlusOrders(state));

    const orders = cases.filter(c =>
        props.selectedSites.indexOf(c.siteId) >= 0);
    const filteredOrders = filterCasesFrom(orders, dateFrom);
    const vendors = [...props.vendors.map(v => { return { ...v, count: filteredOrders.filter(c => c.caseOrders.some(co => co.vendorId === v.id)).length }; })];

    const vendorButtonIds = filteredOrders.filter(c => c.caseOrders.length > 0).flatMap(c => c.caseOrders).flatMap(co => co.vendorId);

    vendors.sort(byCountDescThenByName);

    const top12 = vendors.filter(v => vendorButtonIds.includes(v.id)).slice(0, 12).sort(byName);
    const theRest = vendors.filter(v => !top12.flatMap(vo => vo.id).includes(v.id)).sort(byName);

    const styles = { container: base => ({ ...base, width: '400px' }) };
    const vendorOverflow = theRest.map((vendor) => { return { value: vendor.id, label: vendor.name } });
    const vendor = props.selectedVendor ? vendors.find(v => v.id === props.selectedVendor) : null;

    let header = "Choose vendor";
    if (vendor) {
        header = header + " - " + vendor.name;
    }

    return (
        <Card>
            <Card.Header className="d-flex align-items-center">
                <div className="mr-auto">{header}</div>
                <div className="card-btn-group form-inline">
                    <span className="card-button-group-item">Sites:</span>
                    <SiteMultiSelect sites={props.sites} selectedSites={props.selectedSites}
                        sitesChangedHandler={props.handleSitesChange} />
                </div>
            </Card.Header>
            <Card.Body>
                <ToggleButtonGroup className="d-flex flex-wrap my-2 flex-row justify-content-left" type="radio" name="vendor"
                    value={props.selectedVendor} onChange={val => props.handleVendorChange(val)}>
                    {
                        top12.map((vendor, i) => {
                            const variant = vendor.id === props.selectedVendor ? "primary" : "secondary";
                            return <ToggleButton key={i} value={vendor.id} size="lg" key={vendor.id} className="vendor-button" variant={variant}>{vendor.name}</ToggleButton>;
                        })
                    }
                </ToggleButtonGroup>
                <Form.Group className="form-inline d-flex justify-content-end">
                    <Select
                        isMulti={false}
                        isClearable={false}
                        placeholder="Choose from other vendors..."
                        name="selectedVendor"
                        id="selectedVendor"
                        onChange={selection => props.handleVendorChange(selection.value)}
                        options={vendorOverflow}
                        styles={styles}
                        value={props.selectedVendor ? vendorOverflow.find(v => v.id === props.selectedVendor) : null} />
                </Form.Group>
            </Card.Body>
        </Card>
    );
}

export default VendorSelector;