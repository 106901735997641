import axios from "axios";

const ServerInstanceService = { 
    async saveServerInstance(instance) {
        const response = axios.post(`/api/serverinstance/`, instance);
        return response; 
    }
}

export default ServerInstanceService; 
