import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const TenantDbMissing = (props) => {

    const { t, i18n } = useTranslation();
    return (
        <div class="py-5">
            <div class="container py-5">
                <div class="row">
                    <div class="col-md-2 text-center">
                        <p><FontAwesomeIcon icon={faExclamationTriangle} className="fa-5x text-danger" /><br />{t('Status Code')}: 500</p>
                    </div>
                    <div class="col-md-10">
                        <h3><b>500</b> {t('Tenant Db is missing')}</h3>
                        <p>{t('We are currently unable to connect to the tenant database. Please try again later or contact an administrator.')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TenantDbMissing;