import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './DataTable.css';
import { useImmer } from 'use-immer';
import { Table, Button } from 'react-bootstrap';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";
import { typeValidation, getChildrenByType } from '../controlUtilities';

const DataTable = (props) => {

    const [sort, setSort] = useImmer({ field: null, direction: null });
    const [data, setData] = useImmer([]);
    const [originalData, setOriginalData] = useImmer([]);
    const [filterData, setFilterData] = useImmer({})

    const cols = getChildrenByType(props.children, ['DataColumn']);
    const rowActions = getChildrenByType(props.children, ['DataRowAction'])
    const colConfig = cols.map(col => ({ dataField: col.props.dataField, className: col.props.className, formattedColumn: col.props.formattedColumn }));

    const prepareData = () => {
        let localData = [...originalData];

        // filter data if present
        const filterKeys = Object.keys(filterData).filter(f => filterData[f] != null);
        if (filterKeys.length > 0) {
            filterKeys.forEach(key => {
                if (filterData[key] != null) {
                    if (typeof localData[0][key] === 'string') {
                        localData = localData.filter(row => row[key].toLowerCase().includes(filterData[key].toLowerCase()));
                    } else if (Number.isFinite(localData[0][key])) {
                        localData = localData.filter(row => row[key] === filterData[key]);
                    } else {
                        localData = localData.filter(row => row[key].includes(filterData[key]));
                    }
                }
            });
        }

        // sort the results
        if (sort.field) {
            if (sort.direction === "asc") {
                localData.sort(function (a, b) { return a[sort.field].localeCompare(b[sort.field]); });
            } else if (sort.direction === "desc") {
                localData.sort(function (a, b) { return b[sort.field].localeCompare(a[sort.field]); });
            }
        };
        setData(localData);
    }

    const dataSourceChanged = _.isEqual(props.dataSource, originalData) === false;

    useEffect(() => {
        setOriginalData(props.dataSource);
    }, [dataSourceChanged])


    useEffect(() => {
        prepareData();
    }, [sort, filterData, originalData]);

    useEffect(() => {
        if (props.defaultSort) {
            setSort(props.defaultSort);
        }
    }, []);

    const clickSort = (field) => {
        let newSort = { field: null, direction: null };
        if (sort.field !== field) {
            newSort = { field: field, direction: "asc" }
        } else {
            newSort = sort.direction === "asc" ? { field: field, direction: "desc" } : { field: null, direction: null };
        }
        setSort(newSort);
    };

    const filter = (field, filterText) => {
        let localFilterData = { ...filterData };
        localFilterData[field] = filterText;
        setFilterData(localFilterData);
    };

    return (
        <Table {...props}>
            <thead>
                <tr>
                    <DataTableHeader onSort={(field) => clickSort(field)} onFilter={(field, filterText) => filter(field, filterText)} sort={sort} columns={cols} />
                    {
                        rowActions && rowActions.length > 0 ?
                            <th className="table-min-col"></th> : null
                    }
                    {
                        props.rowDetail ? <th className="table-min-col"></th> : null
                    }
                </tr>
            </thead>
            <tbody>
                <DataTableRows
                    alwaysShowChevrons={props.alwaysShowChevrons}
                    colConfig={colConfig}
                    data={data}
                    rowActions={rowActions}
                    rowClassName={props.rowClassName}
                    onRowClick={props.onRowClick}
                    rowDetail={props.rowDetail}
                    onRowDetailExpanded={props.onRowDetailExpanded} />
            </tbody>
        </Table>
    );
};

const DataTableHeader = (props) => {
    const renderHeaderColumns = () => {
        return React.Children.map(props.columns, (column) => {
            return (
                <th>
                    <div className="datatable-header-cell">
                        <span>{column.props.children}</span>
                        {column.props.sortable && (
                            <FontAwesomeIcon
                                icon={column.props.sorted === "asc" ? faSortUp : column.props.sorted === "desc" ? faSortDown : faSort}
                                onClick={() => column.props.onSort(column.props.dataField)}
                                className="pointer-mouseover"
                            />
                        )}
                    </div>
                </th>
            );
        });
    };

    return (
        <>
            {renderHeaderColumns()}
        </>
    );
};

const DataTableRows = (props) => {

    // Expanded Rows - https://stackblitz.com/edit/react-hooks-dynamically-add-table-row-expand-collapse
    // State variable to keep track of all the expanded rows
    // By default, nothing expanded. Hence initialized with empty array.
    const [expandedRows, setExpandedRows] = useState([]);

    // State variable to keep track which row is currently expanded.
    const [expandedState, setExpandedState] = useState({});

    const handleExpandRow = (event, row, rowIndex, onRowDetailExpanded) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(rowIndex);

        let obj = {};
        isRowExpanded ? obj[rowIndex] = false : obj[rowIndex] = true;
        setExpandedState(obj);

        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(index => index !== rowIndex) :
            [...currentExpandedRows, rowIndex];

        setExpandedRows(newExpandedRows);

        if (onRowDetailExpanded !== null && !isRowExpanded) {
            onRowDetailExpanded(row);
        }
    }

    let colCount = props.colConfig?.length ?? 0;
    colCount += props.rowActions?.length ?? 0;
    colCount += props.rowDetail ? 1 : 0;

    if (props.data.length === 0) {
        return (
            <tr>
                <td className="text-muted" colSpan={colCount}>No items to display</td>
            </tr>
        )
    }

    return (
        <>
            {
                props.data.map((row, rowId) => {
                    var data = expandedRows.filter(item => item === rowId)
                    const rowDetailInfo = props.rowDetail ? props.rowDetail(row, rowId) : null;

                    return (
                        <>
                            <h1 className={data.length > 0 ? "datatable-span-expanded" : "datatable-span-collapsed"}>
                                <span className={data.length > 0 ? "datatable-span-expanded" : "datatable-span-collapsed"} ></span>
                            </h1>
                            <tr key={rowId}
                                className={data.length > 0 ? "datatable-info" : ""}
                                onClick={props.onRowClick ? () => props.onRowClick(row, rowId) : null}>
                                {
                                    props.colConfig.map((cols) => {
                                        return (<td className={cols.className} >
                                            {
                                                cols.formattedColumn ?
                                                    <>{cols.formattedColumn(row, rowId)}</>
                                                    : row[cols.dataField]
                                            }
                                        </td>)
                                    })
                                }
                                {
                                    // Actions 
                                    props.rowActions && props.rowActions.length > 0 ?
                                        <td className="table-min-col" >
                                            <DataRowActionCollection rowActions={props.rowActions} data={row} />
                                        </td> : null
                                }
                                {
                                    // Button to show row detail
                                    props.alwaysShowChevrons || props.rowDetail ? (props.alwaysShowChevrons || rowDetailInfo ? <td
                                        className="data-search-highlight-formatter">
                                        <Button
                                            variant="link"
                                            onClick={event => handleExpandRow(event, row, rowId, props.onRowDetailExpanded)}>
                                            {data.length > 0 ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                                        </Button>
                                    </td> : <td></td>) : null
                                }
                            </tr>
                            <>
                                {
                                    expandedRows.includes(rowId) ?
                                        <tr className="datatable-info">
                                            <td colSpan={colCount} >
                                                {rowDetailInfo}
                                            </td>
                                        </tr> : null
                                }
                            </>
                        </>)
                })
            }
        </>
    );
}

const DataRowActionCollection = (props) => {
    let elements = React.Children.toArray(props.rowActions)
    if (elements.length === 1) {
        elements = React.cloneElement(elements[0], { data: props.data })
    }
    else if (elements.length > 0) {
        elements = elements.map((element) => React.cloneElement(element, { data: props.data }))
    }

    return (
        <div className="child-controls">
            {elements}
        </div>
    )
}

const DataRowAction = (props) => {
    const data = props.data;
    return (
        <span onClick={() => props.onClick(data)}>
            {props.children}
        </span>
    )
}

const DataColumn = (props) => {
    const [filtered, setFiltered] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [display, setDisplay] = useState(props.children);

    useEffect(() => {
        let text = filtered ?
            <input type="input" size="sm" onChange={event => setFilterText(event.target.value)} />
            : props.children;
        setDisplay(text);
        setFilterText("");
    }, [filtered]);

    useEffect(() => {
        let filterOn = props.filterOn ? props.filterOn : props.dataField

        let localFilterText = null;
        let isNumber = !isNaN(filterText);

        if (!isNumber && filterText.length > 0) {
            localFilterText = filterText;
        }

        if (isNumber) {
            localFilterText = parseInt(filterText);
        }

        if (filtered || props.filterValues) {
            props.onFilter(filterOn, localFilterText);
        } else {
            props.onFilter(filterOn, null);
        }
    }, [filterText]);

    const handleSort = () => {
        if (props.sortable && props.onSort) {
            props.onSort(props.dataField);
        }
    };

    return (
        <th className={props.headerColClass}>{display} {props.filterable && <FontAwesomeIcon icon={faFilter} onClick={() => setFiltered(!filtered)} style={{ fontSize: "0.8rem", backgroundColor: "salmon" }} className={filtered ? "pointer-mouseover text-danger" : "pointer-mouseover"} />} {props.sortable && <FontAwesomeIcon icon={props.sorted === "asc" ? faSortUp : props.sorted === "desc" ? faSortDown : faSort} onClick={handleSort} className="pointer-mouseover" />}{filterText}</th>
    );
};

DataRowAction.propTypes = {
    children: PropTypes.node,
    __TYPE: typeValidation('DataRowAction'),
};

DataRowAction.defaultProps = {
    __TYPE: 'DataRowAction',
};

DataColumn.propTypes = {
    children: PropTypes.node,
    __TYPE: typeValidation('DataColumn'),
};

DataColumn.defaultProps = {
    __TYPE: 'DataColumn',
};

export { DataColumn, DataRowAction, DataTable };

export default DataTable;