import React,{ Fragment } from 'react'

export function formatResponseError(response) {
    return (
        <Fragment>
            {response.message}
            <ul>
            {response.errors && (response.errors.map((item, i) => {
                return (
                    <li key={i}>{item}</li>
                );
            }))}
            </ul>
        </Fragment>);

}