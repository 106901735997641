import React from 'react';
import { Form } from 'react-bootstrap';

const Checkbox = React.memo((props) => {
    return (
        <>
            <Form.Check
                id={props.id??("checkbox" + props.name + props.value)}
                name={props.name}
                type="checkbox"
                label={props.label}
                className={props.className}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.formHandler.handleChange}
                onBlur={props.formHandler.handleBlur}
                name={props.name}
                isInvalid={props.formHandler.touched[props.name] && props.formHandler.errors[props.name]}
            />
        </>
    );
});

export default Checkbox