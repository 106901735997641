import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import ConfigService from '../../services/configService';
import * as actionTypes from '../actions/actionTypes';

//REMINDER: when we do the work to allow a customer to switch from one tenant to another, 
//we will need logic in here to destroy the old connection and create a new one. Otherwise, 
//updates from the old tenant will carry over to the new one. 
class MessageHub {
    subscribedTopics = [];

    constructor(store) {
        // messaging hub 
        const endpoint = ConfigService.getApiURL() + "/hubs/messages";

        this.hub = new HubConnectionBuilder()
            .withUrl(endpoint)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryPolicyDelayInMs
            })
            .configureLogging(LogLevel.Information)
            .build();

        // define expected messages 
        this.hub.on('sendMessage', (receivedMessage) => {
            let { type, payload } = receivedMessage;
            store.dispatch({
                type: type,
                payload: payload
            });
        });

        this.hub.onreconnected(connectionId => {
            this.reSubscribeTopics();
        });
    }

    subscribeToTopic(topic) {
        if (this.subscribedTopics.indexOf(topic) < 0) {
            this.subscribedTopics = this.subscribedTopics.concat(topic);
        }

        if (this.hub.connectionState === HubConnectionState.Connected) {
            this.hub.invoke('subscribeToTopic', topic).catch(err => console.log(err));
        }
    }

    unsubscribeFromTopic(topic) {
        this.subscribedTopics = this.subscribedTopics.filter(t => t !== topic);

        if (this.hub.connectionState === HubConnectionState.Connected) {
            this.hub.invoke('unsubscribeFromTopic', topic).catch(err => console.log(err));
        }
    }

    reSubscribeTopics() {
        this.subscribedTopics.map(t => {
            this.hub.invoke('subscribeToTopic', t).catch(err => console.log(err));
        });
    }

    processAction(action) {

        // sending messages       
        switch (action.type) {
            case actionTypes.SEND_SIGNALR_MESSAGE:
                this.hub.invoke('sendMessage', action.message).catch(err => console.log(err));
                break;

            case actionTypes.SUBSCRIBE_SIGNALR_TOPIC:
                this.subscribeToTopic(action.topic);
                break;

            case actionTypes.UNSUBSCRIBE_SIGNALR_TOPIC:
                this.unsubscribeFromTopic(action.topic);
                break;

            case actionTypes.START_SIGNALR_CONNECTION:

                if (this.hub.connectionState !== HubConnectionState.Connected) {
                    this.hub.start()
                        .then(() => {
                            this.reSubscribeTopics();
                        })
                        .catch(err => {
                            console.log('Error while establishing connection');
                            console.log(err);
                        });
                }

                break;
            case actionTypes.STOP_SIGNALR_CONNECTION:
                if (this.hub.connectionState === HubConnectionState.Connected) {
                    this.hub.stop();
                }
                break;
        }
    }
}

export default MessageHub;

export const retryPolicyDelayInMs = retryContext => {
    const intervals = [0, 500, 2000, 10000, 30000, 60000];
    //if signalr connection goes down, wait 0ms, 500ms, 2000ms, etc. before trying to connect again
    //after 6 retries, just keep trying every 60 seconds
    return retryContext.previousRetryCount < intervals.length ?
        intervals[retryContext.previousRetryCount] :
        intervals[intervals.length - 1];

    //NOTE: the RetryContext also has elapsedMilliseconds and retryReason if we want to 
    //get more precise with our retry intervals using either of these
    //https://docs.microsoft.com/en-us/javascript/api/@microsoft/signalr/retrycontext?view=signalr-js-latest
}
