import React from 'react';

//const Home = React.lazy(() => import('../../components/Home'));
//const Organization = React.lazy(() => import('../../components/admin/Organization'));
//const OrganizationList = React.lazy(() => import('../../components/admin/OrganizationList'));
//const User = React.lazy(() => import('../../components/admin/User'));
//const ActiveSockets = React.lazy(() => import('../../components/admin/ActiveSockets'));

import Organization from '../../components/admin/Organization';
import OrganizationList from '../../components/admin/OrganizationList';
import User from '../../components/admin/User';
import ActiveSockets from '../../components/admin/ActiveSockets';
import Utilities from '../../components/admin/Utilities';
import Chat from '../../components/admin/RealtimeMessages';
import Documentation from '../../components/admin/Documentation';
import QualityEvents from '../../components/or/QualityEvents/QualityEvents';
import OrRequests from '../../components/or/OrRequests';
import CreateOrRequest from '../../components/or/CreateOrRequest';
import CreateQualityEvent from '../../components/or/QualityEvents/CreateQualityEvent';
import AssignedItems from '../../components/or/AssignedItems';
import FindItem from '../../components/or/FindItem/FindItem';
import Home from '../../components/Home';

import LoanerHome from '../../components/loaner/LoanerHome';
import DeliverTrays from '../../components/loaner/DeliverTrays/DeliverTrays';
import SelectTrays from '../../components/loaner/SelectTrays/SelectTrays';
import UserChangePassword from '../../components/user/UserChangePassword';
import UserProfile from '../../components/user/UserProfile';
import UserSettings from '../../components/user/UserSettings';
import CaseList from '../../components/loaner/CaseList/CaseList';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = {
    loaner: [
        { path: '/', exact: true, name: 'SPM', component: LoanerHome },
        { path: '/loaners/', exact: true, name: 'Loaners', component: LoanerHome },
        { path: '/loaners/home', exact: true, name: 'Home', component: LoanerHome },
        { path: '/loaners/delivertrays', exact: true, name: 'Drop Off', component: DeliverTrays },
        { path: '/loaners/delivertrays/:id?', exact: true, name: 'Select Loaners', component: SelectTrays },
        { path: '/loaners/reassigntrays', exact: true, name: 'Reassign Loaners', component: LoanerHome },
        { path: '/loaners/reprintlabels', exact: true, name: 'Reprint Labels', component: LoanerHome },
        { path: '/loaners/cases', exact: true, name: 'Cases', component: CaseList },
    ],
    admin: [
        { path: '/', exact: true, name: 'SPM', component: OrganizationList },
        { path: '/admin', exact: true, name: 'Admin', component: OrganizationList },
        { path: '/admin/organizations', exact: true, name: 'Organizations', component: OrganizationList },
        { path: '/admin/organizations/:id?', exact: true, name: 'Organization', component: Organization },
        { path: '/admin/user/:id?', exact: true, name: 'User', component: User },
        { path: '/admin/activesockets/:id?', exact: true, name: 'Active Sockets', component: ActiveSockets },
        { path: '/admin/realtime', exact: true, name: 'Realtime', component: Chat },
        { path: '/admin/documentation', exact: true, name: 'Documentation', component: Documentation },
        { path: '/admin/utilities', exact: true, name: 'Utilities', component: Utilities },
        { path: '/user', exact: true, name: 'User', component: UserProfile },
        { path: '/user/profile', exact: true, name: 'Profile', component: UserProfile },
        { path: '/user/settings', exact: true, name: 'User Settings', component: UserSettings },
        { path: '/user/password', exact: true, name: 'Change Password', component: UserChangePassword }
    ],
    or: [
        { path: '/or/cases', exact: true, name: 'Home', component: Home },
        { path: '/or/case/requests', exact: true, name: 'OR Requests', component: OrRequests },
        { path: '/or/case/request/create', exact: true, name: 'Create OR Request', component: CreateOrRequest },
        { path: '/or/case/request/:id', exact: false, name: 'OR Request', component: CreateOrRequest },
        { path: '/or/case/quality/create', exact: false, name: 'Create Quality Event', component: CreateQualityEvent },
        { path: '/or/finditem', exact: false, name: 'Search', component: FindItem },
        { path: '/or/case/quality', exact: false, name: 'Quality Events', component: QualityEvents },
        { path: '/or/case/:id?', exact: false, name: 'Case', component: AssignedItems },]
};

export default routes;