import React from 'react';
import { Card, Form } from 'react-bootstrap';

const CardWrapper = ({ header, ...props }) => {
    return (
        <Card>
            <Card.Header className="d-flex">
                <div className="mr-auto">{header}</div>
            </Card.Header>
            <Card.Body>
                <Form.Row>
                    {props.children}
                </Form.Row>
            </Card.Body>
        </Card>
        );
};

export default CardWrapper;
