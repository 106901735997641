import axios from "axios";

const UserService = {
    async getUserDetails(userId) {
        const response = axios.get(`/api/user/${userId}`);
        return response;
    },

    async saveUser(user) {
        const response = axios.put(`/api/user/`, user);
        return response; 
    },

    async createUser(user) {
        const response = axios.post(`/api/user/`, user);
        return response;
    },

    async changePassword(userPasswordChangeSet) {
        const response = axios.post(`/api/user/changePassword`, userPasswordChangeSet);
        return response;
    },

    async getRoles(userType) {
        const response = axios.get(`/api/user/roles?userType=${userType}`);
        return response;
    }
}

export default UserService; 
