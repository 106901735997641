import { createSelector } from 'reselect';
import { byName } from '../../utilities/sorts'

const getCases = state => state.loanerOrder.cases;

export const selectCaseOrders = createSelector(
    getCases,
    (cases) => cases.reduce((orders, c) => {
        return orders.concat(c.caseOrders.map(co => {
            return {
                id: co.id,
                siteId: c.siteId,
                siteName: c.siteName,
                physicianId: c.physicianId,
                physicianName: c.physicianName,
                orderComments: co.orderComments,
                caseNumber: c.caseNumber,
                caseStartTime: c.caseStartTime,
                caseNotes: c.caseNotes,
                operatingRoomName: c.operatingRoomName,
                procedureName: c.procedureName,
                vendorName: co.vendorName,
                vendorId: co.vendorId,
                vendorRepName: co.vendorRepName,
                vendorRepId: co.vendorRepId,
                traysExpected: co.traysExpected,
                traysDelivered: co.traysDelivered,
                verified: co.verified,
                isCanceled: c.isCanceled,
                // use the most recent modified date case or caseOrder
                modifiedDate: c.modifiedDate > co.modifiedDate ? c.modifiedDate : co.modifiedDate
            };
        }));
    }, []));

export const selectCasesPlusOrders = createSelector(
    getCases,
    (cases) => cases);

const getPhysicians = state => state.loanerOrder.physicians;

export const selectSortedPhysicians = createSelector(
    getPhysicians,
    (physicians) => physicians.sort(byName));