import axios from "axios";

const TenantService = {
    async getTenantInfo() {
        const response = axios.get('/api/tenant');
        return response;
    },
    async getSpmStatus() {
        const response = axios.get(`/api/tenant/spmstatus`);
        return response;
    }
}

export default TenantService; 
