import axios from "axios";
import { UPDATE_CASE_INFO, ADD_ITEM_TO_CASE } from "../store/actions/actionTypes";

const ORCaseService = {
    async findItem(searchParams) {
        const response = axios.post(`/api/finditem/`, searchParams);
        return response;
    },

    async getAssignedItems(caseNumber) {
        const url = `api/or/assigneditems/${caseNumber}`;
        const response = axios.get(url);

        return response;
    },

    async saveUtilization(updatedCaseInfo) {
        const url = `api/or/assigneditems/utilization`;
        const response = axios.post(url, updatedCaseInfo);
        return response;
    },

    async addItemToCase(itemAddedToCase) {
        const url = `api/or/assigneditems/additemtocase`;
        const response = axios.post(url, itemAddedToCase);
        return response;
    },

    async getQualityEventsForCase(caseNumber) {
        const url = `api/or/qualityevents/${caseNumber}`;
        const response = axios.get(url);

        return response;
    },

    async createQualityEvent(qualityEventCreation) {
        const url = `api/or/qualityevents/create`;
        const response = axios.post(url, qualityEventCreation);
        return response;
    },

    async createORRequest(orRequest) {
        const url = `api/or/requests`;
        const response = axios.post(url, orRequest);
        return response;
    },

    async updateORRequest(orRequest) {
        const url = `api/or/requests/${orRequest.id}`;
        const response = axios.put(url, orRequest);
        return response;
    },
    async getRequirements(requirementsQuery) {
        const url = `api/or/qualityevent/requirements/`;
        const response = axios.post(url, requirementsQuery);
        return response;
    }
}

export default ORCaseService; 
