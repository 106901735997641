import React, { Component, Suspense } from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import NotificationRoot from '../../controls/notifications/NotificationRoot';
import ModalRoot from '../../controls/modals/ModalRoot';
import SpinnerRoot from '../../controls/spinner/SpinnerRoot'

import SpmHeader from '../SpmLayout/SpmHeader';
import SpmFooter from '../SpmLayout/SpmFooter';
import SpmSidebar from '../SpmLayout/SpmSidebar';
import routes from '../Shared/routes';
import nav from '../Shared/nav';

import AssureContext from '../../components/auth/AssureContext';
import CaseContext from '../../controls/caseContext/CaseContext';
import OrContext from '../../components/or/OrContext';

class EpicLayout extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            navigation: { items: [] }
        };
    }

    render() {
        return (
            <AssureContext ignoreClientContext='true'>
                <div className="app default-layout">
                    <OrContext>
                        <SpmSidebar nav={nav.or} />
                        <div className="main-wrapper">
                            <div className="main-body">
                                <main className="main">
                                    <Container fluid>
                                        <Switch>
                                            <Redirect exact strict from="/or" to="/or/home" />
                                            {routes.or.map((route, idx) => {
                                                return route.component && (
                                                    <Route
                                                        key={idx}
                                                        path={route.path}
                                                        exact={route.exact}
                                                        name={route.name}
                                                        render={props => (
                                                            <route.component {...props} />
                                                        )} />
                                                )
                                            })}
                                        </Switch>
                                    </Container>
                                </main>
                            </div>
                            <SpmFooter />
                        </div>
                    </OrContext>
                </div>
                <ModalRoot />
                <NotificationRoot />
                <SpinnerRoot />
            </AssureContext>
        )
    }
}

export default EpicLayout;