import React from 'react'
import { ListGroup } from 'react-bootstrap';
import { faDolly } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LoadingButton from '../../../controls/buttons/LoadingButton';

import { byName } from "../../../utilities/sorts";

const TraySearchResults = ({
    dropOffNewTray,
    promptForDeliverer,
    message,
    setLoading,
    loading,
    searchResults
}) => {
    const { t } = useTranslation();

    const addTray = (result) => {
        setLoading('new-tray', result.id);
        promptForDeliverer(() => dropOffNewTray(result));
    };
    
    return [
        (searchResults ? searchResults : []).some(Boolean)
        ? searchResults.sort(byName).map((result) => {
                return (
                    <ListGroup.Item key={result.id} action className="d-flex align-items-center tight-list-group">
                        <div>{result.name + (result.productCode ? ` (${result.productCode})` : "")}</div>
                        <div className="ml-auto">
                            <LoadingButton
                                size="sm"
                                disabled={loading.key === 'new-tray'}
                                loading={
                                    loading.key === 'new-tray' &&
                                    loading.target === result.id
                                }
                                onClick={() => addTray(result)}>
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faDolly}
                                    tooltip={t("Click to drop off loaner")} />
                                <span>{t('Drop off')}</span>
                            </LoadingButton>
                        </div>
                    </ListGroup.Item>
                );
            })
            : message
                ? <ListGroup.Item><em style={{ color: "red" }}>{t(message)}</em></ListGroup.Item>
                : <div></div>
    ];
}

export default TraySearchResults;