import React from 'react';
import { useTranslation } from 'react-i18next';
import usePreventNavigationModal from '../../../hooks/modals/usePreventNavigationModal';

const SelectTraysPreventNavigation = ({ isDirty, children }) => {
    const { t } = useTranslation();
    const message =
        t('There are items that still have a status of pending delivery.') +
        ' ' +
        t(
            'Please print a label, verify, or remove the items as appropriate, otherwise these items will not be marked delivered.'
        ) +
        '\n\n' +
        t(
            'Are you sure you want to leave with items still marked as pending delivery?'
        );

    const { unblock } = usePreventNavigationModal(isDirty, {
        open: true,
        title: t('Incomplete Order'),
        message: message,
        cancelText: t('Keep working'),
        confirmText: t('Leave'),
    });

    if (typeof children === 'function') {
        children(unblock);
        return null;
    }
    return <>children</>;
};

export default SelectTraysPreventNavigation;
