import * as actionTypes from '../actions/actionTypes';

const initialState = {
    requestUpdates: []
};

const orPortalMessageReducer = (state, action) => {
    state = state || initialState;

    if (action.type === actionTypes.OR_REQUEST_UPDATED) {

        const tempUpdates = [...state.requestUpdates];
        tempUpdates.push(action.payload);
        return {
            ...state,
            requestUpdates: tempUpdates
        };
    }

    if (action.type === actionTypes.CLEAR_OR_REQUEST_UPDATES) {
        return {
            ...state,
            requestUpdates: []
        };
    }

    return state;
}

export default orPortalMessageReducer;