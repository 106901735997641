import React from 'react';
import { Form } from 'react-bootstrap';

const Feedback = React.memo((props) => {
    return (
        <>
            <Form.Control.Feedback
                type="invalid"
                className={props.formHandler.errors[props.name] && props.formHandler.touched[props.name] && "d-block"}>
                {props.formHandler.errors[props.name]}</Form.Control.Feedback>
        </>
    );
});

export default Feedback