import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    FormGroup,
    FormLabel,
    FormControl,
    ToggleButton,
    ButtonGroup,
    Alert,
} from 'react-bootstrap';
import { signalrActionCreator } from '../../store/actions/signalrActions';
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import { AuthenticationModeEnum } from '../../utilities/enumerations';
import authService from '../../services/authService';
import tenantService from '../../services/tenantService';
import LoadingButton from '../../controls/buttons/LoadingButton';

const UserAuthenticationResultEnum = {
    Success: 1,
    Failed: 2,
    LockedOut: 3,
    NotAllowed: 4,
    TwoFactorRequired: 5,
    PendingAuthorization: 6,
    SpmAccountLinkRequired: 7,
    NotAuthorizedOnSpm: 8, // IsWebUser is not checked on the SPM User Account object    
};

class Login extends Component {

    authButtons = [
        { name: 'SPM', value: AuthenticationModeEnum.SPM },
        { name: 'SynergyTrak', value: AuthenticationModeEnum.SynergyTrak },
        { name: 'Web', value: AuthenticationModeEnum.Web }
    ];


    constructor(props, context) {
        super(props, context);

        this.state = {
            password: "",
            passwordValid: false,
            emailAddress: "",
            emailAddressValid: false,
            formErrors: { email: '', password: '' },
            authMode: AuthenticationModeEnum.SPM,
            formValid: false,
            domain: "",
            allowedDomains: [],
            spmConnected: true,
            returnError: '',
            data: '',
            provisionalAuth: false,
            isLoading: false,
        };
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleDomainSelection = this.handleDomainSelection.bind(this);
        this.authenticate = this.authenticate.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    componentDidMount() {
        this.setState({ "provisionalAuth": this.props.isProvisionalAuth ? this.props.isProvisionalAuth : false });
        tenantService.getSpmStatus()
            .then(response => {
                if (response.status === 200 && response.data) {
                    this.setState({ allowedDomains: response.data.payload.allowedDomains, spmConnected: response.data.payload.connected });
                    this.props.updateWebsocketStatus(response.data.payload.connected);
                }
                else {
                    var errorMessage = response.status + ": " + response.statusText;
                    this.setState({ returnError: errorMessage, spmConnected: false });
                }
            });

        if (this.props.callback) {
            this.props.callback(this.state.authMode);
        }
    }

    handleUserInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var partialState = {};
        partialState[name] = value;
        this.setState(partialState);
    }

    handleDomainSelection(event) {
        const target = event.target;
        const value = target.value;

        this.setState({ domain: value });
    }

    isSPMAuthDisabled() {
        if (this.state.authMode === AuthenticationModeEnum.SPM && !this.state.spmConnected) {
            return true;
        }

        return false;
    }

    getUsernameType() {
        return this.state.authMode === AuthenticationModeEnum.SPM ? "text" : "email";
    }

    getUsernameLabel() {
        switch (this.state.authMode) {
            case AuthenticationModeEnum.SPM:
                return "SPM user name";
            case AuthenticationModeEnum.SynergyTrak:
                return "SynergyTrak user name";
            case AuthenticationModeEnum.Web:
                return "Email address";
            default:
                return "Email address";
        }
    }

    getPasswordLabel() {
        switch (this.state.authMode) {
            case AuthenticationModeEnum.SPM:
                return "SPM password";
            case AuthenticationModeEnum.SynergyTrak:
                return "SynergyTrak password";
            case AuthenticationModeEnum.Web:
                return "Password";
            default:
                return "Password";
        }
    }

    authenticate() {
        this.setState({ returnError: '', isLoading: true });

        var obj = {
            userName: this.state.emailAddress,
            password: this.state.password,
            isProvisionalAuth: this.state.provisionalAuth,
            domain: this.state.domain
        };
        authService.login(this.state.authMode, obj)
            .then(response => {
                let result = response.data;
                if (result.success) {

                    switch (result.status) {
                        case UserAuthenticationResultEnum.Success:
                            //Set the login mode used on the context
                            this.props.updateLoginMode(this.state.authMode);
                            
                            if (this.state.provisionalAuth === true) {
                                // Only runs if it is a provisional authentication
                                if (this.props.afterLogin) {
                                    this.props.afterLogin(result.user);
                                }
                            }
                            else {
                                if (this.props.history) {
                                    this.props.startSignalRConnection();

                                    if (result.user !== undefined && result.user.defaultHomeScreen !== null) {
                                        return this.props.history.push(result.user.defaultHomeScreen);
                                    }

                                    //At this time, Orgadmin Users do not have a default home screen via the role.
                                    //If no user home screen is provided for an org admin, then we may have data issues.
                                    return this.props.history.push('/loaners');
                                }
                            }
                            break;
                        default:
                            this.setState({ returnError: result.message });
                    };
                }
                else {
                    var errorMessage = response.status + ": " + response.statusText;
                    this.setState({ returnError: errorMessage });
                }
            })
            .finally(() => this.setState({ isLoading: false }));
    }

    keyPress(event) {
        // if enter key authenticate user
        if (event.which === 13) {
            this.authenticate();
        }
    }

    render() {
        const { t, i18n } = this.props;
        const shouldForgetLogin = !this.props.remember;
        return (
            <div>
                {this.state.returnError !== "" ?
                    <Row>
                        <Col md={4}>

                            <Alert variant="danger mt-2">
                                {t('Error')}! - {this.state.returnError}
                            </Alert>
                        </Col>
                    </Row> : null}
                <Row>
                    <Col md={4}>
                        <FormGroup controlId="userType" className="mt-2">
                            <ButtonGroup toggle>
                                {this.authButtons.map((btn, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        type="radio"
                                        variant={this.state.authMode === btn.value ? "primary" : "secondary"}
                                        name="radio"
                                        value={btn.value}
                                        checked={this.state.authMode === btn.value}
                                        onChange={(e) => this.setState({ authMode: btn.value }, () => {
                                            if (this.props.callback) {
                                                this.props.callback(btn.value);
                                            }
                                        })}>
                                        {t(btn.name)}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </FormGroup>
                        {
                            this.isSPMAuthDisabled() && (
                                <Alert variant="warning">
                                    {t('SPM connection is not available')}
                                </Alert>
                            )
                        }
                        <FormGroup controlId="emailAddress">
                            <FormLabel>{t(this.getUsernameLabel())}</FormLabel>
                            <FormControl
                                autoComplete={shouldForgetLogin ? "off" : "on"}
                                readOnly={shouldForgetLogin ? true : null}
                                type={this.getUsernameType()}
                                name="emailAddress"
                                autoFocus
                                onFocus={function (e) {
                                    var val = e.target.value;
                                    e.target.value = '';
                                    e.target.value = val;
                                    if (shouldForgetLogin) {
                                        e.target.removeAttribute('readonly');
                                    }
                                }}
                                //disabled={this.isSPMAuthDisabled()}
                                value={this.state.emailAddress}
                                onChange={this.handleUserInput}
                                placeholder={t("Enter your user name")} />
                        </FormGroup>
                        <FormGroup controlId="password">
                            <FormLabel>{t(this.getPasswordLabel())}</FormLabel>
                            <FormControl
                                autoComplete={shouldForgetLogin ? "off" : "on"}
                                readOnly={shouldForgetLogin ? true : null}
                                type="password"
                                name="password"
                                disabled={this.isSPMAuthDisabled()}
                                value={this.state.password}
                                onChange={this.handleUserInput}
                                onKeyPress={this.keyPress}
                                onFocus={(e) => {
                                    if (shouldForgetLogin) {
                                        e.target.removeAttribute('readonly');
                                    }}
                                }
                                placeholder={t("Enter your password")} />
                        </FormGroup>
                        {
                            this.state.authMode === AuthenticationModeEnum.SPM && (
                                <FormGroup controlId="domain">
                                    <FormLabel>{t('Domain')}</FormLabel>
                                    <select className="form-control"
                                        disabled={this.isSPMAuthDisabled()}
                                        name="domainSelect"
                                        id="domainSelect"
                                        value={this.state.domain}
                                        required onChange={this.handleDomainSelection}> {
                                            this.state.allowedDomains && (this.state.allowedDomains.map((item, i) => {
                                                return (
                                                    <option key={i} value={item}>{item}</option>
                                                );
                                            }))
                                        }
                                        <option value=''>{t('(none)')}</option>
                                    </select>
                                </FormGroup>)
                        }
                        <LoadingButton
                            disabled={this.isSPMAuthDisabled()}
                            loading={this.state.isLoading}
                            bsStyle="primary float-right"
                            onClick={this.authenticate}
                            onKeyPress={this.keyPress}
                        >
                            {t('Log In')}
                        </LoadingButton>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(connect(null, dispatch => bindActionCreators({ ...signalrActionCreator, ...applicationContextActionCreator }, dispatch))(Login));
