import { createSelector } from 'reselect';

const getSavedSearchTerms = state => state.loanerAppSettings.caseSearch;

const getPatientDataSettings = state => state.loanerAppSettings.patientData;

export const selectPatientInitialsSetting = createSelector(
    getPatientDataSettings,
    (settings) => settings);

export const selectSavedSearchTerms = createSelector(
    getSavedSearchTerms,
    (terms) => terms);