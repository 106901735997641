import React from 'react';
import { Form } from 'react-bootstrap';

const Selectbox = React.memo((props) => {

    const formHandler = props.formHandler;
    const error = formHandler.errors[props.name];
    const touched = formHandler.touched[props.name];

    const onChange = props.onChange ? props.onChange
        : (e) => formHandler.setFieldValue(props.name, props.numeric ? parseInt(e.target.value) : e.target.value, true)

    return (
        <>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control as="select"
                className={props.className}
                placeholder={props.placeholder}
                value={formHandler.values[props.name]}                
                onChange={onChange}
                onKeyPress={props.onKeyPress}
                onBlur={formHandler.handleBlur}
                name={props.name}
                isInvalid={touched && error}>
                {
                    props.options.map((opt, idx) => {
                        return <option key={idx} value={opt.id}>{opt.text}</option>
                    })
                }
            </Form.Control>
            <small className={error && props.helpText ? "d-none" : "form-text text-muted"}>{props.helpText}</small>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </>
    );
})

export default Selectbox;