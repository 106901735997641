//Package imports
import React, { Component } from 'react';
import { Button, Form, Modal, Table, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalActionCreator } from '../../store/actions/modalActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Trans, withTranslation } from 'react-i18next';

//App imports
import enums from "../../utilities/enums";
import CaseSearch from '../../components/loaner/CaseSearch';
import loanerOrderService from '../../services/loanerOrderService';
import SearchResults from '../../components/loaner/CaseOrderSearchResults';
import { byCaseStartTime, byTrayName } from '../../utilities/sorts'
import LoadingButton from '../buttons/LoadingButton';

class TagLoanersModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            caseOrder: props.model,
            deliveredTrays: props.model.loaners.filter(l => l.status >= enums.loanerTrayStatusEnum.delivered.value),
            taggedTrays: [],
            searchText: '',
            selectedCase: null,
            searchResults: [],
            showOnlyVendorCases: true,
            barcodesForSelectedCase: [],
            isLoading:false
        }

        this.onTrayTagged = this.onTrayTagged.bind(this);
        this.searchHandleEnterKey = this.searchHandleEnterKey.bind(this);
        this.updateSearchText = this.updateSearchText.bind(this);
        this.displayResults = this.displayResults.bind(this);
        this.hasTaggedTrays = this.hasTaggedTrays.bind(this);
        this.tagForCaseAndClose = this.tagForCaseAndClose.bind(this);
        this.renderResults = this.renderResults.bind(this);
        this.renderTagTrays = this.renderTagTrays.bind(this);
        this.selectCase = this.selectCase.bind(this);
        this.handleShowCheckbox = this.handleShowCheckbox.bind(this);
        this.wasPrevTaggedForSelectedCase = this.wasPrevTaggedForSelectedCase.bind(this);
        this.getBarcodesForOrder = this.getBarcodesForOrder.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
        this.queryServer = this.queryServer.bind(this);
    }
    
    onTrayTagged(id) {
        var partialState = [...this.state.taggedTrays];
        if (partialState.includes(id)) {
            partialState = partialState.filter((item) => { return item !== id });
        } else {
            partialState.push(id);
        }
        this.setState({ taggedTrays: partialState });
    }

    async updateSearchText(event) {
        const target = event.target;
        this.setState({ searchText: target.value });
    }

    async searchHandleEnterKey(event) {
        if (event.key === "Enter" && this.state.searchText.length > 0) {
            await this.searchMasterLoanerList();
        }
    }

    hasTaggedTrays() {
        return this.state.taggedTrays.length > 0 && this.state.selectedCase !== null && this.state.selectedCase.id > 0;
    }

    tagForCaseAndClose() {
        const payload = {
            caseId: this.state.selectedCase.id,
            vendorId: this.state.caseOrder.vendorId,
            vendorRepId: this.state.caseOrder.vendorRepId,
            vendorName: this.state.caseOrder.vendorName,
            vendorRepName: this.state.caseOrder.vendorRepName,
            userId: 1,
            taggedLoanerIds: this.state.taggedTrays
        };
        this.setState({isLoading:true});
        loanerOrderService.tagLoanersForCase(payload)
            .then(response => {
                this.setState({isLoading:false});
                this.props.closeModal();
            });
    }

    handleShowCheckbox(event) {
        this.setState({ showOnlyVendorCases: event.target.checked });
    }

    getBarcodesForOrder() {
        loanerOrderService.getBarcodesForOrder({ caseId: this.state.selectedCase.id, vendorId: this.state.caseOrder.vendorId })
            .then(response => {
                this.setState({ barcodesForSelectedCase: response.data.payload });
            });
    }

    // TODO: pass params to case search control and to have the query in one place
    queryServer(params) {
        loanerOrderService.caseSearch(params)
            .then(response => {
                if (response.data.success) {
                    const payload = response.data.payload;
                    const cases = payload.cases;
                    this.displayResults(cases);
                }
                this.setState({ allowedToSearchForPi: response.data.payload.allowedToSearchForPi });

            });
    }

    wasPrevTaggedForSelectedCase(barcode) {
        return this.state.barcodesForSelectedCase !== undefined && this.state.barcodesForSelectedCase.some(bc => bc === barcode);
    }

    selectCase(caseSelected) {
        this.setState({ selectedCase: caseSelected }, () => this.getBarcodesForOrder());
    }

    displayResults(results) {
        if (results.length === 0) {
            this.setState({ selectedCase: null, searchResults: results, taggedTrays: [] });
        } else {
            this.setState({ searchResults: results });
        }
    }

    renderResults() {
        const { t } = this.props;
        if (this.state.searchResults) {
            return [
                (
                    <Card>
                        <Card.Header className="d-flex align-items-center light-blue">
                            <div className="mr-auto header"><Trans>Select case</Trans></div>
                            <div className="card-btn-group form-inline">
                                <Form.Check
                                    label={t("Show only cases that match vendor")}
                                    onChange={this.handleShowCheckbox}
                                    className="card-button-group-item"
                                    name="showOnlyVendorCases"
                                    id="showOnlyVendorCases" checked={this.state.showOnlyVendorCases} />
                            </div>
                        </Card.Header>
                        <Card.Body className="card-body-table">
                            <SearchResults asCaseWithOrder results={this.state.searchResults.sort(byCaseStartTime)}
                                caseNumber physician caseStartTime procedure vendor traysExpected radioSelect
                                pageResults 
                                showOnlyVendorCases={this.state.showOnlyVendorCases}
                                selectedCase={this.state.selectedCase} onCaseSelected={this.selectCase}
                                onlyVendor={this.state.caseOrder.vendorId}
                                />
                        </Card.Body>
                    </Card>)
            ];
        }
        return null;
    }

    renderTooltip(barcode) {
        if (this.wasPrevTaggedForSelectedCase(barcode)) {
            return [
                (
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 100 }}
                        style={{ maxWidth: "100%" }}
                        overlay={<Tooltip id="button-tooltip" className={"modal-tooltip"} >
                            <span><Trans>This loaner has already been dropped off for this case.</Trans></span>
                        </Tooltip>}>
                        <FontAwesomeIcon size="md" variant="primary" icon={faInfoCircle} />
                    </OverlayTrigger>
                )
            ];
        }
        return null;
    }

    renderTagTrays() {
        if (this.state.searchResults.length > 0 && this.state.selectedCase !== null && this.state.selectedCase !== undefined) {
            return [
                <Card>
                    <Card.Header>
                        <Trans>Select loaners</Trans>
                    </Card.Header>
                    <Table striped condensed hover responsive>
                        <thead>
                            <tr>
                                <th><Trans>Loaner name</Trans></th>
                                <th><Trans>Barcode</Trans></th>
                                <th className="table-fit center-contents"><Trans>Select</Trans></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.deliveredTrays.sort(byTrayName).map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.trayName}</td>
                                        <td>{item.barcode}</td>
                                        <td className="table-fit center-contents">
                                            {
                                                this.wasPrevTaggedForSelectedCase(item.barcode)
                                                    ? this.renderTooltip(item.barcode)
                                                    : <Form.Check id={`tray-${i}`} onChange={() => this.onTrayTagged(item.id)} />
                                            }
                                        </td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Card>
            ];
        }

        return null;
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal.Header centered="true">
                    <Modal.Title><Trans>Drop Off for Another Case</Trans></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CaseSearch
                        callback={this.displayResults}
                        showSiteMultiSelect={true}
                        defaultSelectedSiteIds={[this.state.caseOrder.siteId]}
                        defaultVendorId={this.state.caseOrder.vendorId}
                        showOnlyVendorCases={this.state.showOnlyVendorCases}
                        casesToExclude={[this.state.caseOrder.caseId]}
                        allowedToSearchForPi={this.state.allowedToSearchForPi}
                        queryServer={this.queryServer} />
                    {this.renderResults()}
                    {this.renderTagTrays()}
                </Modal.Body>
                <Modal.Footer>
                    <div className="card-btn-group">
                        <Button
                            bsStyle="default"
                            disabled={this.state.isLoading}
                            variant={
                                this.hasTaggedTrays() ? 'warning' : 'secondary'
                            }
                            onClick={() => {
                                this.props.closeModal();
                            }}
                        >
                            {this.hasTaggedTrays() ? t('Cancel') : t('Close')}
                        </Button>
                        <LoadingButton
                            style={{ margin: "2.5px" }}
                            variant="primary"
                            loading={this.state.isLoading}
                            disabled={!this.hasTaggedTrays()} onClick={() => { this.tagForCaseAndClose() }}><Trans>Save & Close</Trans></LoadingButton>
                    </div>
                </Modal.Footer>
            </div >
        );
    }
}

export default withTranslation()(connect(null, dispatch => bindActionCreators({ ...modalActionCreator }, dispatch))(TagLoanersModal));