import axios from 'axios';
import ConfigService from '../services/configService';
import { createAndDispatchErrorMessage } from '../store/actions/applicationMessagingActions';
import { dispatchHideSpinner, dispatchShowSpinner } from '../store/actions/spinnerActions';
import { formatResponseError } from './responseDto';

var showSpinner = true;
export function toggleSpinner(value) {
    showSpinner = value;
}
export default function configureAxios(dispatch) {
    // Set axios defaults

    axios.defaults.headers.common = {
        // Adding to make sure that REST comes back 
        // with 401 instead of redirect to login page 
        "X-Requested-With": "XMLHttpRequest",
        // Disable cache for IE11, otherwise GET requests will take a long time to grab updated data
        "Pragma": "no-cache"
    };

    axios.defaults.baseURL = ConfigService.getApiURL();

    axios.interceptors.request.use(request => {
        if (showSpinner) {
            dispatchShowSpinner(dispatch);
        }
        return request;
    });

    const delay = process.env.REACT_APP_API_DELAY;
    if (process.env.NODE_ENV === 'development' && delay) {
        const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        axios.interceptors.response.use(async (res) => {
            await sleep(parseInt(delay));
            return res;
        });
    }

    axios.interceptors.response.use(
        (response) => {
            dispatchHideSpinner(dispatch);

            if (response.data !== null) {
                if (response.data.success !== null && !response.data.success) {
                    let errorMessage = formatResponseError(response.data);
                    createAndDispatchErrorMessage(dispatch, "Service Error", errorMessage);
                }
            }
            else {
                console.log("Response expected to be wrapped: " + response);
            }

        return response;
        }, error => {
            dispatchHideSpinner(dispatch);

            // wrapped this in a check for existing of response
            // because if the server is down, there is no response
            // and we weren't catching that network error - as the
            // check for response was throwing an exception. - DH
            if (error.response) {
                // handling common response related errors
                if (401 === error.response.status) {
                    // Not Authorized
                    window.location = "/auth/login";
                }
                else if (403 === error.response.status) {
                    // Forbidden 
                    window.location = "/auth/unauthorized";
                }
                else if (404 === error.response.status) {
                    // TODO: do we want this to be a modal instead?
                    window.location = "/auth/notfound";
                }
                else if (409 === error.response.status) {
                    window.location = "/auth/invalidtenant";
                }
                else if (503 === error.response.status) {
                    window.location = "/auth/spmnotconnected";
                }
                else {
                    let errorMsg = null;
                    let errortitle = "Service Error";
                    // BadRequest with ResultDto 
                    if ([400, 500].includes(error.response.status)  && error.response.data !== null) {
                        const resultDto = error.response.data;
                        if (resultDto.message) {
                            errorMsg = resultDto.message;
                            errortitle = resultDto.title;
                        }
                        //check both message property and errors collection
                        if (resultDto.errors && resultDto.errors.length > 0) {
                            errorMsg = [errorMsg, ...resultDto.errors].join("\n");
                        }
                    }
                    if (!errorMsg) {
                        errorMsg = error.response.statusText + "(" + error.response.status + ")";
                    }


                    createAndDispatchErrorMessage(dispatch, errortitle, errorMsg);
                    return Promise.resolve({ data: { success: false, message: "Fake false result for the next handler in the chain. This should stay invisible." } });
            }

            return error;
        }
    });

}