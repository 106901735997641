import { createSelector } from 'reselect';

const getCurrentUser = state =>
	state.applicationContext.applicationContext.user;

export const selectPermissionChecker = createSelector(
	getCurrentUser,
	user =>
		function (claim, value) {
			const userClaims = user?.permissions.filter(x => x.permission === claim);

			for (const userClaim of userClaims ?? []) {
				if (value.length === userClaim.permissionValue.length) {
					let match = true;
					for (let i = 0; i < value.length; i++) {
						if (value[i] != '0') {
							match = match && value[i] == userClaim.permissionValue[i];
						}
					}

					if (match) {
						return true;
					}
				}
			}

			return false;
		}
);