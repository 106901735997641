import * as actionTypes from './actionTypes';
import loanerOrderService from '../../services/loanerOrderService';

export function requestLoanerOrderHomeAction() {
    return {
        type: actionTypes.REQUEST_LOANER_ORDER_HOME
    }; 
};

export function receiveLoanerOrderHomeAction(data) {
    return { type: actionTypes.RECEIVE_LOANER_ORDER_HOME, payload: data}
};

export function requestCaseOrderAction(id) {
    return {type: actionTypes.REQUEST_CASE_ORDER, id: id}
};

export function receiveCaseOrderAction(data) {
    return { type: actionTypes.RECEIVE_CASE_ORDER, payload: data};
};

export function updateCaseAction(data) {
    return { type: actionTypes.UPDATE_CASE, payload: data };
};

export function updateDeliverersAction(data) {
    return { type: actionTypes.UPDATE_DELIVERERS, payload: data };
}

export const loanerOrderActionCreator = {

    updateDeliverers: newDeliverer => async (dispatch) => {
        dispatch(updateDeliverersAction(newDeliverer));
    },

    requestLoanerOrderHome: () => async (dispatch) => {
        dispatch(requestLoanerOrderHomeAction());
        //TODO: Enable this once we get SignalR running so we aren't refreshing the store with certain page views
        //if (getState().cases.length > 0) {
        //    // Don't issue a duplicate request (we already have or are loading the requested data)
        //    return;
        //}
        await loanerOrderService.getLoanerHomeData()
            .then(response => {
                let data = null;
                if (response.data.success) {
                    data = { ...response.data.payload };
                    dispatch(receiveLoanerOrderHomeAction(data));
                }
            });
    },

    requestCaseOrder: caseOrderId => async (dispatch, getState) => {
        if(caseOrderId) {
            dispatch(requestCaseOrderAction(caseOrderId));
            await loanerOrderService.getCaseOrder(caseOrderId)
                .then(response => {
                    if(response.data.success) {
                        let data = { ...response.data.payload };
                        dispatch(receiveCaseOrderAction(data));
                    }
                });
        }
    }
};