const qualityEventStatusEnum = {
    "notReported" : {
        "name" : "Not Reported", 
        "value" : 0
    },
    "reported" : {
        "name" : "Reported", 
        "value" : 10
    },
    "underReview" : {
        "name" : "Under Review", 
        "value" : 20
    },
    "rejected" : {
        "name" : "Rejected", 
        "value" : 30
    },
    "closed" : {
        "name" : "Closed", 
        "value" : 40
    }
};

export default qualityEventStatusEnum;               