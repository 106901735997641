import { createSelector } from 'reselect';
import clientSettingsEnum  from '../../utilities/enums/clientSettingsEnum';

const getSettings = state => { 
    return state.applicationContext.applicationContext.client;
}

export const selectClientSettings = createSelector(
    getSettings, 
    (settings) => {
        return settings.settings.map(s => ({...s}));
    }
);

export const selectDefaultLoanerSites = createSelector(
    selectClientSettings,
    (clientSettings) => {
        const sites = [];  
        var setting = clientSettings.find(s => s.settingType === clientSettingsEnum.filteredLoanerSites.value);

        if(setting !== undefined) {
            sites.push(...setting.value);
        }
        return sites; 
    }
);

export const selectSiteSettings = createSelector(
    getSettings,
    (settings) => {
        return settings.siteSettings.map(s => ({...s}));
    }
);