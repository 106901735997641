const eventUtilities = {
    verifyNumericInput(event) {
        var isNumeric = /[0-9]/.test(event.key);
        var isDelete = event.key == 'Delete';
        var isBackspace = event.key == 'Backspace';

        // Allow backspace or numeric digits
        if (!(isNumeric || isDelete || isBackspace)) {
            event.preventDefault();
        }
    }
}

export default eventUtilities;