import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';

class OneSource extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { apiToken: "" };

        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    componentWillMount() {
        //let params = queryString.parse(this.props.location.search);

        const apiToken = queryString.parse(this.props.location.search).api_token;
        const returnLoc = window.location.href;
        if (!apiToken) {
            window.location.assign('http://os-customer.fluidstaging.com/api/auth/token?api_key=Ant4SuNJSopemEtKPQP1FBHUlgsGtU6plPw5tJ7yyjhUaseIz9&secret_key=U3crVqL7zTORdS8Y3EfrjTysUyGB7I0kcZBpbikZTQ7BQTKVCM&redirect=' + returnLoc);
        }
        else {
            this.setState({ apiToken: apiToken });
        }


        //let apiAddress = window.location.protocol + "//" + window.location.host + "/api/organization/" +
    }

    copyToClipboard() {
        const el = document.createElement('textarea');
        el.value = this.state.apiToken;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    render() {
        return (
            <div style={{ marginTop: `50px` }}>
                {
                    this.state.apiToken ? 
                        <>
                            <Row>
                                <Col sm={6} md={4} smoffset={3} mdOffset={4}>
                                    <h4>Your oneSource api token is: </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} md={4} smoffset={3} mdOffset={4}>
                                    <p>{this.state.apiToken}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} md={4} smoffset={3} mdOffset={4}>
                                    <input type="submit" value="Copy key to clipboard" className="btn btn-success float-right" onClick={this.copyToClipboard} />
                                </Col>
                            </Row>
                        </>
                    : <div></div>
                }   
            </div>
            );
        }
    }
    
export default OneSource;