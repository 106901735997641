import React, { Component } from 'react';
import { Modal,Button, Glyphicon } from 'react-bootstrap';

class ErrorModal extends Component {

    constructor(props, context) {
         super(props, context);
         this.state = {};
    }

    hideModal = () => {
        this.props.closeModal();
    }

    render() {
        return (
            <>
                <Modal.Header>{this.props.title}</Modal.Header>
                <Modal.Body>
                    {this.props.message}
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" onClick={this.hideModal}>Close</Button>
                </Modal.Footer>
            </>
        )
    }
}

export default ErrorModal

//class ErrorModal extends Component {
//    constructor(props, context) {
//        super(props, context);

//        this.state = {};
//    }
//    render() {
//        return (
//            <Modal bsSize="small" show={this.state.showDeleteApiKeyModal}>
//                <Modal.Header>
//                    <Modal.Title>Confirm ApiKey Deletion</Modal.Title>

//                </Modal.Header>
//                <Modal.Body>
//                    Are you sure you want to delete the api key: {this.state.editApiKey.clientName}?
//                    </Modal.Body>
//                <Modal.Footer>
//                    <Button onClick={this.hideDeleteApiKeyModal} variant="default" type="button">Cancel</Button>
//                    <Button onClick={this.deleteApiKey} variant="danger" type="button">Delete</Button>
//                </Modal.Footer>
//            </Modal>
//        );
//    }
//}


//export default ErrorModal;