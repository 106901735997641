import React from 'react';
import { Alert, Col, ListGroup } from 'react-bootstrap';

import useLoanerTrayQuery from "./useLoanerTrayQuery";
import TraySearchResults from './TraySearchResults';
import { useTranslation } from 'react-i18next';

const TraySearchAdapter = ({
    vendorId,
    nameText,
    productCodeText,
    dropOffNewTray,
    loading,
    promptForDeliverer,
    shouldSearch,
    setLoading
}) => {
    const { t } = useTranslation();
    const { searchResults, performedSearch, message } = useLoanerTrayQuery(vendorId, nameText, productCodeText, shouldSearch);
    var results = ((nameText && nameText.trim().length > 0) || (productCodeText && productCodeText.trim().length > 0))
        ? searchResults
        : [];
    return [

        <div>
            <Alert variant="secondary"><Alert.Heading>{t("Existing loaners")}</Alert.Heading>{t("If you find the loaner tray in the list below, please click its drop-off button.")}</Alert>
            <ListGroup variant="flush" as={Col} className="tray-selectors">
                {
                    <TraySearchResults
                        dropOffNewTray={dropOffNewTray}
                        promptForDeliverer={promptForDeliverer}
                        searchResults={results}
                        loading={loading}
                        setLoading={setLoading}
                        message={message} />
                }
            </ListGroup>
        </div>
    ];
};

export default TraySearchAdapter;