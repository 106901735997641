import React, { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import PropTypes from 'prop-types';

// The paginator is a paging control which handles dynamically constructing the set of page numbers and notifying other 
// components when the selected slice has changed. See the CaseOrderSearchResults for an example of how it's used. 
// Consumers must provide the data (an array) and a dataSliceChanged func that will receive the slice
// of data to show to the user (see the PropTypes definitions below). They can also optionally specify the maxResultsPerPage
// and the maxPageItems
const Paginator = ({ data, maxPerPage, maxPageItems, dataSliceChanged, defaultPage, pageSelectedChanged }) => {

    const [selectedPage, setSelectedPage] = useState(defaultPage ? defaultPage : 1);

    //break me off a piece of that dataset
    useEffect(() => {
        const slice = data.slice((selectedPage - 1) * maxPerPage, selectedPage * maxPerPage);
        //when filter criteria shortens the data set below the page number we've persisted, decrement the page number and try again
        if (slice.length === 0 && data.length > 0) {
            setSelectedPage(Math.max(selectedPage - 1, 1));
        }
        else {
            dataSliceChanged(slice);
        }

        if(defaultPage && pageSelectedChanged && selectedPage !== defaultPage) {
            pageSelectedChanged(selectedPage);
        }
    }, [selectedPage, data, dataSliceChanged, maxPerPage]); //runs when these are updated

    let items = [];
    const totalPages = (Math.ceil(data.length / maxPerPage));

    if (totalPages <= 1) {
        //don't show the paging control at all if there are 0 or 1 pages
        return null;
    }

    //cap the number of paging buttons to what's passed in
    const maxToRender = Math.min(totalPages, maxPageItems);
    //what's our first numeric button?
    const startingPageNum = Math.floor((selectedPage - 1) / maxToRender) * maxToRender + 1;

    if (maxToRender < totalPages) {
        const disabled = (maxToRender < totalPages) && startingPageNum === 1;
        const firstKey = -1;
        const prevKey = 0;
        items.push((<Pagination.First key={firstKey} disabled={disabled} onClick={() => setSelectedPage(1)} />));
        items.push((<Pagination.Prev key={prevKey} disabled={disabled} onClick={() => setSelectedPage(startingPageNum - 1)} />));
    }

    for (let i = 0; i < maxToRender && (i + startingPageNum) <= totalPages; i++) {
        const pageNum = i + startingPageNum;
        items.push((
            <Pagination.Item key={pageNum} active={pageNum === selectedPage}
                onClick={() => setSelectedPage(pageNum)}>
                {pageNum}
            </Pagination.Item>));
    }

    if (maxToRender < totalPages) {
        const disabled = (startingPageNum + maxToRender - 1) >= totalPages;
        items.push((<Pagination.Next key={startingPageNum + maxToRender} disabled={disabled} onClick={() => setSelectedPage(startingPageNum + maxToRender)} />));
        items.push((<Pagination.Last key={startingPageNum + maxToRender + 1} disabled={disabled} onClick={() => setSelectedPage(totalPages)} />));
    }

    return (
        <div>
            <Pagination className="mb-0 mt-0 float-right">
                {items}
            </Pagination>
        </div>
    );
};

//expected property types (will show an error in console if you screw it up)
Paginator.propTypes = {
    data: PropTypes.array.isRequired,
    maxPerPage: PropTypes.number,
    maxPageItems: PropTypes.number,
    dataSliceChanged: PropTypes.func.isRequired,
    defaultPage: PropTypes.number,
    pageSelectedChanged : PropTypes.func
};

Paginator.defaultProps = {
    maxPageItems: 25,    //if not specified, cap the number of paging buttons at 25
    maxPerPage: 10      //if not specified, cap the results per page at 10
}

export default Paginator;   //Come with me if you want to page.
