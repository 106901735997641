import {ButtonGroup, Form, ToggleButton} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export function SearchAreaSwitcher(props) {
    const {t} = useTranslation();

    return <>
        <Form.Label>{t("Search Area")}</Form.Label>
        <br/>
        <ButtonGroup toggle>
            <ToggleButton
                size="sm"
                type="checkbox"
                variant={props.searchProducts ? "primary" : "outline-primary"}
                checked={props.searchProducts}
                value="1"
                onChange={props.onProductsButtonChange}>
                {t('Products')}
            </ToggleButton>
            <ToggleButton
                size="sm"
                type="checkbox"
                variant={props.searchInstruments ? "primary" : "outline-primary"}
                checked={props.searchInstruments}
                value="1"
                onChange={props.onInstrumentsButtonChange}>
                {t('Instruments')}
            </ToggleButton>
        </ButtonGroup>
    </>;
}