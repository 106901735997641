    const enums = {
        loanerOrderStatusEnum: {"expected":{"name":"Expected","value":0},"partiallyDelivered":{"name":"Partially delivered","value":1},"delivered":{"name":"Delivered","value":2},"partiallyReadyForPickup":{"name":"Partially ready for pickup","value":3},"readyForPickup":{"name":"Ready for pickup","value":4},"partiallyReturned":{"name":"Partially returned","value":5},"returned":{"name":"Returned","value":6}},
        loanerTrayStatusEnum: { "expected": { "name": "Expected", "value": 0 }, "pendingDelivery": { "name": "Pending delivery", "value": 1 }, "delivered": { "name": "Delivered", "value": 2 }, "readyForPickup": { "name": "Ready for pickup", "value": 4 }, "returned": { "name": "Returned", "value": 6 } },
        stateEnum: { "active": { "name": "Active", "value": 100 }, "suspended": { "name": "Suspended", "value": 150 }, "deleted": { "name": "Deleted", "value": 0 }},
    };

    export default enums;



