import React, { Component } from 'react'
import { connect } from 'react-redux'
import classes from './Spinner.module.css';

export const spinnerRoot = (props) => {

    return props.displaySpinner && <Spinner/>
}

export const Spinner = () => {
    return (
        <div className={classes.backdrop}>
            <div className={classes.spinnerContainer} >
                <div className={classes.loader}>Loading...</div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
  displaySpinner: state.spinner.visible
});

export default connect(mapStateToProps)(spinnerRoot);