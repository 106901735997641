import React from 'react';
import {Row, Col, Table} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faPen} from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../../services/authService';
import { withTranslation } from 'react-i18next';
import RolesInformation from './RolesInformation';

class Documentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: []
        };
    }

    componentDidMount() {
        AuthService.getAllRoles()
            .then(response => {
                if(response.data.success)
                {
                    const roles = response.data.payload;
                    this.setState({ roles: roles });
                }
            });
    }

    render() {
        let {t } = this.props;
        return (
            <div>
                <Row>
                    <Col>
                        <h2>{t("Documentation")}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("Web Security Roles")}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RolesInformation roles={this.state.roles} />
                    </Col>
                </Row>
            </div>
            
        );
    }
}

export default withTranslation()(Documentation); 