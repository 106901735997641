import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Tab extends Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    }

    render() {
        const { onClick, props: { activeTab, label, disabled } } = this;

        let className = 'nav-item nav-link';

        if (activeTab === label) {
            className += ' active';
        } else if (!disabled) {
            className += ' pointer-mouseover';
        }

        if(disabled) {
            className += ' disabled';
        }
        
        return (
            <a
                className={className}
                onClick={!disabled ? onClick : null}
                disabled={disabled}
            >
                {label}
            </a>
        );
    }
}