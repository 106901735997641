const requirementTypeEnum = {
    "other" : {
        "name" : "Other", 
        "value" : 0
    },
    "caseCart" : {
        "name" : "Case cart", 
        "value" : 1
    },
    "loanerOrder" : {
        "name" : "Loaner Order", 
        "value" : 2
    },
    "product" : {
        "name" : "Product", 
        "value" : 3
    },
    "sterilizerLoad" : {
        "name" : "Sterilizer Load", 
        "value" : 4
    },
    "task" : {
        "name" : "Task", 
        "value" : 5
    },
    "washerLoad" : {
        "name" : "Washer Load", 
        "value" : 6
    }
};

export default requirementTypeEnum;