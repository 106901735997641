import React, { useState, useEffect } from 'react';
import { Card, Button, Form, FormControl } from 'react-bootstrap';
import orgService from '../../../services/organizationService';
import { useTranslation } from 'react-i18next';

const DelivererSelector = ({ selectedSites, selectedVendor, siteVendorDeliverers, handleNewDeliverer, handleDelivererChange, selectedDeliverer }) => {

    const { t } = useTranslation();
    const [isCreatingNewDeliverer, setCreatingNewDeliverer] = useState(false);
    const [newDelivererName, setNewDelivererName] = useState('');
    const [saveUpdateDeliverer, setSaveUpdateDeliverer] = useState(false);

    useEffect(() => {
        if (saveUpdateDeliverer) {

            var deliverer = {
                siteIds: selectedSites,
                vendorId: selectedVendor,
                name: newDelivererName
            };

            const existingDeliverer = siteVendorDeliverers.find(d => d.toLowerCase() === newDelivererName.toLowerCase());
            //Would like to break up this ternary into a single if-check because we no longer use updateDelivererLastUsed in this selector.
            //That said, I don't know if this will run updateDelivererLastUsed under any condition now so its probably fine to leave here.
            const serviceCall = existingDeliverer === undefined ? orgService.saveDeliverer(deliverer) : orgService.updateDelivererLastUsed(deliverer);

            serviceCall.then(response => {
                if (response.data.success) {
                    handleNewDeliverer(newDelivererName);
                    setSaveUpdateDeliverer(false);
                    setCreatingNewDeliverer(false);
                    setNewDelivererName('');
                }
            });
        }
    }, [saveUpdateDeliverer]);

    const handleKeyDown = (event) => {

        if (event.keyCode === 27) {
            cancelNewDeliverer();
        }

        if (event.keyCode === 13) {
            createNewDeliverer();
        }
    }

    const createNewDeliverer = () => {
        newDelivererName.trim();
        if (newDelivererName.length > 0) {
            setSaveUpdateDeliverer(true);
        }
    }

    const cancelNewDeliverer = () => {
        setCreatingNewDeliverer(false);
        setNewDelivererName('');
    }

    let selector = (
        <div className="card-btn-group form-inline">
            <FormControl style={{ width: "216px" }} as="select" name="selectedDeliverer" id="selectedDeliverer" value={selectedDeliverer} onChange={handleDelivererChange}>
                <option value=''>Select...</option>
                {siteVendorDeliverers.map((n, i) => <option key={i} value={n}>{n}</option>)}
            </FormControl>
            <Button style={{ margin: "0 0 0 .5rem" }} size="md" onClick={() => setCreatingNewDeliverer(true)}>{t('New')}</Button>
        </div>
    );

    if (isCreatingNewDeliverer) {
        selector = (
            <div className="card-btn-group form-inline">
                <Form.Control
                    type="input"
                    autoFocus
                    maxlength="50" //per schema
                    placeholder={t("Enter person's name")}
                    value={newDelivererName}
                    onChange={(event) => setNewDelivererName(event.target.value)}
                    onKeyDown={(event) => handleKeyDown(event)}
                    name="newDelivererName" />
                <div style={{margin: "0 0 0 .5rem"}}>
                    <Button variant="warning" size="md" onClick={() => cancelNewDeliverer()}>{t('Cancel')}</Button>
                    <Button variant="primary" size="md" disabled={newDelivererName.trim().length === 0} onClick={() => createNewDeliverer()}>{t('Create')}</Button>
                </div>
            </div>
        );
    }

    return selector;
}

export default DelivererSelector;