import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Form, Modal, Table, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { modalActionCreator } from '../../store/actions/modalActions';
import { claims, claimValues } from '../../utilities/authConstants.js';

import Login from '../../components/auth/Login';
import LoanerCheckInContext from '../../components/loaner/LoanerCheckInContext';
import LoanerOrderService from '../../services/loanerOrderService';
import enums from "../../utilities/enums";
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import { selectPermissionChecker } from '../../store/selectors/permissionSelectors';
import { byTrayNameThenByDeliveryDate } from '../../utilities/sorts';
import { dateTimeFormat } from "../../utilities/dateTime";
import LoadingButton from '../buttons/LoadingButton';

const arrived = (loaner) => loaner.status !== enums.loanerTrayStatusEnum.expected.value;
const onNotVerified = (loaner) => (loaner.delivConfirmed === null || loaner.delivConfirmed === '');

class VerifyTraysModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            caseOrder: props.model.caseOrder,
            deliveredSets: props.model.caseOrder.loaners.filter(arrived),
            verifiedTrays: [],
            user: null,
            confirmedDate: null,
            userAuthError: false,
            invalidUser: false,
            loading: {
                key: undefined,
            },
        };
        this.onVerifyChecked = this.onVerifyChecked.bind(this);
        this.handleVerify = this.handleVerify.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentWillMount() {
        if (this.props.initialSelection) {
            this.onVerifyChecked(this.props.initialSelection);
        }
        if (this.props.hasPermission(claims.canVerifyLoanerDropOff, claimValues.isTrue)) {
            this.setState({ user: this.props.applicationContext.user, userAuthError: false });
        }
        else {
            this.setState({ user: null, userAuthError: false })
        }
    }

    onVerifyChecked(id) {
        var partialState = [...this.state.verifiedTrays];
        if (partialState.includes(id)) {
            partialState = partialState.filter((item) => { return item !== id });
        } else {
            partialState.push(id);
        }
        this.setState({ verifiedTrays: partialState });
    }

    handleLogin(credentials) {
        // need to check permissions for canVerifyLoanerTray (or something like that)
        if (this.hasPermission(credentials.permissions, claims.canVerifyLoanerDropOff, claimValues.isTrue)) {
            this.setState({ user: credentials, userAuthError: false, invalidUser: false });
        } else {
            // set error
            this.setState({ user: null, userAuthError: true, invalidUser: true });
        }
    }

    hasPermission(permissions, claim, value) {
        let userClaim = permissions.find(x => x.permission === claim);
        if (userClaim !== undefined) {

            if (value.length !== userClaim.permissionValue.length) {
                return false;
            }

            var match = true;
            for (let i = 0; i < value.length; i++) {
                if (value[i] != '0') {
                    match = match && value[i] == userClaim.permissionValue[i];
                }
            }

            return match;
        }

        return false;
    }

    handleVerify() {
        var trays = this.state.verifiedTrays;
        if (trays.length > 0) {
            this.setState({ confirmedDate: moment() }, () => {
                var request = {
                    LoanerTraysToVerify: trays,
                    ConfirmedDate: this.state.confirmedDate,
                    ConfirmedById: this.state.user.id,
                    ConfirmedByName: this.state.user.fullName
                };

                LoanerOrderService.verifyTrays(request)
                    .then(response => {
                        if (response.data.success) {
                            var updatedTrays = [];
                            response.data.payload.map((tray) => {
                                updatedTrays[tray.id] = tray;
                            });
                            var partialState = [...this.state.deliveredSets];
                            partialState.forEach((loaner) => {
                                if (updatedTrays[loaner.id]) {
                                    loaner.delivered = updatedTrays[loaner.id].delivered;
                                    loaner.delivConfirmed = this.state.confirmedDate;
                                    loaner.delivConfirmedBy = this.state.user.fullName;
                                    loaner.statusName = updatedTrays[loaner.id].statusName;
                                    loaner.status = updatedTrays[loaner.id].status;
                                }
                            });

                            this.setState({ deliveredSets: partialState, loading: { key: '' } }, () => {
                                this.props.beforeCloseModal(this.state.deliveredSets);
                                this.props.closeModal();
                            });
                        }
                    });
            });
        } else {
            this.props.closeModal();
        }
    }

    render() {

        const { t } = this.props;
        return (
            <div>
                <Modal.Header centered="true">
                    <Modal.Title> {t('Verify Loaners for Case')} {this.state.caseNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {this.state.user
                            ? <div>
                                <h5 className="float-right">{t('Verifying as')} {this.state.user.fullName}</h5>
                                <LoanerCheckInContext caseOrder={this.props.model.caseOrder} />
                                <Table striped condensed hover responsive>
                                    <thead>
                                        <tr>
                                            <th>{t('Loaner name')}</th>
                                            <th>{t('Status')}</th>
                                            <th>{t('Delivery date')}</th>
                                            <th className="table-fit center-contents">{t('# of Files')}</th>
                                            <th>{t('Barcode')}</th>
                                            <th className="table-fit center-contents">{t('Verify')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.deliveredSets.sort(byTrayNameThenByDeliveryDate).map((item, i) => {
                                            let verified = !(item.delivConfirmed === null || item.delivConfirmed === '');
                                            let checked = verified;

                                            if (!verified && this.state.verifiedTrays) {
                                                checked = this.state.verifiedTrays.includes(item.id);
                                            }

                                            return (
                                                <tr key={i}>
                                                    <td>{item.trayName}</td>
                                                    <td>{item.statusName}</td>
                                                    <td>
                                                        {item.delivered
                                                            ? moment
                                                                .utc(item.delivered)
                                                                .local()
                                                                .format(dateTimeFormat)
                                                            : null}
                                                    </td>
                                                    <td className="table-fit center-contents">
                                                        {item.fileCount}
                                                    </td>
                                                    <td>{item.barcode}</td>
                                                    <td className="table-fit center-contents">
                                                        <Form.Check
                                                            id={`tray-${i}`}
                                                            className="check-box-lil-bigger"
                                                            disabled={verified}
                                                            onChange={() => this.onVerifyChecked(item.id)}
                                                            checked={checked}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                            : (
                                <div>
                                    <Alert variant={this.state.invalidUser ? "danger" : "info"}>
                                        {t(
                                            "You do not have permissions to verify the delivery of loaners."
                                        )}
                                        <br />
                                        {t(
                                            "Please log in with a user that has the appropriate permissions."
                                        )}
                                    </Alert>
                                    <Login
                                        afterLogin={this.handleLogin}
                                        isProvisionalAuth={true}
                                    ></Login>
                                </div>
                            )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="full-width">
                        <div className="float-left card-btn-group">
                            {this.state.user ? (
                                <LoadingButton
                                    className="btn"
                                    disabled={Boolean(this.state.loading.key)}
                                    loading={this.state.loading.key === "save-close"}
                                    onClick={() => {
                                        this.setState({ loading: { key: "save-close" } });
                                        this.handleVerify();
                                    }}
                                >
                                    {t("Save & Close")}
                                </LoadingButton>
                            ) : null}
                            <Button
                                variant="warning"
                                disabled={Boolean(this.state.loading.key)}
                                onClick={this.props.closeModal}>
                                {t("Cancel")}
                            </Button>
                        </div>
                        <div className="float-right">
                            {this.state.user ? (
                                <LoadingButton
                                    disabled={Boolean(this.state.loading.key)}
                                    loading={this.state.loading.key === "verify-all"}
                                    onClick={() => {
                                        this.setState({ loading: { key: "verify-all" } });
                                        var unverifiedSets = this.state.deliveredSets
                                            .filter(onNotVerified)
                                            .map((item) => {
                                                return item.id;
                                            });
                                        this.setState({ verifiedTrays: unverifiedSets }, () => {
                                            this.handleVerify();
                                        });
                                    }}
                                >
                                    {t("Verify all")}
                                </LoadingButton>
                            ) : null}
                        </div>
                    </div>
                </Modal.Footer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    applicationContext: state.applicationContext.applicationContext,
    hasPermission: selectPermissionChecker(state)
});

export default withTranslation()(connect(
    mapStateToProps, dispatch => bindActionCreators({ ...modalActionCreator, ...applicationContextActionCreator }, dispatch))(VerifyTraysModal));