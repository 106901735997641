import React, { useEffect, useContext, memo, useState, useRef } from 'react';
import { produce } from 'immer';
import { useDispatch } from 'react-redux';
import { Button, Card, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { applicationMessagingActionCreator } from '../../store/actions/applicationMessagingActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faBarcode, faFrown, faSmile } from '@fortawesome/free-solid-svg-icons';
import { DataTable, DataColumn } from '../../controls/dataTable/DataTable';
import TooltipOverlay from '../../controls/tooltip-overlay/TooltipOverlay';
import CaseContext from '../../controls/caseContext/CaseContext';
import { OrContext } from './OrContext';
import orgSettingsEnum from '../../utilities/enums/orgSettingsEnum';

import './AssignedItems.css';
import '../../styles/spm.css';
import WarningModal from '../../controls/modals/WarningModal';

const epicBarcodeSeparator = setting => setting.key === orgSettingsEnum.epicBarcodeSeparator.value;

const ItemUsed = memo((props) => {
    if (props.item.used) {
        return <FontAwesomeIcon icon={faCheckCircle} onClick={() => props.onClick(props.item, props.index)} className="text-primary" style={{ fontSize: '1.5rem', cursor: 'pointer' }} />;
    }
    else {
        return <FontAwesomeIcon icon={faCheckCircle} onClick={() => props.onClick(props.item, props.index)} style={{ color: "lightgray", fontSize: '1.5rem', cursor: 'pointer' }} />;
    }
})

const GenericItemUsed = memo((props) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        setCount(props.item.usedQuantity);
    }, []);

    const handleCountChange = (event) => {

        let newValue = parseInt(event.target.value);
        newValue = !isNaN(newValue) ? newValue : 0;
        newValue = newValue > 999 ? 999 : newValue;
        newValue = newValue < 0 ? 0 : newValue;

        setCount(newValue);
    }

    const handleBlur = (event) => {
        if (props.onUsedQuantityChange) {
            props.onUsedQuantityChange(props.index, count);
        }
    }

    if (props.item) {
        return (
            <div>
                <input
                    className="input-assingeditems"
                    size="sm"
                    type="number"
                    id="used-quantity"
                    name="used-quantity"
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => handleCountChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    value={count}
                    aria-describedby="used quantity input" />
            </div>
        );
    }

    return null;
});

const ScanMessageModal = () => {
    const { t } = useTranslation();
    const orContext = useContext(OrContext);
    const scanMessage = orContext.scanMessage;
    const [title, setTitle] = useState(scanMessage?.title ?? '');
    const [message, setMessage] = useState(scanMessage?.message ?? '');
    const [substitutionMessages, setSubstitutionMessages] = useState(scanMessage?.substitutionMessages ?? '');

    useEffect(() => {
        if (orContext.scanMessage) {
            setTitle(scanMessage?.title);
            setMessage(scanMessage?.message);
            setSubstitutionMessages(scanMessage?.substitutionMessages);
        }
    }, [orContext.scanMessage]);

    let productInfo = scanMessage?.productInfo ?? null;
    const history = useHistory();

    const handleORRequestCreation = (event) => {
        event.preventDefault();
        orContext.dismissScanMessage();

        if (productInfo !== null) {
            productInfo.isProduct = true;
        }

        history.push('/or/case/request/create', { mode: "create", data: productInfo });
    }

    return (
        title == "Sterile Warning" ?
            <WarningModal show={!!scanMessage}
                title={title}
                message={message}
                substitutionMessages={substitutionMessages}
                children={productInfo && (<a href='#' onClick={(event) => handleORRequestCreation(event)}>{t('Create OR Request')}</a>)}
                actionButtons={<Button variant="secondary" onClick={() => orContext.dismissScanMessage()}>{t('OK')}</Button>}
            />
            :
            <Modal bsSize="small" show={scanMessage}>
                <Modal.Header>
                    <Modal.Title>
                        {t(title)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(message)}</p>
                    {productInfo && <p>{t('Product:')} {productInfo.name}</p>}
                    {productInfo && (<a href='#' onClick={(event) => handleORRequestCreation(event)}>{t('Create OR Request')}</a>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => orContext.dismissScanMessage()}>{t('Dismiss')}</Button>
                </Modal.Footer>
            </Modal>
    );
}

const AssignedItems = (props) => {
    const { t } = useTranslation();
    const orContext = useContext(OrContext);

    const dispatch = useDispatch();
    const history = useHistory();

    const successNotification = (title, message) => dispatch(applicationMessagingActionCreator.createSuccessNotification(title, message));
    const errorMessage = (title, message) => dispatch(applicationMessagingActionCreator.createErrorMessage(title, message));

    const inputRef = useRef(null);
    const [barcode, setBarcode] = useState('');

    useEffect(() => {
        if (props.match.params.id === undefined) {
            orContext.refreshCase();
        }

        orContext.getOrgSettings();
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        // get the id of the case (this is the foreign id - not spm)
        const caseId = props.match.params.id;
        if (caseId) {
            orContext.getOrCase(caseId);
        }
    }, [props.match.params.id]);

    useEffect(() => {
        const barcodeSeparator = getEpicBarcodeSeparator();

        if (barcode.length > 2) {
            if (barcodeSeparator) {
                const barcodeSeparatorChar = barcodeSeparator.charAt(0);
                if (barcode.length > 3) {
                    if (barcode.charAt(0) === barcodeSeparatorChar && barcode.charAt(barcode.length - 1) === barcodeSeparatorChar) {
                        addScannedItem();
                    }
                }
            }
        }
    }, [barcode]);

    const getEpicBarcodeSeparator = () => {
        const settings = orContext?.orgSettings;
        if (settings) {
            const separator = settings.find(epicBarcodeSeparator)?.value;
            return separator;
        }

        return "";
    }

    const handleUsedQuantityChange = (index, newQuantity) => {
        const currentAssignedItem = orContext.caseData.assignedItems[index];

        if (currentAssignedItem.usedQuantity !== newQuantity) {
            const nextCaseData = produce(orContext.caseData, draftState => {
                const draftAssignedItem = draftState.assignedItems[index];

                draftAssignedItem.usedQuantity = newQuantity;
            });

            orContext.saveUtilization(nextCaseData, false);
        }
    }

    const handleChangeUsedStatus = (item, index) => {

        const nextCaseData = produce(orContext.caseData, draftState => {
            const draftAssignedItem = draftState.assignedItems[index];
            draftAssignedItem.used = draftAssignedItem.used ? false : true;
        })

        orContext.saveUtilization(nextCaseData, false);
    }

    const addScannedItem = () => {
        if (barcode.length !== 0) {
            let tmpBarcode = barcode;
            let barcodeSeparator = getEpicBarcodeSeparator();

            if (barcodeSeparator) {
                const barcodeSeparatorChar = barcodeSeparator.charAt(0);
                if (tmpBarcode.charAt(0) === barcodeSeparatorChar) {
                    tmpBarcode = tmpBarcode.substring(1);
                }

                if (tmpBarcode.charAt(tmpBarcode.length - 1) === barcodeSeparatorChar) {
                    tmpBarcode = tmpBarcode.substring(0, tmpBarcode.length - 1);
                }
            }

            const addItem = {
                barCode: tmpBarcode,
                caseId: orContext.caseData.id,
                siteId: orContext.caseData.siteId,
                spmUserId: orContext.user.spmId
            };

            orContext.addItemToCase(addItem);
            setBarcode('');
        }
    }

    const handleBarcodeInput = (event) => {
        setBarcode(event.target.value,);
    }

    const checkForEnter = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();

            if (barcode.length > 0) {
                addScannedItem();
            }
        }
    }

    return (
        <>
            <ScanMessageModal />
            <CaseContext caseInfo={orContext.caseData} className="context-container-or-portal bg-light" />
            <Card className="table-AssingedItems">
                <Card.Header className="d-flex align-items-center">
                    <div className="mr-auto card-button-group-item">{t('Assigned Items')}</div>
                    <div className="card-btn-group form-inline">
                        <div>
                            <input
                                ref={inputRef}
                                className="form-control input-sm"
                                size="sm"
                                placeholder={t('Scan items to case')}
                                type="text"
                                id="barcode"
                                name="barcode"
                                value={barcode}
                                onKeyPress={(e) => checkForEnter(e)}
                                onChange={handleBarcodeInput}
                                aria-describedby="barcodeHelp" />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body className="card-body-table">
                    <DataTable striped hover responsive size="sm" dataSource={orContext.caseData.assignedItems}>
                        <DataColumn dataField="name"
                            sortable
                            formattedColumn={(row, i) => {
                                return <span>{row["name"]}-{row["indexNumber"]}</span>
                            }}
                        >{t('Name')}</DataColumn>
                        <DataColumn
                            dataField="location" >
                            {t('Location')}
                        </DataColumn>
                        <DataColumn
                            dataField="used"
                            formattedColumn={(row, i) => {
                                if (row.indexNumber === '000') {
                                    return <GenericItemUsed item={row} index={i} onUsedQuantityChange={(index, count) => handleUsedQuantityChange(index, count)} />;
                                } else {
                                    return <ItemUsed item={row} index={i} onClick={(row, i) => handleChangeUsedStatus(row, i)} />
                                }
                            }}
                            className="datatable-info-assingedItems">{t('Used')}</DataColumn>
                        <DataColumn
                            dataField=""
                            formattedColumn={(row, i) => {
                                return (
                                    <TooltipOverlay tooltip={t('Create quality event')}>
                                        <Button className="show-hand" onClick={() => history.push('/or/case/quality/create', { data: row })}>
                                            <FontAwesomeIcon icon={faFrown} size="lg" />
                                            &nbsp;/&nbsp;
                                            <FontAwesomeIcon icon={faSmile} size="lg" />
                                        </Button>
                                    </TooltipOverlay>)

                            }}
                            className="table-fit cellCenterContent"></DataColumn>
                    </DataTable>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header className="d-flex align-items-center">
                    <div className="mr-auto card-button-group-item">{t('Case Carts')}</div>
                    <div className="card-btn-group form-inline">

                    </div>
                </Card.Header>
                <Card.Body className="card-body-table">
                    <DataTable striped hover responsive size="sm" dataSource={orContext.caseData.caseCarts}>
                        <DataColumn
                            dataField="name"
                            formattedColumn={(row, i) => {
                                return <span>{row["name"]}-{row["indexNumber"]}</span>
                            }}
                            sortable>{t('Name')}</DataColumn>
                        <DataColumn dataField="location" headerColClass="cellCenterContent table-fit text-nowrap" className="cellCenterContent table-fit">{t('Current Location')}</DataColumn>
                    </DataTable>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header className="d-flex align-items-center">
                    <div className="mr-auto card-button-group-item">{t('Still Needed')}</div>
                    <div className="card-btn-group form-inline">

                    </div>
                </Card.Header>
                <Card.Body className="card-body-table">
                    <DataTable striped hover responsive size="sm" dataSource={orContext.caseData.stillNeeded}>
                        <DataColumn dataField="name" sortable>{t('Name')}</DataColumn>
                        <DataColumn dataField="qtyNeeded" headerColClass="cellCenterContent table-fit text-nowrap" className="cellCenterContent table-fit">{t('Qty needed')}</DataColumn>
                    </DataTable>
                </Card.Body>
            </Card>
        </>
    )
}
export default AssignedItems;