import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const ErrorPage = (props) => {
    let errorTitle = "The application has encountered an unknown error";
    let errorMessage = "Please contact your Administrator";

    if (props.history?.location?.state !== undefined) {
        const data = props.history.location.state;

        if (data.errorTitle !== undefined) {
            errorTitle = data.errorTitle;
        }
        if (data.errorMessage !== undefined) {
            errorMessage = data.errorMessage;
        }
    }

    const { t } = useTranslation();
    return (
        <div class="container-fluid">
            <div class="py-5">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-2 text-center">
                            <p><FontAwesomeIcon icon={faExclamationTriangle} className="fa-5x text-warning" /></p>
                        </div>
                        <div class="col-md-10">
                            <h3>{t(errorTitle)}</h3>
                            <p>{t(errorMessage)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;