import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { loanerOrderActionCreator } from '../../../store/actions/loanerOrderActions';
import useCaseQuery from './useCaseQuery';
import CaseSearch from '../../../components/loaner/CaseSearch';
import CaseOrderSearchResults from '../../../components/loaner/CaseOrderSearchResults';
import { byCaseStartTime } from '../../../utilities/sorts'
// sorts


const CaseList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loanerOrderActionCreator.requestLoanerOrderHome());
    }, []);

    const initialQuery = {
        from: new Date().toISOString(),
        to: new Date().toISOString(),
        caseNumber: null,
        sites: [],
        physicianId: null,
        isCaseOrdersOnly: true
    };
    const [queryParameters, setParams] = useState(initialQuery);

    const { casesData, sites, defaultSites, message, allowedToSearchForPi } = useCaseQuery(queryParameters);

    const queryServer = (params) => { setParams(params); };

    return (
        <div>
            <CaseSearch
                callback={(r) => {}}
                queryServer={queryServer}
                defaultSelectedSiteIds={defaultSites}
                defaultVendorId={0}
                showOnlyVendorCases={false}
                showSiteMultiSelect
                showPastCases
                allowedToSearchForPi={allowedToSearchForPi}
                casesToExclude={[]} />
            {message ? <div class="font-error">{message}</div> : null}
            <Card>
                <Card.Header>{t("Cases with loaners")}</Card.Header>
                <CaseOrderSearchResults
                    results={casesData ? (casesData.sort(byCaseStartTime)) : []}
                    pageResults 
                    maxResultsPerPage={15}
                    saveSettingsKey='caseSearch'
                    sites={sites}
                    site caseNumber physician caseStartTime procedure vendor traysExpected traysDelivered verified linkTo />
            </Card>
            
        </div>
    );
};

export default CaseList;