import * as actionTypes from '../actions/actionTypes';

const initialState = {    
    loanerHome: {
        selectedRecentHours: 2,
        selectedNextDays: 2
    },
    caseSearch: {},
    patientData: {
        showInitials: false
    }
}; 

const changeLoanerAppSetting = (state, payload) => {
    const newState = { ...state };
    const component = { ...newState[payload.component] };
    component[payload.setting] = payload.value;
    newState[payload.component] = component;

    return newState;
}

const getPatientDataSetting = (state, payload) => {
    const currentState = { ...state };
    currentState.patientData.showInitials = payload.settings.showPatientInitials;
    return currentState;
}

const loanerAppSettingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.CHANGE_LOANER_APP_SETTING: return changeLoanerAppSetting(state, action.payload);
        case actionTypes.PATIENT_DATA_SETTING: return getPatientDataSetting(state, action.payload);
        default: return state;
    }
}; 

export default loanerAppSettingsReducer;