import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { dateFormat } from "../../../utilities/dateTime"
import LoanerOrderService from '../../../services/loanerOrderService';

const TaggedOrdersMenu = ({ loaner }) => {
    const [orderInfo, setOrderInfo] = useState([]);
    let history = useHistory();
    useEffect(() => {
        const getTaggedOrders = async () => {
            const response = await LoanerOrderService.getTaggedCaseOrders({
                caseOrderIdToExclude: loaner.caseOrderId,
                masterLoanerSetId: loaner.masterLoanerSetId,
                uniqueId: loaner.barcode
            });
            
            setOrderInfo(response.data.payload);
        }

        if (loaner.isTagged) {
            getTaggedOrders();
        }

    }, [loaner]);

    const navigateToCaseOrder = caseOrderId => {
        history.push('/loaners/delivertrays/' + caseOrderId );
    }

    const formatResult = result => {
        return (
            <div className="d-flex d-inline ml-auto">
                {`Dropped off for case ${result.caseNumber} ${moment(result.whenStart).format(dateFormat)} ${result.physicianLastName}`}&nbsp;
                <FontAwesomeIcon icon={faChevronCircleRight} className="gi-1-5x text-primary float-right show-hand" onClick={() => navigateToCaseOrder(result.caseOrderId)}/>
            </div>);
    }

    return (loaner && loaner.isTagged
        ? (<Dropdown className="ml-auto linked-cases" drop="left">
            <Dropdown.Toggle as="span" className="noCaret nav-link d-inline">
                <FontAwesomeIcon icon={faShareAlt} className="pointer-mouseover" size="sm" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    orderInfo.map((result, id) => {
                        return (
                            <Dropdown.Item key={id}>
                                {formatResult(result)}
                            </Dropdown.Item>
                        );
                    })
                }
                </Dropdown.Menu>
        </Dropdown>)
        : null);
}
export default TaggedOrdersMenu;