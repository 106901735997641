import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Fingerprint from 'fingerprintjs2';

import { modalActionCreator } from '../store/actions/modalActions';

class Home extends Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.openAlertModal = this.openAlertModal.bind(this);
        this.state = {clientFingerprint: null, fingerprintComponents: null}
    }

    componentWillMount() {
        const localThis = this;
        var d1 = new Date()
        var fp = Fingerprint.get(function (components) {
            var murmur = Fingerprint.x64hash128(components.map(function (pair) { return pair.value }).join(), 31)
            var d2 = new Date()
            var time = d2 - d1
            var details = ""

            for (var index in components) {
                var obj = components[index]
                var line = obj.key + " = " + String(obj.value).substr(0, 100);
                details += line + "\n";
            }

            localThis.setState({ clientFingerprint: murmur })
            localThis.setState({ fingerprintComponents: details })
        })
    }

    openAlertModal(event) {
        this.props.showModal({
            open: true,
            title: 'Alert Modal',
            message: 'TEST',
            closeModal: this.closeModal
        }, 'alert');
    }

    closeModal(event) {
        this.props.hideModal();
    }

    render() {
        return (
            <div>
                <br/><br/><br/>
                <Link to={'/auth/epic?data=qCsvXBkRod0qyzp9gemUKzAv0LMPo2RrnVh4rnbr0o4jzgtwywQnYRVg0sFHi%2Fg6'}>Epic Auth Link</Link><br /><br />
                <Link to={'/auth/epic?data=KKqrgx%2FOaBJggx6vij6AEg%3D%3DmpJFRR16gTz7OF0CcGbgpdnTLgSub9YhXJT1m8Z6fB7oXVDYfXJ5tdai73fQ3XZj'} >Epic Auth New Link</Link><br /><br />
            </div>

        );
    }
}

export default connect(null, dispatch => bindActionCreators(modalActionCreator, dispatch))(Home);
