import * as actionTypes from './actionTypes';

export const modalActionCreator = {
    hideModal: () => dispatch => {
        dispatch({
            type: actionTypes.HIDE_MODAL
        });
    },
    showModal: (modalProps, modalType) => dispatch => {
        dispatch({
            type: actionTypes.SHOW_MODAL,
            modalProps,
            modalType
        });
    }
}