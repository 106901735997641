import axios from "axios";

const LicenseService = {
    async saveLicense(license) {
        const response = axios.post(`/api/license/`, license);
        return response; 
    },
    async deleteLicense(licenseId) {
        const response = axios.post(`api/license/delete/${licenseId}`);
        return response;
    }
}

export default LicenseService; 