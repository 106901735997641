import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const InvalidTenant = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <div class="py-5">
            <div class="container py-5">
                <div class="row">
                    <div class="col-md-2 text-center">
                        <p><FontAwesomeIcon icon={faExclamationTriangle} className="fa-5x text-danger" /><br />{t('Status Code')}: 409</p>
                    </div>
                    <div class="col-md-10">
                        <h3><b>409</b> {t('Invalid Tenant')}</h3>
                        <p>{t('The tenant is invalid.')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvalidTenant;