import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import LoadingButton from '../buttons/LoadingButton';

class CreateApiKeyModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedApiKeyType: null,
            working: false
        };
        this.handleInput = this.handleInput.bind(this);
    }

    handleInput(event) {
        const apiKeyType = parseInt(event.target.value);
        this.setState({ selectedApiKeyType: apiKeyType });
    }

    render() {
        return (
            <div>
                <Modal.Header>
                    <h4>{this.props.title}</h4>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col lg={12}>
                                <div className="linebreaks">
                                    {this.props.message}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                {
                                    this.props.model.map((item, i) => {
                                        return (
                                            <div key={i} className="form-group">
                                                <input type="radio" name="selectedApiKeyType" id={"radio" + i}
                                                    value={item.id}
                                                    id={`${item.condensedName}-${i}`}
                                                    name={`${item.condensedName}-${i}`}
                                                    checked={item.id === this.state.selectedApiKeyType}
                                                    onChange={this.handleInput} /> {item.name}
                                            </div>
                                        );
                                    })
                                }
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" variant="warning" onClick={() => this.props.closeModal()}>Cancel</Button>
                    <LoadingButton bsStyle="success" variant="primary" loading={this.state.working} onClick={() => {
                        this.setState({ working: true });
                        this.props.saveModel(this.state.selectedApiKeyType);
                    }}>Add</LoadingButton>
                </Modal.Footer>
            </div>

        );
    }
}

export default CreateApiKeyModal;