import axios from "axios";

const ConfigService = {
    getApiURL() {
        // Let's create the API root like this for now. 
        return window.location.protocol + "//" + window.location.host;
    },

    async resetMasterLoanerData() {
        const response = axios.post(`/api/masterloanerdata/reset`);
        return response;
    }
}

export default ConfigService; 