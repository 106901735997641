import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'react-bootstrap';
import queryString from 'query-string';
import authService from '../../services/authService';
import ErrorPage from '../errors/ErrorPage';

const UserAuthenticationResultEnum = {
    Success: 1,
    Failed: 2,
    LockedOut: 3,
    NotAllowed: 4,
    TwoFactorRequired: 5,
    PendingAuthorization: 6,
    SpmAccountLinkRequired: 7
};

class EpicAuthentication extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { userContext: [], epicAuthResult: [], urlParam: "", returnError: ""}
    };

    componentDidMount() {
       let data = queryString.parse(this.props.location.search).data;
        this.setState({ data: data });
        
        authService.epicLogin(data)
            .then(response => {
                let result = response.data;

                if (result.success) {
                    this.setState({ epicAuthResult: result });

                    switch (result.status) {
                        case UserAuthenticationResultEnum.SpmAccountLinkRequired:
                            return this.props.history.push('/auth/epic/spm?forwardUrl=/or/case/' + result.data + '&data=' + this.state.data);
                        case UserAuthenticationResultEnum.Success:
                            const currentUser = result.user;                            
                            currentUser.isEpicUser = true;
                            
                            this.props.updateCurrentUser(currentUser);
                            return this.props.history.push('/or/case/' + result.data);
                        case UserAuthenticationResultEnum.Failed:
                            return this.props.history.push('/error/', { errorTitle: result.message, errorMessage: "Please contact your Administrator" });

                        default:
                            this.setState({ returnError: result.statusMessage });
                    };
                }
                else {
                    var errorMessage = response.status + ": " + response.statusText;
                    this.setState({ returnError: errorMessage });
                }
            });
    }
        
    render() {    
        return (
            <Row>
                <Col md={4}>
                {
                    this.state.returnError !== "" ?
                        <Alert variant="danger">
                            <h4>Oh snap! You got an error!</h4>
                            <p>
                                {this.state.returnError}
                            </p>
                        </Alert> : null
                }
                </Col>
            </Row>
        );
    }
}

export default compose(withRouter, connect(
    state => state.applicationContext, dispatch => bindActionCreators({ ...applicationContextActionCreator }, dispatch)))(EpicAuthentication);