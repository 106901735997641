import i18n from "i18next";
import Backend from 'i18next-chained-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Fetch from 'i18next-fetch-backend';
import { initReactI18next } from "react-i18next";
import localizationService from "./services/localizationService";

i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        backend: {
            backends: [
                LocalStorageBackend,  // primary
                Fetch               // fallback
            ],
            backendOptions: [{
                prefix: 'i18next_res_',
                expirationTime: 0, //7 * 24 * 60 * 60 * 1000, // one day
                store: window.localStorage
            }, {
                loadPath: '/api/localization/{{lng}}',
                addPath: '/api/localization/{{lng}}',
                //stringify: JSON.stringify,
            }]
        },
        saveMissing: true,
        keySeparator: false, // we do not use keys in form messages.welcome
        fallbackLng: 'en-US',
        interpolation: {
            escapeValue: false
        },
        parseMissingKeyHandler: function (key) {
            return "_" + key;
        },
        nsSeparator: false
    });

export default i18n;