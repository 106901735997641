//@ts-check
import React, { useRef, useEffect } from 'react';
import { useImmer } from 'use-immer';
import _ from "lodash";
import { promisify } from '../../utilities/promisify';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Col } from 'react-bootstrap';

const ScanItemToCaseModal = (props) => {
    const [barcode, setBarcode] = useImmer('');
    const { t } = useTranslation();

    const inputRef = useRef(null);

    const closeModal = props.closeModal;
    const saveModalPromise = promisify(props.saveModel);
    const barcodeSeparator = props.barcodeSeparator;
    
    useEffect(() => { 
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        if(barcode.length > 2) {
            if(barcodeSeparator) {
                const barcodeSeparatorChar = barcodeSeparator.charAt(0);
                if(barcode.length > 3) {
                    if(barcode.charAt(0) === barcodeSeparatorChar && barcode.charAt(barcode.length-1) === barcodeSeparatorChar) {
                        addItems();
                    }
                }
            }                        
        }
    }, [barcode]);
    
    const handleInput = (event) => {
        setBarcode(event.target.value,);
    }

    const checkForEnter = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();

            if(barcode.length > 0) {
                addItems();
            }
        }

        if(event.key === "Escape") {
            closeModal();
        }
    }

    const addItems = () => {
        if(barcode.length !== 0) {
            let tmpBarcode = barcode;

            if(barcodeSeparator) {
                const barcodeSeparatorChar = barcodeSeparator.charAt(0);
                if(tmpBarcode.charAt(0) === barcodeSeparatorChar) {
                    tmpBarcode = tmpBarcode.substring(1);
                }

                if(tmpBarcode.charAt(tmpBarcode.length-1) === barcodeSeparatorChar) {
                    tmpBarcode = tmpBarcode.substring(0, tmpBarcode.length-1);
                }
            }        

            saveModalPromise(tmpBarcode);
            setBarcode('');
        }
    }

    return (
        <div>
            <Modal.Header>
                <h4>{t('Add item(s) to case')}</h4>
            </Modal.Header>
            <Modal.Body>
                <Col>
                    <div className="form-group">
                        <label htmlFor="barcode">{t('Scan or type barcode')}</label>
                        <input ref={inputRef}
                            className="form-control"
                            type="text"
                            id="barcode"
                            name="barcode"
                            value={barcode}
                            onKeyPress={(e) => checkForEnter(e)}
                            onChange={handleInput}
                            aria-describedby="barcodeHelp" required />
                        <small id="barcodeHelp" className="form-text">{t('If typing in a barcode, press the [Enter] key afterwards!')}</small>
                    </div>
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={() => closeModal()}>{t('Close')}</Button>
                {/* <Button variant="success" onClick={() => addItems()}>{t('Add item')}</Button> */}
            </Modal.Footer>
        </div>

    );
}

export default ScanItemToCaseModal