import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { claimValues } from '../../../utilities/authConstants';
import { useTranslation } from 'react-i18next';

const PermissionDetail = props => {
    const p = props.permission; 
    const { t } = useTranslation();

    return (
        <div class="row">
            <div className="col-md-6">
                <b>{p.claim}</b>
                <br />
                {p.description}
            </div>
            <div className="col-md-4">
                {p.permissionValue === claimValues.isTrue && 
                    <h5><span class="badge badge-success">{t("Yes")}</span></h5>
                }
                {p.permissionValue === claimValues.isFalse && 
                    <h5><span class="badge badge-secondary">{t("No")}</span></h5>
                }

                <h5>
                    {p.permissionValue[0] == "1" && 
                        <span style={{ marginRight: "2.5px" }} className="badge badge-success">{t("Create")}</span>
                    }
                    {p.permissionValue[1] == "1" && 
                        <span style={{ marginRight: "2.5px" }} class="badge badge-primary">{t("Read")}</span>    
                    }
                    {p.permissionValue[2] == "1" && 
                        <span style={{ marginRight: "2.5px" }} class="badge badge-warning">{t("Update")}</span>  
                    }
                    {p.permissionValue[3] == "1" && 
                        <span style={{ marginRight: "2.5px" }} class="badge badge-danger">{t("Delete")}</span>  
                    }   
                </h5>  
            </div>
        </div>
    )
}

const RolesInformation = props => {
    const roles = props.roles;
    
    return (
        <div>
            {
                roles.map((item, i) => {     
                   return (
                        <Accordion key={item.id}>
                            <Card key={item.id}>
                                <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                                    {item.name}
                                </Accordion.Toggle>
                                <Card.Footer>
                                    {item.description}
                                </Card.Footer>                       
                                <Accordion.Collapse eventKey={item.id}>
                                    <Card.Body>
                                        <div>
                                            {
                                                item.permissions.map((perm, p) => {
                                                    return (
                                                    <PermissionDetail permission={perm} /> 
                                                    )
                                                })
                                            }
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>              
                    )

                })
            }
        </div>
    )
}


export default RolesInformation;