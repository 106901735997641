import React from 'react';
import { Form } from 'react-bootstrap';

const Textbox = React.memo((props) => {

    const inErrorState = props.formHandler.errors[props.name];

    return (
        <>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control
                type={props.type ? props.type : "text"}
                className={props.className}
                placeholder={props.placeholder}
                value={props.formHandler.values[props.name]}
                onChange={props.formHandler.handleChange}
                onKeyPress={props.onKeyPress}
                onBlur={props.formHandler.handleBlur}
                name={props.name}
                isInvalid={props.formHandler.touched[props.name] && props.formHandler.errors[props.name]}
            />
            <small className={inErrorState && props.helpText ? "d-none" : "form-text text-muted"}>{props.helpText}</small>
            <Form.Control.Feedback type="invalid">{props.formHandler.errors[props.name]}</Form.Control.Feedback>
        </>
    );
})

export default Textbox;