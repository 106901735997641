import * as actionTypes from './actionTypes';

export const orPortalActionCreator = {
    orRequestUpdated: updatedORRequest => async (dispatch) => {
        dispatch({
            type: actionTypes.OR_REQUEST_UPDATED,
            payload: updatedORRequest
        });
    },

    clearORRequestUpdates: () => async (dispatch) => {
        dispatch({
            type: actionTypes.CLEAR_OR_REQUEST_UPDATES
        });
    },
};