import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AlertList } from "react-bs-notifier";
import { bindActionCreators } from 'redux';
import { applicationMessagingActionCreator } from '../../store/actions/applicationMessagingActions';

class NotificationRoot extends Component {

    onAlertDismissed(alert) {
        this.props.dismissNotification(alert);
    }

    render () {
        return (
            <AlertList
                position={"top-right"}
                alerts={this.props.alerts}
                timeout={3000}
                dismissTitle="Dismis!"
                onDismiss={this.onAlertDismissed.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    alerts: state.applicationMessaging.notifications
})

export default connect(mapStateToProps, dispatch => bindActionCreators({...applicationMessagingActionCreator}, dispatch))(NotificationRoot)
