import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import signalRMiddleware from '../middlewares/signalRMiddleware';
import orCaseInfoReducer from './orCaseInfoReducer';
import modalReducer from './modalReducer';
import applicationContextReducer from './applicationContextReducer';
import applicationMessagingReducer from './applicationMessagingReducer';
import spinnerReducer from './spinnerReducer';
import realtimeMessageReducer from './realtimeMessageReducer';
import loanerMessageReducer from './loanerMessageReducer';
import loanerOrderReducer from './loanerOrderReducer';
import loanerAppSettingsReducer from './loanerAppSettingsReducer';
import orPortalMessageReducer from './orPortalMessageReducer';

import * as actionType from '../actions/actionTypes';

export default function configureStore(history, initialState) {
  const reducers = {
    orCaseInfo: orCaseInfoReducer,
    modal: modalReducer,
    applicationContext: applicationContextReducer,
    applicationMessaging: applicationMessagingReducer,
    spinner: spinnerReducer,
    realtimeMessages: realtimeMessageReducer,
    loanerMessage: loanerMessageReducer,
    loanerOrder: loanerOrderReducer,
    loanerAppSettings: loanerAppSettingsReducer,
    orPortal: orPortalMessageReducer,
  };

  const middleware = [
    thunk,
    routerMiddleware(history),
    signalRMiddleware,
  ];

  // In development, use the browser's Redux dev tools extension if installed.
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const appReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  const rootReducer = (state, action) => onLogoutWipeoutState(state, action, appReducer);

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}

//this idea is cribbed from here: https://medium.com/@asher.cassetto.cohen/resetting-redux-state-with-a-root-reducer-bonus-how-to-reset-state-selectively-e2a008d0de61
//when the user logs out, we want to wipe out the redux state (except for certain reducers)
export const onLogoutWipeoutState = (state, action, appReducer) => {
  if (action.type === actionType.USER_LOG_OUT) {
    const { applicationContext } = state;
    state = { applicationContext };
  }

  return appReducer(state, action);
}