/*
 
 One-stop shop for all your datetime needs!
 
 */

export const dateFormat = "YYYY-MM-DD";
export const dateDisplayFormat = "MM/DD/YYYY";
export const dateTimeFormat = "MM/DD/YYYY hh:mm a";
export const timeFormat = "hh:mm a";
export const threeMinutesInMilliseconds = 3 * 60 * 1000;
export const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
export const threeDaysInMilliseconds = 3 * oneDayInMilliseconds;

export const toMidnightPlusDays = (input, days) => {
    const today = new Date(input.getFullYear(), input.getMonth(), input.getDate());
    const midnight = new Date(today.getTime() +  oneDayInMilliseconds);
    return new Date(midnight.getTime() + (days * oneDayInMilliseconds));
};