//Package imports
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Modal, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import JSPM from 'jsprintmanager/';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

//App imports
import { applicationMessagingActionCreator } from '../../store/actions/applicationMessagingActions';
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import SiteMultiSelect from '../../components/loaner/DeliverTrays/SiteMultiSelect';
import SiteService from '../../services/siteService';
import OrganizationService from '../../services/organizationService';
import { claims, claimValues } from '../../utilities/authConstants';
import settings from "../../utilities/enums/clientSettingsEnum";
import { PrinterContext } from '../../contexts/PrinterContext';
import { selectPermissionChecker } from '../../store/selectors/permissionSelectors';

//A filter regularly used to evaluate or find the object corresponding to the setting type
const filteredLoanerSitesSetting = (setting) => setting.settingType === settings.filteredLoanerSites.value;
const selectedLabelPrinterSetting = (setting) => setting.settingType === settings.selectedLabelPrinter.value;

class ClientSettingsModal extends Component {

    static contextType = PrinterContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            sites: [],
            selectedSites: [],
            settings: [],
            printers: []
        }
        this.handleSitesChange = this.handleSitesChange.bind(this);
        this.getSelectedPrinter = this.getSelectedPrinter.bind(this);
    }

    componentDidMount() {

        if (this.context.jspmStatus === "Open") {
            JSPM.JSPrintManager.getPrinters().then(printersList => {
                const kvp = printersList.map((printer, index) => { return { value: index, label: printer } });
                this.setState({ printers: kvp });
            });
        }
        //Push a copy of the client's settings into a local settings 
        //collection for saving and comparison
        const settings = [];
        this.props.client.settings.map(s => settings.push({ ...s }));

        this.setState({ settings: settings });
        //Get all sites and update selected sites if the client has saved sites
        SiteService.getAllSites().then(response => {
            if (response.data.success) {
                if (response.data.payload != null) {
                    this.setState({ sites: [...response.data.payload] }, () => {

                        //Attempt to pre-select those sites from the clients settings
                        if (this.state.settings.some(filteredLoanerSitesSetting)) {
                            const setting = this.state.settings.find(filteredLoanerSitesSetting);
                            if (setting != null) {
                                this.handleSitesChange(setting.value.map(v => {
                                    return { value: v }
                                }));
                            }
                        }
                    });
                } else {
                    this.props.createErrorMessage(response.data.message, response.data.errors[0]);
                }
            }
        });
    }

    //TODO As this settings screen evolves this will have to change to handle multiple inputs by control name
    handleSitesChange(data) {
        this.setState({
            selectedSites: data ? data.map(d => d.value) : []
        }, () => {
            //Filter down to just the site setting and update its value with what the user selected to keep in sync for save
            let everythingBut = this.state.settings.filter(setting => setting.settingType !== settings.filteredLoanerSites.value);
            let justThisSetting = this.state.settings.find(filteredLoanerSitesSetting);
            justThisSetting.value = this.state.selectedSites;
            this.setState({ settings: [...everythingBut, justThisSetting] });
        });
    }

    save() {
        //The following lines running up to saveClientSettings() are in here as a temporary means to stringify just the
        //only integer setting we have now. When we have more than one setting this should be moved into the service and stringify
        //all integer settings values for transport.
        const settings = [];
        this.state.settings.map(s => settings.push({ ...s }));
        const setting = settings.find(filteredLoanerSitesSetting);
        setting.value = JSON.stringify(setting.value);

        OrganizationService.saveClientSettings(settings).then(response => {
            if (response.data.success) {
                if (response.data.payload != null) {
                    var client = { ...this.props.client };
                    client.settings = this.state.settings;
                    this.props.updateCurrentClient(client);

                    //Update JSPM's current printer if necessary
                    const selectedPrinter = this.state.settings.find(selectedLabelPrinterSetting).value;
                    if (this.context.currentLabelPrinter !== this.state.settings.find(selectedLabelPrinterSetting).value) {
                        this.context.changePrinter(selectedPrinter);
                    }
                    this.props.closeModal(this);
                } else {
                    this.props.createErrorMessage(response.data.message, response.data.errors[0]);
                }
            }
        });
    }

    isFormDisabled() {
        const canEditClientSettings = this.props.hasPermission(claims.crudClientSettings, claimValues.createReadUpdateDelete);
        if (canEditClientSettings) {
            return false;
        }
        return true;
    }

    handlePrinterChange(input) {
        let otherSettings = this.state.settings.filter(setting => setting.settingType !== settings.selectedLabelPrinter.value);
        let justThisSetting = this.state.settings.find(selectedLabelPrinterSetting);
        justThisSetting.value = input;
        this.setState({ settings: [...otherSettings, justThisSetting] });
    }

    getSelectedPrinter() {
        const selectedPrinter = this.state.settings.find(selectedLabelPrinterSetting);
        if (selectedPrinter && selectedPrinter !== "SystemDefault") {
            let printer = this.state.printers && this.state.printers.find(p => p.label === selectedPrinter.value);
            return printer;
        }
    }


    render() {
        const { t } = this.props;
        const { jspmStatus } = this.context;
        const styles = { container: base => ({ ...base, width: '435px' }) };
        return (
            <div>
                <Modal.Header>
                    <h3>{t("Browser Settings for ")}{this.props.client.name}</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <br />
                        <h5>{t('These settings are for this browser on this computer.')}</h5>
                        <br /><br />
                        <Form.Group>
                            <Form.Label>
                                {t("Choose the sites that will filter the loaner kiosk by default.")}
                            </Form.Label>
                            <SiteMultiSelect
                                disabled={this.isFormDisabled()}
                                sites={this.state.sites}
                                selectedSites={this.state.selectedSites}
                                sitesChangedHandler={this.handleSitesChange} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Form.Label>
                                <div>
                                    {t("Choose a label printer to use")}
                                    &nbsp;
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 100 }}
                                        style={{ maxWidth: "100%" }}
                                        overlay={<Tooltip id="button-tooltip" className={"modal-tooltip"} >
                                            <span>{t("If no printer is selected, then the operating system default will be used.")}</span>
                                        </Tooltip>}>
                                        <FontAwesomeIcon size="md" variant="primary" icon={faInfoCircle} />
                                    </OverlayTrigger>
                                </div>
                            </Form.Label>
                            <Select
                                isMulti={false}
                                isClearable={false}
                                name="selectedPrinter"
                                id="selectedPrinter"
                                onChange={selection => this.handlePrinterChange(selection.label)}
                                options={this.state.printers}
                                styles={styles}
                                value={this.getSelectedPrinter()} />
                        </Form.Group>
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" variant="warning" onClick={() => this.props.closeModal(this)}>{t("Close")}</Button>
                    <Button variant="primary" disabled={this.isFormDisabled()} onClick={() => this.save()}>{t("Save & Close")}</Button>
                </Modal.Footer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    client: state.applicationContext.applicationContext.client,
    hasPermission: selectPermissionChecker(state)
});


export default withTranslation()(connect(mapStateToProps, dispatch => bindActionCreators({ ...applicationMessagingActionCreator, ...applicationContextActionCreator }, dispatch))(ClientSettingsModal));