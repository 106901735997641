import React from 'react';
import { Modal} from 'react-bootstrap';
import Exclamation from '../../assets/img/Exclaimation.png';
import { useTranslation } from 'react-i18next';
const WarningModal = ({ show, title, message, substitutionMessages, children, actionButtons }) =>  {
    const { t } = useTranslation();
    return (
        <Modal show={show} >
            <Modal.Header className="modal-header-warning" >
                <Modal.Title>
                    {t(title)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-warning">
                <div className="modal-message-warning-container">
                    <span><img src={Exclamation} /></span>
                    <div className="modal-message-warning">
                        {t(message).split(/{(\d+)}/g).map((part, index) => (
                            <span key={index}>
                                {index % 2 === 0 ? part.split(/{(\d+)}|(\r\n|\n|\r)+/g).map((line, lineIndex) => (
                                    <span key={lineIndex}>
                                        {lineIndex % 2 !== 0 && <br />}
                                        {line}
                                    </span>
                                )) : <b>{t(substitutionMessages[Math.floor(index / 2)])}</b>}
                            </span>
                        ))
                        }
                    </div>
                </div>
                <div className="modal-message-warning-container">
                    {children}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {actionButtons}
            </Modal.Footer>
        </Modal>
    );

};

export default WarningModal
