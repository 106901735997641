import { DataColumn, DataTable } from "../../../controls/dataTable/DataTable";
import Paginator from "../../../controls/paging/Paginator";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Table } from "react-bootstrap";
import moment from "moment/moment";
import { dateTimeFormat } from "../../../utilities/dateTime";
import { useHistory } from 'react-router-dom';
import { sortData } from "../../../utilities/sorts";

export function ProductResultsTable(props) {
    const { t } = useTranslation();
    const history = useHistory();

    const [selectedProductPage, setSelectedProductPage] = useState([]);
    const [sortedData, setSortedData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ field: 'name', direction: 'asc' });

    const pageSize = 20;

    useEffect(() => {
        // Sort the entire dataset here
        const sortedItems = sortData([...props.dataSource], sortConfig);
        setSortedData(sortedItems);
    }, [props.dataSource, sortConfig]);

    const requestSort = (field) => {
        let direction = 'asc';
        if (sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ field, direction });
    };

    const productRowDetail = (row) => {
        return (row.setIndexes && row.setIndexes.length > 0 ? (<div className="finditem-datatable-info-subtable">
            <h6>{t('Indexes for')} {row.name}:</h6>
            <Table striped hover responsive>
                <thead>
                    <tr>
                        <th>{t('Index')}</th>
                        <th>{t('Current Location')}</th>
                        <th>{t('Last Scanned')}</th>
                        <th>{t('In Service')}</th>
                        <th>{t('Sterile')}</th>
                    </tr>
                </thead>
                <tbody>
                    {row.setIndexes.map((item, i) => {
                        return (<tr key={i}>
                            <td>{item.indexNumber}</td>
                            <td>{item.currentLocation.length > 0 ? (item.suspect ? "? " : "") + `${item.currentLocation} (${item.currentSite})` : ""}</td>
                            <td>{item.dateDone ? moment(item.dateDone).format(dateTimeFormat) : ""}</td>
                            <td>{item.isInService ? "In Service" : "Not In Service"}</td>
                            <td>{item.sterile}</td>
                        </tr>);
                    })}
                </tbody>
            </Table>
        </div>) : null);
    };

    const createOrRequest = (itemDetail, isProduct) => {
        itemDetail.isProduct = isProduct;
        history.push('/or/case/request/create', { mode: "create", data: itemDetail });
    };

    return <>
        <DataTable
            id="productResultsTable"
            striped hover responsive size="sm"
            dataSource={selectedProductPage}
            rowDetail={productRowDetail}
            onRowDetailExpanded={(row) => props.onRowDetailExpanded(row)}>
            <DataColumn
                dataField="name"
                sortable
                sorted={sortConfig.field === 'name' ? sortConfig.direction : null}
                onSort={() => requestSort('name')}
            >
                {t('Product name')}
            </DataColumn>
            <DataColumn dataField="barcode">{t('Barcode')}</DataColumn>
            <DataColumn dataField="siteName">{t('Site')}</DataColumn>
            <DataColumn dataField="productGroup">{t('Primary group')} </DataColumn>
            <DataColumn dataField="hospitalId">{t('Hospital #')}</DataColumn>
            <DataColumn dataField="usualLocation">{t('Usual location')}</DataColumn>
            <DataColumn dataField="altLocation">{t('Alternate location')}</DataColumn>
            <DataColumn
                dataField="actionColumn"
                formattedColumn={(row) => {
                    return (
                        <Button variant="primary" size="sm" onClick={() => createOrRequest(row, true)}>
                            Create Request
                        </Button>
                    )
                }}
            >
                {t('Action')}
            </DataColumn>
        </DataTable>
        {sortedData.length > 0 ? (
            <Paginator
                data={sortedData}
                maxPerPage={pageSize}
                maxPageItems={5}
                dataSliceChanged={setSelectedProductPage}
            />
        ) : null}
    </>;
}