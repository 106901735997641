import axios from "axios";

const SiteService = {

    async saveSite(site) {
        const response = axios.post(`/api/site/`, site);
        return response;
    },

    async deleteSite(id) {
        const response = axios.delete(`/api/site/${id}`);
        return response;
    },

    async getAllSites() {
        const response = axios.get(`/api/site/`);
        return response;
    },

    async getSiteWithSettings(id) {
        const response = axios.get(`/api/site/getSiteWithSettings/${id}`);
        return response;
    },

    async saveSiteSettings(settings) {
        const response = axios.post(`/api/site/saveSiteSettings`, settings);
        return response;
    }
}

export default SiteService; 
