import axios from "axios";

const MasterLoanerService = {

    async searchMasterLoanerSets(searchData) {
        const response = axios.post(`/api/masterloanerdata/set/search`, searchData);
        return response;
    },

    async addMasterLoanerSet(newSet) {
        const instance = axios.create();    //TODO: fix this when the modal problem gets fixed (error modals shutting down our modal)
        const response = instance.post(`/api/masterloanerdata/set/add`, newSet);
        return response;
    }
}

export default MasterLoanerService;