import { faHome, faLink, faCheck, faSearch, faList, faExchangeAlt, faDolly, faTools, faBook, faFire } from '@fortawesome/free-solid-svg-icons';

const nav = {
    loaner: [
        {
            name: 'Loaners Home',
            url: '/loaners/home',
            icon: faHome
        },
        {
            name: 'Drop Off',
            url: '/loaners/delivertrays',
            icon: faDolly
        },
        {
            name: 'Cases',
            url: '/loaners/cases',
            icon: faList
        }
    ],
    or: [
        {
            name: 'Assigned Items',
            url: '/or/case',
            icon: faList
        },
        {
            name: 'OR Requests',
            url: '/or/case/requests',
            icon: faExchangeAlt
        },
        {
            name: 'Quality Events',
            url: '/or/case/quality',
            icon: faCheck
        },
        {
            name: 'Search',
            url: '/or/finditem',
            icon: faSearch
        },
    ],
    admin: [
        {
            name: 'Organizations',
            url: '/admin/organizations',
            icon: faList
        },
        {
            name: 'Active Sockets',
            url: '/admin/activesockets',
            icon: faLink
        },
        {
            name: 'Hangfire',
            url: '/hangfire',
            reactRoute : false,
            icon: faFire
        },
        {
            name: 'Documentation',
            url: '/admin/documentation',
            icon: faBook
        },
        {
            name: 'Utilities',
            url: '/admin/utilities',
            icon: faTools
        }
    ],
};
export default nav;