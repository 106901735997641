import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";

export function SearchInput(props) {
    const { t } = useTranslation();

    return <>
        <Form.Label>{t("Search Text")}</Form.Label>
        <InputGroup>
            <Form.Control type="text" name="searchText" size="sm" value={props.value} onChange={props.onChange}
                          onKeyPress={props.onKeyPress}/>
            <InputGroup.Append>
                <Button variant="primary" size="sm" onClick={props.onClick}>
                    <FontAwesomeIcon icon={faSearch}/>
                </Button>
            </InputGroup.Append>
        </InputGroup>
    </>
}