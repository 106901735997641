import * as actionTypes from '../actions/actionTypes';

// Test implementation of SignalRMessage functionality 
// Can be removed once notifications and loaners are implemented 
const initialState = {
    messages: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RECEIVE_SIGNALR_MESSAGE:
            return {
                messages: state.messages.concat(action.message)
            };
        default:
            return state;
    }
};