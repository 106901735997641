//Package imports
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Card, Form, FormControl, Button, } from 'react-bootstrap';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

//User imports
import { selectSortedPhysicians } from '../../store/selectors/loanerOrderSelectors';
import { loanerAppSettingsActionCreator } from '../../store/actions/loanerAppSettingsActions';
import SiteMultiSelect from "./DeliverTrays/SiteMultiSelect";
import TooltipOverlay from '../../controls/tooltip-overlay/TooltipOverlay';

//Filters and formats
import { dateFormat } from "../../utilities/dateTime";

class CaseSearch extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedSites: [],
            selectedPhysician: null,
            dateFrom: null,
            dateTo: null,
            filteredCaseResults: [],
            disablePiBox: false
        }
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handlePhysicianChange = this.handlePhysicianChange.bind(this);
        this.searchForCases = this.searchForCases.bind(this);
        this.searchHandleEnterKey = this.searchHandleEnterKey.bind(this);
        this.handleSitesChange = this.handleSitesChange.bind(this);
        this.renderMultiSelect = this.renderMultiSelect.bind(this);
        this.getQueryParams = this.getQueryParams.bind(this);
        this.enablePiBox = this.enablePiBox.bind(this);

        this.caseNumber = React.createRef();

    }

    componentDidMount() {
        const searchTerms = this.props.savedSearchTerms;
        if (this.props.queryServer && Object.keys(searchTerms).length > 0) {
            this.setState(
                {
                    selectedSites: searchTerms.sites,
                    selectedPhysician: searchTerms.physicianId,
                    dateFrom: searchTerms.from,
                    dateTo: searchTerms.to
                }, () => this.searchForCases());
        } else {
            const dateFrom = this.getToday();
            const dateTo = moment(new Date(new Date().getTime() + (3 * 24 * 60 * 60 * 1000))).format(dateFormat);

            const newState = { dateFrom: dateFrom, dateTo: dateTo, selectedSites: this.props.defaultSelectedSiteIds };
            this.setState(newState, () => this.searchForCases());
        }
    }

    enablePiBox() {
        this.setState({ disablePiBox: false });
    }

    componentDidUpdate(prevProps) {
        const sitesDiffer = !_.isEqual(this.props.defaultSelectedSiteIds, prevProps.defaultSelectedSiteIds);
        if (!this.props.showSiteMultiSelect) {
            let update = false;
            if (sitesDiffer) {
                update = true;
            }
            if (update) {
                this.setState({ selectedSites: this.props.defaultSelectedSiteIds }, () => this.searchForCases());
            }
        } else {
            if (sitesDiffer) {
                this.setState({ selectedSites: this.props.defaultSelectedSiteIds }, () => this.searchForCases());
            }
        }
        if (this.props.showOnlyVendorCases !== prevProps.showOnlyVendorCases) {
            this.searchForCases();
        }

        if (this.props.showPatientInitials != null && this.props.showPatientInitials) {
            if (this.props.allowedToSearchForPi != null && !this.props.allowedToSearchForPi) {
                if (!this.state.disablePiBox) {
                    this.setState({ patientInitials: "" });
                    this.setState({ disablePiBox: true });
                }
            } else {
                if (this.state.disablePiBox) {
                    this.setState({ disablePiBox: false });
                }
            }
        }

        if (this.props.selectedDeliverer && (this.props.selectedDeliverer !== prevProps.selectedDeliverer)) {
            this.focusOnCaseNumber();
        }
    }

    searchHandleEnterKey(event) {
        if (event.key === "Enter" && this.state.caseNumber && this.state.caseNumber.length > 0) {
            this.searchForCases();
        }
    }

    handleSitesChange(data) {
        const newSites = data ? data.map(d => d.value) : [];
        this.setState({
            selectedSites: newSites
        }, this.searchForCases());
    }

    handleUserInput(event) {
        // taken straight from the React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const partialState = this.state;
        partialState[name] = value;
        this.setState({ partialState });
    }

    handlePhysicianChange(event) {
        const target = event.target;
        const value = isNaN(target.value) ? null : parseInt(target.value);
        this.setState({
            selectedPhysician: value
        }, () => this.searchForCases());
    }

    getToday() {
        return moment(new Date()).format(dateFormat);
    }

    getQueryParams() {

        let fromDate = this.state.dateFrom;
        const date = Date.parse(fromDate);
        const today = Date.parse(this.getToday());
        if (!this.props.showPastCases && date < today) {
            fromDate = moment();
        }
        const params = {};
        params.from = fromDate;
        params.to = this.state.dateTo;
        params.caseNumber = this.state.caseNumber;
        params.deliveryPersonName = this.props.selectedDeliverer;
        params.sites = this.state.selectedSites;
        params.physicianId = this.state.selectedPhysician;
        params.clientName = this.props.client.name;
        params.vendorId = this.props.showOnlyVendorCases ? this.props.defaultVendorId : null;
        params.isCaseOrdersOnly = this.props.showPastCases;
        params.caseToExclude = this.props.casesToExclude && this.props.casesToExclude.length ? this.props.casesToExclude[0] : null;
        params.patientInitials = this.state.patientInitials;
        return params;
    }

    saveSearchTerms() {
        const settingName = 'caseSearch';
        this.props.changeLoanerAppSetting(settingName, 'from', this.state.dateFrom);
        this.props.changeLoanerAppSetting(settingName, 'to', this.state.dateTo);
        this.props.changeLoanerAppSetting(settingName, 'caseNumber', this.state.caseNumber);
        this.props.changeLoanerAppSetting(settingName, 'patientInitials', this.state.patientInitials);
        this.props.changeLoanerAppSetting(settingName, 'sites', this.state.selectedSites);
        this.props.changeLoanerAppSetting(settingName, 'physicianId', this.state.selectedPhysician);
        this.props.changeLoanerAppSetting(settingName, 'vendorId', this.props.showOnlyVendorCases ? this.props.defaultVendorId : null);
        this.props.changeLoanerAppSetting(settingName, 'isCaseOrdersOnly', this.props.showPastCases);
        this.props.changeLoanerAppSetting(settingName, 'selectedPageInSearchResults', 1);
    }

    searchForCases() {
        this.saveSearchTerms();
        this.props.queryServer(this.getQueryParams());
    }

    renderMultiSelect() {
        const { t } = this.props;
        if (this.props.showSiteMultiSelect && this.props.sites) {
            return [
                <Form.Row className="d-flex align-items-center">
                    <div className="card-btn-group form-inline">
                        <span className="card-button-group-item">{t('Sites')}:&nbsp;</span>
                        <SiteMultiSelect
                            sites={this.props.sites}
                            selectedSites={this.state.selectedSites}
                            sitesChangedHandler={this.handleSitesChange} />
                    </div>
                </Form.Row>
            ];
        }
        return null;
    }

    focusOnCaseNumber() {
        if (this.caseNumber && this.caseNumber.current) {
            this.caseNumber.current.focus();
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Card>
                <Card.Header className="d-flex">
                    <div className="mr-auto">{t('Search by case (by case number or physician/date)')}</div>
                    {this.renderMultiSelect()}
                </Card.Header>
                <Card.Body>
                    <table className="case-search-fields">
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <Form.Label>{t('Case number')}</Form.Label>
                                    <Form.Control
                                        name="caseNumber"
                                        id="caseNumber"
                                        autoFocus
                                        ref={this.caseNumber}
                                        value={this.state.caseNumber}
                                        onChange={this.handleUserInput}
                                        onKeyPress={this.searchHandleEnterKey} />
                                </td>
                                <td colspan="1">
                                    <Form.Label>{t('Physician')}</Form.Label>
                                    <FormControl as="select" name="selectedPhysician" id="selectedPhysician" value={this.state.selectedPhysician} onChange={this.handlePhysicianChange}>
                                        <option value={null}>{t("Select...")}</option>
                                        {
                                            this.props.physicians.map((doc) => {
                                                return <option key={doc.id} value={doc.id}>{doc.name}</option>;
                                            })
                                        }
                                    </FormControl>
                                </td>
                                <td rowspan="2">
                                    <div className="d-flex">
                                        <Button className="ml-auto mr-auto" onClick={() => this.searchForCases()}>{t('Search')}</Button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <Form.Label>{t('Date range')}</Form.Label>
                                    <div className="form-inline">
                                        <Form.Control placeholder=""
                                            type="date"
                                            name="dateFrom" className="date-control"
                                            id="dateFrom"
                                            min={!this.props.showPastCases ? moment().format('l') : ""}
                                            value={this.state.dateFrom}
                                            onChange={this.handleUserInput} />&nbsp;&nbsp;
                                        <Form.Control placeholder=""
                                            type="date"
                                            name="dateTo" className="date-control"
                                            id="dateTo"
                                            value={this.state.dateTo}
                                            onChange={this.handleUserInput} />
                                    </div>
                                </td>
                                {this.props.showPatientInitials ?
                                    <td>
                                        <Form.Label>{t('Patient initials')}</Form.Label>
                                        <TooltipOverlay tooltip={t("Limit: 3 patient initial searches per minute")}>
                                            <Form.Control placeholder=""
                                                name="patientInitials"
                                                id="patientInitials"
                                                autoComplete="off"
                                                value={this.state.patientInitials}
                                                onChange={this.handleUserInput}
                                                disabled={this.state.disablePiBox}
                                            />
                                        </TooltipOverlay>
                                    </td>
                                    : <td></td>}
                            </tr>
                        </tbody>
                    </table>
                </Card.Body>
            </Card >
        );
    }
}

const mapStateToProps = (state) => ({
    caseNumber: state.loanerMessage.caseNumber,
    lastUpdated: state.loanerMessage.lastUpdated,
    vendors: state.loanerOrder.vendors,
    sites: state.loanerOrder.sites,
    physicians: selectSortedPhysicians(state),
    savedSearchTerms: state.loanerAppSettings.caseSearch,
    client: state.applicationContext.applicationContext.client
});

export default withTranslation()(connect(mapStateToProps, dispatch => bindActionCreators({ ...loanerAppSettingsActionCreator }, dispatch))(CaseSearch));