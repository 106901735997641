import axios from "axios";

const LoanerOrderService = {
    async getLoanerHomeData() {
        const response = axios.get(`/api/loanerorder/home`);
        return response;
    },

    async getCaseOrder(id) {
        const response = axios.get(`/api/loanerorder/caseorder/${id}`);
        return response;
    },

    async verifyTrays(trays) {
        const response = axios.post(`/api/loanerorder/verify/`, trays);
        return response;
    },

    async updateTrays(trays) {
        const response = axios.put(`/api/loanerorder/updatestatus/`, trays);
        return response;
    },

    async searchMasterLoanerSets(searchData) {
        const response = axios.post(`/api/loanerorder/search`, searchData);
        return response;
    },

    async caseSearch(caseQuery) {
        const response = axios.post(`/api/loanerorder/casesearch`, caseQuery);
        return response;
    },

    async saveCapturedImages(capturedImages) {
        const response = axios.post(`/api/loanerorder/images/`, capturedImages);
        return response;
    },

    async getSavedImagesWithoutData(id) {
        const response = axios.get(`/api/loanerorder/${id}/images`);
        return response;
    },

    async getSavedImageData(id) {
        const response = axios.get(`/api/loanerorder/images/${id}/data`);
        return response;
    },
    async deleteImage(id) {
        const response = axios.delete(`/api/loanerorder/images/${id}`);
        return response;
    },

    //This will come in handy for when we manage more than screenshots down the road, it already has a command/handler
    //We just decided we didnt need it for webcam capture. Please leave here.
    //async deleteImages(ids) {
    // async deleteImages(ids) {        
    //    const response = axios.post("/api/loanerorder/deleteImages", ids);
    //    return response;
    // },

    async saveNewCaseOrderLoaner(caseOrderId, newLoaner) {
        const response = axios.post(`/api/loanerorder/caseorder/${caseOrderId}/loaner`, newLoaner);
        return response;
    },

    async undeliverCaseOrderLoaner(caseOrderId, loanerId) {
        const response = axios.post(`/api/loanerorder/${caseOrderId}/undeliver/${loanerId}`);
        return response;
    },

    async quickCreateCaseOrder(caseOrder) {
        const response = axios.post(`api/loanerorder`, caseOrder);
        return response;
    },

    async tagLoanersForCase(taggedLoaners) {
        const response = axios.post(`api/loanerorder/tagforcase`, taggedLoaners);
        return response;
    },

    async getBarcodesForOrder(query) {
        const response = axios.post(`/api/loanerorder/caseorder/loanerbarcodes`, query);
        return response;
    },

    async getTaggedCaseOrders(query) {
        const response = axios.post(`/api/loanerorder/caseorder/taggedCaseOrders`, query);
        return response;
    }
}

export default LoanerOrderService;