//Package imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Card, Button, Form, Col, Row, ListGroup } from 'react-bootstrap';
import IdleTimer from 'react-idle-timer';

//User imports
import { modalActionCreator } from '../../../store/actions/modalActions';
import LoanerCheckInContext from '../LoanerCheckInContext';
import LoanerOrderService from '../../../services/loanerOrderService';
import enums from "../../../utilities/enums";
import settings from "../../../utilities/enums/siteSettingsEnum";
import { caseOrderSourceEnum } from '../../../utilities/enumerations';
import labelFormatter from '../../../utilities/labelFormatter';
import * as topicTypes from '../../../store/actions/topicTypes';
import { signalrActionCreator } from '../../../store/actions/signalrActions';
import { loanerOrderActionCreator } from '../../../store/actions/loanerOrderActions';
import ReceivedTrays from './ReceivedTrays';
import { PrinterContext } from '../../../contexts/PrinterContext';
import SelectTraysPreventNavigation from './SelectTraysPreventNavigation';
import orgService from '../../../services/organizationService';
import TrayDropoff from './TrayDropoff.jsx'

const notYetArrived = (loaner) => loaner.status === enums.loanerTrayStatusEnum.expected.value;
const pendingDelivery = (loaner) =>
    loaner.status === enums.loanerTrayStatusEnum.pendingDelivery.value;

const redirectTimeout = process.env.REACT_APP_API_IDLE_REDIRECT || 300000; //timeout after 5 minutes

class SelectTrays extends Component {

    static contextType = PrinterContext;
    //this.context.whatever
    constructor(props, context) {
        super(props, context);
        this.unblock = () => { };
        this.state = {
            caseOrder: {
                id: 0,
                caseId: 0,
                vendorId: 0,
                loaners: []
            },
            showSearchPanel: false,
            searchText: "",
            searchResults: [],
            settings: [],
            // needed for DelivererSelector
            sites: [],
            deliverers: [],
            loading: {
                key: '',
                target: undefined,
            },
            siteVendorDeliverers: [],
            selectedDeliverer: '',
            searchPressed: false,
        };

        this.createNewTray = this.createNewTray.bind(this);
        this.handleVerifyModalClose = this.handleVerifyModalClose.bind(this);
        this.showVerifyModal = this.showVerifyModal.bind(this);
        this.setLoading = this.setLoading.bind(this);

        this.dropOffAllExpected = this.dropOffAllExpected.bind(this);
        this.dropOffExpectedTray = this.dropOffExpectedTray.bind(this);
        this.dropOffNewTray = this.dropOffNewTray.bind(this);
        this.processNewTray = this.processNewTray.bind(this);

        //Webcam capture specific
        this.handleWebcamCaptureClose = this.handleWebcamCaptureClose.bind(this);
        this.updateDeliveryPersonLastUsed = this.updateDeliveryPersonLastUsed.bind(this);

        // DelivererSelector specific bindings
        this.updateDelivererList = this.updateDelivererList.bind(this);
        this.handleDelivererSelectorModalClose = this.handleDelivererSelectorModalClose.bind(this);
        this.hasDeliveryPerson = this.hasDeliveryPerson.bind(this);
        this.promptForDeliveryPersonAndThen = this.promptForDeliveryPersonAndThen.bind(this);

        this.undeliverTray = this.undeliverTray.bind(this);
        this.updateTrays = this.updateTrays.bind(this);

        //timeout
        this.handleOnIdle = this.handleOnIdle.bind(this);
    }

    componentDidMount() {
        this.props.requestLoanerOrderHome();
        window.scrollTo(0, 0);   //TODO: make this global, a la https://reactrouter.com/web/guides/scroll-restoration
        //or when Dan moves the scrolling to the inner card, we may not need to unscroll at all
        const caseOrderId = parseInt(this.props.match.params.id);
        this.props.requestCaseOrder(caseOrderId);
        this.props.subscribeToTopic(topicTypes.CASE_ORDERS);
        this.setState({
            selectedDeliverer: (this.props.location.state && this.props.location.state.deliverer) ? this.props.location.state.deliverer : ''
        });
    }

    componentWillUnmount() {
        this.props.unsubscribeFromTopic(topicTypes.CASE_ORDERS);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading) {
            //See if diff on props.match.params.id in prevProps
            //iff diff put in a local variable
            if (this.props.refreshCaseOrder && this.state.caseOrder || this.props.match.params.id !== prevProps.match.params.id) {

                let idToRefreshWith = this.state.caseOrder.id;
                if (this.props.match.params.id !== prevProps.match.params.id) {
                    idToRefreshWith = parseInt(this.props.match.params.id);
                }
                this.props.requestCaseOrder(idToRefreshWith);
            }

            if (this.props.vendors.length !== 0 &&
                this.props.caseOrder &&
                (this.props.caseOrder !== prevProps.caseOrder || this.state.caseOrder.id === 0)) {
                var vendor = this.props.vendors.find(v => v.id === this.props.caseOrder.vendorId);
                var vendorName = vendor ? vendor.name : this.props.caseOrder.vendorName;

                this.setState({
                    caseOrder: {
                        ...this.props.caseOrder,
                        vendorName: vendorName
                    }
                }, () => {
                    this.updateDelivererList();
                });
            }
        }
    }

    handleOnIdle() {
        this.unblock();
        this.props.hideModal();
        this.props.history.push(this.props.user.defaultHomeScreen || '/loaners');
    }

    hasDeliveryPerson() {
        return this.state.selectedDeliverer;
    }

    dropOffExpectedTray(loaner) {
        this.updateTrays([loaner.id], enums.loanerTrayStatusEnum.pendingDelivery.value);
    }

    updateDeliveryPersonLastUsed() {
        const deliverer = {
            siteId: this.state.caseOrder.siteId,
            vendorId: this.state.caseOrder.vendorId,
            name: (this.state.selectedDeliverer || this.props.t('Unknown'))
        };
        orgService.updateDelivererLastUsed(deliverer);
    }

    dropOffNewTray(masterLoaner) {
        this.props.hideModal();
        const newLoaner = {
            id: 0,
            caseOrderId: this.state.caseOrder.id,
            masterOrderLoanerSetId: masterLoaner.id,
            trayName: masterLoaner.name,
            status: enums.loanerTrayStatusEnum.pendingDelivery.value,
            fileCount: 0,
            statusName: enums.loanerTrayStatusEnum.pendingDelivery.name,
            delivConfirmed: null,
            source: 0,
            deliveredBy: this.state.selectedDeliverer
        };

        const caseOrderId = this.state.caseOrder.id;
        this.setState({
            loading: {
                key: 'new-tray',
                target: masterLoaner.id,
            },
        });

        LoanerOrderService.saveNewCaseOrderLoaner(caseOrderId, newLoaner)
            .then((response) => {
                newLoaner.id = response.data.payload.id;
                newLoaner.barcode = response.data.payload.uniqueId;
                newLoaner.touched = true;

                this.updateDeliveryPersonLastUsed();
            })
            .finally(() =>
                this.setState({ loading: { key: '', target: undefined } })
            );
    }

    setLoading(key, target) {
        this.setState({ loading: { key: key, target: target } });
    }

    promptForDeliveryPersonAndThen(doThis) {
        if (this.hasDeliveryPerson() && doThis) {
            doThis();
        }
        else {
            this.props.showModal({
                open: true,
                size: "md",
                focus: true,
                model: {
                    selectedSites: [this.state.caseOrder.siteId],
                    selectedVendor: this.state.caseOrder.vendorId,
                    selectedDeliverer: this.state.selectedDeliverer,
                    siteVendorDeliverers: this.state.siteVendorDeliverers,
                    deliverers: this.props.deliverers,
                    handleDelivererChange: this.handleDelivererChange,
                    handleNewDeliverer: this.handleNewDeliverer
                },
                beforeCloseModal: (data) => {
                    this.handleDelivererSelectorModalClose(data, doThis);
                },
                onCancel: () => this.setLoading('', undefined)
            }, 'delivererSelectorModal');
        }
    }

    promptForDeliveryPersonAndDropOffExpectedTray(loaner) {
        this.promptForDeliveryPersonAndThen(() => { this.dropOffExpectedTray(loaner); });
    }

    updateDelivererList() {
        const deliverers = this.state.caseOrder.vendorId && this.state.caseOrder.siteId && this.props.deliverers ?
            this.props.deliverers
                .filter(d => d.vendorId === this.state.caseOrder.vendorId && this.state.caseOrder.siteId === d.siteId)
                .map(d => d.name)
                .filter((item, i, ar) => ar.indexOf(item) === i)    //get unique names
                .sort() : [];

        let selectedDeliverer = this.state.selectedDeliverer;
        if (!deliverers.includes(selectedDeliverer) && deliverers.length > 0) {
            selectedDeliverer = '';
        }

        // krisztian gyuris:
        // this log files here are intentional and has to survive the PR review 
        // they will be gone once the bug (30659) is fixed 
        console.log('updateDelivererList', deliverers, selectedDeliverer);
        console.log('before state change:', this.state);

        this.setState({
            siteVendorDeliverers: deliverers, // now we are setting the list to empty 
            selectedDeliverer: selectedDeliverer
        });
    }

    handleDelivererSelectorModalClose(data, onDelivererUpdate) {
        this.setState({
            selectedDeliverer: data
        }, () => {
            if (onDelivererUpdate && this.hasDeliveryPerson()) {
                onDelivererUpdate();
            }
        });
    }

    dropOffAllExpected() {
        const undelivered = this.state.caseOrder.loaners.filter(notYetArrived);
        var traysToUpdate = [];
        undelivered.forEach((loaner) => {
            traysToUpdate.push(loaner.id);
        });
        this.setState({ loading: { key: 'drop-off-all', target: undefined } });
        this.updateTrays(
            traysToUpdate,
            enums.loanerTrayStatusEnum.pendingDelivery.value
        );
    }

    updateTrays(traysToUpdate, status, shouldPrintAfterUpdate) {
        const delivererName = this.state.selectedDeliverer || this.props.t('Unknown');
        const request = {
            TraysToUpdate: traysToUpdate,
            Deliverer: delivererName,
            Status: status
        };

        LoanerOrderService.updateTrays(request)
            .then(response => {
                if (response.data.success) {
                    const updatedTrays = response.data.payload;
                    if (updatedTrays) {
                        const partialState = { ...this.state.caseOrder };
                        var loaners = [...partialState.loaners];
                        updatedTrays.forEach(updatedTray => {
                            var index = loaners.findIndex(l => l.id === updatedTray.id);
                            updatedTray.touched = true;
                            if (shouldPrintAfterUpdate !== null && shouldPrintAfterUpdate === true) {
                                this.context.print(labelFormatter.formatLoanerZpl(updatedTray, this.props.client.siteSettings, this.state.caseOrder));
                            }
                            loaners[index] = updatedTray;
                        });
                        partialState.loaners = loaners;

                        this.setState({
                            caseOrder: partialState,
                            loading: { key: '', target: undefined },
                        });

                        this.updateDeliveryPersonLastUsed();
                    }
                }
            });
    }

    undeliverTray(loaner) {
        this.props.hideModal();

        LoanerOrderService.undeliverCaseOrderLoaner(this.state.caseOrder.id, loaner.id)
            .then(response => {
                if (response.data.success) {
                    var partialState = { ...this.state.caseOrder };
                    partialState.loaners = [...this.state.caseOrder.loaners];

                    if (loaner.source === caseOrderSourceEnum.Adhoc) {
                        // remove loaner if adhoc 
                        partialState.loaners = partialState.loaners.filter((item) => { return item.id !== loaner.id });
                    }
                    else if (loaner.source === caseOrderSourceEnum.Torq && !loaner.isTagged) {
                        // put back loaner to the expected list
                        var index = partialState.loaners.findIndex(l => l.id === loaner.id);
                        partialState.loaners[index].status = enums.loanerTrayStatusEnum.expected.value;
                    }

                    this.setState({ caseOrder: partialState });
                }
            });
    }

    createNewTray() {
        this.props.showModal({
            open: true,
            size: "xl",
            vendorId: this.state.caseOrder.vendorId,
            vendorName: this.state.caseOrder.vendorName,
            cancel: this.props.hideModal,
            confirm: this.processNewTray,
            dropOffNewTray: this.dropOffNewTray,
            promptForDeliverer: this.promptForDeliveryPersonAndThen,
            loading: this.state.loading,
            setLoading: this.setLoading
        }, 'addNewTray');
    }

    processNewTray(newTray) {
        this.props.hideModal();
        this.dropOffNewTray(newTray);
    }

    handleVerifyModalClose(data) {
        this.setState({ partialState: data });
    }

    showVerifyModal(id) {
        this.props.showModal({
            open: true,
            title: this.props.t("Verify Loaners for Case") + " " + this.props.caseNumber,
            user: null,
            size: "xl",
            focus: true,
            initialSelection: id,
            beforeCloseModal: this.handleVerifyModalClose,
            model: {
                caseOrder: this.state.caseOrder
            }
        }, 'verifyLoanerModal');
    }

    handleWebcamCaptureClose(count, index) {
        const partialState = this.state.caseOrder.loaners.filter(i => i.id === index);
        partialState[0].fileCount = count;
        this.setState({ partialState });
    }

    getSiteInstructions() {
        return [
            (
                <Card.Header>
                    <div>{this.props.t("Site Instructions")}</div>
                </Card.Header>
            ),
            (
                <Card.Body>
                    <textarea className="form-control"
                        rows="3"
                        style={{ overflowY: "scroll", height: "100px", resize: "none" }}
                        id="dropOffInstructions"
                        name="dropOffInstructions"
                        value={this.props.client.siteSettings.find(s => s.settingType === settings.siteLoanerDropOffInstructions.value)?.value}
                        readOnly={true} />
                </Card.Body>
            )
        ];
    }

    render() {
        const siteInstructions = this.getSiteInstructions();

        return (
            <Fragment>
                <SelectTraysPreventNavigation
                    isDirty={this.state.caseOrder.loaners.filter(pendingDelivery).length > 0} >
                    {unblock => this.unblock = unblock}</SelectTraysPreventNavigation>
                <IdleTimer
                    onIdle={this.handleOnIdle}
                    timeout={redirectTimeout}
                />
                <Card className="card-page-level">
                    <LoanerCheckInContext caseOrder={this.state.caseOrder} />

                    <TrayDropoff
                        dropOffNewTray={this.dropOffNewTray}
                        dropOffAllExpected={this.dropOffAllExpected}
                        dropOffExpectedTray={this.dropOffExpectedTray}
                        caseOrder={this.state.caseOrder}
                        selectedDeliverer={this.state.selectedDeliverer}
                        showModal={this.props.showModal}
                        setLoading={this.setLoading}
                        loading={this.state.loading}
                        promptForDeliverer={this.promptForDeliveryPersonAndThen}
                        createNewTray={this.createNewTray}
                    />

                    <ReceivedTrays caseOrder={this.state.caseOrder}
                        showVerifyModal={this.showVerifyModal}
                        onWebcamModalClose={this.handleWebcamCaptureClose}
                        undeliverTray={this.undeliverTray}
                        onTraysUpdated={this.updateTrays}
                    />
                    <Card.Body>
                        <Row>
                            <Form.Group
                                as={Col}
                                lg={{ span: 2, offset: 5 }}
                                className="full-width">
                                <Button
                                    block
                                    onClick={() =>
                                        this.props.history.push('/loaners/delivertrays')
                                    }>
                                    Done
                                </Button>
                            </Form.Group>
                        </Row>
                    </Card.Body>

                    {siteInstructions}
                </Card>
            </Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    caseOrder: state.loanerOrder.caseOrder,
    isLoading: state.loanerOrder.isLoading,
    refreshCaseOrder: state.loanerOrder.refreshCaseOrder,
    vendors: state.loanerOrder.vendors,
    user: state.applicationContext.applicationContext.user,
    client: state.applicationContext.applicationContext.client,
    deliverers: state.loanerOrder.deliverers
});

export default withTranslation()(connect(mapStateToProps, dispatch => bindActionCreators({ ...loanerOrderActionCreator, ...signalrActionCreator, ...modalActionCreator }, dispatch))(SelectTrays));
