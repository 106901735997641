import React, { Component } from 'react';
import {
    Row, Col, FormCheck, FormGroup, FormLabel, FormControl, Badge, Table, HelpBlock,
    Button, Alert, Glyphicon, DropdownButton, MenuItem, Tooltip, OverlayTrigger, Modal
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { registerClientResultEnum } from '../utilities/enumerations';
import { applicationContextActionCreator } from '../store/actions/applicationContextActions';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Fingerprint from 'fingerprintjs2';
import queryString from 'query-string';

const defaultRegisterClientResult = { confirmedClient: {}, potentialClients: [], result: null, message: "" };

class RegisterClient extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleIntUserInput = this.handleIntUserInput.bind(this);
        this.handleSelectedClientInput = this.handleSelectedClientInput.bind(this);
        this.handleChooseExistingClient = this.handleChooseExistingClient.bind(this);
        this.handleCreateNewClient = this.handleCreateNewClient.bind(this);
        this.getSitesForCurrentOrg = this.getSitesForCurrentOrg.bind(this);
        this.getFingerprint = this.getFingerprint.bind(this);

        this.state = {
            createClient: { name: "", primarySiteId: 0, fingerprint: '', components: '' },
            registerClientResult: defaultRegisterClientResult,
            selectedClientId: 0,
            returnUrl: this.props.returnUrl,
            working: false,
            fingerprint: { murmur: '', components: '' },
            sites: []
        };
    }

    componentWillMount() {


        let returnUrl = queryString.parse(this.props.location.search).returnUrl;
        this.setState({ returnUrl: returnUrl });

        this.getFingerprint();

        this.getSitesForCurrentOrg();
    }

    getFingerprint() {

        this.setState({ working: true });

        const localThis = this;

        Fingerprint.get(function (components) {
            var murmur = Fingerprint.x64hash128(components.map(function (pair) { return pair.value }).join(), 31);

            var details = "";
            for (var index in components) {
                var obj = components[index];
                var line = obj.key + " = " + String(obj.value).substr(0, 100);
                details += line + "\n";
            }
            localThis.setState({ fingerprint: { murmur: murmur, components: details } });
            localThis.registerClient(murmur).then(response => {
                localThis.handleRegisterClientResponse(response);
            });
        });
    }

    getSitesForCurrentOrg() {
        const url = `api/organization/sites/`;
        fetch(url)
            .then(response => {
                if (response.redirected === true) {
                    this.props.history.push("/auth/login");
                }
                if (response.ok) {
                    return response;
                }
                else if (response.status === 401) {
                    // redirect to authentication
                    this.props.history.push("/auth/login");
                }
                else if (response.status === 403) {
                    // redirect to unauthorized
                    this.props.history.push("../auth/unauthorized");
                }
                else if (response.status === 409) {
                    // redirect to unauthorized
                    this.props.history.push("../auth/invalidtenant");
                }
                else {
                    throw new Error(response.status + ": " + response.statusText);
                }
            })
            .then(response => {
                return response.json();
            })
            .then(json => {
                this.setState({ sites: json.payload });
            })
            .catch(error => {
                this.setState({ returnError: error.message });
            });
    }

    handleRegisterClientResponse(response) {
        switch (response.result) {
            case registerClientResultEnum.Success:
                const currentClient = response.confirmedClient;
                //JSON parse all settings
                currentClient.settings = currentClient.settings.map(cs => {
                    return {
                        id: cs.id,
                        settingType: cs.settingType,
                        value: cs.value.startsWith("[") || cs.value.startsWith("{") ? JSON.parse(cs.value) : cs.value
                    }
                });
                this.setState({ registerClientResult: response });
                // set client in redux
                this.props.updateCurrentClient(currentClient);
                this.props.history.push(this.state.returnUrl);

                break;
            case registerClientResultEnum.NoActiveSocketConnection:
            case registerClientResultEnum.NoMatchingClient:
            case registerClientResultEnum.MatchedFingerprint:
            case registerClientResultEnum.MatchedName:
            case registerClientResultEnum.CreateClientFailed:
                this.setState({ registerClientResult: response });
                break;
        }

        this.setState({ working: false });
    }

    handleUserInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var createClientState = this.state.createClient;
        createClientState[name] = value;
        this.setState({ createClient: createClientState });
    }

    handleIntUserInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = parseInt(target.value);
        const name = target.name;
        var createClientState = this.state.createClient;
        createClientState[name] = value;
        this.setState({ createClient: createClientState });
    }

    handleSelectedClientInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var state = this.state;
        state[name] = value;
        this.setState(state);
    }

    handleCreateNewClient() {
        this.setState({ registerClientResult: defaultRegisterClientResult }, () => {
            let spmAuthUri = `api/client/`;

            let createClient = this.state.createClient;
            createClient.fingerprint = this.state.fingerprint.murmur;
            createClient.components = this.state.fingerprint.components;

            fetch(spmAuthUri,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    body: JSON.stringify(createClient)
                }
            )
                .then(response => {
                    if (response.redirected === true) {
                        this.props.history.push("/auth/login");
                    }
                    if (response.ok) {
                        return response;
                    }
                    else if (response.status === 401) {
                        // redirect to authentication
                        this.props.history.push("/auth/login");
                    }
                    else if (response.status === 403) {
                        // redirect to unauthorized
                        this.props.history.push("../auth/unauthorized");
                    }
                    else if (response.status === 409) {
                        // redirect to unauthorized
                        this.props.history.push("../auth/invalidtenant");
                    }
                    else {
                        throw new Error(response.status + ": " + response.statusText);
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(json => {
                    this.handleRegisterClientResponse(json);
                })
                .catch(error => {
                    this.setState({ returnError: error.message });
                });
        });
    }

    handleChooseExistingClient() {
        const id = this.state.selectedClientId;
        const url = `api/client/register/${id}`;
        return fetch(url)
            .then(response => {
                if (response.redirected === true) {
                    this.props.history.push("/auth/login");
                }
                if (response.ok) {
                    return response;
                }
                else if (response.status === 401) {
                    // redirect to authentication
                    this.props.history.push("/auth/login");
                }
                else if (response.status === 403) {
                    // redirect to unauthorized
                    this.props.history.push("../auth/unauthorized");
                }
                else if (response.status === 409) {
                    // redirect to unauthorized
                    this.props.history.push("../auth/invalidtenant");
                }
                else {
                    throw new Error(response.status + ": " + response.statusText);
                }
            })
            .then(response => {
                return response.json();
            })
            .then(json => {
                this.handleRegisterClientResponse(json);
            })
            .catch(error => {
                this.setState({ returnError: error.message });
            });
    }

    registerClient(fingerprint) {

        const url = `api/client/fingerprint/${fingerprint}`;
        return fetch(url)
            .then(response => {
                if (response.redirected === true) {
                    this.props.history.push("/auth/login");
                }
                if (response.ok) {
                    return response;
                }
                else if (response.status === 401) {
                    // redirect to authentication
                    this.props.history.push("/auth/login");
                }
                else if (response.status === 403) {
                    // redirect to unauthorized
                    this.props.history.push("../auth/unauthorized");
                }
                else if (response.status === 409) {
                    // redirect to unauthorized
                    this.props.history.push("../auth/invalidtenant");
                }
                else {
                    throw new Error(response.status + ": " + response.statusText);
                }
            })
            .then(response => {
                return response.json();
            })
            .then(json => {
                return json;
            })
            .catch(error => {
                this.setState({ returnError: error.message });
            });

    }

    render() {
        const { t, i18n } = this.props;
        return (
            <div>
                <Row style={{ margin: "0 0 20px 0" }} className="d-flex align-items-center justify-content-center">
                    <h3>{t("We Don't Recognize This Machine.")}</h3><br /><br />
                    <Alert variant="danger"
                        show={this.state.registerClientResult?.message != undefined && this.state.registerClientResult?.message.length > 0}>
                        <Alert.Heading>{t("Error")}</Alert.Heading>
                        {this.state.registerClientResult?.message}
                    </Alert>
                </Row>
                <Row>
                    {
                        this.state.registerClientResult.potentialClients.length === 0 ? null : (
                            <>
                                <Col sm={5}><h4>{t("Choose from these potential matches")}</h4></Col>
                                <Col sm={1}> </Col>
                            </>
                        )
                    }
                    <Col sm={5}><h4>{t("Register a New Client")}</h4></Col>
                </Row>
                <Row>
                    {
                        this.state.registerClientResult.potentialClients.length === 0 ? null : (
                            <>
                                <Col sm={5}>
                                    {
                                        this.state.registerClientResult.potentialClients.map((p, i) => <FormCheck key={i} type="radio" style={{ margin: "0 0 0 20px" }} name="selectedClientId" value={p.id} onChange={this.handleSelectedClientInput} label={p.name} />)
                                    }
                                </Col>
                                <Col sm={1}>
                                    <h3><b>OR</b></h3>
                                </Col>
                            </>
                        )
                    }
                    <Col sm={5}>
                        <div className="form-group">
                            <label htmlFor="name">{t("Client Name")}</label>
                            <input className="form-control" type="text" id="name" name="name" value={this.state.name} onChange={this.handleUserInput} aria-describedby="nameHelp" required />
                            <small id="nameHelp" className="form-text text-muted">{t("Please enter the name of this machine or location.")}</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="primarySiteId">{t("Primary Site")}</label>
                            <select className="form-control" name="primarySiteId" id="primarySiteId" value={this.state.primarySiteId} required onChange={this.handleIntUserInput}>
                                <option value="0">{t("Choose Primary Site")}</option>
                                {this.state.sites.map((p, i) => <option key={i} value={p.foreignSiteId}>{p.name} ({p.siteCode})</option>)}
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        this.state.registerClientResult.potentialClients.length === 0 ? null : (
                            <>
                                <Col sm={5}>
                                    <div className="form-group">
                                        <Button
                                            disabled={!(this.state.selectedClientId > 0)}
                                            variant="primary"
                                            className="float-right"
                                            onClick={this.handleChooseExistingClient}>{t("Register")}</Button>
                                    </div>
                                </Col>
                                <Col sm={1}> </Col>
                            </>
                        )
                    }
                    <Col sm={5}>
                        <div className="form-group">
                            <Button
                                variant="primary"
                                disabled={!(this.state.createClient.primarySiteId > 0 && this.state.createClient.name.length > 3)}
                                className="float-right"
                                onClick={this.handleCreateNewClient}>{t("Create Client")}</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(compose(withRouter, connect(
    state => state.applicationContext, dispatch => bindActionCreators({ ...applicationContextActionCreator }, dispatch)))(RegisterClient));