const siteSettingsEnum = {
    "siteLoanerDropOffInstructions": {
        "name": "SiteLoanerDropOffInstructions",
        "value": 100
    },
    "caseOrderLabelZpl": {
        "name": "CaseOrderLabelZpl",
        "value": 101
    },
    "loanerTrayLabelZpl": {
        "name": "LoanerTrayLabelZpl",
        "value": 102
    }
};

export default siteSettingsEnum;