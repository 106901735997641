import React from "react";

const SiteSelector = (props) => {

    const caseSite = props.sites.find(site => site.foreignSiteId === props.caseSiteId);
    const caseSiteName = caseSite?.name;
    const caseSiteId = props.caseSiteId;
    const otherSites = props.sites.filter(site => site.foreignSiteId !== props.caseSiteId);

    return <div className="form-group">
        <label htmlFor="site">Site</label>
        <select className="form-control" id="site" name="site" onChange={props.onChange}>
            <option key={1} value={caseSiteId} selected>{caseSiteName}</option>
            {otherSites.map((s, i) => <option key={i + 1}
                                              value={s.foreignSiteId}>{s.name}</option>)}
        </select>
    </div>;
}
export default SiteSelector