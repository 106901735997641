import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Container, Row, Col, FormGroup, FormLabel, FormControl, Button, Alert } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import logo from '../../assets/img/brand/spm.png';

const UserAuthenticationResultEnum = {
    Success: 1,
    Failed: 2,
    LockedOut: 3,
    NotAllowed: 4,
    TwoFactorRequired: 5,
    PendingAuthorization: 6,
    SpmAccountLinkRequired: 7,
    NotAuthorizedOnSpm: 8, // IsWebUser is not checked on the SPM User Account object
};

class EpicSpmLink extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userName: "",
            userNameValid: false,
            password: "",
            passwordValid: false,
            emailAddress: "",
            emailAddressValid: false,
            domain: "",
            formErrors: { email: '', password: '' },
            formValid: false,
            returnError: "",
            domainList: [],
            returnUrl: "",
            data: ""
        };
        this.handleUserInput = this.handleUserInput.bind(this);
        this.authenticate = this.authenticate.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    componentDidMount() {
        // get domains.. if no domains, hide the domain control.
        let returnUrl = queryString.parse(this.props.location.search).forwardUrl;
        this.setState({ returnUrl: returnUrl });
        let data = queryString.parse(this.props.location.search).data;
        this.setState({ data: data });

        let getDomainsUri = window.location.protocol + "//" + window.location.host + "/api/auth/domains";
        fetch(getDomainsUri)
            .then(response => {
                if (response.redirected === true) {
                    this.props.history.push("/auth/login");
                }
                if (response.ok) {
                    return response;
                } else {
                    throw new Error(response.status + ": " + response.statusText + " - Error retrieving list of allowed domains.");
                }
            })
            .then(response => {
                return response.json();
            })
            .then(json => {
                this.setState({ domainList: json });
            })
            .catch(error => this.setState({ returnError: error.message }));
    }

    handleUserInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var partialState = {};
        partialState[name] = value;
        this.setState(partialState);
    }

    authenticate() {
        this.setState({ submitted: "true" });
        this.setState({ returnError: "" });
        let spmAuthUri = window.location.protocol + "//" + window.location.host + "/api/auth/SpmEpicLogin";
        var obj = { userName: this.state.userName, password: this.state.password, domain: this.state.domain, data: this.state.data, emailAddress: this.state.emailAddress };
        fetch(spmAuthUri,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'post',
                body: JSON.stringify(obj)
            }
        )
            .then(response => {
                if (response.redirected === true) {
                    this.props.history.push("/auth/login");
                }
                if (response.ok) {
                    return response;
                }
                else {
                    throw new Error(response.status + ": " + response.statusText + " - Error logging in with the SPM server.");
                }
            })
            .then(response => {
                return response.json();
            })
            .then(json => {
                switch (json.status) {
                    case UserAuthenticationResultEnum.Success:
                        const currentUser = json.user;
                        this.props.updateCurrentUser(currentUser);
                        return this.props.history.push(this.state.returnUrl);
                    default:
                        this.setState({ returnError: json.message });
                };
            })
            .catch(error => {
                this.setState({ returnError: error.message });
            });
    }

    keyPress(event) {
        // if enter key authenticate user
        if (event.which === 13) {
            this.authenticate();
        }
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <div>
                <div className="app flex-row align-items-center" style={{ "borderLeft": "2px solid darkgray", "borderRight": "2px solid darkgray" }}>
                    <Container>
                        {
                            this.state.returnError !== "" ?
                                <Row>
                                    <Col md={4}>
                                        <Alert variant="danger">
                                            {t('Error!')} - {this.state.returnError}
                                        </Alert>
                                    </Col>
                                </Row> : null
                        }
                        <Row>
                            <Col md={6}>
                                <img
                                    src={logo}
                                    width="66"
                                    height="66"
                                    className="d-inline-block float-left"
                                    alt="SPM Logo"
                                    style={{ "margin-right": "10px" }}
                                />
                                <span>
                                    <h3>SPM Login</h3>
                                    {t('Please authenticate with SPM using your SPM account credentials.')}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <FormGroup controlId="userName">
                                    <FormLabel>SPM Username</FormLabel>
                                    <FormControl type="text" name="userName" value={this.state.userName} onChange={this.handleUserInput} placeholder="Enter your SPM username" />
                                </FormGroup>
                                <FormGroup controlId="password">
                                    <FormLabel>SPM Password</FormLabel>
                                    <FormControl type="password" name="password" value={this.state.password} onChange={this.handleUserInput} onKeyPress={this.keyPress} placeholder="Enter your SPM password" />
                                </FormGroup>
                                {
                                    this.state.domainList && this.state.domainList.length > 0 ?
                                        <FormGroup controlId="formControlsSelect">
                                            <FormLabel>SPM Domain</FormLabel>
                                            <FormControl componentClass="select" name="domain" onChange={this.handleUserInput} value={this.state.domain} placeholder="select">
                                                <option value="">select</option>
                                                {
                                                    this.state.domainList.map((item, key) => {
                                                        return (<option key={key} value={item}>{item}</option>);
                                                    })
                                                }
                                            </FormControl>
                                        </FormGroup>
                                        : null
                                }
                                <Button className="primary float-left" onClick={this.authenticate} onKeyPress={this.keyPress}>Sign In</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <br />If you do not have an active SPM account, please <a href='#'>Click Here</a> to request one.
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div>
        );
    }
}

export default withTranslation()(compose(withRouter, connect(
    state => state.applicationContext, dispatch => bindActionCreators({ ...applicationContextActionCreator }, dispatch)))(EpicSpmLink));