import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, ButtonToolbar, ToggleButtonGroup, ToggleButton, Card, Popover, OverlayTrigger } from 'react-bootstrap';
import { AlertList } from "react-bs-notifier";
import { modalActionCreator } from '../../store/actions/modalActions';
import { renderRoleTooltip } from './Documentation/RoleTooltip';

import userService from '../../services/userService';

import '../../styles/spm.css';

const UserRoles = ({ userRoles, allRoles, handleRoleChange }) => {
    if (userRoles && allRoles) {
        return (
            <Form.Row>
                <Form.Group as={Col} md={5} controlId="roles">
                    <Form.Label>Web Security Roles</Form.Label>
                    {
                        allRoles.map((item, i) => {
                            return (
                                <>
                                    <OverlayTrigger
                                        id={`id-${item.name}-${i}`}
                                        key={`ot-${item.name}-${i}`}
                                        placement="right"
                                        delay={{ show: 250, hide: 100 }}
                                        overlay={renderRoleTooltip(item)}
                                        style={{ maxWidth: "110%" }}>
                                        <Form.Check
                                            id={`${item.name}-${i}`}
                                            key={`${item.name}-${i}`}
                                            name={`${item.name}-${i}`}
                                            checked={userRoles.indexOf(item.id) !== -1}
                                            type="checkbox"
                                            label={item.name}
                                            value={item.id}
                                            onChange={() => { handleRoleChange(item.id); }} />
                                    </OverlayTrigger>
                                </>
                            )
                        })
                    }
                    <div>For more info, please see <Link to='/admin/documentation'>Documentation</Link></div>
                </Form.Group>
            </Form.Row>
        );
    }

    return (null);
};

class User extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showScanItemsModal: false,
            user: {},
            alerts: [],
            roleIds: []
        };

        this.handleSaveUser = this.handleSaveUser.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleRadioInput = this.handleRadioInput.bind(this);
        this.clearAlerts = this.clearAlerts.bind(this);
        this.generateMessage = this.generateMessage.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.id !== undefined) {
            userService.getUserDetails(this.props.match.params.id)
                .then(response => {
                    if (response.data.success) {
                        var resultUser = response.data.payload;
                        var ids = resultUser.roles.map((item) => item.id);
                        this.setState({ user: resultUser, roleIds: ids }, () => this.getRoleTypes());
                    }
                });
        }
    }

    getRoleTypes() {
        userService.getRoles(this.state.user.userType)
            .then(response => {
                if (response.data.success) {
                    var result = response.data.payload;
                    this.setState({ allRoles: result });
                }
            });
    }

    handleRoleChange(id) {
        let newRoleIds = [...this.state.roleIds];
        const idx = newRoleIds.indexOf(id);

        if (idx >= 0) {
            newRoleIds = [...newRoleIds.slice(0, idx), ...newRoleIds.slice(idx + 1)];
        }
        else {
            newRoleIds.push(id);
        }

        this.setState({ roleIds: newRoleIds });
    }

    handleUserInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var partialState = this.state.user;
        partialState[name] = value;
        this.setState({ user: partialState });
    }

    generateMessage(type, headline, message) {
        const newAlert = {
            id: (new Date()).getTime(),
            type: type,
            headline: headline,
            message: message
        };

        this.setState({
            alerts: [...this.state.alerts, newAlert]
        });
    }

    clearAlerts() {
        this.setState({ alerts: [] });
    }

    onAlertDismissed(alert) {
        const alerts = this.state.alerts;

        // find the index of the alert that was dismissed
        const idx = alerts.indexOf(alert);

        if (idx >= 0) {
            this.setState({
                // remove the alert from the array
                alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
            });
        }
    }

    handleRadioInput(name, event) {
        var partialState = this.state.user;
        partialState[name] = event;
        this.setState({ user: partialState });
    }

    handleSaveUser() {
        var user = { ...this.state.user, roleIds: this.state.roleIds };

        userService.saveUser(user)
            .then(response => {
                if (response.data.success) {
                    var result = response.data.payload;

                    this.generateMessage("success", "Success", "Save was successful!");
                    this.setState({ user: result });
                    this.props.history.push('/admin/organizations/' + result.organizationId);
                }
            });
    }

    render() {
        return (
            <Row>
                <AlertList
                    position={"top-right"}
                    alerts={this.state.alerts}
                    timeout={3000}
                    dismissTitle="Dismis!"
                    onDismiss={this.onAlertDismissed.bind(this)}
                />
                <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
                    <Card>
                        <Card.Header className="d-flex align-items-center">
                            <div className="mr-auto" >{this.state.user.firstName} {this.state.user.lastName}</div>
                            <div className="card-btn-group">
                                <Button variant="primary" size="sm" onClick={() => this.handleSaveUser()}>Save & Close</Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} controlId="firstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control placeholder="" name="firstName" id="firstName" value={this.state.user.firstName} onChange={this.handleUserInput} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control placeholder="" name="lastName" id="lastName" value={this.state.user.lastName} onChange={this.handleUserInput} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="emailAddress">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control placeholder="" name="emailAddress" id="emailAddress" value={this.state.user.emailAddress} onChange={this.handleUserInput} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="accountName">
                                    <Form.Label>State</Form.Label>
                                    <ButtonToolbar>
                                        <ToggleButtonGroup type="radio" name="state" id="state" defaultValue={100} value={this.state.user.state} onChange={(e) => this.handleRadioInput("state", e)}>
                                            <ToggleButton value={100} variant="secondary">Active</ToggleButton>
                                            <ToggleButton value={150} variant="secondary">Suspended</ToggleButton>
                                            <ToggleButton value={0} variant="secondary">Deleted</ToggleButton>
                                        </ToggleButtonGroup>
                                    </ButtonToolbar>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Button onClick={() => this.props.showModal({
                                        open: true,
                                        title: 'Change User Password',
                                        message: '',
                                        model: this.state.user.id,
                                        hideModal: this.props.hideModal()
                                    }, 'changeUserPasswordModal')}>Change Password</Button>
                                </Form.Group>
                            </Form.Row>
                            <UserRoles userRoles={this.state.roleIds}
                                allRoles={this.state.allRoles}
                                handleRoleChange={this.handleRoleChange} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default connect(null, dispatch => bindActionCreators({ ...modalActionCreator }, dispatch))(User);