import React from 'react'
import { Button, Row, Col, ListGroup, ListGroupItem, Accordion, Card, Alert } from 'react-bootstrap'
import { compare } from '../../../utilities/sorts';

const NoRequirements = () => <div>No requirements loaded.</div>;

const byProcessStepOrder = (resultA, resultB) =>
    compare(resultA.processStepOrder, resultB.processStepOrder);

const byDescription = (resultA, resultB) =>
    compare(resultA.description, resultB.description);

const Requirements = ({ processSteps, requirements, selection, handleSelectionChange }) => {
    return (
        <div>
            {
                processSteps.map((ps, i) => {
                    return (
                        <div>
                            <Row>
                                <Col lg={6} md={6}>
                                    <div className="form-group">
                                        <h6>{ps}</h6>
                                        <ListGroup key={i} className="ml-2">
                                            {
                                                requirements
                                                    .sort(byDescription)
                                                    .filter(rq => rq.processStep === ps)
                                                    .map(r => {
                                                        return (
                                                            <ListGroupItem key={r.id} action variant={!selection.includes(r.id) ? "light" : "info"} onClick={() => handleSelectionChange(r.id)}>
                                                                {r.description}
                                                            </ListGroupItem>
                                                        )
                                                    })
                                            }
                                        </ListGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6}>

                                </Col>
                            </Row>
                        </div>
                    )
                })}
        </div>
    )
}

function RequirementsList({ requirements, selectedRequirements, handleRequirementChange }) {
    requirements.sort(byProcessStepOrder);

    // Order the process steps by processStep order and the requirements 
    // Decontam / Prep Pack / Sterilization / Point of use is the expected order
    let processSteps = [...new Set(requirements.map(data => data.processStep))];
    return (
        <div>
            <div>
                <Row>
                    <Col lg={6} md={6}>
                        <Alert variant="warning">
                            Click the row(s) below to mark which had poor quality
                        </Alert>
                    </Col>
                </Row>
            </div>
            {
                (requirements.length > 0) ?
                    <Requirements processSteps={processSteps} requirements={requirements} selection={selectedRequirements} handleSelectionChange={handleRequirementChange} />
                    :
                    <NoRequirements />
            }
        </div>
    );
}

export default RequirementsList
