import React, { Component } from 'react';
import { Row, Col, FormGroup, FormLabel, FormControl, Table, Button } from 'react-bootstrap';

class MessageLogs extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = { showScanItemsModal: false, organization: [], returnError: '' };
    }


    render() {

        var logs = this.props.apiUsageLogs.length ? this.props.apiUsageLogs : [{ emailUsage: [], smsUsage: [] }];
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <Table striped condensed hover responsive>
                            <thead>
                                <tr>
                                    <th style={{ display: (logs.length > 1 ? 'table-cell' : 'none') }}>Organization</th>
                                    <th>Site</th>
                                    <th>Type</th>
                                    {
                                        logs[0].emailUsage.map((item, i) => {
                                            return (
                                                <th key={i}>{item.periodName}</th>
                                            );
                                        })
                                    }
                                </tr>
                            </thead>
                            {
                                this.props.apiUsageLogs.map((item, i) => {
                                    return (
                                        <tbody key={i}>
                                            <tr>
                                                <td rowSpan="2" style={{ display: (logs.length > 1 ? 'table-cell' : 'none') }}> {item.organizationName}</td>
                                                <td rowSpan="2"> {item.siteName}</td>
                                                <td>Email</td>
                                                {
                                                    item.emailUsage.map((logItem, i) => {
                                                        return (
                                                            <td key={i}>{logItem.total}</td>
                                                        );
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td>Sms</td>
                                                {
                                                    item.smsUsage.map((logItem, i) => {
                                                        return (
                                                            <td key={i}>{logItem.total}</td>
                                                        );
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    );
                                })

                            }
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default MessageLogs;
