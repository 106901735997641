import React, { useState, useCallback } from 'react'
import { Card, Button, Form, Col, Row, ListGroup } from 'react-bootstrap';
import { faDolly, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useLoanerTrayQuery from "./useLoanerTrayQuery";

import TraySearchResults from "./TraySearchResults";

import TooltipOverlay from '../../../controls/tooltip-overlay/TooltipOverlay';
import LoadingButton from '../../../controls/buttons/LoadingButton';
import debounce from "../../debounce";

import enums from "../../../utilities/enums";
import { byTrayName } from "../../../utilities/sorts";

const notYetArrived = (loaner) => loaner.status === enums.loanerTrayStatusEnum.expected.value;

const oneSecondDelay = 1000;
const fifthSecondDelay = 200;

const TrayDropoff = ({
    caseOrder,
    selectedDeliverer,
    promptForDeliverer,
    createNewTray,
    dropOffNewTray,
    dropOffExpectedTray,
    dropOffAllExpected,
    setLoading,
    loading
}) => {
    const { t } = useTranslation();

    const [vendorId, setVendorId] = useState(0);
    const [nameText, setSearchText] = useState("");
    const [shouldSearch, setShouldSearch] = useState(false);
    const [showSearchPanel, setShowSearchPanel] = useState(false);
    const [showInitialResults, setShowInitialResults] = useState(false);

    const fastDebounceQuery = useCallback(debounce(() => {
        setShouldSearch(true);
    }, fifthSecondDelay), []);

    const slowDebounceQuery = useCallback(debounce(() => {
        setShouldSearch(true);
    }, oneSecondDelay), []);

    const initiateQuery = (fastQuery) => {
        setVendorId(caseOrder.vendorId);
        if (fastQuery) {
            fastDebounceQuery();
        } else {
            slowDebounceQuery();
        }
    };

    const onSearchTextChanged = (event) => {
        setShouldSearch(false);
        const text = event.target.value;
        setSearchText(text);
        setShowInitialResults(!text.trim().length);
        initiateQuery(text.length >= 3);
    };

    const productCodeText = "";
    const { searchResults, message } = useLoanerTrayQuery(vendorId, nameText, productCodeText, shouldSearch);

    const getExpectedCount = () => {
        return caseOrder?.loaners.filter(l => l.status === enums.loanerTrayStatusEnum.expected.value).length;
    };

    const searchHeader = showSearchPanel ? (
        <Col className="d-flex align-items-center">
            <Form.Label className="ml-auto find-items-label">{t("Find other items:")}</Form.Label>
            <div className="card-btn-group form-inline">
                <Form.Control size="sm" autoFocus onChange={onSearchTextChanged} value={nameText}></Form.Control>
                <Button size="sm" onClick={() => promptForDeliverer(createNewTray)}>{t("New")}</Button>
            </div>
        </Col>
    ) :
        <Col><Button className="float-right" size="sm" onClick={() => {
            setShouldSearch(true);
            setVendorId(caseOrder.vendorId);
            setShowSearchPanel(true);
            setShowInitialResults(true);
        }}>{t("Find other loaners")}</Button>
        </Col>;

    const searchPanel = showSearchPanel
        ? <ListGroup variant="flush" as={Col} className="tray-selectors">
            {
                <TraySearchResults
                    dropOffNewTray={dropOffNewTray}
                    promptForDeliverer={promptForDeliverer}
                    searchResults={searchResults}
                    message={message}
                    setLoading={setLoading}
                    loading={loading} />
            }
        </ListGroup>
        : <Col></Col>;

    return [
        <Card.Header>
            <div>{t('Add') + ` ${caseOrder.vendorName} ` + t('loaners')}</div>
        </Card.Header>,
        <Card.Body>
            <Row>
                <Col><Form.Label className="expected-items">{t('Expected items')} {`(${getExpectedCount()}):`}</Form.Label></Col>
                <Col>{searchHeader}</Col>
            </Row>
            <Row>
                <Col>
                    <ListGroup variant="flush" as={Col} className="tray-selectors">
                        {(caseOrder && caseOrder.loaners ?
                            caseOrder.loaners : []).filter(notYetArrived).sort(byTrayName).map((loaner) => {
                                return (
                                    <ListGroup.Item key={loaner.id} action className="d-flex align-items-center justify-content-between tight-list-group">
                                        <div>{loaner.trayName}</div>
                                        <LoadingButton
                                            size="sm"
                                            disabled={loading.key === 'expected-tray'}
                                            loading={
                                                loading.key === 'expected-tray' &&
                                                loading.target === loaner.id
                                            }
                                            onClick={() => {
                                                setLoading('expected-tray', loaner.id);
                                                promptForDeliverer(() => {
                                                    dropOffExpectedTray(loaner);
                                                });
                                            }}>
                                            <FontAwesomeIcon icon={faDolly} className="gi-1x mr-2" />
                                            <span>{t('Drop off')}</span>
                                        </LoadingButton>
                                    </ListGroup.Item>
                                );
                            })
                        }
                    </ListGroup>
                </Col>
                <Col>
                    {searchPanel}
                </Col>
            </Row>
            <Row colSpan={1}>
                <Col className="d-flex align-items-center justify-content-end">
                    <Form.Label style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>{t("Delivering as:")}&nbsp;{selectedDeliverer || t('Unknown')}&nbsp;</Form.Label>
                    <Button className="mr-2" size="sm" style={{ backgroundColor: "transparent", borderColor: "transparent" }} onClick={() => promptForDeliverer(null)}>
                        <FontAwesomeIcon icon={faPencilAlt} color="black" backgroundColor="transparent" className="gi-1-5x" />
                    </Button>
                    <LoadingButton
                        size="sm"
                        disabled={!(caseOrder && caseOrder.loaners && caseOrder.loaners.some(Boolean) && caseOrder.loaners.some(notYetArrived))}
                        loading={loading.key === 'drop-off-all'}
                        onClick={() => {
                            setLoading('drop-off-all', undefined);
                            promptForDeliverer(dropOffAllExpected);

                        }}>
                        <FontAwesomeIcon icon={faDolly} className="mr-2" />
                        <span>{t('Drop off all')}</span>
                    </LoadingButton>
                </Col>
                <Col className="d-flex align-items-center justify-content-beginning">
                    {showInitialResults ? <h6 className="ml-auto vendor-search-limit">{t("Showing first 100 loaners. Search to find more.")}</h6> : null}
                </Col>
            </Row>
        </Card.Body>

    ];
}
export default TrayDropoff;