import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, FormLabel, FormControl, Table, Button } from 'react-bootstrap';
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import  authService from '../../services/authService';

class Logout extends Component {
    constructor(props, context) {
        super(props, context);
    }
    
    componentDidMount() {
        authService.logout()
            .then(response => {
                console.log('logout');
                console.log(response);
                if(response.status === 200) {
                    console.log("redirecting");
                    this.props.clearCurrentUser();
                    this.props.history.push("/auth/login");
                }
                else 
                {
                    console.log("error");
                    var errorMessage = response.status + ": " + response.statusText + " - Error logging out.";
                    this.setState({ returnError: errorMessage });
                }
            });
    }

    render() {
        return (
            <div>Redirecting...</div>    
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearCurrentUser: () => dispatch(applicationContextActionCreator.updateCurrentUser(null))
    };
}; 

export default connect(null, mapDispatchToProps)(Logout);
