import React, { Component } from 'react';
import { Row, Col, Table, Button, Modal } from 'react-bootstrap';
import meditechMappingService from '../../services/meditechMappingService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faKey, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import eventUtilities from '../../utilities/eventUtilities';

class MeditechMappings extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showEditModal: false,
            showDeleteModal: false,
            returnError: '',
            licenseKeyModalHeaderText: "",
            editMapping: {
                organizationId: 0,
                foreignIdType: '',
                foreignId: '',
                localId: ''
            },
            disabled: false
        };
        this.showEditModal = this.showEditModal.bind(this);
        this.showNewModal = this.showNewModal.bind(this);
        this.hideEditModal = this.hideEditModal.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleUserIntInput = this.handleUserIntInput.bind(this);
    }

    showEditModal(mapping, event) {
        this.setState({ licenseKeyModalHeaderText: 'Edit Meditech Mapping', editMapping: { ...mapping }, showEditModal: true });
    }

    showNewModal() {
        //Check disabled to prevent double clicking, which would spawn two dialogs
        if (!this.state.disabled) {
            this.setState({
                licenseKeyModalHeaderText: 'Create New Meditech Mapping',
                editMapping: {
                    organizationId: this.state.organizationId
                },
                showEditModal: true,
                disabled: true
            });
        }
    }

    hideEditModal() {
        this.setState({ licenseKeyModalHeaderText: 'Edit Meditech Mapping', editMapping: {}, showEditModal: false, disabled: false });
    }

    showDeleteModal(mapping, event) {
        this.setState({ editMapping: { ...mapping }, showDeleteLicenseModal: true });
    }

    hideDeleteModal() {
        this.setState({ editMapping: {}, showDeleteLicenseModal: false });
    }

    handleDelete() {
        meditechMappingService.deleteMapping(this.state.editMapping.id).then(response => {
            if (response.data.success) {
                var existing = this.props.mappings.filter(x => x.id !== this.state.editMapping.id);
                this.props.modifyState("mappings", existing);
                this.setState({ editMapping: {}, showDeleteLicenseModal: false });
            }
        })
    }

    handleSave() {
        var mapping = { ...this.state.editMapping };
        mapping.organizationId = this.props.organizationId;

        meditechMappingService.saveMapping(mapping)
            .then(response => {
                if (response.data.success) {

                    if (mapping.id) {
                        // edit existing
                        var existing = this.props.mappings.filter(x => x.id !== mapping.id);
                        existing.push(mapping);
                        this.props.modifyState("mappings", existing);
                    }
                    else {
                        // new mapping
                        mapping.id = response.data.payload.id;
                        this.props.mappings.push(mapping);
                    }

                    this.setState({ editMapping: mapping, disabled: false });
                    this.hideEditModal();
                }
            });
    }

    handleUserInput(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        var partialState = this.state.editMapping;
        partialState[name] = value;
        this.setState({ editMapping: partialState });
    }

    handleUserIntInput(event) {
        const target = event.target;
        const value = target.value ? parseInt(target.value) : '';
        const name = target.name;
        var partialState = { ...this.state.editMapping };
        partialState[name] = value;
        this.setState({ editMapping: partialState });
    }

    //Note: the name attribute for each input must match the name of the underlying property in the value.
    //The onChange handler depends on the name to update the correct property in the mapping data.*/ }
    render() {
        return (
            <div>
                <Modal bsSize="small" show={this.state.showDeleteLicenseModal}>
                    <Modal.Header>
                        <Modal.Title>Confirm Deletion</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete the mapping?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.hideDeleteModal} variant="default" type="button">Cancel</Button>
                        <Button onClick={this.handleDelete} variant="danger" type="button">Delete</Button>
                    </Modal.Footer>
                </Modal>
                <Modal bsSize="small" show={this.state.showEditModal}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.licenseKeyModalHeaderText}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="foreignIdType">Mapping Type</label>
                            <input required className="form-control" name='foreignIdType' value={this.state.editMapping.foreignIdType} onChange={this.handleUserInput}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="foreignId">Meditech Key</label>
                            <input required className="form-control" name='foreignId' value={this.state.editMapping.foreignId} onChange={this.handleUserInput}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="localId">SPM Key</label>
                            <input required className="form-control" name='localId' onKeyDown={eventUtilities.verifyNumericInput} value={this.state.editMapping.localId} onChange={this.handleUserIntInput} type="text" pattern="[0-9]*" />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.hideEditModal} variant="warning" type="button">Cancel</Button>
                        <Button onClick={this.handleSave} variant="primary" type="button">Save</Button>
                    </Modal.Footer>
                </Modal>
                <Row>
                    <Col md={12}>
                        <Table striped condensed hover responsive>
                            <thead>
                                <tr>                
                                    <th>Mapping Type</th>
                                    <th>Meditech Key</th>
                                    <th>SPM Key</th>
                                    <th />
                                    <th>
                                        <span className="fa-layers fa-fw text-success float-right show-hand"
                                            onClick={this.showNewModal} disabled={this.state.disabled}>
                                            <FontAwesomeIcon icon={faKey} transform={{ rotate: -45 }} />
                                            <FontAwesomeIcon icon={faPlus} transform="shrink-8 right-10" />
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.mappings.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.foreignIdType}</td>
                                                <td>{item.foreignId}</td>
                                                <td>{item.localId}</td>
                                                <td className="table-fit"><FontAwesomeIcon icon={faPen} className="gi-1-5x text-primary show-hand" onClick={(e) => this.showEditModal(item, e)} /></td>
                                                <td className="table-fit"><FontAwesomeIcon icon={faTimesCircle} className="gi-1-5x text-danger show-hand" onClick={(e) => this.showDeleteModal(item, e)} /></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default MeditechMappings;
