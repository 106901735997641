import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';

export default class TabList extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            activeTab: ""
        };
    }

    componentDidMount() {
        let children = this.props.children;
        if (!Array.isArray(children)) {
            children = [children];
        }
        const activeTab = children[0].props.label;
        this.setState({ activeTab: activeTab, children: children });
    }

    componentDidUpdate(prevProps) {
        if (this.props.children[0].props.label !== prevProps.children[0].props.label) {
            this.setState({ activeTab: this.props.children[0].props.label });
        }
    }

    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    }

    render() {
        let { onClickTabItem, props: { children }, state: { activeTab } } = this;

        if (!Array.isArray(children)) {
            children = [children];
        }

        let className = "nav";

        switch (this.props.variant) {
            case "pills":
                className += ' nav-pills';
                break;
            case "verticalPills":
                className += ' nav-pills flex-column';
                break;
            case "verticalTabs":
                className += ' nav-tabs flex-column';
                break;
            default:
                className += ' nav-tabs';
        }

        return (
            <div className="tab-list-container">
                <nav>
                    <div className={className}>
                        {children.filter(c => c !== null).map((child, idx) => {
                            const { label, disabled, hidden } = child.props;

                            return (
                                !hidden && <Tab
                                    index={idx}
                                    activeTab={activeTab}
                                    key={label}
                                    label={label}
                                    onClick={onClickTabItem}
                                    disabled={disabled ? true : false}
                                />
                            );
                        })}
                    </div>
                </nav>
                <div className="tab-content">
                    {children.filter(c => c !== null).map((child, i) => {
                        if (child.props.label !== activeTab || child.props.hidden === true) return undefined;
                        return <div key={i} className={child.props.isTable ? "tab-content-table" : "tab-content-form"}>{child.props.children}</div>;
                    })}
                </div>
            </div>
        );
    }
}