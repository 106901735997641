import React, { Component, Suspense } from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import NotificationRoot from '../../controls/notifications/NotificationRoot';
import ModalRoot from '../../controls/modals/ModalRoot';
import SpinnerRoot from '../../controls/spinner/SpinnerRoot'

import SpmHeader from '../SpmLayout/SpmHeader';
import SpmFooter from '../SpmLayout/SpmFooter';
import SpmSidebar from '../SpmLayout/SpmSidebar';
import routes from '../Shared/routes';
import nav from '../Shared/nav';

import AssureContext from '../../components/auth/AssureContext';
import PrinterContext from '../../contexts/PrinterContext';
// temp

class LoanerLayout extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            navigation: { items: [] }
        };
    }

    render() {
        return (
            <AssureContext>
                <PrinterContext>
                    <div className="app default-layout">
                        <SpmSidebar nav={nav.loaner} />
                        <div className="main-wrapper">
                            <SpmHeader routes={routes.loaner} applicationContext={this.props.applicationContext} />
                            <div className="main-body">
                                <main className="main">
                                    <Container fluid>
                                        <Switch>
                                            <Redirect exact strict from="/loaners" to="/loaners/home" />
                                            {routes.loaner.map((route, idx) => {
                                                return route.component && (
                                                    <Route
                                                        key={idx}
                                                        path={route.path}
                                                        exact={route.exact}
                                                        name={route.name}
                                                        render={props => (
                                                            <route.component {...props} />
                                                        )} />
                                                )
                                            })}

                                            {/*
                                        <Route
                                            path='/admin/organizations'
                                            name="Admin"
                                            render={props => <OrganizationList {...props} />} />
                                        <Route
                                            path='/admin/organization/:id?'
                                            name="Organization"
                                            render={props => <Organization {...props} />} />
                                        <Route
                                            path='/admin/user/:id?'
                                            name="User"
                                            render={props => <User {...props} />} />
                                        <Route
                                            path='/admin/activesockets/:id?'
                                            name="Active Sockets"
                                            render={props => <ActiveSockets {...props} />} />
                                        */}
                                            <Redirect exact strict from="/loaners" to="/loaners/home" />
                                            <Redirect from="/admin" to="/admin/organizations" />
                                            <Redirect from="/" to="/" />
                                        </Switch>
                                    </Container>
                                </main>
                            </div>
                            <SpmFooter />
                        </div>
                    </div>
                    <ModalRoot />
                    <NotificationRoot />
                    <SpinnerRoot />
                </PrinterContext>
            </AssureContext>
        )
    }
}
export default LoanerLayout;