import React, { Component } from 'react';
import { applicationContextActionCreator } from '../store/actions/applicationContextActions';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

class UserContext extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.props.applicationContext.user !== null ? this.props.applicationContext.user.fullName : "Undefined User"}
                {/*
            <div>
                    <div className="user-context">
                        <div className="user-context-name">
                            {this.props.applicationContext.user !== null ? this.props.applicationContext.user.fullName : null}
                        </div>
                        <div>
                            {this.props.applicationContext.client !== null ? this.props.applicationContext.client.name : null}
                        </div>
                    </div>
            </div>
            */}
                {
                    //JSON.stringify(this.props.applicationContext)
                }
            </>
        );
    }
}
export default compose(withRouter, connect(
    state => state.applicationContext, dispatch => bindActionCreators({ ...applicationContextActionCreator }, dispatch)))(UserContext);