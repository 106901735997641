export const claims = {
    userType: 'user_type',
    userRole: 'user_role',
    organizationId: 'organization_id',

    isSpmUser: 'isSpmUser',
    isEpicOrUser: 'isEpicOrUser',
    isLoanerKioskUser: 'isLoanerKioskUser',

    isOrgAdminUser: 'isOrgAdminUser',
    isSterisSupportUser: 'isSterisSupportUser',
    isSterisSupportAdminUser: 'isSterisSupportAdminUser',
    isSterisAdminUser: 'isSterisAdminUser',

    isMessagingApiClient: 'isMessagingApiClient',
    isSpmInterfaceApiClient: 'isSpmInterfaceApiClient',
    isLicenseKeyApiClient: 'isLicenseKeyApiClient',
    isCasechekApiClient: 'isCasechekApiClient',
    isTorqApiClient: 'isTorqApiClient',
    isDynamicConfigClient: 'isDynamicConfigClient',
    isSupportApiClient: 'isSupportApiClient',

    crudOrganizations: 'crudOrganizations',
    crudOrganizationUsers: 'crudOrganizationUsers',
    crudOrganizationApiKeys: 'crudOrganizationApiKeys',
    crudLicenseKeys: 'crudLicenseKeys',
    crudSiteSettings: 'crudSiteSettings',
    crudClientSettings: 'crudClientSettings',
    canVerifyLoanerDropOff: 'canVerifyLoanerDropOff',
};

export const claimValues = {
    createReadUpdateDelete: '1111',
    createReadUpdate: '1110',
    createRead: '1100',
    readUpdate: '0110',
    create: '1000',
    read: '0100',
    update: '0010',
    delete: '0001',
    isTrue: 'true',
    isFalse: 'false',
};

export const roleIds = {
    loanerKioskUser: 10100,
};


export const passwordRegexString = "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-!\"#$%&'()*+,./:;<=>?@[\\]^_`{|}~£])([a-zA-Z0-9-!\"#$%&'()*+,./:;<=>?@[\\]^_`{|}~£]{8,})";
export const specCharRegexString = "[-!\\\"#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~£]";

