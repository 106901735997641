import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, InputGroup, Card, Form, Button, ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../styles/spm.css';

import UserList from './UserList';
import ApiKeyList from './ApiKeyList';
import SiteList from './SiteList';
import MessageLogs from './MessageLogs';
import LicenseKeys from './LicenseKeys';
import MeditechMappings from './MeditechMappings';
import ServerInstanceList from './ServerInstanceList';
import { modalActionCreator } from '../../store/actions/modalActions';
import { applicationMessagingActionCreator } from '../../store/actions/applicationMessagingActions';
import OrganizationService from '../../services/organizationService';
import { TabList, TabItem } from '../../controls/tabcontrol';
import { ConditionalRender } from '../../controls/formControls'
import settingsEnum from '../../utilities/enums/orgSettingsEnum';

const epicBarcodeSeparator = setting => setting.key === settingsEnum.epicBarcodeSeparator.value;

class Organization extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            orgCodeList: [],
            invalidOrgCode: "",
            invalidOrgName: "",
            organization: { users: [], apiKeys: [], licenses: [], sites: [], allowRepNotifiedEvent: false, usePatientInitials: false, serverInstances: [{ registeredClients: [] }], apiUsageLogs: [{ emailUsage: [], smsUsage: [] }], settings: [] },
            returnError: "",
            saveSuccess: true,
            tenantDatabaseButtonDisabled: false,
        };

        this.modifyState = this.modifyState.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleSaveOrganization = this.handleSaveOrganization.bind(this);
        this.handleCreateTenantDatabase = this.handleCreateTenantDatabase.bind(this);
        this.saveOrganization = this.saveOrganization.bind(this);
        this.restrictToAlpha = this.restrictToAlpha.bind(this);
        this.handleRadioInput = this.handleRadioInput.bind(this);
        this.prepOrganization = this.prepOrganization.bind(this);
        this.orderChildLists = this.orderChildLists.bind(this);
        this.validateOrgCodeUserInput = this.validateOrgCodeUserInput.bind(this);
        this.validateOrgNameUserInput = this.validateOrgNameUserInput.bind(this);

        this.updateServerInstanceList = this.updateServerInstanceList.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.setParentOrg = this.setParentOrg.bind(this);

        this.renderOrgType = this.renderOrgType.bind(this);
        this.renderParentOrg = this.renderParentOrg.bind(this);

        this.getHostname = this.getHostname.bind(this);
        this.getAppUrl = this.getAppUrl.bind(this);
        this.getOrganization = this.getOrganization.bind(this);
        this.getAllOrganizations = this.getAllOrganizations.bind(this);

        this.copyOrgUrl = this.copyOrgUrl.bind(this);
        this.openOrgUrl = this.openOrgUrl.bind(this);

        this.handleSettingsInput = this.handleSettingsInput.bind(this);
    }

    copyOrgUrl() {
        const url = this.getAppUrl();

        if (window.isSecureContext && navigator.clipboard != null) {
            navigator.clipboard.writeText(url);
        }
    }

    openOrgUrl() {
        const url = this.getAppUrl();
        window.open(url, "_blank");
    }

    componentDidMount() {
        this.getOrganization();
        this.getAllOrganizations();
    }

    getAllOrganizations() {
        OrganizationService.getAllOrganizations()
            .then(response => {
                if (response.data.success) {
                    var result = response.data.payload;
                    const rawOrgs = result.filter(p => p.id !== this.state.organization.id);
                    var filteredOrgCodes = [];
                    // && p.orgCode !== null
                    //.map((item, i) => { return item.orgCode; });
                    rawOrgs.map((item, i) => {
                        if (item.orgCode !== null) {
                            filteredOrgCodes.push(item.orgCode.toLowerCase());
                        }
                        item.childOrganizations.map((child, p) => {
                            if (child.orgCode !== null) {
                                filteredOrgCodes.push(child.orgCode.toLowerCase());
                            }
                        });
                    });

                    this.setState({ orgCodeList: filteredOrgCodes.sort() });
                }
            });
    }

    isOrgTypeId = (orgType) => this.state.organization.organizationTypeId === orgType;

    getOrganization() {
        OrganizationService.getOrganizationDetails(this.props.match.params.id)
            .then(response => {
                if (response.data.success) {
                    let org = response.data.payload;

                    this.orderChildLists(org);
                    this.setState({ organization: org });
                }
            });
    }

    prepOrganization(json) {

        var returnValue = json;

        if (returnValue.success === true) {
            var returnedOrg = returnValue.payload;

            // sort child lists
            var savedOrg = this.orderChildLists(returnedOrg);
            // update the state
            this.setState({ organization: savedOrg });
        }
        else {
            // error condition
            this.setState({ returnError: json.message, saveSuccess: json.success });
        }
    }

    orderChildLists(org) {
        if (org.users && org.users.length !== 0) {
            org.users = org.users.sort((a, b) => (a.lastName.toLowerCase() > b.lastName.toLowerCase()) ? 1 : -1);
        }

        if (org.apiKeys && org.apiKeys.length !== 0) {
            org.apiKeys = org.apiKeys.sort((a, b) => (a.id > b.id) ? 1 : -1);
        }

        if (org.licenses && org.licenses.length !== 0) {
            org.licenses = org.licenses.sort((a, b) => (a.siteCode.toLowerCase() > b.siteCode.toLowerCase()) ? 1 : (b.siteCode.toLowerCase() > a.siteCode.toLowerCase()) ? -1 : (a.licenseType > b.licenseType) ? 1 : (b.licenseType > a.licenseType) ? -1 : 0);
        }

        if (org.serverInstances && org.serverInstances.length !== 0) {
            org.serverInstances = org.serverInstances.sort((a, b) => (a.instanceName.toLowerCase() > b.instanceName.toLowerCase()) ? 1 : -1);
        }

        if (org.sites && org.sites.length !== 0) {
            org.sites = org.sites.sort((a, b) => (a.siteCode.toLowerCase() > b.siteCode.toLowerCase()) ? 1 : ((b.siteCode.toLowerCase() > a.siteCode.toLowerCase) ? -1 : 0));
        }

        return org;
    }

    updateServerInstanceList(serverInstance) {
        const org = this.state.organization;
        const existing = org.serverInstances.filter(x => x.id !== serverInstance.id);
        existing.push(serverInstance);
        org.serverInstances = existing;
        this.orderChildLists(org);
        this.setState({ organization: org });
    }

    handleSaveOrganization() {
        if (this.state.invalidOrgCode.length === 0 &&
            this.state.invalidOrgName.length === 0) {
            this.saveOrganization();
        }
    }

    displayWarningMessage(title, msg) {
        const t = this.props.t;

        this.props.showModal({
            open: true,
            title: title,
            message: msg,
            confirm: this.props.hideModal,
            confirmText: t("OK"),
            cancel: this.props.hideModal
        }, 'errorModal');
    }

    handleCreateTenantDatabase() {
        const t = this.props.t;
        const orgCode = this.state.organization.orgCode;

        if (!orgCode || orgCode.length === 0) {
            this.displayWarningMessage(t("Org Code is not provided"), t("Please fill out the Org Code and then save."));
        }
        else {
            OrganizationService.getOrganizationDetails(this.props.match.params.id)
                .then(response => {
                    if (response.data.success) {
                        let org = response.data.payload;

                        if (!org.orgCode || orgCode.length === 0) {
                            this.displayWarningMessage(t("Org Code is not provided"), t("The server reports that the Org Code field is empty. Please, fill the Org Code field and save before creating the database."));
                        } else {
                            var payload = { id: org.id };
                            OrganizationService.createTenantDatabase(payload)
                                .then(response => {
                                    if (response.data.success) {
                                        this.props.createSuccessNotification(t("Success"), t("Successfully sent tenant creation request to the server!"));
                                        this.setState({ tenantDatabaseButtonDisabled: true });
                                    }
                                });
                        }
                    }
                });
        }
    }

    saveOrganization() {
        const t = this.props.t;

        const saveFunc = () => {
            OrganizationService.saveOrganization(this.state.organization)
                .then(response => {
                    if (response.data.success) {
                        const org = response.data.payload;
                        if (!org.state) {

                            const closeFunc = () => {
                                this.props.hideModal();
                                // redirect back to org list
                                this.props.history.push("/admin/organizations");
                            }

                            // show successful deletion modal
                            this.props.showModal({
                                open: true,
                                title: t("Success"),
                                message: t("{{orgName}} was successfully deleted", { "orgName": org.name }),
                                closeModal: closeFunc
                            }, 'alertModal');
                        }
                        else {
                            const errors = response.data.errors;
                            const hasKeyError = errors && errors.some(x => x.includes("public key"));
                            const publicKeyStatus = hasKeyError ? t("Public key could not be generated. Patient initials can not be used.") : "";
                            this.props.createSuccessNotification(t("Success"), t("Save was successful!") + publicKeyStatus);
                            this.prepOrganization(response.data);

                            if (hasKeyError) {
                                this.onPatientInitialsChecked(false);
                            }
                        }
                    }
                });
        }

        if (!this.state.organization.state) {
            // this will force a confirmation of the deleted status to the user.
            const message = t("You are about to delete the {{orgName}} organization.", { "orgName": this.state.organization.name })
                + "\n\n"
                + t("Please choose confirm to continue to delete the organization or choose cancel to return to the screen.");
            this.props.showModal({
                open: true,
                title: t("Please confirm."),
                message: message,
                confirm: saveFunc,
                confirmText: t("Confirm"),
                cancel: this.props.hideModal
            }, 'confirm');
        } else {
            saveFunc();
        }
    }

    makeChildOrganization() {
        const t = this.props.t;
        OrganizationService.makeChildOrganization(this.state.organization)
            .then(response => {
                if (response.data.success) {
                    this.props.createSuccessNotification(t("Success"), t("Save was successful!"));
                    this.prepOrganization(response.data);
                }
            });
    }

    handleRadioInput(name, event) {
        var partialState = this.state.organization;
        partialState[name] = event;
        this.setState({ organization: partialState });
    }

    restrictToAlpha(event) {
        if (!event.key.match(/^[a-z]+$/i)) {
            event.preventDefault();
        }
    }

    validateOrgCodeUserInput(event) {
        const t = this.props.t;
        const value = event.target.value.toLowerCase().trim();

        let errorMessage;
        if (value.length < 3) {
            errorMessage = t("Organization code should be at least 3 characters long.");
            this.setState({ invalidOrgCode: errorMessage });
        } else {
            this.setState({ invalidOrgCode: "" });

            if (value === "admin") {
                errorMessage = t("{{orgCode}} - is not allowed.  Please select a different organization code", { "orgCode": value });
                this.setState({ invalidOrgCode: errorMessage });
            }
            else {
                this.setState({ invalidOrgCode: "" });

                const containsIt = (this.state.orgCodeList.indexOf(value) > -1);
                if (containsIt) {
                    // respond correctly - warning message, etc...
                    errorMessage = t("{{orgCode}} - is already in use by another organization.  Please select a different organization code", { "orgCode": value });
                    this.setState({ invalidOrgCode: errorMessage });
                }
                else {
                    this.setState({ invalidOrgCode: "" });
                    // update the value

                    const partialState = this.state.organization;
                    partialState.orgCode = value;
                    this.setState({ organization: partialState });
                }
            }
        }
    }

    validateOrgNameUserInput(event) {
        const t = this.props.t;
        const value = event.target.value.toLowerCase().trim();

        let errorMessage;
        if (value.length < 5) {
            errorMessage = t("Organization name should be at least 5 characters long.");
            this.setState({ invalidOrgName: errorMessage });
        }
        else {
            this.setState({ invalidOrgName: "" });
        }
    }

    handleUserInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const partialState = this.state.organization;
        partialState[name] = value;
        this.setState({ organization: partialState });
    }

    handleSettingsInput(event, settingName) {
        const partialState = this.state.organization;
        const settingIndex = partialState.settings.findIndex(settingName);

        if (settingIndex > -1) {
            partialState.settings[settingIndex].value = event.target.value;
        }
        else {
            const settingKey = settingsEnum[event.target.id];
            if (settingKey != null) {
                const newSetting = {
                    id: 0,
                    organizationId: this.state.organization.id,
                    key: settingKey.value,
                    value: event.target.value
                }

                partialState.settings = [...partialState.settings, newSetting];
            }
        }

        this.setState({ organization: partialState });
    }

    onPatientInitialsChecked(value) {
        this.modifyState("usePatientInitials", value);
    }

    onAllowRepNotifiedEventChecked(value) {
        this.modifyState("allowRepNotifiedEvent", value);
    }

    modifyState(propName, obj) {
        const org = { ...this.state.organization };
        org[propName] = obj;
        this.orderChildLists(org);
        this.setState({ organization: org });
    }

    closeModal(event) {
        this.props.hideModal();
    }

    setParentOrg = (prop) => {
        this.modifyState("parentOrganizationId", prop);
        this.makeChildOrganization();
        this.props.hideModal();
    }

    renderParentOrg(param) {
        const t = this.props.t;
        switch (param.organizationTypeId) {
            case 1:
                return (
                    <div className="form-group">
                        <label htmlFor="makeChild">{t("Make this a child organization")}</label><br />
                        <input type="submit" id="makeChild" name="makeChild" value={t("Choose parent")} className="btn btn-primary" aria-describedby="makeChildHelp" onClick={() => this.props.showModal({
                            open: true,
                            title: t('Make Parent of another Organization'),
                            message: '',
                            model: '',
                            closeModal: this.closeModal,
                            saveModel: this.setParentOrg
                        }, 'makeChild')}
                        /><br />
                    </div>
                );
            case 3:
                if (typeof param.parentOrganizationId !== 'undefined' && param.parentOrganizationId > 0) {
                    return (
                        <div className="form-group">
                            <label htmlFor="orgType">{t("Parent Organization Name")}</label>
                            <input className="form-control" type="text" id="orgType" name="orgType" value={param.parentOrganizationName} aria-describedby="orgTypeHelp" disabled />
                            <small id="orgTypeHelp" className="form-text text-muted"> </small>
                        </div>
                    );
                }
                break;
            default:
                return <div />;
        }
    }

    getHostname() {
        var host = window.location.host;
        var domain = host.split('.');
        return host.substring(host.indexOf('.') + 1);
    }

    getAppUrl() {
        var protocol = window.location.protocol;
        return protocol + "//" + this.state.organization.orgCode + "." + this.getHostname()
    }
    renderOrgType(param) {
        const t = this.props.t;
        switch (param) {
            case 1:
                return t('Organization');
            case 2:
                return t('Parent Organization');
            case 3:
                return t('Child Organization');
            case 4:
                return t('Steris Organization');
            default:
                return t('Unknown');
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Row>
                <Col lg={{ span: 10, offset: 1 }} >
                    <Card>
                        <Card.Header className="d-flex align-items-center">
                            <div className="mr-auto" >{this.state.organization.name}</div>
                            <div className="card-btn-group">
                                <Button variant="primary" size="sm" onClick={this.handleSaveOrganization}>{t("Save")}</Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} controlId="name">
                                    <Form.Label>{t("Name")}</Form.Label>
                                    <Form.Control value={this.state.organization.name || ''} name="name" onChange={this.handleUserInput} onBlur={this.validateOrgNameUserInput} />
                                    {
                                        this.state.invalidOrgName.length > 0 ?
                                            <small id="orgNameWarning" className="form-text text-danger"><b>{this.state.invalidOrgName}</b></small>
                                            :
                                            <small id="nameHelp" className="form-text text-muted">{t("Please enter the organization's name.")}</small>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="orgCode">
                                    <Form.Label>{t("Org Code")} / {t("Tenant ID")}</Form.Label>
                                    <Form.Control name="orgCode" value={this.state.organization.orgCode || ''} maxLength="10" onKeyPress={(e) => this.restrictToAlpha(e)} onChange={this.handleUserInput} onBlur={this.validateOrgCodeUserInput} aria-describedby="orgCodeHelp" required />
                                    {
                                        this.state.invalidOrgCode.length > 0 ?
                                            <small id="orgCodeWarning" className="form-text text-danger"><b>{this.state.invalidOrgCode}</b></small>
                                            :
                                            <small id="orgCodeHelp" className="form-text text-danger"><b>{t("Note")}</b>: {t("Editing this will change the Customer's app URL!")}</small>
                                    }
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="organizationTypeId">
                                    <Form.Label>{t("Organization Type")}</Form.Label>
                                    <Form.Control
                                        value={this.renderOrgType(this.state.organization.organizationTypeId)}
                                        aria-describedby="orgTypeHelp"
                                        readOnly />
                                    <small id="orgTypeHelp" className="form-text text-muted"> </small>
                                </Form.Group>
                                <Form.Group as={Col} controlId="orgUrl">
                                    <Form.Label>{t("Customer App URL")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control value={this.getAppUrl()} aria-describedby="tenantUrlHelp" readOnly />
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary"
                                                onClick={this.openOrgUrl}
                                                disabled={this.state.organization.orgCode == null || this.state.organization.orgCode.length == 0} >
                                                {t("Open")}
                                            </Button>
                                            <Button variant="outline-secondary"
                                                onClick={this.copyOrgUrl}
                                                disabled={!window.isSecureContext || this.state.organization.orgCode == null || this.state.organization.orgCode.length == 0} >
                                                {t("Copy")}
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <small id="tenantUrlHelp" className="form-text text-muted">{t("This is the Customer's unique URL for accessing the API and application.")}</small>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="createTenantDatabase">
                                    {!this.state.organization.isDatabaseCreated && (
                                        <>
                                            <Form.Label>{t("Tenant Database")}</Form.Label>
                                            <div className="form-inline">
                                                <Button
                                                    disabled={this.state.tenantDatabaseButtonDisabled}
                                                    onClick={this.handleCreateTenantDatabase}>
                                                    {t("Create Database")}
                                                </Button>
                                            </div>
                                            {!this.state.tenantDatabaseButtonDisabled && <small id="tenantDatabaseCreationHelp" className="form-text text-muted">{t("Tenant Database for LS:360 and OR Portal is not yet created.")}</small>}
                                            {this.state.tenantDatabaseButtonDisabled && <small id="tenantDatabaseCreationHelp" className="form-text text-muted">{t("Tenant Database for LS:360 and OR Portal is in progress.")}</small>}
                                        </>
                                    )}
                                </Form.Group>
                                <ConditionalRender visible={this.state.organization.vendorName !== null}>
                                    <Form.Group as={Col} controlId="demoVendor">
                                        <Form.Label>{t("Vendor Name")} / {t("Vendor ID")}</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control aria-describedby="VendorName" value={this.state.organization.vendorName || ''} readOnly />
                                        </InputGroup>
                                    </Form.Group>
                                </ConditionalRender>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="orgType">
                                    {this.renderParentOrg(this.state.organization)}
                                </Form.Group>
                                <Form.Group as={Col} controlId="State">
                                    <Form.Label>{t("State")}</Form.Label>
                                    <ButtonToolbar>
                                        <ToggleButtonGroup type="radio" name="state" id="state" defaultValue={100} value={this.state.organization.state} onChange={(e) => this.handleRadioInput("state", e)}>
                                            <ToggleButton value={100}>{t("Active")}</ToggleButton>
                                            <ToggleButton value={150}>{t("Suspended")}</ToggleButton>
                                            <ToggleButton value={0} >{t("Deleted")}</ToggleButton>
                                        </ToggleButtonGroup>
                                    </ButtonToolbar>
                                    <small id="orgTypeHelp" className="form-text text-muted"> </small>
                                </Form.Group>
                            </Form.Row>                    
                            <Form.Row>
                                <Form.Label className="organization-section-header">{t("Loaner Solution Settings")}</Form.Label>
                            </Form.Row>
                            <Form.Row style={{ border: '1px solid lightgrey', padding: '10px', borderRadius: '5px' }}>
                                <Form.Group className="pr-5" controlId="usePatientInitialsToggle">
                                    <Form.Label>{t("Use Patient Initials")}</Form.Label>
                                    <div className="form-inline">
                                        <ToggleButton
                                            id="usePatientInitials-check"
                                            type="checkbox"
                                            variant={this.state.organization.usePatientInitials ? "success" : "secondary"}
                                            checked={this.state.organization.usePatientInitials}
                                            value="1"
                                            onChange={(e) => this.onPatientInitialsChecked(e.currentTarget.checked)}>
                                            {this.state.organization.usePatientInitials ? t("Yes") : t("No")}</ToggleButton>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="allowRepNotifiedEventCheckedToggle">
                                <Form.Label>{t("Allow Rep Notified Message")}</Form.Label>
                                    <div className="form-inline">
                                        <ToggleButton
                                            id="allowRepNotifiedEvent-check"
                                            type="checkbox"
                                            variant={this.state.organization.allowRepNotifiedEvent ? "success" : "secondary"}
                                            checked={this.state.organization.allowRepNotifiedEvent}
                                            value="1"
                                            onChange={(e) => this.onAllowRepNotifiedEventChecked(e.currentTarget.checked)}>
                                            {this.state.organization.allowRepNotifiedEvent ? t("Yes") : t("No")}</ToggleButton>
                                    </div>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{t("EPIC Barcode Separator")}</Form.Label>
                                    <Form.Control
                                        value={this.state.organization.settings.find(epicBarcodeSeparator)?.value}
                                        id="epicBarcodeSeparator"
                                        name="epicBarcodeSeparator"
                                        onChange={event => this.handleSettingsInput(event, epicBarcodeSeparator)} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <TabList id="orgDataTabs">
                                    <TabItem label={t("Api Keys")} isTable>
                                        <ApiKeyList modifyState={this.modifyState} apiKeys={this.state.organization.apiKeys} organization={this.state.organization} />
                                    </TabItem>
                                    <TabItem label={t("Users")} isTable hidden={this.isOrgTypeId(2)}>
                                        <UserList
                                            users={this.state.organization.users}
                                            modifyState={this.modifyState}
                                            organization={this.state.organization}
                                            organizationId={this.state.organization.id}
                                            organizationName={this.state.organization.name} />
                                    </TabItem>
                                    <TabItem label={t("License Keys")} isTable hidden={this.isOrgTypeId(2)}>
                                        <LicenseKeys licenses={this.state.organization.licenses} sites={this.state.organization.sites} modifyState={this.modifyState} organizationId={this.state.organization.id} organizationName={this.state.organization.name} />
                                    </TabItem>
                                    <TabItem label={t("Sites")} isTable hidden={this.isOrgTypeId(2)}>
                                        <SiteList sites={this.state.organization.sites} modifyState={this.modifyState} organizationId={this.state.organization.id} />
                                    </TabItem>
                                    <TabItem label={t("Messaging Logs")} isTable hidden={this.isOrgTypeId(2)}>
                                        <MessageLogs apiUsageLogs={this.state.organization.apiUsageLogs} />
                                    </TabItem>
                                    <TabItem label={t("Server Instances")} isTable hidden={this.isOrgTypeId(3)}>
                                        <ServerInstanceList updateServerInstanceList={this.updateServerInstanceList} serverInstances={this.state.organization.serverInstances} organization={this.state.organization} />
                                    </TabItem>
                                    <TabItem label={t("Meditech")} isTable hidden={!this.state.organization.hasMeditechApiKey}>
                                        <MeditechMappings
                                            mappings={this.state.organization.mappings }
                                            licenses={this.state.organization.licenses}
                                            sites={this.state.organization.sites}
                                            modifyState={this.modifyState}
                                            organizationId={this.state.organization.id}
                                            organizationName={this.state.organization.name}></MeditechMappings>
                                    </TabItem>
                                    {
                                        /*
                                        <TabItem label={t("Error Reports")} isTable hidden={this.isOrgTypeId(2)} disabled>
                                            <Row>
                                                <Col md={6}>
                                                    <span className="header-text"><b>Error Reports (Not Implemented)</b></span>
                                                </Col>
                                            </Row>
                                        </TabItem>
                                        */
                                    }
                                </TabList>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }
}


export default withTranslation()(connect(null, dispatch => bindActionCreators({ ...modalActionCreator, ...applicationMessagingActionCreator }, dispatch))(Organization));