import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { applicationContextActionCreator } from './store/actions/applicationContextActions';
import { SpmLayout, EpicLayout, LoanerLayout } from './layouts/';
import NoAuthLayout from './layouts/NoAuthLayout';
import AppRoute from './components/AppRoute';
import OneSource from './components/OneSource';
import RegisterClient from './components/RegisterClient';

import EpicAuthentication from './components/auth/EpicAuthentication';
import EpicSpmLink from './components/auth/EpicSpmLink';

import MainLogin from './components/auth/MainLogin';
import Logout from './components/auth/Logout';
import { Unauthorized, InvalidTenant, NotFound, SpmNotConnected, TenantDbMissing } from './components/auth/Errors';
import TenantService from './services/tenantService';
import { Spinner } from './controls/spinner/SpinnerRoot';
import ErrorPage from './components/errors/ErrorPage';

class App extends Component {
    state = { userContext: [] }

    componentDidMount() {
        // get tenant info
        var result = TenantService.getTenantInfo()
            .then(response => {
                let result = response.data;
                if (result.success) {
                    this.props.updateCurrentTenant(result.payload);
                }
            });
        TenantService.getSpmStatus()
            .then(response => {
                let result = response.data;
                if (result.success) {
                    var connected = result.payload.connected;
                    this.props.updateWebsocketStatus(connected);
                }
            })
    }

    render() {
        return (

            <Suspense fallback={<Spinner />}>
                <Switch>
                    {/*
                <AppRoute exact path='/' component={Login} layout={NoAuthLayout} />
                <AppRoute path='/Home' component={Home} layout={NoAuthLayout} />
                <AppRoute exact path='/auth/epic/' component={EpicAuthentication} layout={NoAuthLayout}/>
                <AppRoute path='/auth/epic/spm/' component={EpicSpmLink} layout={NoAuthLayout} />
                <AppRoute path='/registerclient/:returnUrl?' component={RegisterClient} layout={NoAuthLayout} />

                <AppRoute exact path='/or/' component={ORHome} layout={AppLayout} />
                <AppRoute path='/or/assigneditems/:id?' component={AssignedItems} layout={AppLayout} />
                <AppRoute exact path='/or/requests' component={ORRequestsRedux} layout={AppLayout} />
                <AppRoute exact path='/or/request/:id?' component={CreateORRequest} layout={AppLayout} />
                <AppRoute path='/or/requests/create' component={CreateORRequest} layout={AppLayout} />
                <AppRoute path='/or/finditem' component={FindItem} layout={AppLayout} />
                <AppRoute path='/or/quality' component={QualityEvents} layout={AppLayout} />

                <AppRoute path='/auth/login' component={Login} layout={NoAuthLayout} />
                <AppRoute path='/auth/logout' component={Logout} layout={NoAuthLayout} />
                <AppRoute path='/auth/unauthorized' component={Unauthorized} layout={NoAuthLayout}/>

                */}
                    <AppRoute exact path='/' component={MainLogin} name="SPM" layout={NoAuthLayout} />
                    <AppRoute path='/auth/login' component={MainLogin} layout={NoAuthLayout} />
                    <AppRoute path='/auth/logout' component={Logout} layout={NoAuthLayout} />
                    <AppRoute path='/auth/unauthorized' component={Unauthorized} layout={NoAuthLayout} />
                    <AppRoute path='/auth/spmnotconnected' component={SpmNotConnected} layout={NoAuthLayout} />
                    <AppRoute path='/auth/notfound' component={NotFound} layout={NoAuthLayout} />
                    <AppRoute path='/auth/invalidtenant' component={InvalidTenant} layout={NoAuthLayout} />
                    <AppRoute path='/auth/tenantdbmissing' component={TenantDbMissing} layout={NoAuthLayout} />
                    <AppRoute exact path='/auth/epic/' component={EpicAuthentication} layout={NoAuthLayout} />
                    <AppRoute path='/auth/epic/spm/' component={EpicSpmLink} layout={NoAuthLayout} />
                    <AppRoute path='/registerclient/:returnUrl?' component={RegisterClient} layout={NoAuthLayout} />

                    <Route exact path='/error/' render={props => <ErrorPage {...props} />} />
                    <Route path="/admin" name="Admin Home" render={props => <SpmLayout {...props} />} />
                    <Route path="/user" name="User" render={props => <SpmLayout {...props} />} />
                    <Route path="/or" name="OR Home" render={props => <EpicLayout {...props} />} />
                    <Route path="/loaners" render={props => <LoanerLayout {...props} />} />

                    <AppRoute path='/onesource/:api_token?' component={OneSource} layout={NoAuthLayout} />
                </Switch>
            </Suspense>
        );
    }
}
export default connect(state => state.applicationContext,
    dispatch => bindActionCreators({ ...applicationContextActionCreator }, dispatch))(App);
