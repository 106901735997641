import React, { Component, Suspense } from 'react';
import { Card } from 'react-bootstrap'

class UserSettings extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { queues: [{ codingEvents: [] }] };
    }

    render() {
        return (
            <Card>
                <Card.Header className="d-flex align-items-center">
                    <div className="mr-auto" >UserSettings</div>
                    <div className="card-btn-group">
                    </div>
                </Card.Header>
                <Card.Body>
                    <div>UserSettings</div>
                </Card.Body>
            </Card>
        );
    }

}

export default UserSettings;