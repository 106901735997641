import * as actionTypes from '../actions/actionTypes';

const initialMessageState = {
        errors: [],
        notifications: [] 
};

export default (state = initialMessageState, action) => {
    if (action.type === actionTypes.ERROR_MESSAGE_ARRIVED) {
        return {
            ...state,
            errors: state.errors.concat(action.msg)
        };
    }

    if (action.type === actionTypes.ADD_NOTIFICATION) {
        return {
            ...state,
            notifications: state.notifications.concat(action.msg)
        };
    }

    if(action.type === actionTypes.DISMISS_NOTIFICATION) {
        const notifications = state.notifications;
        const idx = notifications.indexOf(action.msg);

        if(idx >= 0)
        {
            return {
                ...state,
                notifications: [...notifications.slice(0, idx), ...notifications.slice(idx + 1)]
            };
        }
    }

    if(action.type === actionTypes.CLEAR_NOTIFICATIONS) {
        return {
            ...state, 
            notifications: []
        };
    }

    return state;
};