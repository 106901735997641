import * as actionTypes from '../actions/actionTypes';
import { AuthenticationModeEnum } from '../../utilities/enumerations';

const initialAppState = {
    applicationContext: {
        client: {
            "id": 0,
            "name": null,
            "primarySiteId": 0,
            "foreignClientId": 0,
            "fingerprint": null,
            "spmClientGuid": "",
            "siteShortName": "",
            "settings": [],
            "siteSettings": []
        },
        loginMode: AuthenticationModeEnum.Unset, 
        user: null,
        tenant: {
            "name": "Unknown",
            "culture": "en-US"
        },
        webSocketConnected: false
    }
};

export default (state, action) => {
    state = state || initialAppState;
    const applicationContext = { ...state.applicationContext };

    if (action.type === actionTypes.WEB_SOCKET_CONNECTED) {
        applicationContext.webSocketConnected = true;
        return {
            ...state,
            applicationContext
        };
    }

    if (action.type === actionTypes.WEB_SOCKET_DISCONNECTED) {
        applicationContext.webSocketConnected = false;
        return {
            ...state,
            applicationContext
        };
    }

    if (action.type === actionTypes.UPDATE_LOGIN_MODE) {
        applicationContext.loginMode = action.loginMode;
        return {
            ...state,
            applicationContext
        };
    }

    if (action.type === actionTypes.UPDATE_CURRENT_CLIENT) {
        applicationContext.client = { ...action.currentClient };
        return {
            ...state,
            applicationContext
        };
    }

    if (action.type === actionTypes.UPDATE_CURRENT_USER) {
        applicationContext.user = action.currentUser;
        return {
            ...state,
            applicationContext
        };
    }

    if (action.type === actionTypes.UPDATE_CURRENT_TENANT) {
        applicationContext.tenant = action.currentTenant;
        return {
            ...state,
            applicationContext
        };
    }

    return state;
};