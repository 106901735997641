import axios from "axios";

const ApiKeyService = {
    async createApiKey(keyTypeToAdd) {
        const response = axios.post(`/api/apikey/create/`, keyTypeToAdd);
        return response; 
    },
    
    async resetApiKey(id) {
        const response = axios.get(`/api/apikey/reset/${id}`);
        return response;        
    }, 

    async deleteApiKey(id) {
        const response = axios.delete(`/api/apikey/${id}`);
        return response;
    }    
}

export default ApiKeyService; 