import React, { useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { Table, Form, Pagination } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Paginator from '../../controls/paging/Paginator';
import { dateTimeFormat, timeFormat } from "../../utilities/dateTime";
import { selectSavedSearchTerms } from '../../store/selectors/loanerAppSettingsSelector';
import { loanerAppSettingsActionCreator } from '../../store/actions/loanerAppSettingsActions';

//Shows search results for both the flattened caseorder dataset and the hierarchical 'case with caseOrders' dataset. 
//General Props:
//  results - the array of results
//  sites - the array of sites (only needed if displaying the site column)
//  asCaseWithOrder - indicates that you are sending a hierarchical dataset instead of a flattened one
//  onlyVendor - for hierarchical datasets, only display case order properties for the case order with this vendor id
//Props Representing Columns to Display:
//  Both hierarchical and flattened: site, caseNumber, physician, caseStartTime, procedure, vendor, traysDelivered, traysExpected, verified, linkTo
//  Hierarchical only: radioSelect
//  Flattened only: modified
const CaseOrderSearchResults = ({ results, sites,
    asCaseWithOrder, onlyVendor, onCaseLinkTo, selectedDeliverer,
    selectedCase, onCaseSelected,
    pageResults, maxResultsPerPage,
    saveSettingsKey, showOnlyVendorCases,
    ...selectedColumns }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const savedSearchTerms = useSelector(state => selectSavedSearchTerms(state));
    const [dataSlice, setDataSlice] = useState(results);

    let pageSelected = 1;
    if (saveSettingsKey && savedSearchTerms.selectedPageInSearchResults) {
        pageSelected = savedSearchTerms.selectedPageInSearchResults;
    }

    const setPageSelected = (newPageSelected) => {
        if (saveSettingsKey) {
            dispatch(loanerAppSettingsActionCreator.changeLoanerAppSetting(saveSettingsKey, 'selectedPageInSearchResults', newPageSelected));
        }
    }

    let columns = sharedColumns(sites, t);

    if (asCaseWithOrder) {
        columns = columns.concat(caseWithOrdersColumns(t, onCaseLinkTo, selectedCase, onCaseSelected, selectedDeliverer));
    }
    else {
        columns = columns.concat(caseOrderColumns(t));
    }

    const data = pageResults ? dataSlice : results;

    const list = composeCaseList(data, asCaseWithOrder, showOnlyVendorCases, onlyVendor);

    return (
        <>
            {
                pageResults ? (<Paginator data={results} maxPerPage={maxResultsPerPage || 5} maxPageItems={10} dataSliceChanged={setDataSlice} defaultPage={pageSelected} pageSelectedChanged={setPageSelected} />) : null
            }
            <Table striped condensed hover responsive>
                <thead>
                    <tr>
                        {
                            columns.map((col) => selectedColumns[col.name] && col.header)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data === undefined || data.length === 0 ?
                            <tr><td colspan="11">No results</td></tr> :
                            list.map((element, i) => {
                                const item = element.case;
                                const order = element.caseOrder;
                                return (
                                    <tr key={i}>
                                        {
                                            columns.map((col) => selectedColumns[col.name] && col.row(item, order, i))
                                        }
                                    </tr>
                                );
                            })
                    }
                </tbody>
            </Table>
        </>
    );
};

export default CaseOrderSearchResults;

function composeCaseList(data, asCaseWithOrder, showOnlyVendorCases, onlyVendor) {
    const list = [];
    data.reduce((prev, item, currentIndex, array) => {
        if (asCaseWithOrder) {
            const myVendorOrder = item.caseOrders.find(co => co.vendorId === onlyVendor);
            if (!showOnlyVendorCases || myVendorOrder) {
                list.push({ case: item, caseOrder: myVendorOrder });
            }
        } else {
            list.push({ case: item, caseOrder: null });
        }
    }, list);
    return list;
}

export {composeCaseList};

const th = text => (<th>{text}</th>);
const thCentered = text => (<th className="center-contents">{text}</th>);
const td = text => (<td>{text}</td>);
const tdCentered = text => (<td className="center-contents">{text}</td>);

const sharedColumns = (sites, t) => {
    const getSiteName = id => {
        const site = sites.find(s => parseInt(s.id) === parseInt(id));
        return site?.shortName || t('undefined');
    };
    return [
        { name: 'site', header: th(t('Site')), row: item => td(getSiteName(item.siteId)) },
        { name: 'caseNumber', header: th(t('Case number')), row: item => (<td>{item.caseNumber}{item.isCanceled && (<><br /><span className="font-error no-padding">Canceled</span></>)}</td>) },
        { name: 'physician', header: th(t('Physician')), row: item => td(item.physicianName) },
        { name: 'caseStartTime', header: th(t('Case start time')), row: item => td(moment(item.caseStartTime).format(dateTimeFormat)) },
        { name: 'procedure', header: th(t('Procedure')), row: item => td(item.procedureName) }
    ];
};

const caseWithOrdersColumns = (t, onCaseLinkTo, selectedCase, onCaseSelected, selectedDeliverer) => [
    { name: 'vendor', header: th(t('Vendor')), row: (item, order) => td(order?.vendorName) },
    { name: 'initials', header: th(t('Initials')), row:(item, order) => td(item.patientInitials) },
    { name: 'traysDelivered', header: thCentered(t('# Delivered')), row: (item, order) => tdCentered(order?.traysDelivered) },
    { name: 'traysExpected', header: thCentered(t('# Expected')), row: (item, order) => tdCentered(order?.traysExpected) },
    { name: 'verified', header: thCentered(t('Verified')), row: (item, order) => (<td className="center-contents"><FontAwesomeIcon icon={faCheckCircle} className={"gi-1-5x show-hand " + (order?.verified ? "greenCheckIcon" : "grayCheckIcon")} /></td>) },
    {
        name: 'linkTo', header: (<th className="table-fit"></th>), row: (item, order) =>
            (<td className="table-fit">
                {order ? (<Link to={
                    { pathname: '/loaners/delivertrays/' + order.id, state: { deliverer: selectedDeliverer } }} className="float-right text-primary"><FontAwesomeIcon icon={faChevronCircleRight} className="gi-1-5x" /></Link>) :
                    (<div className="float-right text-primary pointer-mouseover" onClick={() => onCaseLinkTo(item.id)} ><FontAwesomeIcon icon={faChevronCircleRight} className="gi-1-5x" /></div>)}
            </td>)
    },
    {
        name: 'radioSelect', header: (<th className="table-fit center-contents">{t('Select')}</th>), row: (item, order, i) =>
            (<td className="table-fit center-contents">
                <Form.Check type="radio" name="case" id={`case-${i}`} checked={selectedCase !== null && selectedCase.id === item.id} onChange={() => onCaseSelected(item)} />
            </td>)
    }
];

const caseOrderColumns = (t) => [
    { name: 'vendor', header: th(t('Vendor')), row: item => td(item.vendorName) },
    { name: 'traysDelivered', header: thCentered(t('# Delivered')), row: item => tdCentered(item.traysDelivered) },
    { name: 'traysExpected', header: thCentered(t('# Expected')), row: item => tdCentered(item.traysExpected) },
    { name: 'verified', header: thCentered(t('Verified')), row: item => (<td className="center-contents"><FontAwesomeIcon icon={faCheckCircle} className={"gi-1-5x show-hand " + (item.verified ? "greenCheckIcon" : "grayCheckIcon")} /></td>) },
    { name: 'modified', header: thCentered(t('Modified')), row: item => tdCentered(moment.utc(item.modifiedDate).local().format(timeFormat)) },
    {
        name: 'linkTo', header: (<th className="table-fit"></th>), row: item => (<td className="table-fit">
            <Link to={'/loaners/delivertrays/' + item.id} className="float-right text-primary"><FontAwesomeIcon icon={faChevronCircleRight} className="gi-1-5x" /></Link>
        </td>)
    }
];

