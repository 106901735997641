import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Card, Button, ButtonGroup,  ToggleButton } from 'react-bootstrap';
import { Modal, InputGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import CaseContext from '../../controls/caseContext/CaseContext';
import { OrContext } from './OrContext';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import moment from 'moment';
import { DataTable, DataColumn } from '../../controls/dataTable/DataTable';

import FindItemService from '../../services/findItemService';
import { dateTimeFormat } from '../../utilities/dateTime';
import { ConditionalRender } from '../../controls/formControls';
import ReasonForModification from './ReasonForModification';

const CreateOrRequest = (props) => {
    const orContext = useContext(OrContext);
    const history = useHistory();
    const { t } = useTranslation();

    const [orRequest, setOrRequest] = useImmer({ id: 0, products: [] });
    const [requestedItems, setRequestedItems] = useState([]);
    const [requestNotes, setRequestNotes] = useState("");

    const [note, setNote] = useState("");
    const [editingORRequest, setEditingORRequest] = useState(false);

    // Search Modal
    const [searchText, setSearchText] = useState('');
    const [modalSearchText, setModalSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedSearchResults, setSelectedSearchResults] = useState([]);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [searchingProducts, setSearchingProducts] = useState(true);

    const unknownUserName = "Unknown";

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showReasonModal, setShowReasonModal] = useState(false);

    useEffect(() => {

        if (props.history?.location?.state !== undefined) {
            if (props.history?.location?.state.mode === "edit") {
                let req = { ...props.history.location.state.data };
                setEditingORRequest(true);

                setOrRequest(req);
                setRequestNotes(req.notes);
                
                let reqItems = [];
                req.requestItems.forEach(item => {
                    let reqItem = { ...item, isProduct: item.setTypeId ? true : false };
                    reqItems.push(reqItem);

                });
                setRequestedItems(reqItems);

            }
            else if (props.history?.location?.state.mode === "create") {
                let rawItem = props.history.location.state.data;

                let completeItem = {
                    id: rawItem.id,
                    instrumentId: null,
                    name: rawItem.name,
                    quantityNeeded: 1,
                    setTypeId: null,
                    isProduct: rawItem.isProduct
                };

                setRequestedItems(new Array(completeItem));
            }
        }
    }, []);

    useEffect(() => {
        orContext.getSites();
    }, [orContext.caseData.id]);

    function performSearch() {
        search(searchText, searchingProducts);
    }

    function search(searchTerm, searchProducts) {
        setModalSearchText(searchTerm);
        setSearchingProducts(searchProducts);
        setSelectedSearchResults([]);
        setShowSearchModal(true);

        let siteId = orContext.caseData.siteId;
        let params = { searchText: searchTerm, searchProducts: true, searchInstruments: false, siteId: siteId };

        if (!searchProducts) {
            params.searchProducts = false;
            params.searchInstruments = true;
        }

        FindItemService.findItem(params)
            .then(response => {
                var resultDto = response.data;

                if (resultDto.success) {
                    var data = resultDto.payload;
                    if (searchingProducts) {
                        if (data !== null && data.productSearchResults !== null) {
                            var results = [...data.productSearchResults];
                            setSearchResults(results);
                        }
                    }
                    else {
                        // Searching Instruments
                        if (data !== null && data.instrumentSearchResults !== null) {
                            var results = [...data.instrumentSearchResults];
                            results.forEach(replaceNullProperties);
                            setSearchResults(results);
                        }
                    }
                }
            });
    }

    function replaceNullProperties(item) {
        if (item.manufacturerName === null || item.manufacturerName === undefined) {
            item.manufacturerName = "";
        }

        if (item.productNumber === null || item.productNumber === undefined) {
            item.productNumber = "";
        }
    }

    function hideProductSearch() {
        setShowSearchModal(false);
    }

    function handleModalSearchTextChange(event) {
        var st = event.target.value;
        setModalSearchText(st);

        if (st.length >= 3) {
            search(st, searchingProducts);
        }
    }

    function addRemoveSelectedItem(item) {
        var idx = selectedSearchResults.indexOf(item);
        if (idx >= 0) {
            // remove item 
            var newResults = [...selectedSearchResults.slice(0, idx), ...selectedSearchResults.slice(idx + 1)];
            setSelectedSearchResults(newResults);
        }
        else {
            // add 
            setSelectedSearchResults([...selectedSearchResults, item]);
        }
    }

    function addSelectedItems() {
        var updatedRequestedItems = [...requestedItems];

        // loop through selected items and add to orRequest
        selectedSearchResults.forEach(item => {
            var newItem = { ...item };
            newItem.isProduct = searchingProducts;
            newItem.quantityNeeded = 1;

            var idx = requestedItems.findIndex(p => p.id === newItem.id && p.isProduct === searchingProducts);

            if (idx < 0) {
                updatedRequestedItems.push(newItem);
            }
            else {
                updatedRequestedItems[idx].quantityNeeded += 1;
            }
        });

        setRequestedItems(updatedRequestedItems);
        setShowSearchModal(false);
        setModalSearchText("");
        setSearchText("");
        setSelectedSearchResults([]);
    }

    function removeRequestedItem(item) {
        var idx = requestedItems.indexOf(item);
        setShowReasonModal(true);
        if (idx >= 0) {
            var newItems = [...requestedItems.slice(0, idx), ...requestedItems.slice(idx + 1)];
            setRequestedItems(newItems);
        }
    }

    function increaseQuantity(idx) {
        var updatedRequestedItems = [...requestedItems];
        updatedRequestedItems[idx].quantityNeeded += 1;
        setRequestedItems(updatedRequestedItems);
    }

    function decreaseQuantity(idx) {
        var updatedRequestedItems = [...requestedItems];

        if (updatedRequestedItems[idx].quantityNeeded > 0) {
            updatedRequestedItems[idx].quantityNeeded -= 1;
        }

        setRequestedItems(updatedRequestedItems);
    }

    function getUserName() {
        return orContext.user?.fullName ?? t(unknownUserName);
    }

    function appendNoteWithUserAndDate() {
        const user = getUserName();
        const now = moment(new Date()).format(dateTimeFormat);
        var newNote = (requestNotes ?? "") + "\n\n" + `----By ${user} at ${now}\n${note.trim()}\n----`;
        setRequestNotes(newNote);
        setNote("");
    }

    function showReasonModalOrSaveNewORRequest() {
        if (editingORRequest === true && showReasonModal === true) 
        {
            handleShow();
        }
        else
        {
            handleSaving("");
        }
    }

    function appendReasonAndSave(modalResult, reason) {
        handleClose(false);
       if (modalResult === true) {
           handleSaving(reason);
           setShowReasonModal(false);
        }
   }

    

    function handleSaving(reason) {
        var userName = getUserName();
        var userId = orContext.user !== null ? orContext.user.spmId : 0;

        if (editingORRequest) {
            // update

            const user = getUserName();
            const now = moment(new Date()).format(dateTimeFormat);

            var newNote = (requestNotes ?? "")
            if (showReasonModal === true) {
                newNote = newNote + "\n\n" + `----By ${user} at ${now}\n`;
                // Only augment Note with Reason if we have added an OR Reason
                if (reason.length > 0) {
                    newNote = newNote + `${reason.trim()}`
                }
                newNote = newNote + `\n----`;
            }
            var payload = { ...orRequest };
            payload.notes = newNote;

            payload.requestStatus = 2;

            payload.requestItems = [...requestedItems];
            orContext.updateORRequest(payload);
        }
        else {
            // create 
            var payload = {
                caseId: orContext.caseData.id,
                orRoomId: orContext.caseData.orRoomId,
                siteId: orContext.caseData.siteId,
                requestStatus: 0,
                requestedById: userId,
                requestedBy: userName,
                assignedTo: "",
                notes: requestNotes,
                requestItems: []
            };

            requestedItems.forEach(item => {
                payload.requestItems.push({
                    name: item.name,
                    quantityNeeded: item.quantityNeeded,
                    instrumentId: item.isProduct ? null : item.id,
                    setTypeId: item.isProduct ? item.id : null
                });
            });

            orContext.createORRequest(payload);
        }

        history.push("/or/case/requests");
    }

    function ReturnResult(result) {
        <div>
            <p>message: {result}</p>
        </div>
    }


    return (
        <>
            {/* Product/Instrument Search modal begins */}
            <Modal bsSize="large" show={showSearchModal} size="lg" >
                <Modal.Header>
                    <Modal.Title>
                        Search{searchingProducts ? <span> Products</span> : <span> Instruments</span>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="searchText">{t("Search Text")}</label>
                        <InputGroup>
                            <Form.Control type="text" name="searchText" value={modalSearchText} onChange={handleModalSearchTextChange} />
                        </InputGroup>
                    </div>
                    <div className="form-group" style={{ overflowY: "scroll", resize: "none", height: "50vh" }}>
                            <DataTable
                                striped hover responsive
                                size="small"
                                onRowClick={(row) => addRemoveSelectedItem(row)}
                                dataSource={searchResults}>
                                <DataColumn sortable dataField="name">{t('Name')}</DataColumn>
                            {searchingProducts ? <span /> : <DataColumn sortable dataField="manufacturerName">{t('Manufacturer')}</DataColumn>}
                            {searchingProducts ? <span /> : <DataColumn sortable dataField="productNumber">{t('Product number')}</DataColumn>}
                            {searchingProducts ? <DataColumn sortable dataField="siteName">{t('Site')}</DataColumn> : <span />}
                                <DataColumn
                                    formattedColumn={(item, i) => {
                                        return (
                                            <Form.Check>
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    checked={selectedSearchResults.indexOf(item) >= 0} />
                                            </Form.Check>
                                        )
                                    }
                                    }
                                >{t('Action')}</DataColumn>
                            </DataTable>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => hideProductSearch()} variant="warning" type="button">Cancel</Button>
                    <Button onClick={() => addSelectedItems()} variant="primary" type="button">Select</Button>
                </Modal.Footer>
            </Modal>
            {/* Product/Instrument Search modal ends */}

            <CaseContext caseInfo={orContext.caseData} className="context-container-or-portal bg-light" />
            <Card className="mt-30">
                <Card.Header className="d-flex align-items-center">
                    <div className="mr-auto card-button-group-item">{orRequest.id > 0 ? t('OR Request') + ' ' + orRequest.id : t('Create OR Request')}</div>
                    <div className="card-btn-group form-inline">
                        <Button variant="primary" onClick={() => showReasonModalOrSaveNewORRequest()}> {t('Save')}</Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col lg={3} md={6}>
                            <div className="form-group">
                                <label htmlFor="caseNumber">Case number</label>
                                <p className="form-control-static" id="caseNumber" name="caseNumber">{orContext.caseData.caseNumber}</p>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="form-group">
                                <label htmlFor="orRoomName">Room</label>
                                <p className="form-control-static" id="orRoomName" name="orRoomName">{orContext.caseData.orRoomName}</p>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="form-group">
                                <label htmlFor="eta">ETA</label>
                                <p className="form-control-static" id="eta" name="eta">{orRequest.eta !== null ? moment.utc(orRequest.eta).local().format(dateTimeFormat) : ""}</p>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="form-group">
                                <label htmlFor="status">Status</label>
                                <p className="form-control-static" id="status" name="status">{orRequest.requestStatusText}</p>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={12}>
                            <div className="form-group">
                                <label htmlFor="notes">Notes History</label>
                                <textarea className="form-control" name="notes" id="notes" rows="5" style={{ overflowY: "scroll", height: "100px", resize: "none" }} readOnly value={requestNotes}></textarea>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={11}>
                            <div className="form-group">
                                <textarea className="form-control" name="notes" id="notes" value={note} onChange={(e) => setNote(e.target.value)} rows="2" style={{ overflowY: "scroll", height: "100px", resize: "none" }} placeholder="Add new note"></textarea>
                            </div>
                        </Col>
                        <Col xs={1}>
                            <button type="submit" className="btn btn-default float-right" onClick={() => appendNoteWithUserAndDate()}><FontAwesomeIcon icon={faPlus} /></button>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col lg={9} md={12}>
                            <InputGroup>
                                <Form.Control type="text"
                                    name="searchText"
                                    disabled={editingORRequest}
                                    placeholder="Enter search"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)} />
                                <InputGroup.Append>
                                    <Button variant="primary"
                                        disabled={editingORRequest || searchText.length < 3}
                                        onClick={() => performSearch()}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col lg={3} md={12}>
                            <ButtonGroup toggle style={{ marginBottom: '10px' }}>
                                <ToggleButton
                                    type="radio"
                                    checked={searchingProducts}
                                    disabled={editingORRequest}
                                    variant={searchingProducts ? "primary" : "outline"}
                                    onChange={() => { setSearchingProducts(true) }}
                                    name="searchingProducts">
                                    {t("Products")}
                                </ToggleButton>
                                <ToggleButton
                                    type="radio"
                                    checked={!searchingProducts}
                                    disabled={editingORRequest}
                                    variant={searchingProducts ? "outline" : "primary"}
                                    onChange={() => { setSearchingProducts(false) }}
                                    name="searchingInstruments">
                                    {t("Instruments")}
                                </ToggleButton>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <DataTable
                                striped bordered condensed hover responsive
                                size="small"
                                dataSource={requestedItems}>
                                <DataColumn
                                    dataField="name"
                                    className="table-fit">{t('Requested item')}
                                </DataColumn>
                                <DataColumn
                                    dataField=""
                                    className="table-fit cellCenterContent"
                                    headerColClass="cellCenterContent"
                                    formattedColumn={(row, i) => {
                                        return row.isProduct ? t("Product") : t("Instrument");
                                }}
                                >{t('Type')}
                                </DataColumn>
                                <DataColumn
                                    dataField=""
                                    className="table-fit cellCenterContent" h
                                    eaderColClass="cellCenterContent"
                                    formattedColumn={(row, i) => {
                                        return (
                                            <>
                                            <ConditionalRender visible={editingORRequest}>
                                                {row.quantityNeeded}
                                            </ConditionalRender>                                       
                                            <ConditionalRender visible={!editingORRequest}>
                                                <InputGroup style={{ maxWidth: "120px" }}>
                                                    <Button variant="outline-secondary"
                                                        size="sm"
                                                        id="button-add"
                                                        onClick={() => { decreaseQuantity(i) }}>
                                                        <FontAwesomeIcon icon={faMinus} />
                                                    </Button>
                                                    <Form.Control type="text" value={row.quantityNeeded} />
                                                    <Button variant="outline-secondary"
                                                        size="sm"
                                                        onClick={() => { increaseQuantity(i) }}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </Button>
                                                </InputGroup>
                                            </ConditionalRender>
                                            </>
                                        );
                                }}
                                >{t('Qty needed')} 
                                </DataColumn>
                                <DataColumn
                                    dataField=""
                                    className="table-fit cellCenterContent"
                                    headerColClass="cellCenterContent"
                                    formattedColumn={(row, i) => {
                                        return <FontAwesomeIcon icon={faTimesCircle} className="show-hand text-danger gi-1-5x" onClick={() => removeRequestedItem(row)} />
                                }}
                                >{t('Actions')}</DataColumn>

                                </DataTable>
                        </Col>
                    </Row>                
                </Card.Body>
            </Card>


            <ReasonForModification show={show} close={(dialogResult, reason) => appendReasonAndSave(dialogResult, reason)} handleReturnResult={ReturnResult} /> 

        </>
    )
}
export default CreateOrRequest;