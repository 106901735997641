import React, { createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { enableES5 } from 'immer';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser, selectClient } from '../../store/selectors';
import { applicationMessagingActionCreator } from '../../store/actions/applicationMessagingActions';
import { orPortalActionCreator } from '../../store/actions/orPortalActions';
import orCaseService from '../../services/orCaseService';
import orgService from '../../services/organizationService';
import CaseNotFound from './CaseNotFound';
import { signalrActionCreator } from '../../store/actions/signalrActions';
import * as topicTypes from '../../store/actions/topicTypes';
import OrganizationNotLicensed from './OrganizationNotLicensed';

export const OrContext = createContext();
export const OrContextConsumer = OrContext.Consumer;

enableES5(); // for immer to work with IE11

const OrContextProvider = (props) => {
    const { t } = useTranslation();

    const user = useSelector(selectCurrentUser);
    const client = useSelector(selectClient);
    const [caseData, setCaseData] = useImmer({ assignedItems: [], stillNeeded: [], caseCarts: [], requests: [] });
    const [qualityEvents, setQualityEvents] = useImmer([]);
    const [sites, setSites] = useImmer([]);
    const [requirements, setRequirements] = useImmer([]);
    const [orRequests, setORRequests] = useImmer([]);
    const [statusCaseNotFound, setStatusCaseNotFound] = useImmer(false);
    const [hasValidORPortalLicense, setHasValidORPortalLicense] = useImmer(false);
    const [orgSettings, setOrgSettings] = useImmer([]);
    const [scanMessage, setScanMessage] = useImmer(null);

    const dispatch = useDispatch();
    const successNotification = (title, message) => dispatch(applicationMessagingActionCreator.createSuccessNotification(title, message));
    const errorMessage = (title, message) => dispatch(applicationMessagingActionCreator.createErrorMessage(title, message));
    const resetUpdateMessages = () => dispatch(orPortalActionCreator.clearORRequestUpdates());

    const orPortal = useSelector(state => state.orPortal);

    useEffect(() => {
        checkForValidORPortalLicense();
    }, []);

    useEffect(() => {
        if (orPortal.requestUpdates.length > 0) {
            let tempRequests = [...orRequests];
            orPortal.requestUpdates.forEach(request => {

                if (caseData.id === request.caseId) {
                    // update or replace request
                    const index = tempRequests.findIndex(x => x.id === request.id);
                    if (index > -1) {
                        tempRequests[index] = request;
                    } else {
                        tempRequests.push(request);
                    }
                }
            });

            // send dispatch to reset request list
            setORRequests(tempRequests);
            resetUpdateMessages();
        }
    }, [orPortal]);

    useEffect(() => {
        dispatch(signalrActionCreator.subscribeToTopic(topicTypes.OR_REQUESTS));

        return () => {
            dispatch(signalrActionCreator.unsubscribeFromTopic(topicTypes.OR_REQUESTS));
        }
    }, []);

    const orderCaseData = (caseData) => {
        caseData.assignedItems.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
        caseData.stillNeeded.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
        caseData.caseCarts.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
    }

    const getOrCase = (id) => {
        // fetch the data for the case
        orCaseService.getAssignedItems(id)
            .then(response => {
                var resultDto = response.data;

                if (resultDto.success) {
                    const orCase = resultDto.payload;

                    if (orCase === null || orCase.id === 0) {
                        setStatusCaseNotFound(true);
                    }

                    resetUpdateMessages();

                    orderCaseData(orCase);
                    setCaseData(orCase);
                    setORRequests(orCase.requests);
                }
            }).catch((reason) => {
                console.log('getORCase failed.', reason);
            });
    }

    const refreshCase = () => {
        if (caseData !== null && caseData.id !== 0) {
            getOrCase(caseData.id);
        }
    }

    const addItemToCase = (item) => {
        orCaseService.addItemToCase(item)
            .then(response => {
                var resultDto = response.data;

                if (resultDto.success) {
                    const result = resultDto.payload;

                    if (result.scanSuccess === false) {
                        setScanMessage({
                            message: result.message,
                            substitutionMessages: result.substitutionMessages,
                            title: result.title,
                            productInfo: result.productInfo,
                        });
                    }
                    else {
                        const newCaseData = { ...caseData };
                        newCaseData.assignedItems = [...result.caseData.assignedItems];
                        newCaseData.stillNeeded = [...result.caseData.stillNeeded];

                        orderCaseData(newCaseData);
                        setCaseData(newCaseData);
                    }
                }
            }).catch((reason) => {
                console.log('addItemToCase failed.', reason);
            });
    }

    const dismissScanMessage = () => {
        setScanMessage(null);
    }
    const updateOrCase = (orCase) => {
        setCaseData(orCase);
    }

    const saveUtilization = (caseData, isSoftSave) => {
        orCaseService.saveUtilization({ caseid: caseData.id, assignedItems: caseData.assignedItems, isSoftSave: isSoftSave })
            .then(response => {
                var resultDto = response.data;
                if (resultDto.success) {
                    if (resultDto.payload) {
                        if (!isSoftSave) {
                            successNotification(t('Success'), t('Utilization was updated.'));
                        }
                        setCaseData(caseData);
                    }
                    else {
                        errorMessage(t('Error'), t('Failed to update utilization.'))
                    }
                }
            });
    }

    const getQualityEvents = () => {
        if (caseData.id) {
            orCaseService.getQualityEventsForCase(caseData.id)
                .then(response => {
                    var resultDto = response.data;
                    if (resultDto) {
                        if (resultDto.success) {
                            setQualityEvents(resultDto.payload);
                        }
                    }
                });
        }
    }

    const createQualityEvent = (qualityEvent) => {
        if (qualityEvent) {
            orCaseService.createQualityEvent(qualityEvent)
                .then(response => {
                    var resultDto = response.data;
                    if (resultDto.success) {
                        var qeList = [...qualityEvents];
                        const newQualityEvent = resultDto.payload;

                        if (newQualityEvent) {
                            qeList.push(newQualityEvent);
                            setQualityEvents(qeList);
                        }
                        successNotification(t('Success'), t('Quality event was created.'));
                    }
                    else {
                        errorMessage(t('Error'), t('Failed to create quality event.'))
                    }
                });
        }
    }

    const createORRequest = (orRequest) => {
        if (orRequest) {
            orCaseService.createORRequest(orRequest)
                .then(response => {
                    var resultDto = response.data;
                    if (resultDto.success) {
                        var orRequestList = [...orRequests];
                        const newOrRequest = resultDto.payload;

                        if (newOrRequest) {
                            orRequestList.push(newOrRequest);
                            setORRequests(orRequestList);
                        }
                    }
                });
        }
    }

    const updateORRequest = (orRequest) => {
        if (orRequest) {
            orCaseService.updateORRequest(orRequest)
                .then(response => {
                    var resultDto = response.data;
                    if (resultDto.success) {
                        const updatedOrRequest = resultDto.payload;
                        var orRequestList = [...orRequests];

                        // replace the orRequest in the list
                        const index = orRequestList.findIndex(x => x.id === orRequest.id);
                        if (index > -1) {
                            orRequestList[index] = updatedOrRequest;
                            setORRequests(orRequestList);
                        }
                    }
                });
        }
    }

    const getSites = () => {
        orgService.getSitesForCurrentOrg()
            .then(response => {
                var resultDto = response.data;

                if (resultDto) {
                    if (resultDto.success) {
                        const sitesForOrg = resultDto.payload;
                        setSites(sitesForOrg);
                    }
                }
            });
    }

    const checkForValidORPortalLicense = () => {
        orgService.organizationHasValidORPortalLicense()
            .then(response => {
                var resultDto = response.data;

                if (resultDto) {
                    if (resultDto.success) {
                        const hasValidLicense = resultDto.payload;
                        setHasValidORPortalLicense(hasValidLicense);
                    }
                }
            });
    }

    const getRequirements = (siteId, requirementType) => {
        let requirementsQuery = { siteId: parseInt(siteId), requirementType: parseInt(requirementType) };
        orCaseService.getRequirements(requirementsQuery)
            .then(response => {
                var resultDto = response.data;

                if (resultDto) {
                    if (resultDto.success) {
                        setRequirements(resultDto.payload);
                    }
                }
            });
    }

    const getOrgSettings = () => {
        orgService.getOrgSettingsForCurrentOrg()
            .then(response => {
                var resultDto = response.data;

                if (resultDto) {
                    if (resultDto.success) {
                        setOrgSettings(resultDto.payload);
                    }
                }
            })
    }

    return (
        <OrContext.Provider
            value={{
                getOrCase,
                updateOrCase,
                refreshCase,
                caseData,
                user,
                client,
                getQualityEvents,
                createQualityEvent,
                qualityEvents,
                sites,
                getSites,
                requirements,
                getRequirements,
                orRequests,
                createORRequest,
                updateORRequest,
                addItemToCase,
                saveUtilization,
                orgSettings,
                getOrgSettings,
                scanMessage,
                dismissScanMessage,
                checkForValidORPortalLicense
            }}>
            {hasValidORPortalLicense ? statusCaseNotFound ? <CaseNotFound /> : props.children : <OrganizationNotLicensed />}

        </OrContext.Provider>
    )
}

export default OrContextProvider;