import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const OrganizationNotLicensed = (props) => {

    const { t, i18n } = useTranslation();
    return (
        <div className="container-fluid">
            <div className="py-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-md-2 text-center">
                            <p><FontAwesomeIcon icon={faExclamationTriangle} className="fa-5x text-danger" /></p>
                        </div>
                        <div className="col-md-10">
                            <h3>{t('Not Licensed')}</h3>
                            <p>{t('This Organization is not licensed to use OR Portal')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrganizationNotLicensed;