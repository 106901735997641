import MessageHub from './MessageHub'; 

const signalRMiddleware = store => {
    let messageHub = new MessageHub(store);

    return (next) => (action) => { 
        messageHub.processAction(action);

        return next(action);
    }
}

export default signalRMiddleware;