//Package imports
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Table, Dropdown, ToggleButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faPrint, faCamera, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { Link } from "react-router-dom";
import { PropTypes } from 'prop-types';

//App imports
import enums from "../../../utilities/enums";
import { claims, claimValues } from '../../../utilities/authConstants';
import { caseOrderSourceEnum } from '../../../utilities/enumerations';
import { selectPermissionChecker } from '../../../store/selectors/permissionSelectors';
import { modalActionCreator as modal } from '../../../store/actions/modalActions';
import { applicationMessagingActionCreator as msg } from '../../../store/actions/applicationMessagingActions';
import { PrinterContext } from '../../../contexts/PrinterContext';
import labelFormatter from "../../../utilities/labelFormatter";
import { dateTimeFormat } from "../../../utilities/dateTime";
import { byTrayNameThenByDeliveryDate } from "../../../utilities/sorts";
import TaggedOrdersMenu from './TaggedOrdersMenu';


//Filters
const pendingDelivery = (loaner) => loaner.status === enums.loanerTrayStatusEnum.pendingDelivery.value;
const delivered = (loaner) => loaner.status === enums.loanerTrayStatusEnum.delivered.value;
const arrived = (loaner) => loaner.status !== enums.loanerTrayStatusEnum.expected.value;

const ReceivedTrays = ({ caseOrder, showVerifyModal, onWebcamModalClose, undeliverTray, onTraysUpdated }) => {
    const { t } = useTranslation();
    const hasPermission = useSelector(selectPermissionChecker);
    const dispatch = useDispatch();

    const loaners = caseOrder?.loaners;

    const showRemoveLoanerTray = loaner => {
        const canVerify = hasPermission(claims.canVerifyLoanerDropOff, claimValues.isTrue);
        const isDelivered = loaner.delivConfirmed || delivered(loaner);
        return isDelivered ? canVerify : true;
    };

    const openTagLoanersModal = () => dispatch(modal.showModal({
        open: true,
        size: "xl",
        focus: true,
        model: caseOrder
    }, 'tagLoanersModal'));

    const openWebcamCaptureModal = (loaner) => dispatch(modal.showModal({
        open: true,
        model: loaner,
        size: "xl",
        beforeCloseModal: onWebcamModalClose
    }, 'webcamCaptureModal'));

    const confirmUndeliverTray = (loaner) => {
        // allow always if the user has SPM role 
        // if delivered but added in this session
        if ((loaner.touched !== undefined) ||
            pendingDelivery(loaner) ||
            (delivered(loaner))) {

            const trayName = loaner.trayName;
            const utfSmallBullet = '\u25cf ';
            const taggedMsg = "\n\n" + utfSmallBullet + t("This tray will no longer be marked as being used in another case.");
            const adHocMsg = t("Are you sure you want to remove {{trayName}} from the case?", { "trayName": trayName }) +
                "\n\n" +
                utfSmallBullet +
                t("Doing so will remove the barcode and any images associated with the loaner.");

            const torqMsg = t("Are you sure you want to put the {{trayName}} back on the expected loaners list?",
                { "trayName": trayName }) +
                "\n\n" +
                utfSmallBullet +
                t("Any images associated with the loaner will be discarded.");

            let message = loaner.source === caseOrderSourceEnum.Adhoc ? adHocMsg : torqMsg;
            if (loaner.isTagged) {
                message = message + taggedMsg;
            }

            dispatch(modal.showModal({
                open: true,
                cancel: () => dispatch(modal.hideModal()),
                confirm: () => undeliverTray(loaner),
                confirmText: t("Remove from case"),
                title: t("Remove {{trayName}} From Case?", { "trayName": trayName }),
                message: message
            }, 'confirm'));
        }
    };

    const siteSettings = useSelector(state => state.applicationContext.applicationContext.client.siteSettings);

    const { print, jspmStatus } = useContext(PrinterContext);

    const queueSingleLoanerLabel = (loaner) => {
        //Printer should be available and we should have settings (zpl) to print from
        if (jspmStatus === "Open") {
            const caseInfoZpl = labelFormatter.formatCaseOrderInfoZpl(siteSettings, caseOrder);
            if (loaner.status === enums.loanerTrayStatusEnum.pendingDelivery.value) {
                print(caseInfoZpl);
                onTraysUpdated([loaner.id],
                    enums.loanerTrayStatusEnum.delivered.value,
                    true);
            } else {
                print(caseInfoZpl);
                print(labelFormatter.formatLoanerZpl(loaner, siteSettings, caseOrder));
            }
        } else {

            dispatch(msg.createErrorMessage(t("Print Manager Unavailable"),
                t("The server may be trying to connect. Currently the JS Print Manager server status is {{jspmStatus}}.",
                    new { "jspmStatus": jspmStatus })));
        }
    };

    const queueMultipleLoanerLabels = () => {
        if (loaners && loaners.some(arrived)) {
            const arrivedSets = loaners.filter(arrived);
            arrivedSets.forEach(loaner => queueSingleLoanerLabel(loaner));
        }
    };

    const getLoanersDroppedOff = () => {
        return loaners.filter(l => l.status >= enums.loanerTrayStatusEnum.pendingDelivery.value).length;
    };

    return (
        <>
<Card.Header className="d-flex align-items-center">
    <div className="mr-auto form-inline">{t("Loaners dropped off")}
        <div className="gi-0-75x tight-list-group loaner-counts">{`(${getLoanersDroppedOff()}`} {t('of')} {`${loaners.length})`}</div>
    </div>
    <div className="card-btn-group form-inline">
        <Button variant="secondary" size="sm" disabled={!loaners.some(l => l.status >= enums.loanerTrayStatusEnum.delivered.value)} onClick={openTagLoanersModal}>
            <FontAwesomeIcon icon={faShareAlt} className="gi-1x mr-2"/><span>{t("Drop off for another case")}</span>
        </Button>
        <Button variant="secondary" size="sm" disabled={!loaners.some(pendingDelivery) && !loaners.some(delivered)} onClick={() => queueMultipleLoanerLabels()}>
            <FontAwesomeIcon icon={faPrint} className="gi-1x mr-2"/><span>{t("Print all labels")}</span>
        </Button>
    </div>
</Card.Header>
<Card.Body className="card-body-table">
    <Table striped condensed hover responsive>
        <thead>
        <tr>
            <th>{t("Loaner name")}</th>
            <th>{t("Status")}</th>
            <th>{t("Delivery date")}</th>
            <th>{t("Delivered by")}</th>
            {/*<th className="table-fit center-contents">{t("Hold")}</th>*/}
            <th className="table-fit center-contents">{t("Label")}</th>
            <th className="table-fit center-contents">{t("Files")}</th>
            <th className="table-fit">{t("Barcode")}</th>
            <th className="table-fit center-contents">{t("Verified")}</th>
            <th className="table-fit"></th>
        </tr>
        </thead>
        <tbody>
        {
                            (loaners.some(arrived)
                                ? loaners.filter(arrived).sort(byTrayNameThenByDeliveryDate).map((loaner, index) => {
                                    return (
                                        <tr key={loaner.id}>
                                            <td>{loaner.trayName}</td>
                                            <td>{loaner.statusName} {loaner.delivered && !!loaner.processingStatus ? '(' + t(loaner.processingStatus) + ')' : null}</td>
                                            <td>{loaner.delivered
                                                ? moment.utc(loaner.delivered).local().format(dateTimeFormat)
                                                : null}</td>
                                            <td>{loaner.deliveredBy}</td>
                                            {/*<td className="table-fit center-contents">*/}
                                            {/*    <ToggleButton variant="link" className="btn-revert" disabled={true}*/}
                                            {/*        type="checkbox" checked={loaner.doNotOpen} value="donotopen" />*/}
                                            {/*</td>*/}
                                            <td className="table-fit center-contents pointer-mouseover" onClick={() => queueSingleLoanerLabel(loaner)}><FontAwesomeIcon icon={faPrint} className="gi-1-5x" /></td>
                                            <td className="table-fit center-contents pointer-mouseover" onClick={() => openWebcamCaptureModal(loaner)}>{loaner.fileCount} <FontAwesomeIcon icon={faCamera} className="gi-1-5x" /></td>
                                            <td>
                                                <div className="d-flex barcode-linked-cases">
                                                    <span className="text-nowrap">{loaner.barcode}</span><TaggedOrdersMenu loaner={loaner} />
                                                </div>
                                            </td>
                                            <td className="table-fit center-contents">{
                                                loaner.delivConfirmed 
                                                    ? <FontAwesomeIcon icon={faCheckCircle} className="gi-1-5x greenCheckIcon" onClick={() => { showVerifyModal(0); }} />
                                                    : <Link href="#" onClick={() => { showVerifyModal(loaner.id) }}>{t("Verify")}</Link>
                                            }</td>
                                            <td className="table-fit">
                                                {
                                                    showRemoveLoanerTray(loaner)
                                                        ? <Link href="#" className="float-right text-danger" onClick={() => confirmUndeliverTray(loaner)}>
                                                            <FontAwesomeIcon icon={faTimesCircle} className="gi-1-5x" />
                                                        </Link>
                                                        : null
                                                }

                                            </td>
                                        </tr>
                                    );
                                })
                                : <tr><td colSpan="9"></td></tr>
                            )
                        }
        </tbody>
    </Table>
</Card.Body>

</>
    );
};

ReceivedTrays.propTypes = {
    caseOrder: PropTypes.object,    //TODO: add more required properties using shape()
    showVerifyModal: PropTypes.func.isRequired,
    onWebcamModalClose: PropTypes.func.isRequired,
    undeliverTray: PropTypes.func.isRequired,
    onTraysUpdated: PropTypes.func.isRequired
};

export default ReceivedTrays;