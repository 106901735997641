import React, { Component } from 'react';
import { Row, Col, FormGroup, FormLabel, FormControl, Table, Button, Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';
import licenseService from '../../services/licenseService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faKey, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { dateFormat, dateDisplayFormat } from "../../utilities/dateTime";

class LicenseKeys extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showEditModal: false,
            showDeleteLicenseModal: false,
            returnError: '',
            licenseKeyModalHeaderText: "",
            editLicense: {},
            disabled: false
        };
        this.showEditModal = this.showEditModal.bind(this);
        this.showNewModal = this.showNewModal.bind(this);
        this.hideEditModal = this.hideEditModal.bind(this);
        this.showDeleteLicenseModal = this.showDeleteLicenseModal.bind(this);
        this.hideDeleteLicenseModal = this.hideDeleteLicenseModal.bind(this);
        this.handleSaveLicense = this.handleSaveLicense.bind(this);
        this.handleDeleteLicense = this.handleDeleteLicense.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleUserIntInput = this.handleUserIntInput.bind(this);
    }

    showEditModal(license, event) {
        this.setState({ licenseKeyModalHeaderText: 'Edit License Key', editLicense: { ...license }, showEditModal: true });
    }
    showNewModal() {
        //Check disabled to prevent double clicking, which would spawn two dialogs
        if (!this.state.disabled) {
            // Initialize dates to midnight (startOf) for the day they are selecting with the offset in place for later UTC conversion
            this.setState({
                licenseKeyModalHeaderText: 'Create New License Key',
                editLicense: {
                    validFrom: moment().startOf('day').toDate(),
                    validThrough: moment().startOf('day').add('years', 1).toDate(),
                    licenseTypeId: 1
                },
                showEditModal: true,
                disabled: true
            });
        }
    }
    hideEditModal() {
        this.setState({ licenseKeyModalHeaderText: 'Edit License Key', editLicense: {}, showEditModal: false, disabled: false });
    }

    showDeleteLicenseModal(license, event) {
        this.setState({ editLicense: { ...license }, showDeleteLicenseModal: true });
    }

    hideDeleteLicenseModal() {
        this.setState({ editLicense: {}, showDeleteLicenseModal: false });
    }

    handleDeleteLicense() {
        licenseService.deleteLicense(this.state.editLicense.id).then(response => {
            if (response.data.success) {
                var existing = this.props.licenses.filter(x => x.id !== this.state.editLicense.id);
                this.props.modifyState("licenses", existing);
                this.setState({ editLicense: {}, showDeleteLicenseModal: false });
            }
        })
    }

    handleSaveLicense() {
        var license = { ...this.state.editLicense };
        license.organizationId = this.props.organizationId;
        license.organizationName = this.props.organizationName;
        license.State = 100;
        //Create new moment of the day picked to get date with correct offset (ISO 8601 format).
        //Moment uses browser info to infer the timezone info. Here we are also ensuring the start of the day (midnight) is to be saved.
        license.validFrom = moment(license.validFrom).startOf('day').toDate();
        //Converting the ISO date to UTC for saving. This is reinterpreted by moment to local time when re-read from DB
        license.validFrom = moment.utc(license.validFrom);

        //Same thing (per above) is done for validThrough...
        license.validThrough = moment(license.validThrough).startOf('day').toDate();
        license.validThrough = moment.utc(license.validThrough);
        licenseService.saveLicense(license)
            .then(response => {
                if (response.data.success) {
                    var result = response.data.payload;

                    this.setState({ editLicense: result, disabled: false });
                    var existing = this.props.licenses.filter(x => x.id !== result.id);
                    existing.push(result);
                    this.props.modifyState("licenses", existing);
                    this.hideEditModal();
                }
            });
    }

    handleUserInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var partialState = this.state.editLicense;
        partialState[name] = value;
        this.setState({ editLicense: partialState });
    }

    handleUserIntInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = parseInt(target.value);
        const name = target.name;
        var partialState = { ...this.state.editLicense };
        partialState[name] = value;
        this.setState({ editLicense: partialState });
    }

    render() {
        return (
            <div>
                <Modal bsSize="small" show={this.state.showDeleteLicenseModal}>
                    <Modal.Header>
                        <Modal.Title>Confirm Deletion</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete the license key?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.hideDeleteLicenseModal} variant="default" type="button">Cancel</Button>
                        <Button onClick={this.handleDeleteLicense} variant="danger" type="button">Delete</Button>
                    </Modal.Footer>
                </Modal>
                <Modal bsSize="small" show={this.state.showEditModal}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.licenseKeyModalHeaderText}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="LicenseTypeId">License Type</label>
                            <select className="form-control" name="licenseTypeId" id="licenseTypeId" value={this.state.editLicense.licenseTypeId} required onChange={this.handleUserIntInput}>
                                <option value='1'>Sms Messaging Api</option>
                                <option value='2'>Email Messaging Api</option>
                                <option value='4'>OR Portal License</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="orgSiteId">Site Id</label>
                            <select className="form-control" name="orgSiteId" id="orgSiteId" required value={this.state.editLicense.orgSiteId} onChange={this.handleUserIntInput}>
                                <option>Please pick a site</option>
                                {
                                    this.props.sites.map((item, i) => {
                                        return (
                                            <option key={i} value={item.id}>{item.name + ' (' + item.siteCode + ')'}</option>
                                        );
                                    })
                                }
                            </select>
                            <small id="siteCodeHelp" className="form-text text-muted">Please enter the site code.</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="validFrom">Valid From</label>
                            <input type='date' className="form-control" id="validFrom" name="validFrom"
                                value={moment(this.state.editLicense.validFrom).format(dateFormat)}
                                aria-describedby="validFromHelp" onChange={this.handleUserInput} required />
                            <small id="validFromHelp" className="form-text text-muted">Please enter the start date of the license.</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="validThrough">Valid Through</label>
                            <input type='date' className="form-control" id="validThrough" name="validThrough" value={moment(this.state.editLicense.validThrough).format(dateFormat)} aria-describedby="validThroughHelp" onChange={this.handleUserInput} required />
                            <small id="validThroughHelp" className="form-text text-muted">Please enter the end date of the license or leave blank for an indefinite ending.</small>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.hideEditModal} variant="warning" type="button">Cancel</Button>
                        <Button onClick={this.handleSaveLicense} variant="primary" disabled={!this.state.editLicense.validFrom} type="button">Save</Button>
                    </Modal.Footer>
                </Modal>
                <Row>
                    <Col md={12}>
                        <Table striped condensed hover responsive>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Site</th>
                                    <th>Valid From</th>
                                    <th>Valid Through</th>
                                    <th />
                                    <th>
                                        <span className="fa-layers fa-fw text-success float-right show-hand"
                                            onClick={this.showNewModal} disabled={this.state.disabled}>
                                            <FontAwesomeIcon icon={faKey} transform={{ rotate: -45 }} />
                                            <FontAwesomeIcon icon={faPlus} transform="shrink-8 right-10" />
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.licenses.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.licenseType}</td>
                                                <td>{item.siteName}</td>
                                                <td>{moment(item.validFrom).format(dateDisplayFormat)}</td>
                                                <td>{!item.validThrough ? "Forever" : moment(item.validThrough).format(dateDisplayFormat)}</td>
                                                <td className="table-fit"><FontAwesomeIcon icon={faPen} className="gi-1-5x text-primary show-hand" onClick={(e) => this.showEditModal(item, e)} /></td>
                                                <td className="table-fit"><FontAwesomeIcon icon={faTimesCircle} className="gi-1-5x text-danger show-hand" onClick={(e) => this.showDeleteLicenseModal(item, e)} /></td>
                                            </tr>
                                        );
                                    }
                                    )
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default LicenseKeys;
