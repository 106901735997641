import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Card, Form } from 'react-bootstrap';
import loanerOrderService from '../../../services/loanerOrderService';
import { withTranslation } from 'react-i18next';

import * as topicTypes from '../../../store/actions/topicTypes';
import { modalActionCreator } from '../../../store/actions/modalActions';
import { signalrActionCreator } from '../../../store/actions/signalrActions';
import { loanerOrderActionCreator } from '../../../store/actions/loanerOrderActions';
import { selectDefaultLoanerSites } from '../../../store/selectors/settingsSelectors';
import { loanerAppSettingsActionCreator } from '../../../store/actions/loanerAppSettingsActions';

import SearchResults from '../CaseOrderSearchResults';
import CardWrapper from '../../../controls/cards/CardWrapper';

import VendorSelector from './VendorSelector';
import DelivererSelector from './DelivererSelector';
import CaseSearch from '../CaseSearch';

import moment from 'moment';

import { byCaseStartTime } from '../../../utilities/sorts';
import { filterCases } from "../../../utilities/filters";
import { dateFormat, threeDaysInMilliseconds, threeMinutesInMilliseconds } from "../../../utilities/dateTime";

class DeliverTrays extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            sites: [],
            selectedSites: [],

            selectedVendor: null,

            siteVendorDeliverers: [],
            selectedDeliverer: '',

            filteredCaseResults: null,

            dateFrom: null,
            dateTo: null,
            showOnlyVendorCases: true
        }

        this.handleVendorChange = this.handleVendorChange.bind(this);
        this.handleVendorSelection = this.handleVendorSelection.bind(this);
        this.handleDelivererChange = this.handleDelivererChange.bind(this);
        this.handleNewDeliverer = this.handleNewDeliverer.bind(this);
        this.handleSitesChange = this.handleSitesChange.bind(this);
        this.updateDelivererList = this.updateDelivererList.bind(this);
        this.createCaseOrder = this.createCaseOrder.bind(this);
        this.handleCaseResults = this.handleCaseResults.bind(this);
        this.handleShowCheckbox = this.handleShowCheckbox.bind(this);
        this.queryServer = this.queryServer.bind(this);
    }

    componentDidMount() {
        this.props.requestLoanerOrderHome();
        this.props.getPatientDataSettings();
        this.props.subscribeToTopic(topicTypes.CASE_ORDERS);

        let dateFrom = moment(new Date()).format(dateFormat);
        let dateTo = moment(new Date(new Date().getTime() + (threeDaysInMilliseconds))).format(dateFormat);

        const newState = { dateFrom: dateFrom, dateTo: dateTo };

        let passedInState = this.props.location.state;
        if (passedInState) {
            newState.selectedVendor = passedInState.vendorId;
            newState.selectedDeliverer = passedInState.deliverer;
        }

        this.setState(newState);
    }

    componentWillUnmount() {
        this.props.unsubscribeFromTopic(topicTypes.CASE_ORDERS);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading) {
            var partialState = this.state;

            const hasUpdates = propName => {
                if (this.props[propName] !== prevProps[propName]) {
                    partialState[propName] = this.props[propName];
                    return true;
                }
                return false;
            }

            const sitesUpdated = hasUpdates('sites') | hasUpdates('defaultLoanerSites');

            if (sitesUpdated) {
                if (!this.state.selectedSites || this.state.selectedSites.length === 0) {
                    partialState.selectedSites = this.props.defaultLoanerSites;
                }
            }

            if (sitesUpdated) {
                this.setState({ ...partialState }, () => this.updateDelivererList());
            }
        }
    }

    handleShowCheckbox(event) {
        this.setState({ showOnlyVendorCases: event.target.checked });
    }

    handleSitesChange(data) {
        const newSites = data ? data.map(d => d.value) : [];
        this.setState({
            selectedSites: newSites
        }, this.updateDelivererList);
    }

    handleVendorChange(input) {
        const value = isNaN(input) ? null : parseInt(input);
        this.setState({
            selectedVendor: value,
            selectedDeliverer: ''
        }, this.updateDelivererList);
    }

    handleDelivererChange(event) {
        const newValue = event.target.selectedIndex > 0 ? event.target.value : '';

        this.setState({
            selectedDeliverer: newValue
        });
    }

    handleNewDeliverer(name) {
        const existingDeliverer = this.state.siteVendorDeliverers.find(d => d.toLowerCase() === name.toLowerCase());

        if (existingDeliverer) {
            this.setState({
                selectedDeliverer: existingDeliverer
            });
        }
        else {
            this.props.updateDeliverers({
                siteId: this.state.selectedSites[0],
                vendorId: this.state.selectedVendor,
                name: name
            });

            this.setState({ selectedDeliverer: name }, () => {
                this.updateDelivererList();
            });
        }
    }

    handleVendorSelection(caseId, vendorId) {
        this.setState({ selectedVendor: vendorId }, () => this.createCaseOrder(caseId));
    }

    showVendorSelectionDialog(vendors, caseId, caseNumber) {
        this.props.showModal({
            open: true,
            title: this.props.t("Select a Vendor for Case") + " " + caseNumber,
            user: null,
            size: "lg",
            focus: true,
            beforeCloseModal: this.handleVendorSelection,
            model: {
                vendors: vendors,
                caseId: caseId
            }
        }, 'vendorSelectionModal');
    }

    queryServer(params) {
        loanerOrderService.caseSearch(params)
            .then(response => {
                if (response.data.success) {
                    const payload = response.data.payload;

                    if (payload.isExactMatch) {
                        const exactMatch = payload.cases[0];

                        const caseId = exactMatch.id;
                        if (!exactMatch.caseOrders.some(Boolean)) {
                            this.createCaseOrder(caseId);
                        } else {
                            const caseOrder = exactMatch.caseOrders.find(co => co.vendorId === this.state.selectedVendor);
                            if (caseOrder) {
                                this.props.changeLoanerAppSetting('caseSearch', 'caseNumber', null);
                                this.props.history.push(
                                    {
                                        pathname: `/loaners/delivertrays/${caseOrder.id}`,
                                        state: { deliverer: this.state.selectedDeliverer }
                                    });
                            } else {
                                const vendors = exactMatch.caseOrders.map(c => {
                                    return { id: c.vendorId, name: c.vendorName };
                                });
                                const vendor = this.props.vendors.find(v => v.id === this.state.selectedVendor)?.name;
                                vendors.push({ id: this.state.selectedVendor, name: vendor});
                                this.showVendorSelectionDialog(vendors, caseId, exactMatch.caseNumber);
                            }
                        }
                    } else {
                        const cases = filterCases(payload.cases, new Date().toISOString(), params.to);
                        this.handleCaseResults(cases);
                    }
                }
                var canSearchForPi = response.data != null &&
                    response.data.payload != null &&
                    response.data.payload.allowedToSearchForPi;
                this.setState({ allowedToSearchForPi: canSearchForPi });

                if (!canSearchForPi && this.props.showPatientInitials) {
                    setTimeout(() => {
                        this.setState({ allowedToSearchForPi: true });
                    }, threeMinutesInMilliseconds);
                }
            });
    }

    getSiteName(id) {
        const sites = this.state.sites;
        for (var i = 0, len = sites.length; i < len; i++) {
            if (parseInt(sites[i].id) === parseInt(id)) {
                return sites[i].shortName;
            }
        }

        return 'undefined';
    }

    updateDelivererList() {
        const siteVendorDeliverers = this.state.selectedVendor && this.state.selectedSites && this.props.deliverers ?
            this.props.deliverers
                .filter(d => d.vendorId === this.state.selectedVendor && this.state.selectedSites.includes(d.siteId))
                .map(d => d.name)
                .filter((item, i, ar) => ar.indexOf(item) === i)    //get unique names
                .sort() : [];

        let selectedDeliverer = this.state.selectedDeliverer;
        if (!siteVendorDeliverers.includes(selectedDeliverer)) {
            selectedDeliverer = '';
        }

        this.setState({
            siteVendorDeliverers: siteVendorDeliverers,
            selectedDeliverer: selectedDeliverer
        });
    }

    async createCaseOrder(caseId) {
        if (this.state.selectedVendor) {
            const vendor = this.props.vendors.find(v => v.id === this.state.selectedVendor);

            await loanerOrderService.quickCreateCaseOrder({ caseId: caseId, vendorId: this.state.selectedVendor, vendorName: vendor.name })
                .then(response => {
                    if (response.data.payload > 0) {
                        this.props.history.push({
                            pathname: `/loaners/delivertrays/${response.data.payload}`,
                            state: { deliverer: this.state.selectedDeliverer }
                        });
                    }
                });
        }
    }

    handleCaseResults(results) {
        this.setState({ filteredCaseResults: results });
    }

    renderCaseResults() {
        if (this.state.filteredCaseResults) {
            const hasInitials = this.state.filteredCaseResults.some(x => x.patientInitials);
            return [
                (<Card>
                    <Card.Header className="d-flex align-items-center">
                        <div className="mr-auto card-button-group-item">Select case</div>
                        <div className="card-btn-group form-inline">
                            <Form.Check label="Show only cases that match vendor"
                                onChange={this.handleShowCheckbox}
                                className="card-button-group-item"
                                name="showOnlyVendorCases"
                                id="showOnlyVendorCases" checked={this.state.showOnlyVendorCases} />
                        </div>
                    </Card.Header>
                    <Card.Body className="card-body-table">
                        <SearchResults
                            asCaseWithOrder
                            sites={this.state.sites}
                            results={this.state.filteredCaseResults.sort(byCaseStartTime)}
                            onlyVendor={this.state.selectedVendor}
                            showOnlyVendorCases={this.state.showOnlyVendorCases}
                            selectedDeliverer={this.state.selectedDeliverer}
                            pageResults site caseNumber physician caseStartTime procedure vendor traysExpected linkTo
                            initials={hasInitials}
                            onCaseLinkTo={this.createCaseOrder} />
                    </Card.Body>
                </Card>
                )
            ];
        }
        return null;
    }

    render() {
        const { t } = this.props;
        let delivererHeader = t("Select delivery person");
        if (this.state.selectedDeliverer) {
            delivererHeader += ' - ' + this.state.selectedDeliverer;
        }
        return (
            <div>
                <VendorSelector
                    sites={this.state.sites}
                    selectedSites={this.state.selectedSites}
                    handleSitesChange={this.handleSitesChange}
                    cases={this.props.cases}
                    vendors={this.props.vendors}
                    deliverer={this.state.selectedDeliverer}
                    selectedVendor={this.state.selectedVendor}
                    handleVendorChange={this.handleVendorChange} />
                {
                    (this.state.selectedSites.length > 0 &&
                        this.state.selectedVendor > 0 &&
                        this.state.siteVendorDeliverers)
                        ? (
                            <CardWrapper header={delivererHeader}>
                                <DelivererSelector
                                    selectedSites={this.state.selectedSites}
                                    selectedVendor={this.state.selectedVendor}
                                    selectedDeliverer={this.state.selectedDeliverer}
                                    siteVendorDeliverers={this.state.siteVendorDeliverers}
                                    handleDelivererChange={this.handleDelivererChange}
                                    handleNewDeliverer={this.handleNewDeliverer} />
                            </CardWrapper>
                        )
                        : null
                }
                {(this.state.selectedVendor > 0 && this.state.selectedDeliverer) &&
                    [
                        (<CaseSearch
                            callback={this.handleCaseResults}
                            defaultVendorId={this.state.selectedVendor}
                            showOnlyVendorCases={this.state.showOnlyVendorCases}
                            defaultSelectedSiteIds={this.state.selectedSites}
                            queryServer={this.queryServer}
                            selectedDeliverer={this.state.selectedDeliverer}
                            showPastCases={false}
                            showPatientInitials={this.props.showPatientInitials}
                            allowedToSearchForPi={this.state.allowedToSearchForPi}
                        />),
                        this.renderCaseResults()
                    ]
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    vendors: state.loanerOrder.vendors,
    sites: state.loanerOrder.sites,
    defaultLoanerSites: selectDefaultLoanerSites(state),
    deliverers: state.loanerOrder.deliverers,
    isLoading: state.loanerOrder.isLoading,
    showPatientInitials: state.loanerAppSettings.patientData.showInitials
});

export default withTranslation()(connect(mapStateToProps, dispatch => bindActionCreators({ ...loanerOrderActionCreator, ...signalrActionCreator, ...loanerAppSettingsActionCreator, ...modalActionCreator }, dispatch))(DeliverTrays));
