import React from 'react';
import { claimValues } from '../../../utilities/authConstants';

const PermissionItemMini = props => {
    const p = props.permission;
    
    return (
        <tr key={p.id}>
            <td className="table-fit"><b>{p.claim}</b></td>
            <td className="table-fit">
                {p.permissionValue === claimValues.isTrue && 
                    <h6><span class="badge badge-success">T</span></h6>
                }
                {p.permissionValue === claimValues.isFalse && 
                    <h6><span class="badge badge-secondary">F</span></h6>
                }

                <h6>
                    {p.permissionValue[0] === "1" && 
                        <span style={{ marginRight: "2.5px" }} className="badge badge-success">Cr</span>
                    }
                    {p.permissionValue[1] === "1" && 
                        <span style={{ marginRight: "2.5px" }} class="badge badge-primary">R</span>    
                    }
                    {p.permissionValue[2] === "1" && 
                        <span style={{ marginRight: "2.5px" }} class="badge badge-warning">U</span>  
                    }
                    {p.permissionValue[3] === "1" && 
                        <span style={{ marginRight: "2.5px" }} class="badge badge-danger">D</span>  
                    }
                </h6>  
            </td>
        </tr> 
    )
}


export default PermissionItemMini;

