import axios from "axios";

const EpicEncryptionService = {
    async encrypt(epicValues) {
        const url = `api/epicencryption/encrypt`;
        const response = axios.post(url, epicValues);
        return response;
    },
}

export default EpicEncryptionService;