import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';
import FormControls from '../formControls/';
import { organizationTypes, organizationTypeEnum } from '../../utilities/enumerations';
import LoadingButton from '../buttons/LoadingButton';

const CreateNewOrganizationModal = (props) => {

    const [parentOrgs, setParentOrgs] = useState([]);
    const [tenantIds, setTenantIds] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {

        // build list of lower cased tenant ids for checking
        var localTenantIds = [];
        props.orgList?.forEach((item) => {
            if (item.orgCode != null) {
                localTenantIds.push(item.orgCode.toLowerCase());
            }

            item.childOrganizations?.forEach((child) => {
                if (child.orgCode != null) {
                    localTenantIds.push(child.orgCode.toLowerCase());
                }
            })
        });

        // filter list of parent orgs 
        var localParentOrgs = props.orgList?.filter((org) => { return org.organizationTypeId === organizationTypeEnum.ParentOrganization; })
            .map((item, i) => ({ id: item.id, text: item.name }));

        // add in default display
        parentOrgs.unshift({ id: 0, text: t('Please choose a parent organization') });

        setTenantIds(localTenantIds);
        setParentOrgs(localParentOrgs);

    }, [props.orgList]);

    const restrictToAlphaAndLowerCase = (input) => {

        if (!input.key.match(/^[a-z]+$/i)) {
            input.preventDefault();
        }
        input.value = input.value?.toLowerCase();
    }

    const initialValues = {
        name: '',
        orgCode: '',
        organizationTypeId: 1,
        parentOrganizationId: 0,
        state: 100
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required(t("The Organization Name is required"))
            .test('nameTest', t('Organization Name must be a minimum of 5 characters, a maximum of 200 characters, and must not start or end with a space.'), (value) => {
                const trimmed = value?.trim();

                if (trimmed) {
                    return trimmed.length > 5;
                }

                return false;
            })
            .min(5, t("The Organization Name must be greater than 5 characters long."))
            .max(200, t("The Organization Name must be less than 200 characters long.")),
        orgCode: Yup.string()
            .required(t("Tenant ID is required."))
            .test('orgCodeTest',
                t('This Tenant ID is already in use, please choose another.'),
                (value) => {
                    if (!value) {
                        return true;
                    }
                    return tenantIds.indexOf(value.toLowerCase()) < 0
                })
            .min(3, t("The Tenant ID must be greater than 3 characters long."))
            .max(10, t("The Tenant ID must be less than 10 characters long.")),
        parentOrganizationId: Yup.number().when("organizationTypeId", {
            is: organizationTypeId => organizationTypeId === organizationTypeEnum.ChildOrganization,
            then: Yup.number().min(1, t("You must select a parent organization if selecting the child organization type."))
        })
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, actions) => {

            values.orgCode = values.orgCode.toLowerCase();
            // need to set this to null in case it was changed
            // and then not intended.  0 will cause failures
            if (values.parentOrganizationId === 0) {
                values.parentOrganizationId = null;
            }
            props.saveModel(values);
            props.closeModal();
            actions.setSubmitting(false);
        },
    });

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Header>{t('Create New Organization')}</Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <FormControls.Textbox
                            label={t("Organization Name")}
                            name="name"
                            formHandler={formik} />
                    </Form.Group>
                    <Form.Group>
                        <FormControls.Textbox
                            label={t('Tenant Id')}
                            name="orgCode"
                            onKeyPress={(e) => restrictToAlphaAndLowerCase(e)}
                            formHandler={formik} />
                    </Form.Group>
                    <Form.Group>
                        <FormControls.Selectbox
                            label={t('Type')}
                            name="organizationTypeId"
                            numeric
                            formHandler={formik}
                            defaultValue={1}
                            onChange={(e) => {
                                const val = parseInt(e.target.value);
                                val !== organizationTypeEnum.ChildOrganization ?? formik.setFieldName("parentOrganizationId", 0, true);
                                formik.setFieldValue("organizationTypeId", val, true)
                            }}
                            options={organizationTypes}
                        />
                    </Form.Group>
                    <FormControls.ConditionalRender visible={formik.values["organizationTypeId"] === organizationTypeEnum.ChildOrganization}>
                        <Form.Group>
                            <FormControls.Selectbox
                                label={t('Parent Organization')}
                                numeric
                                name="parentOrganizationId"
                                helpText={t('Please choose the Parent Organization for this child organization.')}
                                formHandler={formik}
                                defaultValue={1}
                                options={parentOrgs}
                            />
                        </Form.Group>
                    </FormControls.ConditionalRender>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={formik.isSubmitting}
                        onClick={() => props.closeModal()}
                        variant="warning"
                        type="button">{t("Cancel")}
                    </Button>
                    <LoadingButton
                        loading={formik.isSubmitting}
                        disabled={!formik.dirty}
                        variant="primary"
                        type="submit">{t("Create")}
                    </LoadingButton>
                </Modal.Footer>
            </Form>
        </>

    );
};

export default CreateNewOrganizationModal;