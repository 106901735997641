import axios from "axios";

const SocketAdminService = {
    async getAllSockets() {
        const response = axios.get(`/api/socketadmin/`);
        return response; 
    },

    async deleteSocket(id) {
        const response = axios.delete(`/api/socketadmin/${id}`);
        return response;
    }    
}

export default SocketAdminService; 