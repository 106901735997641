//Framework imports
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Modal, Row, Form, Button, Alert } from 'react-bootstrap';

//App imports
import TooltipOverlay from '../../controls/tooltip-overlay/TooltipOverlay';
import MasterLoanerService from '../../services/masterLoanerService';
import LoadingButton from '../buttons/LoadingButton';

import TraySearchAdapter from '../../components/loaner/SelectTrays/TraySearchAdapter';

class AddNewTrayModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.innerRef = React.createRef();
        this.state = {
            trayName: null,
            vendor: props.vendorName,
            vendorId: props.vendorId,
            prodCode: null,
            saveErrorMessage: null,
            notOkayToSend: true,
            isLoading: false,
            shouldSearch: false,
            hasSimilarTrays: false,
            message: "",
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.addTrayHandler = this.addTrayHandler.bind(this);
        this.queryExistingTrays = this.queryExistingTrays.bind(this);
        this.switchButtons = this.switchButtons.bind(this);
    }

    componentDidMount() {
        setTimeout(() => { this.innerRef.current.focus(); }, 1);
    }

    queryExistingTrays() {
        if (this.state.trayName || this.state.prodCode) {
            const { t } = this.props;
            if (this.state.trayName && this.state.trayName.length > 100) {
                this.setState({
                    saveErrorMessage: t('Loaner name must be 100 characters or less')
                });
            } else if (this.state.prodCode && this.state.prodCode.length > 30) {
                this.setState({
                    saveErrorMessage: t('Product code must be 30 characters or less')
                });
            }

            this.setState({ shouldSearch: false },
                () => {
                    this.setState({ shouldSearch: true });
                });
        } else {
            this.setState({ shouldSearch: false });
        }
    }

    handleUserInput(event) {
        // taken straight from the React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        var partialState = this.state;
        partialState[event.target.name] = event.target.value;
        partialState.notOkayToSend = !this.state.trayName;

        this.setState({ ...partialState });
        this.queryExistingTrays();
    }

    async addTrayHandler() {
        this.setState({ shouldSearch: false });
        this.setState({ isLoading: true });
        this.setState({ saveErrorMessage: null });

        await MasterLoanerService.addMasterLoanerSet({
            vendorId: this.state.vendorId,  /*test data: 733*/
            name: this.state.trayName,
            companyProductCode: this.state.prodCode
        }).then((response) => {
            if (response.data.success) {
                this.props.confirm(response.data.payload);
            } else {
                this.setState({
                    saveErrorMessage: response.data.message
                });
            }
        }).catch((error) => {
            if (401 === error.response.status) {
                // Not Authorized
                window.location = "/auth/login";
            }
            else if (403 === error.response.status) {
                // Forbidden 
                window.location = "/auth/unauthorized";
            }
            else {
                //TODO: make better error message
                let errorMsg = error.response.statusText + " (" + error.response.status + ")";

                // BadRequest with ResultDto 
                if (400 === error.response.status && error.response.data !== null) {
                    const resultDto = error.response.data;
                    errorMsg = resultDto.message;
                }

                this.setState({
                    saveErrorMessage: errorMsg
                });
            }
        }).finally(() => this.setState({ isLoading: false }));
    }

    switchButtons(hasResults) {
        if (this.state.hasSimilarTrays !== hasResults) {
            this.setState({ hasSimilarTrays: hasResults });
        }
    }

    render() {
        const { t } = this.props;
        const error = this.state.saveErrorMessage
            ? <Alert variant="danger">{this.state.saveErrorMessage}</Alert>
            : null;

        return (
            <>
                <Modal.Header>
                    <Modal.Title>{t("Add New Loaner")}</Modal.Title>
                </Modal.Header>
                <div className="modal-scroll">
                    <Modal.Body>
                        <Form autoComplete="off">
                            <Row>
                                <Col>
                                    <div className="shrink-col">
                                        <Form.Group as={Row} controlId="formTrayName">
                                            <Form.Label column sm={3}>{t("Name")}</Form.Label>
                                            <Col sm={9}>
                                                <TooltipOverlay
                                                    tooltip={this.state.trayName && this.state.trayName.length > 20 ? this.state.trayName : null}
                                                    trigger={this.state.trayName && this.state.trayName.length > 20 ? ['hover'] : []}>
                                                    <Form.Control type="input"
                                                        ref={this.innerRef}
                                                        name="trayName"
                                                        value={this.state.trayName}
                                                        onChange={this.handleUserInput} />
                                                </TooltipOverlay>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formVendor">
                                            <Form.Label column sm={3}>{t("Vendor")}</Form.Label>
                                            <Col sm={9}><Form.Control type="input" disabled name="vendor" value={this.state.vendor} /></Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formProductCode">
                                            <Form.Label column sm={3}>{t("Product code")}</Form.Label>
                                            <Col sm={9}>
                                                <TooltipOverlay
                                                    tooltip={this.state.prodCode && this.state.prodCode.length > 20 ? this.state.prodCode : null}
                                                    trigger={this.state.prodCode && this.state.prodCode.length > 20 ? ['hover'] : []}>
                                                    <Form.Control type="input" name="prodCode" value={this.state.prodCode} onChange={this.handleUserInput} />
                                                </TooltipOverlay>
                                            </Col>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <div className="vr" />
                                <Col>
                                    <Form.Label>{t("Please confirm this is not an existing loaner as you are entering information. Use the following tips when you create a new loaner:")}</Form.Label>
                                    <ul>
                                        <li><b>DO</b> capitalize the first letter of each word in the name and enter the product code only in the <b>Product code</b> field.</li>
                                        <li><b>DO NOT</b> include lot number, vendor/manufacturer, product code, or use abbreviations in the loaner name.</li>
                                    </ul>
                                    {error}
                                </Col>
                            </Row>
                        </Form>
                        <Col>
                            <TraySearchAdapter
                                dropOffNewTray={this.props.dropOffNewTray}
                                promptForDeliverer={this.props.promptForDeliverer}
                                vendorId={this.props.vendorId}
                                nameText={this.state.trayName}
                                productCodeText={this.state.prodCode}
                                loading={this.props.loading}
                                setLoading={this.props.setLoading}
                                addNewTray={this.addTrayHandler}
                                shouldSearch={this.state.shouldSearch}
                            />
                        </Col>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="warning"
                            disabled={this.state.isLoading}
                            onClick={this.props.cancel}
                        >{t('Cancel')}</Button>

                        <LoadingButton
                            variant="primary"
                            loading={this.state.isLoading}
                            disabled={this.state.notOkayToSend}
                            onClick={this.addTrayHandler}
                        >{t('New loaner')}</LoadingButton>
                    </Modal.Footer>
                </div>
            </>
        );
    }
}

export default withTranslation()(AddNewTrayModal);