import React from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const renderTooltip = (tooltipText) => (
    <Tooltip id="button-tooltip">
       {tooltipText}
    </Tooltip>
);

const IconWithTooltip = (props) => {
    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 100 }}
            overlay={renderTooltip(props.tooltip)} >
            <FontAwesomeIcon 
                icon={props.icon} 
                onClick={props.onClick}
                className={props.className} />
        
        </OverlayTrigger>  
    );
}

export default IconWithTooltip;