import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const TooltipOverlay = ({
    children,
    tooltip,
    placement = 'top',
    delay = { show: 250, hide: 100 },
    ...otherProps
}) => {
    return (
        <OverlayTrigger
            placement={placement}
            delay={delay}
            overlay={<Tooltip>{tooltip}</Tooltip>}
            {...otherProps}
        >
            {children}
        </OverlayTrigger>
    )
}

export default TooltipOverlay
