import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/updateObject'; 

const initialState = {
    caseOrderId: 0, 
    caseOrder: null,
    sites: [],
    physicians: [], 
    vendors: [],
    cases: [],
    deliverers: [],
    isLoading: false,
    refreshCaseOrder: false
}; 

const requestLoanerOrderHome = (state, action) => {
    return updateObject( state, { isLoading: true } );
};

const receiveLoanerOrderHome = (state, action) => {
    var newData = updateObject( state, { 
        isLoading: false, 
        sites: action.payload.sites, 
        physicians: action.payload.physicians,
        vendors: action.payload.vendors,
        cases: action.payload.cases,
        deliverers: action.payload.deliverers
    });
    
    return newData;
};

const requestCaseOrder = (state, action) => {
    return updateObject( state, {
        isLoading: true, 
        refreshCaseOrder: false,
        caseOrderId: action.id
    });
};

const receiveCaseOrder = (state, action) => {
    return updateObject( state, {
        isLoading: false, 
        caseOrder: action.payload
    });
};

const updateDeliverers = (state, action) => {
    return {
        ...state, 
        deliverers: [...state.deliverers, action.payload]
    }
}

const updateCase = (state, action) => {
    if (state.isLoading) {
        return state;
    }

    const newCase = action.payload.case;
    const refreshCaseOrder = state.refreshCaseOrder || (newCase.caseOrders.some(co => co.id === state.caseOrderId));

    return {
        ...state,
        refreshCaseOrder: refreshCaseOrder,
        cases: state.cases.some(c => c.id === newCase.id)
            ? state.cases.map(c => (c.id === newCase.id) ? { ...newCase } : c)
            : state.cases.concat([newCase])
    };
}; 

const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case actionTypes.REQUEST_LOANER_ORDER_HOME: return requestLoanerOrderHome(state, action);
        case actionTypes.RECEIVE_LOANER_ORDER_HOME: return receiveLoanerOrderHome(state, action);
        case actionTypes.REQUEST_CASE_ORDER: return requestCaseOrder(state, action);
        case actionTypes.RECEIVE_CASE_ORDER: return receiveCaseOrder(state, action);
        case actionTypes.UPDATE_CASE: return updateCase(state, action);
        case actionTypes.UPDATE_DELIVERERS: return updateDeliverers(state, action);
        default: return state; 
    }
}; 

export default reducer;