import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {Card, Col, Form} from 'react-bootstrap';
import {TabItem, TabList} from '../../../controls/tabcontrol';
import '../../../scss/FindItem.scss';
import {dispatchHideSpinner} from '../../../store/actions/spinnerActions';
import {applicationMessagingActionCreator} from '../../../store/actions/applicationMessagingActions';
import FindItemService from '../../../services/findItemService';
import {OrContext} from '../OrContext';
import CaseContext from '../../../controls/caseContext/CaseContext';
import {ProductResultsTable} from "./ProductResultsTable";
import {SearchInput} from "./SearchInput";
import {SearchAreaSwitcher} from "./SearchAreaSwitcher";
import {InstrumentResultsTable} from "./InstrumentResultsTable";

const FindItem = () => {
    const searchWaitTime = 25 * 1000;

    const [searchText, setSearchText] = useState('');
    const [searchProducts, setSearchProducts] = useState(true);
    const [searchInstruments, setSearchInstruments] = useState(false);
    const [instrumentResults, setInstrumentResults] = useState([]);
    const [productResults, setProductResults] = useState([]);
    const [searchedOnce, setSearchedOnce] = useState(false);
    const [searchInProgress, setSearchInProgress] = useState(false);

    const orContext = useContext(OrContext);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const siteId = orContext.caseData.siteId ? orContext.caseData.siteId : 1;
    const currentSiteProducts = productResults.filter((product) => product.siteId === siteId);
    const currentSiteProductsCount = currentSiteProducts ? currentSiteProducts.length : 0;
    const otherSitesProducts = productResults.filter((product) => product.siteId !== siteId);
    const otherSitesProductsCount = otherSitesProducts ? otherSitesProducts.length : 0;
    const instrumentResultCount = instrumentResults ? instrumentResults.length : 0;

    useEffect(() => {
        if (!searchProducts && !searchInstruments) {
            setSearchProducts(true);
        }
        if (searchText.length > 0) {
            performSearch();
        }

    }, [searchProducts, searchInstruments])

    const errorMessage = (title, message) => dispatch(applicationMessagingActionCreator.createErrorMessage(title, message));


    const performSearch = () => {
        if (!searchInProgress) {
            setSearchInProgress(true);
            // if neither is selected - just use a default search of search products;
            const searchProductsLocal = !searchProducts && !searchInstruments ? true : searchProducts;
            const params = {searchText, searchProducts: searchProductsLocal, searchInstruments, siteId}

            const cancelTokenSource = axios.CancelToken.source();

            const handleError = () => {
                cancelTokenSource.cancel();
                dispatchHideSpinner(dispatch);
                errorMessage(t('Error'), t('Server took too long to respond. Request timed out!'));
            };

            const searchRequest = FindItemService.findItem(params, {
                cancelToken: cancelTokenSource.token
            })
                .then(response => {
                    var resultDto = response.data;

                    if (resultDto.success) {
                        const results = resultDto.payload;

                        // set product results
                        const productList = results.productSearchResults?.length > 0 ? results.productSearchResults : [];
                        setProductResults(productList);

                        // set instrument results
                        const instrumentList = results.instrumentSearchResults?.length > 0 ? results.instrumentSearchResults : [];
                        setInstrumentResults(instrumentList);

                        // set searched once
                        setSearchedOnce(true);
                        setSearchInProgress(false);
                    }
                });

            const timer = new Promise((_, reject) => setTimeout(reject, searchWaitTime, {timedout: "request taking too long!"}));
            Promise.race([searchRequest, timer]).catch(handleError);
        }
    }

    const checkForEnter = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();

            performSearch();
        }
    }

    const refreshProductIndexes = (row) => {
        if (row != null) {
            const params = {setTypeId: row.id, indexes: row.indexes.map((item) => item.value)};

            FindItemService.setIndexSearch(params)
                .then(response => {
                    var resultDto = response.data;
                    const results = resultDto.payload;

                    const newProductResults = [...productResults];
                    const index = newProductResults.findIndex(prod => prod.id === row.id);

                    const newProduct = {...newProductResults[index]};
                    newProduct.setIndexes = results.indexes.filter(si => si.indexNumber !== "000");
                    newProductResults[index] = newProduct;
                    setProductResults(newProductResults);
                });
        }
    }

    const refreshInstrumentIndexes = (row) => {
        if (row != null) {
            const instrumentId = row.id;
            const params = {instrumentId, siteId}

            FindItemService.getProductsForInstrument(params)
                .then(response => {
                    var resultDto = response.data;
                    const results = resultDto.payload;

                    const newInstrumentResults = [...instrumentResults];
                    const index = newInstrumentResults.findIndex(inst => inst.id === row.id);

                    newInstrumentResults[index].productsForInstrument = results;
                    setInstrumentResults(newInstrumentResults);
                });
        }
    }

    return (<>
        <CaseContext caseInfo={orContext.caseData} className="context-container-or-portal bg-light"/>
        <Card className="mt-30">
            <Card.Header className="d-flex align-items-center">
                <div className="mr-auto card-button-group-item">{t('Search Products & Instruments')}</div>
            </Card.Header>
            <Card.Body>
                <Form.Row>
                    <Form.Group as={Col} lg={6} sm={12}>
                        <SearchInput value={searchText}
                                     onChange={(e) => setSearchText(e.target.value)}
                                     onKeyPress={(e) => checkForEnter(e)} onClick={() => performSearch()}/>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} sm={12}>
                        <SearchAreaSwitcher searchProducts={searchProducts}
                                            onProductsButtonChange={(e) => setSearchProducts(e.currentTarget.checked)}
                                            searchInstruments={searchInstruments}
                                            onInstrumentsButtonChange={(e) => setSearchInstruments(e.currentTarget.checked)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <TabList id="results">
                        <TabItem
                            label={t("Current Site Products{{cnt}}", {cnt: currentSiteProductsCount > 0 ? ' (' + currentSiteProductsCount + ')' : ''})}
                            hidden={!(searchProducts && searchText.length > 0 && searchedOnce)}
                            isTable>
                            <ProductResultsTable
                                onRowDetailExpanded={refreshProductIndexes}
                                dataSource={currentSiteProducts}
                            />
                        </TabItem>
                        <TabItem
                            label={t("Other Sites Products{{cnt}}", {cnt: otherSitesProductsCount > 0 ? ' (' + otherSitesProductsCount + ')' : ''})}
                            hidden={!(searchProducts && searchText.length > 0 && searchedOnce)}
                            isTable>
                            <ProductResultsTable
                                onRowDetailExpanded={refreshProductIndexes}
                                dataSource={otherSitesProducts}
                            />
                        </TabItem>
                        <TabItem
                            label={t("Instrument Results {{cnt}}", {cnt: instrumentResultCount > 0 ? ' (' + instrumentResultCount + ')' : ''})}
                            hidden={!(searchInstruments && searchText.length > 0 && searchedOnce)}
                            isTable>
                            <InstrumentResultsTable
                                onRowDetailExpanded={refreshInstrumentIndexes}
                                dataSource={instrumentResults}
                            />
                        </TabItem>
                    </TabList>
                </Form.Row>
            </Card.Body>
        </Card>
    </>);
}

export default FindItem;