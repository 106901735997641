import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';
import { dateTimeFormat } from "../../utilities/dateTime";

const renderVendorRepTooltip = (caseOrder, t) => {
    const content = [
        caseOrder.vendorRepName ? t('Vendor rep') + ': ' + caseOrder.vendorRepName : null,
        caseOrder.vendorRepEmail ? t('Email') + ': ' + caseOrder.vendorRepEmail : null,
        caseOrder.vendorRepOffice ? t('Office') + ': ' + caseOrder.vendorRepOffice +
            (caseOrder.vendorRepPrimaryNumberType === 1 ? t('(primary)') : '') : null,
        caseOrder.vendorRepCell ? t('Cell') + ': ' + caseOrder.vendorRepCell +
            (caseOrder.vendorRepPrimaryNumberType === 2 ? t('(primary)') : '') : null,
        caseOrder.vendorRepPager ? t('Pager') + ': ' + caseOrder.vendorRepPager +
            (caseOrder.vendorRepPrimaryNumberType === 3 ? t('(primary)') : '') : null
        ].filter(c => c);

    if (content.length === 0) {
        content.push(t('Vendor rep info not found'));
    }

    return (
        <Tooltip id="button-tooltip" className="d-flex justify-content-start wide-tooltip" >
            {content.map((c, i) => (
                <span key={i} className="d-flex align-items-center justify-content-start text-nowrap">
                    {c}
                </span>))
            }
        </Tooltip>
    );
};


class LoanerCheckInContext extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        }

        this.renderVendorRepIconButton = this.renderVendorRepIconButton.bind(this);

    }

    renderVendorRepIconButton(label) {
        if (label === this.props.t('Vendor rep')+ ':') {

            return [
           
            <span>&nbsp;
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 100 }}
                        overlay={renderVendorRepTooltip(this.props.caseOrder, this.props.t)}
                        style={{ maxWidth: "100%" }}>
                        <Button variant="secondary" size="sm" style={{ backgroundColor: "transparent", borderColor: "transparent" }} >
                            <FontAwesomeIcon icon={faInfoCircle}  />
                        </Button>
                        </OverlayTrigger>
                </span>
                
            ];
        }
        return null;
    }

    render() {
        const { t } = this.props;

        const caseInfo = [
            { label: t('Site') + ':', value: this.props.caseOrder && (this.props.caseOrder.siteName)},
            { label: t('Start time') + ':', value: this.props.caseOrder && (moment(this.props.caseOrder.caseStartTime).format(dateTimeFormat))},
            { label: t('Vendor') + ':', value: this.props.caseOrder && (this.props.caseOrder.vendorName)},
            { label: t('Physician') + ':', value: this.props.caseOrder && (this.props.caseOrder.physicianName)},
            { label: t('Procedure') + ':', value: this.props.caseOrder && (this.props.caseOrder.procedureName) },
            { label: t('Comment') + ':', value: this.props.caseOrder && (this.props.caseOrder.procedureComment) },
            { label: t('Description') + ':', value: this.props.caseOrder && (this.props.caseOrder.procedureDescription) },
            { label: t('Vendor rep') + ':', value: this.props.caseOrder && (this.props.caseOrder.vendorRepName)}
        ];

        return (
            <Row className="context-container">
                <Col lg={2} className="justify-content-left align-items-center">
                    <h5 className="mb-0">{t('Case')} {this.props.caseOrder && (this.props.caseOrder.caseNumber)}</h5>
                    {this.props.caseOrder && this.props.caseOrder.isCanceled && <div className="font-error">Canceled</div>}
                </Col>
                <Col>
                    <Row className="d-flex justify-content-around">
                        {
                            caseInfo.map((ci, i) => 
                                    (
                                    <Col key={i} xl={4} md={6}>
                                        <span>{ci.label}</span>
                                        <span className="ml-2 flex-grow-1">
                                            {ci.value}
                                            {this.renderVendorRepIconButton(ci.label)}
                                        </span>
                                    </Col>
                                    )
                                )
                            }
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default withTranslation()(LoanerCheckInContext);