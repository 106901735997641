import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBomb } from '@fortawesome/free-solid-svg-icons';

import { modalActionCreator as modal } from '../../../store/actions/modalActions';
import LoadingButton from '../../../controls/buttons/LoadingButton';
import TooltipOverlay from '../../../controls/tooltip-overlay/TooltipOverlay';
import ConfigService from "../../../services/configService";
import EpicAuthentication from './EpicAuthentication';

const Utilities = ({ thing }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [showConfirmed, setShowConfirmed] = useState(false);
    const [status, setStatus] = useState("");

    const confirm = () => {
        dispatch(modal.showModal({
            open: true,
            cancel: () => dispatch(modal.hideModal()),
            confirm: () => {
                setShowConfirmed(true);
                dispatch(modal.hideModal());
            },
            confirmText: t("Yes, I'm sure"),
            title: t("Are you sure?"),
            message: t(
                "Doing this will wipe ALL master vendor and set data. It will also take a while to complete and may alter or delete existing orders or loaner tray data.")
        },
            'confirm'));
    };

    const execute = () => {
        setStatus("Resetting master loaner data. Please wait...");
        ConfigService.resetMasterLoanerData()
            .then(response => {
                if (response.data.success) {
                    setStatus("Successfully reset master loaner data. Thank you for waiting patiently.");
                } else {
                    setStatus("Something went wrong.");
                }
            });
    };

    return [
        <>
            <EpicAuthentication />
            <Card border="danger">
                <Card.Header bg="danger">
                    Danger Zone
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>{t("These functions are only available on Dev, Test, and Staging environments.")}</Col>
                    </Row>
                    <Row>
                        <Col>
                            {showConfirmed ?
                                <LoadingButton
                                    size="sm"
                                    variant="danger"
                                    onClick={() => execute()}>
                                    <FontAwesomeIcon
                                        icon={faBomb}
                                        className="mr-2"
                                        tooltip={t("I hope you know what you're doing...")} />
                                    <TooltipOverlay tooltip={t("I hope you know what you're doing...")}>
                                        <span>{t('Yes! Clear all master loaner data now')}</span>
                                    </TooltipOverlay>
                                </LoadingButton>
                                : <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() => confirm()}>
                                    <FontAwesomeIcon icon={faBomb} className="mr-2" />
                                    <span>{t('Clear all master loaner data')}</span>
                                </Button>}
                        </Col>
                        <Col>
                            {t(status)}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>        
        </>

    ];
}


export default Utilities;
