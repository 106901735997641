import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Modal,Button, Table, Card } from 'react-bootstrap';
import { byIndexNumber } from '../../utilities/sorts';
import { dateFormat } from '../../utilities/dateTime';

export default function ProductIndexModal(props) {
    const { t } = useTranslation();
    const closeModal = props.closeModal;
    
    return (
        <div>
            <Modal.Header>
                <h4>{t('Product')} - {props.model.name}</h4>
            </Modal.Header>
            <Modal.Body>
                    <Table striped condensed hover responsive>
                        <thead>
                            <tr>
                                <th>{t('Index')}</th>
                                <th>{t('Current Location')}</th>
                                <th>{t('Last Scanned - DateDone')}</th>
                                <th>{t('In Service')}</th>
                                <th>{t('Sterile')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.model.setIndexes.sort(byIndexNumber).map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.indexNumber}</td>
                                        <td>{item.currentLocation.length > 0 ? `${item.currentLocation} (${item.currentSite})` : ""}</td>
                                        <td>{item.dateDone ? moment(item.dateDone).format(dateFormat): ""}</td>
                                        <td>{item.isInService ? "In Service" : "Not In Service"}</td>
                                        <td>{item.sterile}</td>
                                    </tr>                                    
                                );
                            })} 
                        </tbody>
                    </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={() => closeModal()}>{t('Close')}</Button>
            </Modal.Footer>
        </div>
    )
}
