import React from "react";
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import apiService from '../../services/apiKeyService';
import { modalActionCreator } from '../../store/actions/modalActions';
import { ApiKeyButton } from './ApiKeyButton';
import { apiKeyTypes } from '../../utilities/constants'

import '../../styles/spm.css';

class ApiKeyList extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showScanItemsModal: false,
            returnError: '',
            showResetPasswordModal: false,
            showDeleteApiKeyModal: false,
            editApiKey: {},
        };


        this.resetApiKeyPassword = this.resetApiKeyPassword.bind(this);
        this.deleteApiKey = this.deleteApiKey.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(event) {
        this.props.hideModal();
    }


    showDeleteApiKeyModal(apiKey, event) {
        this.setState({ editApiKey: apiKey, showDeleteApiKeyModal: true });
    }

    hideDeleteApiKeyModal() {
        this.setState({ editApiKey: {}, showDeleteApiKeyModal: false });
    }

    deleteApiKey() {
        // delete the api key
        var id = this.state.editApiKey.id;
        apiService.deleteApiKey(id)
            .then(response => {
                if (response.data.success) {
                    var deleteId = this.state.editApiKey.id;
                    var existing = this.props.organization.apiKeys.filter(x => x.id !== deleteId);
                    this.props.modifyState("apiKeys", existing);
                    this.hideDeleteApiKeyModal();
                }
            });
    }

    resetApiKeyPassword(apiKeyId) {
        //const apiKeyId = this.state.editApiKey.id;
        apiService.resetApiKey(apiKeyId)
            .then(response => {
                if (response.data.success) {
                    var result = response.data.payload;
                    var current = this.props.organization.apiKeys.filter(key => key.id === result.id)[0];
                    result.apiKeyType = current.apiKeyType;
                    var existing = this.props.organization.apiKeys.filter(x => x.id !== result.id);
                    existing.push(result);
                    this.props.modifyState("apiKeys", existing);
                    this.props.hideModal();
                }
            });
    }



    render() {
        const t = this.props.t;
        return (
          <div>
            <Modal bsSize="small" show={this.state.showDeleteApiKeyModal}>
              <Modal.Header>
                <Modal.Title>
                  <Trans>Confirm ApiKey Deletion</Trans>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Trans>Are you sure you want to delete the api key:</Trans>{" "}
                {this.state.editApiKey.clientName}?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={this.hideDeleteApiKeyModal}
                  variant="default"
                  type="button"
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  onClick={this.deleteApiKey}
                  variant="danger"
                  type="button"
                >
                  <Trans>Delete</Trans>
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal bsSize="small" show={this.state.showResetPasswordModal}>
              <Modal.Header>
                <Modal.Title>
                  <Trans>Confirm Password Reset</Trans>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Trans>Are you sure you want to reset the api key:</Trans>{" "}
                {this.state.editApiKey.clientName}?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={this.hideResetPasswordModal}
                  variant="light"
                  type="button"
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  onClick={this.resetApiKeyPassword}
                  variant="warning"
                  type="button"
                >
                  <Trans>Reset</Trans>
                </Button>
              </Modal.Footer>
            </Modal>
            <Row>
              <Col>
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th className="table-fit">
                        <Trans>Client Name</Trans>
                      </th>
                      <th className="table-fit">
                        <Trans>Client Secret</Trans>
                      </th>
                      <th>
                        <Trans>Client Type</Trans>
                      </th>
                      <th className="table-fit">
                        <Trans>Status</Trans>
                      </th>
                      <th className="table-fit">
                        <ApiKeyButton
                          showModal={this.props.showModal}
                          closeModal={this.closeModal}
                          organization={this.props.organization}
                          onSaved={(result)=> {
                              const existing = this.props.organization.apiKeys.filter((x) => x.id !== result.id);
                              existing.push(result);
                              this.props.modifyState("apiKeys", existing);

                              if (result.apiKeyType === apiKeyTypes.acmeware) {
                                  var org = this.props.organization;
                                  org.hasMeditechApiKey = true;
                                  this.props.modifyState("organization", org);
                              }
                          }}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.apiKeys.map((item, i) => {
                      return (
                        // put mapper here @foreach (var apiKey in Model.ApiKeys)
                        <tr key={item.id}>
                          <td className="table-fit">{item.clientName}</td>
                          <td className="table-fit">{item.clientSecret}</td>
                          <td>{item.apiKeyType}</td>
                          <td className="table-fit">{item.status}</td>
                          <td className="table-fit">
                            <FontAwesomeIcon
                              icon={faRedo}
                              className="gi-1-5x text-warning show-hand"
                              onClick={() =>
                                this.props.showModal(
                                  {
                                    open: true,
                                    title: t("Confirm Client Secret Reset."),
                                    message:
                                      t(
                                        "Are you sure you want to reset the client secret for the {{ apiKeyType }}?",
                                        { apiKeyType: item.apiKeyType }
                                      ) +
                                      "\n\n" +
                                      t("Client Name: {{clientName}}", {
                                        clientName: item.clientName,
                                      }),
                                    confirm: () =>
                                      this.resetApiKeyPassword(item.id),
                                    confirmVariant: "warning",
                                    confirmText: t("Reset"),
                                    cancel: this.props.hideModal,
                                    cancelVariant: "light",
                                  },
                                  "confirm"
                                )
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        );
    }
}

export default withTranslation()(connect(null, dispatch => bindActionCreators(modalActionCreator, dispatch))(ApiKeyList));
