import * as actionTypes from '../actions/actionTypes';

const initialState = {
    caseNumber: "",
    lastUpdated: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOANER_UPDATE:
            return {
                caseNumber: action.payload.caseNumber,
                lastUpdated: action.payload.timeStamp
            };
        default:
            return state;
    }
};