import React from 'react';
import { Row, Col, FormGroup, FormLabel, FormControl, Badge, Table, HelpBlock, Button, Alert, DropdownButton, MenuItem, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';

const AlertModal = ({ closeModal, title, message }) => {
    return (
        <div>
            <Modal.Header>
                <h4>{title}</h4>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="success" onClick={closeModal}>Dismiss</Button>
            </Modal.Footer>
        </div>

    );
};

export default AlertModal