import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Col, Modal, Row, Form, Button, Alert } from 'react-bootstrap';

// import MasterLoanerService from '../../services/masterLoanerService';
import { spinnerActionCreator } from '../../store/actions/spinnerActions'

class SingleTextEntryModal extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.innerRef = React.createRef();
        this.state = {
            textEntry: '',
            saveErrorMessage: null,
            notOkayToSend: true
        };        

        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUserInput(event) {
        // taken straight from the React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        var partialState = this.state;
        partialState[event.target.name] = event.target.value;
        partialState.notOkayToSend = !this.state.textEntry;

        this.setState({ ...partialState });
    }

    handleConfirm() {
        this.props.confirm(this.state.textEntry);
    }
    
    handleSubmit(e) {
        e.preventDefault();

        if(!this.state.notOkayToSend) {
            this.props.confirm(this.state.textEntry);
        }
    }

    render() {
        const error = this.state.saveErrorMessage
        ? <Alert variant="danger">{this.state.saveErrorMessage}</Alert>
        : null;

        return (
            <>
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Label>{this.props.subTitle}</Form.Label>
                        {error}
                        <Form.Group as={Row} controlId="formDelivererName">
                            <Form.Label column sm={4}>{this.props.entryLabel}</Form.Label>
                            <Col sm={8}><Form.Control type="input" ref={this.innerRef} name="textEntry" value={this.state.textEntry} onChange={this.handleUserInput} /></Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.cancel}>Cancel</Button>
                    <Button variant="primary" disabled={this.state.notOkayToSend} onClick={this.handleConfirm}>Continue</Button>
                </Modal.Footer>
            </>
        );
    }
}


export default connect(null, dispatch => bindActionCreators({ ...spinnerActionCreator }, dispatch))(SingleTextEntryModal);