import React, { Component, useState, useEffect, memo, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';
import { dateTimeFormat } from "../../utilities/dateTime";

const renderVendorRepTooltip = (caseInfo, t) => {
    const content = [
        caseInfo.vendorRepName ? t('Vendor rep') + ': ' + caseInfo.vendorRepName : null,
        caseInfo.vendorRepEmail ? t('Email') + ': ' + caseInfo.vendorRepEmail : null,
        caseInfo.vendorRepOffice ? t('Office') + ': ' + caseInfo.vendorRepOffice +
            (caseInfo.vendorRepPrimaryNumberType === 1 ? t('(primary)') : '') : null,
        caseInfo.vendorRepCell ? t('Cell') + ': ' + caseInfo.vendorRepCell +
            (caseInfo.vendorRepPrimaryNumberType === 2 ? t('(primary)') : '') : null,
        caseInfo.vendorRepPager ? t('Pager') + ': ' + caseInfo.vendorRepPager +
            (caseInfo.vendorRepPrimaryNumberType === 3 ? t('(primary)') : '') : null
        ].filter(c => c);

    if (content.length === 0) {
        content.push(t('Vendor rep info not found'));
    }

    return (
        <Tooltip id="button-tooltip" className="d-flex justify-content-start wide-tooltip" >
            {content.map((c, i) => (
                <span key={i} className="d-flex align-items-center justify-content-start text-nowrap">
                    {c}
                </span>))
            }
        </Tooltip>
    );
};

const renderVendorRepNameAndIconButton = (caseInfo, t) => {
        return (
            <span>{caseInfo.vendorRepName}
                &nbsp
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 100 }}
                    overlay={renderVendorRepTooltip(caseInfo, t)}
                    style={{ maxWidth: "100%" }}>
                    <Button variant="secondary" size="sm" style={{ backgroundColor: "transparent", borderColor: "transparent" }} >
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </Button>
                </OverlayTrigger>
            </span>)
}

const createCaseInfo = (caseInfo, isLoanerContext, t) => {
    const createCaseInfo = [];
    if (isLoanerContext) {
        createCaseInfo.push(
            { label: t('Site') + ':', value: caseInfo && (caseInfo.siteName) },
            { label: t('Start time') + ':', value: caseInfo && (moment(caseInfo.caseStartTime).format(dateTimeFormat)) },
            { label: t('Vendor') + ':', value: caseInfo && (caseInfo.vendorName) },
            { label: t('Physician') + ':', value: caseInfo && (caseInfo.physicianName) },
            { label: t('Procedure') + ':', value: caseInfo && (caseInfo.procedureName) },
            { label: t('Vendor rep') + ':', value: caseInfo && (renderVendorRepNameAndIconButton(caseInfo, t)) }
        );
    } else {
        createCaseInfo.push(
            { label: t('Room') + ':', value: caseInfo && (caseInfo.orRoomName) },
            { label: t('Start time') + ':', value: caseInfo && (moment(caseInfo.caseStartTime).format(dateTimeFormat)) },
            { label: t('Physician') + ':', value: caseInfo && (caseInfo.physicianName) },
            { label: t('Procedure') + ':', value: caseInfo && (caseInfo.procedureName) }
        );
        if (caseInfo.foreignCaseId != null) {
            //&& caseInfo.foreignCaseId != null
            const fcidLabel = caseInfo.foreignCaseSource === 1 ? t('Epic CSN') : t('Epic CSN'); // change to 'Foreign ID' later
            createCaseInfo.push({ label: fcidLabel + ':', value: caseInfo.foreignCaseId ?? 'UNKNOWN' });
            //filler
            createCaseInfo.push({ label: "", value: "" });
        }
    }
    return createCaseInfo;
}

const CaseContext = memo(({ caseInfo, isLoanerContext, className }) => {
    const { t } = useTranslation();
    const [caseInfoCollection, setCaseInfoCollection] = useState([]);

    useEffect((props) => {
        const localCaseInfo = createCaseInfo(caseInfo, isLoanerContext, t);
        setCaseInfoCollection(localCaseInfo);
    }, [caseInfo])


    return (
        <Row className={className ? className + " context-container" : "context-container"}>
            <Col lg={2} className="justify-content-left align-items-center">
                <h5 className="mb-0">
                    {t('Case')} {caseInfo && (caseInfo.caseNumber)}
                </h5>
                {caseInfo && caseInfo.isCanceled && <div className="font-error">Canceled</div>}
            </Col>
            <Col>
                <Row className="d-flex justify-content-around">
                    {
                        caseInfoCollection.map((ci, i) =>
                            (
                                <Col key={i} xl={4} md={6}>
                                    <span>{ci.label}</span>
                                    <span className="ml-2 flex-grow-1">
                                        {ci.value}
                                    </span>
                                </Col>
                            )
                        )
                    }
                </Row>
            </Col>
        </Row>
    );
});

export default CaseContext;