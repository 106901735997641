//Framework
import React, { Component } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalActionCreator } from '../../store/actions/modalActions';

import organizationService from '../../services/organizationService';
import { applicationMessagingActionCreator } from '../../store/actions/applicationMessagingActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { passwordRegexString, specCharRegexString } from '../../utilities/authConstants';

class ChangeUserPasswordModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            canSave: false,
            changePassValues: { userId: this.props.model, newPassword: "", newPasswordConfirm: "" },
            errors: {
                lower: false,
                upper: false,
                number: false,
                minLength: false,
                specChar: false,
                newMustMatch: false
            }
        };
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateAll = this.validateAll.bind(this);
        this.makeInvalidOrValid = this.makeInvalidOrValid.bind(this);
        this.renderValidationIcon = this.renderValidationIcon.bind(this);
    }

    handleUserInput(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var partialState = this.state.changePassValues;
        partialState[name] = value;
        this.setState({ changePassValues: partialState });
    }

    makeInvalidOrValid(validationName, isValid) {
        var partialState = this.state.errors;
        partialState[validationName] = isValid;
        var completed = this.setState({ partialState }, () => { return true });
    }

    validateAll() {
        const obj = this.state.changePassValues;
        let valid = true;
        for (const [key, value] of Object.entries(obj)) {
            valid = valid && this.validateField(key, value);
        }
        this.setState({ canChange: valid });
        return valid;
    }

    renderValidationIcon = (isValid) => {
        if (isValid) {
            return <FontAwesomeIcon icon={faCheck} className="text-success"/>
        } else {
            return <FontAwesomeIcon icon={faCheck} className="text-white"/>
        }
    }

    validateField(name, value) {

        let isValid = true;

        if (name === "oldPassword") {
            if (value.length < 1) {
                isValid = isValid && false;
            }
        }
        if (name === "newPassword") {

            if (value.length >= 8) {
                this.makeInvalidOrValid("minLength", true);
            } else {
                this.makeInvalidOrValid("minLength");
                isValid = isValid && false;
            }

            if (value !== this.state.changePassValues.oldPassword && value.length > 0) {
                this.makeInvalidOrValid("notMatchOld", true);
            } else {
                this.makeInvalidOrValid("notMatchOld", false);
                isValid = isValid && false;
            }

            const upperCaseRegex = /[A-Z]/g;
            if (value.match(upperCaseRegex)) {
                this.makeInvalidOrValid("upper", true);
            } else {
                this.makeInvalidOrValid("upper", false);
                isValid = isValid && false;
            }

            const lowerCaseRegex = /[a-z]/g;
            if (value.match(lowerCaseRegex)) {
                this.makeInvalidOrValid("lower", true);
            } else {
                this.makeInvalidOrValid("lower", false);
                isValid = isValid && false;
            }

            const numberRegex = /[0-9]/g;
            if (value.match(numberRegex)) {
                this.makeInvalidOrValid("number", true);
            } else {
                this.makeInvalidOrValid("number", false);
                isValid = isValid && false;
            }

            const specCharRegex = new RegExp(specCharRegexString, "g");
            
            if (value.match(specCharRegex)) {
                this.makeInvalidOrValid("specChar", true);
            } else {
                this.makeInvalidOrValid("specChar", false);
                isValid = isValid && false;
            }

            if (value === this.state.changePassValues.newPasswordConfirm && value.length > 0) {
                this.makeInvalidOrValid("newMustMatch", true);
            } else {
                this.makeInvalidOrValid("newMustMatch", false);
                isValid = isValid && false;
            }
        }
        if (name === "newPasswordConfirm") {
            if (value === this.state.changePassValues.newPassword && value.length > 0) {
                this.makeInvalidOrValid("newMustMatch", true);
            } else {
                this.makeInvalidOrValid("newMustMatch", false);
                isValid = isValid && false;
            }
        }

        return isValid;
    }

    handleChangePassword() {
        //Validate everything again just in-case they altered JS via browser's dev-tools
        if (this.validateAll()) {
            const changePassValues = this.state.changePassValues;
            organizationService.changeUserPassword(changePassValues)
                .then(response => {
                    if (response.data.success) {
                        if (response.data.payload) {
                            this.props.createSuccessNotification("Success", response.data.message);
                            //Set everything back to default
                            this.setState({
                                canChange: false,
                                changePassValues: {
                                    newPassword: '',
                                    newPasswordConfirm: ''
                                },
                                errors: {
                                    lower: false,
                                    upper: false,
                                    number: false,
                                    minLength: false,
                                    specChar: false,
                                    newMustMatch: false
                                }
                            }
                            );
                            this.props.closeModal();
                        } else {
                            this.validateAll();
                            this.props.createErrorMessage(response.data.message, response.data.errors[0]);
                        }
                    }
                });
        }
    }

    render() {
        return (
            <div>
                <Modal.Header>
                    <h4>{this.props.title}</h4>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col lg={12}>
                                <h4>{this.props.message}</h4>
                            </Col>
                        </Row>
                        <Form.Row>
                            <Form.Group as={Col} md={6}>
                                <Form.Label>New password</Form.Label>
                                <Form.Control
                                    id="newPassword"
                                    name="newPassword"
                                    placeholder="Enter new password"
                                    type="password"
                                    pattern={passwordRegexString}
                                    value={this.state.changePassValues.newPassword}
                                    onChange={this.handleUserInput}
                                    onBlur={this.validateAll} />
                                <Form.Label>Confirm new password</Form.Label>
                                <Form.Control
                                    id="newPasswordConfirm"
                                    name="newPasswordConfirm"
                                    pattern={passwordRegexString}
                                    placeholder="Enter new password again"
                                    type="password"
                                    value={this.state.changePassValues.newPasswordConfirm}
                                    onChange={this.handleUserInput}
                                    onBlur={this.validateAll} />
                            </Form.Group>
                            <div id="message" style={{ marginLeft: "20px" }}>
                                <h6>New password must:</h6>
                                <p id="lower">{this.renderValidationIcon(this.state.errors.lower)} Have a <b>lowercase</b> letter</p>
                                <p id="upper">{this.renderValidationIcon(this.state.errors.upper)} Have a <b>capital (uppercase)</b> letter</p>
                                <p id="number">{this.renderValidationIcon(this.state.errors.number)} Contain a <b>number</b></p>
                                <p id="minLength">{this.renderValidationIcon(this.state.errors.minLength)} Have at least <b>8 characters</b></p>
                                <p id="specChar">{this.renderValidationIcon(this.state.errors.specChar)} Contain at least one special character, for example: <b>@$!%*?&</b></p>
                                <p id="newMustMatch" >{this.renderValidationIcon(this.state.errors.newMustMatch)} <b>Match</b> new password confirmation</p>
                            </div>
                            <br />
                            <div>
                                {/*Uncomment for DEBUG =>*/}{/*{JSON.stringify(this.state)}*/}
                            </div>
                        </Form.Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" onClick={this.props.closeModal}>Cancel</Button>
                    <Button bsStyle="success" disabled={!this.state.canChange} onClick={this.handleChangePassword}>Save</Button>
                </Modal.Footer>
            </div>

        );
    }
}

export default connect(null, dispatch => bindActionCreators({ ...modalActionCreator, ...applicationMessagingActionCreator }, dispatch))(ChangeUserPasswordModal);