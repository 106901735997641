import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signalrActionCreator } from '../../store/actions/signalrActions';


export class RealtimeMessages extends Component {

    state = {
        message: '',
    };   

    sendMessage = () => {
        this.props.onMessageSend(this.state.message);
        this.setState({message: ''});
    }; 

    render() {
        console.log('Rendering props');
        console.log(this.props);
        return (
            <div>
                <h3>Realtime Messages - Testing only</h3>
                <h4>Can be removed once the signalR is working.</h4>
                <br />
                <input 
                    type="text"
                    value={this.state.message}
                    onChange={e => this.setState({ message: e.target.value })}
                />

                <button onClick={this.sendMessage}>Send</button>
                
                <div>
                    {this.props.messages.map((message, index) => (
                        <span style={{display: 'block'}} key={index}> {message} </span>
                    ))}
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        messages: state.realtimeMessages.messages,
    };
}; 

const mapDispatchToProps = dispatch => {
    return {
        onMessageSend: (message) => dispatch(signalrActionCreator.sendRealtimeMessage(message))
    };
}; 


export default connect(mapStateToProps, mapDispatchToProps)(RealtimeMessages);
