import React, { Component } from 'react';
import { Row, Col, FormGroup, FormLabel, FormControl, Table, Button, Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTimesCircle, faSitemap, faHospital } from '@fortawesome/free-solid-svg-icons';

import SiteService  from '../../services/siteService';

class SiteList extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = { showEditModal: false, editSite: {}, returnError: '', siteModalHeaderText: "", showDeleteSiteModal: false };

        this.showNewSiteModal = this.showNewSiteModal.bind(this);
        this.showEditSiteModal = this.showEditSiteModal.bind(this);
        this.hideSiteModal = this.hideSiteModal.bind(this);
        this.handleSaveSite = this.handleSaveSite.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleUserIntInput = this.handleUserIntInput.bind(this);
        this.handleDeleteSite = this.handleDeleteSite.bind(this);
        this.hideDeleteSiteModal = this.hideDeleteSiteModal.bind(this);
    }

    handleSaveSite(e) {
        /// NEW 
        var site = { ...this.state.editSite };
        site.organizationid = this.props.organizationId;
        site.State = 100;

        SiteService.saveSite(site)
            .then(response => {
                if(response.data.success)
                {
                  var newSite = response.data.payload;
                  
                  this.setState({editSite: newSite});
                  var existing = this.props.sites.filter(x => x.id !== newSite.id);
                  existing.push(newSite);
                  this.props.modifyState("sites", existing);
                  this.hideSiteModal();

                } else {
                    this.setState({ returnError: response.data.message });
                }                
            })
            .catch(error => {
                this.setState({ returnError: error.message });
            });        
    }

    showNewSiteModal(e) {
        
        this.setState({ editSite: {}, showEditModal: true, siteModalHeaderText: "Create New Site" });
    }

    showEditSiteModal(site, e) {
        this.setState({ editSite: { ...site }, showEditModal: true, siteModalHeaderText: "Edit Existing Site" });
    }

    hideSiteModal() {
        this.setState({editSite: {}, showEditModal: false, siteModalHeaderText: "" });
    }

    showDeleteSiteModal(site, event) {
        this.setState({ editSite: { ...site }, showDeleteSiteModal: true});
    }

    hideDeleteSiteModal() {
        this.setState({ editSite: {}, showDeleteSiteModal: false });
    }

    handleDeleteSite() {
        var id = this.state.editSite.id;
        SiteService.deleteSite(id)
            .then(response => {
                if(response.data.success) {
                    var deleteId = this.state.editSite.id;
                    var existing = this.props.sites.filter(x => x.id !== deleteId);
                    existing = existing.sort((a, b) => (a.siteCode.toLowerCase() > b.siteCode.toLowerCase()) ? 1 : ((b.siteCode.toLowerCase() > a.siteCode.toLowerCase) ? -1 : 0));
                    this.props.modifyState("sites", existing);
                    this.hideDeleteSiteModal();        
                } else {
                    this.setState({ returnError: response.data.message });
                }
            })
            .catch(error => {
                this.setState({returnError: error.message });
            });
    }

    handleUserInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var partialState = this.state.editSite;
        partialState[name] = value;
        this.setState({ editSite: partialState });
    }

    handleUserIntInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = parseInt(target.value);
        const name = target.name;
        var partialState = this.state.editSite;
        partialState[name] = value;
        this.setState({ editSite: partialState });
    }

    render() {
        return (
            <div>
                <Modal bsSize="small" show={this.state.showDeleteSiteModal}>
                    <Modal.Header>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                        
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete site: {this.state.editSite.name}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.hideDeleteSiteModal} variant="default" type="button">Cancel</Button>
                        <Button onClick={this.handleDeleteSite} variant="danger" type="button">Delete</Button>
                    </Modal.Footer>
                </Modal>
                <Modal bsSize="small" show={this.state.showEditModal}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.siteModalHeaderText}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="name">Site Name</label>
                            <input className="form-control" type="text" id="name" name="name" aria-describedby="siteName" required value={this.state.editSite.name} onChange={this.handleUserInput} />
                            <small id="siteName" className="form-text text-muted">Please enter the site name.</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="siteCode">Site Short Name</label>
                            <input className="form-control" type="text" id="siteCode" name="siteCode" aria-describedby="siteCodeHelp" required value={this.state.editSite.siteCode} onChange={this.handleUserInput} maxlength="8" />
                            <small id="siteCodeHelp" className="form-text text-muted">Please enter the site short name.</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="foreignSiteId">Foreign Site Id</label>
                            <input className="form-control" type="text" id="foreignSiteId" name="foreignSiteId" aria-describedby="foreignSiteIdHelp" required value={this.state.editSite.foreignSiteId} onChange={this.handleUserIntInput} />
                            <small id="foreignSiteIdHelp" className="form-text text-muted">Please enter the foreign site id.</small>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.hideSiteModal} variant="warning" type="button">Cancel</Button>
                        <Button onClick={this.handleSaveSite} variant="primary" type="button">Save</Button>
                    </Modal.Footer>
                </Modal>
                <Row>
                    <Col lg={12}>
                        <Table striped condensed hover responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Code</th>
                                    <th>Foreign Site Id</th>
                                    <th />
                                    <th>
                                        <span className="fa-layers fa-fw text-success float-right show-hand"
                                            onClick={this.showNewSiteModal}>
                                            <FontAwesomeIcon icon={faSitemap} />
                                            <FontAwesomeIcon icon={faPlus} transform="shrink-8 right-12" />
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // repeat with map here @foreach (var site in Model.Sites)
                                    this.props.sites.map((item, i) => {
                                        return (
                                            <tr key={i}> 
                                                <td>{item.name}</td>
                                                <td>{item.siteCode}</td>
                                                <td>{item.foreignSiteId}</td>
                                                <td className="table-fit"><FontAwesomeIcon icon={faPen} className="gi-1-5x text-primary show-hand" onClick={(e) => this.showEditSiteModal(item, e)} /></td>
                                                <td className="table-fit"><FontAwesomeIcon icon={faTimesCircle} className="gi-1-5x text-danger show-hand" onClick={(e) => this.showDeleteSiteModal(item, e)} /></td>
                                            </tr>
                                        );
                                    }
                                    )
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SiteList;
