import * as actionTypes from './actionTypes';

export const spinnerActionCreator = {
    hideSpinner: () => dispatch => {
        dispatch({
            type: actionTypes.HIDE_SPINNER
        });
    },
    showSpinner: () => dispatch => {
        dispatch({
            type: actionTypes.SHOW_SPINNER
        });
    }
}


export function dispatchShowSpinner(dispatch) {
    dispatch({
        type: actionTypes.SHOW_SPINNER
    });
}

export function dispatchHideSpinner(dispatch) {
    dispatch({
        type: actionTypes.HIDE_SPINNER
    });
}