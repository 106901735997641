import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { Dropdown, Navbar, Nav, NavItem, NavLink, Badge } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { modalActionCreator } from '../../store/actions/modalActions';
import { faBell, faList, faLock, faCog, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import defaultUserAvatar from '../../assets/img/defaultUserAvatar.png';
import SpmBreadcrumb from '../../controls/breadcrumb/SpmBreadcrumb';
import UserContext from '../../components/UserContext';
import { PrinterContext } from '../../contexts/PrinterContext';
import { claims, claimValues } from '../../utilities/authConstants';
import WebSocketStatus from '../../controls/websocket/WebSocketStatus';
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import { selectPermissionChecker } from '../../store/selectors/permissionSelectors';
import { AuthenticationModeEnum } from '../../utilities/enumerations';
class SpmHeader extends Component {
    static contextType = PrinterContext;
    constructor(props, context) {
        super(props, context);
        this.openSiteSettings = this.openSiteSettings.bind(this);
        this.openClientSettings = this.openClientSettings.bind(this);
        this.getSettingsCogWheel = this.getSettingsCogWheel.bind(this);
    }

    openSiteSettings() {
        this.props.showModal({
            open: true,
            size: "lg"
        }, 'siteSettingsModal');
    }

    openClientSettings() {
        this.props.showModal({
            open: true,
            size: "lg"
        }, 'clientSettingsModal');
    }


    getSettingsCogWheel() {
        const { t } = this.props;
        const siteSettingsOption = this.props.hasPermission(claims.crudSiteSettings, claimValues.read)
            ? (<Dropdown.Item as="button" onClick={() => this.openSiteSettings()}>
                <FontAwesomeIcon icon={faCog} />&nbsp;{t("Site Settings")}
            </Dropdown.Item>)
            : null;
        const clientSettingsOption = this.props.hasPermission(claims.crudClientSettings, claimValues.read)
            ? (<Dropdown.Item as="button" onClick={() => this.openClientSettings()}>
                <FontAwesomeIcon icon={faCog} />&nbsp;{t("Browser Settings")}
            </Dropdown.Item>)
            : null;

        const { jspmStatus } = this.context;
        let status;
        switch (jspmStatus) {
            case "Open":
                status = (<span className="badge badge-success">{t("Ready")}</span>);
                break;
            case "Closed":
                status = (<span className="badge badge-danger">{t("Closed")}</span>);
                break;
            case "Blocked":
                status = (<span className="badge badge-warning">{t("Blocked")}</span>);
                break;
            default:
                status = (<span className="badge badge-warning">{t("Waiting")}</span>);
        }

        return (siteSettingsOption || clientSettingsOption) ?
            (<Dropdown >
                <Dropdown.Toggle as="span" className="noCaret nav-link">
                    <FontAwesomeIcon className="pointer-mouseover" size="lg" icon={faCog} />
                </Dropdown.Toggle>
                <Dropdown.Menu right className="avatar-dropdown-menu">
                    {siteSettingsOption}
                    {clientSettingsOption}
                    <Dropdown.Divider />
                    {
                        this.context
                            ? <span style={{ margin: "0 0 0 .75rem" }}>
                                {t("Print Status")} {status}
                            </span>
                            : null
                    }
                </Dropdown.Menu>
            </Dropdown>) : null;
    }

    render() {

        const settingsCogWheel = this.context && this.getSettingsCogWheel();
        const { t, i18n } = this.props;

        return (
            <div>
                <Navbar expand="lg" className="bg-light">
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            { /* TODO: make these work? */
                            }
                            { /*<NavItem href="#home">
                                <LinkContainer to={'/home'}>
                                    <NavLink>{t("Home")}</NavLink>
                                </LinkContainer>
                            </NavItem>
                            <NavItem href="#home">
                                <LinkContainer to={'/hello'}>
                                    <NavLink>{t('Link')}</NavLink>
                                </LinkContainer>
                            </NavItem>*/
                            }
                        </Nav>
                        <Nav className="ml-auto" navbar>
                            { /*Uncomment this code once notification is implemented*/
                            }
                            { /*
                            <NavItem className="d-md-down-none">
                                <NavLink to="#" className="nav-link">
                                    <FontAwesomeIcon icon={faBell} size="lg" />
                                    <Badge pill variant="danger">5</Badge>
                                </NavLink>
                            </NavItem>
                            */
                            }
                            {settingsCogWheel}
                            <Navbar.Text as="div"><WebSocketStatus applicationContext={this.props.applicationContext} /></Navbar.Text>
                            <Navbar.Text as="div">{this.props.applicationContext.tenant?.name}</Navbar.Text>
                            <Dropdown>
                                <Dropdown.Toggle as="span" className="noCaret">
                                    <span className="pointer-mouseover">
                                        <img src={defaultUserAvatar} className="img-avatar" alt="admin@test.com" />
                                        <Navbar.Text><UserContext /></Navbar.Text>
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu right className="avatar-dropdown-menu">
                                    { /*Uncomment this code once user an user settings is implemented*/
                                    }
                                    { /*  <Dropdown.Item>
                                        <LinkContainer to={'/user/profile'}>
                                            <a>
                                                <FontAwesomeIcon icon={faUser} /> User Profile
                                            </a>
                                        </LinkContainer>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <LinkContainer to={'/user/settings'}>
                                            <a>
                                                <FontAwesomeIcon icon={faCog} /> User Settings
                                            </a>
                                        </LinkContainer>
                                    </Dropdown.Item>
                                */
                                    }
                                    {this.props.applicationContext.loginMode === AuthenticationModeEnum.Web ?
                                        <div>
                                            <Dropdown.Item>
                                                <LinkContainer to={'/user/password'}>
                                                    <span><FontAwesomeIcon icon={faLock} /> {t('Change Password')}</span>
                                                </LinkContainer>
                                            </Dropdown.Item>
                                            <div className="dropdown-divider"></div>
                                        </div>
                                        : null
                                    }
                                    { /*No showing the Lock Account until this feature is implement uncomment line below once implemented*/
                                    }
                                    { /* <Dropdown.Item><i className="fa fa-shield"></i> Lock Account</Dropdown.Item>*/
                                    }
                                    <Dropdown.Item><i className="fa fa-lock"></i>
                                        <LinkContainer to="/auth/logout">
                                            <span><FontAwesomeIcon icon={faSignOutAlt} /> {t('Logout')}</span>
                                        </LinkContainer>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <SpmBreadcrumb routes={this.props.routes} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    applicationContext: state.applicationContext.applicationContext,
    hasPermission: selectPermissionChecker(state)
});

export default withTranslation()(connect(mapStateToProps,
    dispatch => bindActionCreators({ ...modalActionCreator, ...applicationContextActionCreator }, dispatch))(SpmHeader));