import axios from "axios";
import { AuthenticationModeEnum } from '../utilities/enumerations';

const AuthService = {
    async getDomains() {
        const response = axios.get(`/api/auth/domains`);
        return response;
    },
    async logout() {
        const response = axios.get(`/api/auth/logout`);
        return response;
    },
    async login(authMode, login) {
        let spmAuthUri = "/api/auth/login";

        switch (authMode) {
            case AuthenticationModeEnum.SPM:
                spmAuthUri = "/api/auth/spmlogin";
                break;
            case AuthenticationModeEnum.Web:
                spmAuthUri = "/api/auth/login";
                break;
            case AuthenticationModeEnum.SynergyTrak:
                spmAuthUri = "/api/auth/synergytraklogin";
                break;
        }

        const response = axios.post(spmAuthUri, login);
        return response;
    },
    async getAllRoles() {
        const response = axios.get(`/api/auth/roles`);
        return response;
    },
    async epicLogin(epicData) {
        let epicAuthUri = "/api/auth/epiclogin?data=" + epicData;
        const response = axios.get(epicAuthUri);
        return response;
    }
}

export default AuthService; 
