import React, { useEffect, memo } from 'react';
import { useImmer } from 'use-immer';
import _ from "lodash";
import { promisify } from '../../utilities/promisify';
import { Button, Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ConditionalRender } from '../formControls';
import LoadingButton from '../buttons/LoadingButton';
import { DataTable, DataColumn } from '../../controls/dataTable/DataTable';

const ItemUsed = memo((props) => {
    if (props.item.used) {
        return <FontAwesomeIcon icon={faCheckCircle} className="text-primary" style={{ fontSize: '1.5rem', cursor: 'pointer' }} />;
    }
    else {
        return <FontAwesomeIcon icon={faCheckCircle} style={{ color: "lightgray", fontSize: '1.5rem', cursor: 'pointer' }} />;
    }
})

const MarkAsUsedModal = (props) => {
    const [assignedItems, setAssignedItems] = useImmer([]);
    const [errText, setErrText] = useImmer('');
    const [working, setWorking] = useImmer(false);
    const closeModal = props.closeModal;
    const saveModelPromise = promisify(props.saveModel);
    const { t } = useTranslation();

    useEffect(() => {
        setAssignedItems(props.model.assignedItems);
    }, [JSON.stringify(props.model.assignedItems)])

    const handleChangeUsedStatus = (item, index) => {
        setAssignedItems(draft => {
            draft[index].used = draft[index].used ? false : true;
        });
    }

    const saveCase = () => {
        //this.props.saveModel(this.state.assignedItems); this.props.closeModal();
        setWorking(true);
        saveModelPromise(assignedItems)
            .then(() => closeModal())
            .catch(err => {
                setErrText(err.message);
            });
        setWorking(false);
    }

    return (
        <div>
            <Modal.Header>
                <h4>Mark Assigned Items as Used</h4>
            </Modal.Header>
            <Modal.Body className="modal-body-table">
                <ConditionalRender visible={errText.length != ''}>
                    <Alert variant="danger">{errText}</Alert>
                </ConditionalRender>
                <DataTable
                    striped
                    hover
                    responsive
                    size="sm"
                    dataSource={assignedItems}
                    rowClassName="pointer-mouseover"
                    onRowClick={(row, i) => handleChangeUsedStatus(row, i)}>
                    <DataColumn dataField="name"
                        sortable
                        formattedColumn={(row, i) => {
                            return <span>{row["name"]}-{row["indexNumber"]}</span>
                        }}
                    >{t('Name')}</DataColumn>
                    <DataColumn
                        dataField="used"
                        formattedColumn={(row, i) => {
                            return <ItemUsed item={row} index={i} />
                        }}
                        className="table-fit cellCenterContent">{t('Used')}</DataColumn>
                </DataTable>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={() => closeModal()}>Cancel</Button>
                <LoadingButton variant="success" onClick={() => saveCase()} loading={working}>Update</LoadingButton>
            </Modal.Footer>
        </div>

    );
}

export default MarkAsUsedModal