import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Card, Table, Button, Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { applicationMessagingActionCreator } from '../../store/actions/applicationMessagingActions';
import { modalActionCreator } from '../../store/actions/modalActions';
import { withTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faMinus } from '@fortawesome/free-solid-svg-icons';

import OrganizationService from '../../services/organizationService';

class OrganizationList extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            organizations: [],
            filteredOrganizations: [],
            filterText: ''
        };

        this.handleFilterText = this.handleFilterText.bind(this);
        this.saveOrganization = this.saveOrganization.bind(this);
    }

    componentDidMount() {

        OrganizationService.getAllOrganizations()
        .then(response => {
            if(response.data.success)
            {
                const rawOrgs = response.data.payload;
                
                this.setState({ organizations: rawOrgs });
                this.setState({ filteredOrganizations: rawOrgs });

                var filteredOrgCodes = [];  
                rawOrgs.map((item, i) => {
                    if (item.orgCode !== null) {
                        filteredOrgCodes.push(item.orgCode.toLowerCase());
                    }
                    item.childOrganizations.map((child, p) => {
                        if (child.orgCode !== null) {
                            filteredOrgCodes.push(child.orgCode.toLowerCase());
                        }
                    });
                });

                this.setState({ orgCodeList: filteredOrgCodes.sort() });   
            }          
        });
    }

    saveOrganization(values) {

        OrganizationService.saveOrganization(values)
            .then(response => {                                
                if (response.data.success) {
                    const org = response.data.payload;
                    return this.props.history.push('/admin/organizations/' + org.id);
                }
            });
    }

    handleFilterText(event) {
        var filteredText = event.target.value.toLowerCase();
        this.setState({ filterText: filteredText });
        if (event.target.value.length > 2) {
            // long enough to filter
            // maybe change text color to green if long enough
            var filteredList = this.state.organizations.filter(p => p.name.toLowerCase().includes(filteredText) || (p.orgCode ? p.orgCode.toLowerCase().includes(filteredText) : false));
            this.setState({ filteredOrganizations: filteredList });
        } else {
            // to short to filter - clear filter
            // maybe change text color to red if not long enough
            this.setState({ filteredOrganizations: this.state.organizations });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Card>
                <Card.Header>
                    <Navbar expand="lg" className="no-padding">
                        <div className="mr-auto">
                            {t('Organizations')}
                        </div>

                        <Nav className="ml-auto">
                            <div className="float-right">
                                <div className="form-inline" style={{ "padding": "0px" }}>
                                    <input type="text" className="form-control input-sm" placeholder={t('Filter Organizations')} name="filterText" value={this.state.filterText} onChange={this.handleFilterText} />&nbsp;
                                    <Button variant="warning" size="sm" asp-action="Index">{t('Reset')}</Button>&nbsp;
                                    <Button size="sm"
                                        onClick={() => this.props.showModal({
                                            open: true,
                                            title: t("Create New Organization"),
                                            orgList: this.state.organizations,
                                            closeModal: this.props.hideModal,
                                            saveModel: this.saveOrganization
                                        }, 'createNewOrganizationModal')}>{t('Create New')}</Button>
                                </div>
                            </div>
                        </Nav>
                    </Navbar>
                </Card.Header>
                <Card.Body style={{ "padding": "0px" }}>
                        <Table striped size="sm" hover responsive>
                            <thead>
                                <tr>
                                    <th>{t('Name')}</th>
                                    <th>{t('Org Code')}</th>
                                    <th>{t('Socket Status')}</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.filteredOrganizations.map((item, i) => {
                                        return (
                                            <>
                                                <tr key={item.id}>
                                                    <td>{item.name}</td>
                                                    <td>{item.orgCode}</td>
                                                    <td>{item.webSocketConnected ? "connected" : ""}</td>
                                                    <td>
                                                        <Link to={'/admin/organizations/' + item.id} className="float-right primary-text"><FontAwesomeIcon icon={faPen} className="gi-1-5x" /></Link>
                                                    </td>
                                                </tr>
                                                {
                                                    item.childOrganizations !== null ?
                                                        item.childOrganizations.map((child, p) => {
                                                            return (
                                                                <tr key={item.id + '-' + child.id}>
                                                                    <td><FontAwesomeIcon icon={faMinus} className="gi-1-3x" /> {child.name}</td>
                                                                    <td>{child.orgCode}</td>
                                                                    <td>{child.webSocketConnected === true ? "connected" : ""}</td>
                                                                    <td>
                                                                        <Link to={'/admin/organizations/' + child.id} className="float-right primary-text"><FontAwesomeIcon icon={faPen} className="gi-1-5x" /></Link>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }) : null
                                                }
                                            </>
                                        );
                                    })
                                }
                            </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    }
}

export default withTranslation()(connect(null, dispatch => bindActionCreators({ ...modalActionCreator, ...applicationMessagingActionCreator }, dispatch))(OrganizationList));