import * as actionTypes from './actionTypes';
import i18n from "i18next";

export const applicationContextActionCreator = {
    updateWebsocketStatus: webSocketConnected => (dispatch) => {
        var action = webSocketConnected ? actionTypes.WEB_SOCKET_CONNECTED : actionTypes.WEB_SOCKET_DISCONNECTED;
        dispatch({ type: action });
    },
    updateCurrentClient: currentClient => async (dispatch, getState) => {
        dispatch({ type: actionTypes.UPDATE_CURRENT_CLIENT, currentClient });
    },
    updateLoginMode: loginMode => async dispatch => {
        dispatch({ type: actionTypes.UPDATE_LOGIN_MODE, loginMode });
    },
    updateCurrentUser: currentUser => async (dispatch, getState) => {
        if (currentUser !== null) {
            //start signalR
            dispatch({ type: actionTypes.START_SIGNALR_CONNECTION });
        } else {
            // stop signalR
            dispatch({ type: actionTypes.STOP_SIGNALR_CONNECTION });
            dispatch({ type: actionTypes.USER_LOG_OUT });
        }
        dispatch({ type: actionTypes.UPDATE_CURRENT_USER, currentUser });
    },
    updateCurrentTenant: currentTenant => async (dispatch, getState) => {
        i18n.changeLanguage(currentTenant.culture ?? 'en-US');
        dispatch({ type: actionTypes.UPDATE_CURRENT_TENANT, currentTenant });
    }
};