import * as actionTypes from './actionTypes';
import organizationService from '../../services/organizationService';

export const changeSetting = (component, setting, value) => {
    return {
        type: actionTypes.CHANGE_LOANER_APP_SETTING,
        payload: { component, setting, value }
    }
};

export const patientDataSettings = (setting, settings) => {
    return {
        type: actionTypes.PATIENT_DATA_SETTING,
        payload: { setting, settings }
    }
};

export const loanerAppSettingsActionCreator = {
    changeLoanerAppSetting: (component, setting, value) => dispatch => {
        dispatch(changeSetting(component, setting, value));
    },

    getPatientDataSettings: (setting) => async dispatch => {
        await organizationService.getOrganizationSettings()
            .then(response => {
                if (response.data.success) {
                    const settings = response.data.payload;
                    dispatch(patientDataSettings(setting, settings));
                }
            });
    }
};
