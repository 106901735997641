
/*

  compare(a, b) sorts ascending
  -compare(a, b) sorts descending
  use || for "then by"

 */

export const compare = (a, b) => (a > b) - (a < b);
export const byCaseNumber = (a, b) => compare(a.caseNumber.toUpperCase(), b.caseNumber.toUpperCase());
export const byCaseStartTime = (a, b) => compare(a.caseStartTime, b.caseStartTime);
export const byName = (a, b) => compare(a.name.toUpperCase(), b.name.toUpperCase());
export const byCountDescThenByName = (a, b) => -compare(a.count, b.count) || byName(a, b);
export const byCaseStartTimeThenByVendorName = (a, b) => byCaseStartTime(a, b) || compare(a.vendorName.toUpperCase(), b.vendorName.toUpperCase());
export const byModifiedDateDesc = (a, b) => -compare(a.modifiedDate, b.modifiedDate);
export const byTrayName = (a, b) => compare(a.trayName.toUpperCase(), b.trayName.toUpperCase());
export const byIndexNumber = (a, b) => compare(a.indexNumber.toUpperCase(), b.indexNumber.toUpperCase());
export const byTrayNameThenByDeliveryDate = (a, b) =>
    byTrayName(a, b) ||
    compare(a.status, b.status) ||
    -compare(a.delivered, b.delivered);

export const sortData = (data, sortConfig) => {
    if (sortConfig.field && sortConfig.direction) {
        return data.slice().sort((a, b) => {
            if (sortConfig.direction === 'asc') {
                return a[sortConfig.field].localeCompare(b[sortConfig.field]);
            } else {
                return b[sortConfig.field].localeCompare(a[sortConfig.field]);
            }
        });
    } else {
        return data.slice(); // Return a shallow copy of the original data if no sorting is applied
    }
};