import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SHOW_MODAL, HIDE_MODAL } from '../../store/actions/actionTypes';

const usePreventNavigationModal = (isDirty, modalProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [blocker, setBlocker] = useState({
        isBlocked: isDirty,
        unblock: () => {},
    });

    const showModal = useCallback(
        (action, unblock) => {
            dispatch({
                type: SHOW_MODAL,
                modalProps: {
                    ...modalProps,
                    cancel: () => {
                        dispatch({ type: HIDE_MODAL });
                        modalProps.cancel?.();
                    },
                    confirm: () => {
                        dispatch({ type: HIDE_MODAL });
                        unblock();
                        modalProps.confirm?.();
                        if ('pathname' in action) {
                            history.push(action.pathname);
                        }
                    },
                },
                modalType: 'confirm',
            });
        },
        [dispatch, history, modalProps]
    );

    const registerBlock = () => {
        const unblock = history.block((action) => {
            if (action.pathname === location.pathname) {
                return true;//allow navigation
            }

            showModal(action, unblock);
            
            return !isDirty; //true equals allow navigation (opposite of window.onbeforeunload)
        });
        return unblock;
    };

    useEffect(() => {
        if (isDirty) {
            window.onbeforeunload = () => true;
            setBlocker(() => {
                const unblock = registerBlock();
                return {
                    isBlocked: isDirty,
                    unblock: unblock,
                };
            });
        } else {
            setBlocker((prevBlocker) => {
                prevBlocker.unblock();
                return {
                    isBlocked: isDirty,
                    unblock: () => {},
                };
            });
            window.onbeforeunload = undefined;
        }
        return () => {
            blocker.unblock();
            window.onbeforeunload = undefined;
        };
        //es-lint is wrong about blocker, history and showmodal
        // eslint-disable-next-line
    }, [isDirty]);
    return blocker;
};

export default usePreventNavigationModal;
