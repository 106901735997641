import React, { Component, Suspense } from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import NotificationRoot from '../../controls/notifications/NotificationRoot';
import ModalRoot from '../../controls/modals/ModalRoot';
import SpinnerRoot from '../../controls/spinner/SpinnerRoot'

import SpmHeader from './SpmHeader';
import SpmFooter from './SpmFooter';
import SpmSidebar from './SpmSidebar';
import routes from '../Shared/routes';
import nav from '../Shared/nav';

import AssureContext from '../../components/auth/AssureContext';
// temp

class SpmLayout extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            navigation: { items: [] }
        };
    }

    render() {
        return (
            <AssureContext ignoreClientContext='true'>
                <div className="app default-layout">
                    <SpmSidebar nav={nav.admin} />
                    <div className="main-wrapper">
                        <SpmHeader routes={routes.admin} applicationContext={this.props.applicationContext}/>
                        <div className="main-body">
                            <main className="main">
                                <Container fluid>
                                    <Switch>
                                        <Redirect exact strict from="/admin" to="/admin/organizations" />
                                        {
                                            routes.admin.map((route, idx) => {
                                                return route.component && (
                                                    <Route
                                                        key={idx}
                                                        path={route.path}
                                                        exact={route.exact}
                                                        name={route.name}
                                                        render={props => (
                                                            <route.component {...props} />
                                                        )} />
                                                )
                                            })
                                        }
                                        <Redirect from="/user" to="/user/profile" />
                                    <Redirect from="/" to="/"/>
                                    </Switch>
                                </Container>
                            </main>
                        </div>
                        <SpmFooter />
                    </div>
                </div>
                <ModalRoot />
                <NotificationRoot />
                <SpinnerRoot />
            </AssureContext>
        )
    }
}
//export default connect(
//    state => state.applicationContext, dispatch => bindActionCreators({ ...applicationContextActionCreator }, dispatch))(SpmLayout);
export default SpmLayout;