import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalActionCreator } from '../../store/actions/modalActions';

import LoadingButton from '../buttons/LoadingButton';

class VendorSelectionModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedVendor: null,
            loading: {
                key: undefined
            }
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal.Header centered="true">
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mr-1">
                        {t('Several vendors are associated with this case. Select the vendor for which you are dropping off trays.')}
                    </div>
                    <div className="d-flex">
                        {
                            this.props.model.vendors.map(v => {
                                const variant = this.state.selectedVendor && v.id === this.state.selectedVendor.id ? 'primary' : 'secondary';
                                return <Button
                                    className="vendor-button"
                                    variant={variant}
                                    onClick={() => this.setState({ selectedVendor: v })}>{v.name}</Button>;
                            })
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="full-width">
                        <div className="float-right card-btn-group">
                            {this.state.selectedVendor ? (
                                <LoadingButton
                                    className="btn"
                                    disabled={Boolean(this.state.loading.key)}
                                    loading={this.state.loading.key === "save-close"}
                                    onClick={() => {
                                        this.setState({ loading: { key: "save-close" } });
                                        this.props.beforeCloseModal(this.props.model.caseId, this.state.selectedVendor.id);
                                        this.props.closeModal();
                                    }}>
                                    {t("Select ") + this.state.selectedVendor.name}
                                </LoadingButton>
                            ) : null}
                            <Button
                                variant="warning"
                                disabled={Boolean(this.state.loading.key)}
                                onClick={this.props.closeModal}>
                                {t("Cancel")}
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </div>
        );
    }
}

export default withTranslation()(connect(null, dispatch => bindActionCreators({ ...modalActionCreator }, dispatch))(VendorSelectionModal));