//Package imports
import React, { createContext, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import JSPM from 'jsprintmanager/';

//App imports
import settings from "../utilities/enums/clientSettingsEnum";

export const PrinterContext = createContext();
export const PrinterContextConsumer = PrinterContext.Consumer;

const PrinterContextProvider = (props) => {

    //Non user facing text. No translation needed;
    const uninitialized = "Uninitialized";
    const [jspmStatus, setStatus] = useState(uninitialized);

    const [currentLabelPrinter, setClientPrinter] = useState(null);

    const changePrinter = (printerName) => {
        printerName === "SystemDefault"
            ? setClientPrinter(new JSPM.DefaultPrinter())
            : setClientPrinter(new JSPM.InstalledPrinter(printerName));
    }

    const appContext = useSelector(state => state.applicationContext.applicationContext);
    const selectedLabelPrinterSetting = (setting) => setting.settingType === settings.selectedLabelPrinter.value;

    useEffect(() => {
        JSPM.JSPrintManager.auto_reconnect = true;
        JSPM.JSPrintManager.license_url = window.location.protocol + "//" + window.location.host + "/api/client/jspm";
        JSPM.JSPrintManager.start();

        JSPM.JSPrintManager.WS.onOpen = function () {
            setStatus(JSPM.WSStatus[JSPM.JSPrintManager.WS.status]);
        };

        JSPM.JSPrintManager.WS.onStatusChanged = function () {
            setStatus(JSPM.WSStatus[JSPM.JSPrintManager.WS.status]);
        };

        JSPM.JSPrintManager.WS.onClose = function () {
            setStatus(JSPM.WSStatus[JSPM.JSPrintManager.WS.status]);
        };
    }, []);

    useEffect(() => {
        if (jspmStatus !== uninitialized && appContext.client.settings) {
            const selectedPrinter = appContext.client.settings.find(selectedLabelPrinterSetting)?.value;
            if (selectedPrinter) {
                
                changePrinter(selectedPrinter);
            }
        }
    },
        [appContext, jspmStatus]);

    const print = (commands) => {
        const cpj = new JSPM.ClientPrintJob();
        cpj.clientPrinter = currentLabelPrinter;
        cpj.printerCommands = commands;
        cpj.printerCommandsCodePage = -1;
        cpj.sendToClient();
    }

    return (
        <PrinterContext.Provider
            value={{
                print,
                changePrinter,
                currentLabelPrinter,
                jspmStatus
            }}>
            {props.children}
        </PrinterContext.Provider>
    );
}

export default PrinterContextProvider;