import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { modalActionCreator } from '../../store/actions/modalActions';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import userService from '../../services/userService';
import IconWithTooltip from '../../controls/buttons/IconWithTooltip';
import enums from "../../utilities/enums";

import '../../styles/spm.css';

class UserList extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showScanItemsModal: false,
            organization: [],
            returnError: '',
            editUser: {},
            showCreateNewUserModal: false
        };

        this.handleShowCreateNewUserModal = this.handleShowCreateNewUserModal.bind(this);
        this.handleHideCreateNewUserModal = this.handleHideCreateNewUserModal.bind(this);
        this.saveNewUser = this.saveNewUser.bind(this);
        this.handleRadioInput = this.handleRadioInput.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.createNewUser = this.createNewUser.bind(this);
        this.resolveStatus = this.resolveStatus.bind(this);
    }

    handleRadioInput(name, event) {
        var partialState = this.state.editUser;
        partialState[name] = event;
        this.setState({ user: partialState });
    }

    handleUserInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var partialState = this.state.editUser;
        partialState[name] = value;
        this.setState({ editUser: partialState });
    }

    handleShowCreateNewUserModal() {
        this.setState({ editUser: { organizationId: this.props.organizationId, organizationName: this.props.organizationName, state: 100 }, showCreateNewUserModal: true });
    }

    handleHideCreateNewUserModal() {
        this.setState({ showCreateNewUserModal: false });
    }

    saveNewUser() {
        var user = { ...this.state.editUser };
        userService.saveUser(user)
            .then(response => {
                if (response.data.success) {
                    var result = response.data.payload;
                    //this.setState({ user: result });
                    var existing = this.props.users;
                    existing.push({ ...result });
                    this.props.modifyState("users", existing);
                    this.handleHideCreateNewUserModal();
                }
            });
    }

    createNewUser(user) {
        userService.createUser(user)
            .then(response => {
                if (response.data.success) {
                    var result = response.data.payload;
                    this.setState({ user: result });
                    var existing = this.props.users;
                    existing.push({ ...result });
                    this.props.modifyState("users", existing);
                    this.props.hideModal();
                }
            });
    }

    resolveStatus(state) {
        let status = "";
        switch (state) {
            case enums.stateEnum.active.value:
                status = enums.stateEnum.active.name;
                break;
            case enums.stateEnum.deleted.value:
                status = enums.stateEnum.deleted.name;
                break;
            case enums.stateEnum.suspended.value:
                status = enums.stateEnum.suspended.name;
                break;
        }
        return status;
    }

    render() {
        const t = this.props.t;
        return (
            <Table striped condensed hover responsive>
                <thead>
                    <tr>
                        <th>{t("First Name")}</th>
                        <th>{t("Last Name")}</th>
                        <th>{t("Email Address")}</th>
                        <th>{t("Web Security Roles")}</th>
                        <th className="table-fit">{t("Status")}</th>
                        <th className="table-fit">
                            <IconWithTooltip icon={faUserPlus}
                                tooltip={t("Add User")}
                                className="text-success float-right show-hand"
                                onClick={() => this.props.showModal({
                                    open: true,
                                    focus: true,
                                    title: t("Create New User"),
                                    model: this.props.organization,
                                    closeModal: this.props.hideModal,
                                    saveModel: this.createNewUser
                                }, 'createNewUserModal')} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.users.map((item, i) => {
                            return (
                                //put mapper in here to produce columns @foreach (var user in Model.Users)
                                <tr key={i}>
                                    <td>{item.firstName}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.emailAddress}</td>
                                    <td>{item.userRoles}</td>
                                    <td className="table-fit">{this.resolveStatus(item.state)}</td>
                                    <td className="table-fit">
                                        <Link to={'/admin/user/' + item.id} className="float-right text-primary"><FontAwesomeIcon icon={faPen} className="gi-1-5x" /></Link>
                                    </td>
                                    {
                                        //<td className="table-fit">
                                        //    <Glyphicon glyph="remove" className="gi-1-5x text-danger" />
                                        //</td>
                                    }
                                </tr>
                            );
                        }
                        )
                    }
                </tbody>
            </Table>
        );
    }
}

export default withTranslation()(connect(null, dispatch => bindActionCreators(modalActionCreator, dispatch))(UserList));