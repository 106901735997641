import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Layout.css';

export default props => (
    <div>
        <div className="noAuthMainContainer">
            <Container fluid >
               {props.children}
            </Container>
        </div>
    </div>
);