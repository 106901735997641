import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faServer } from '@fortawesome/free-solid-svg-icons';

import { modalActionCreator } from '../../store/actions/modalActions';

import '../../styles/spm.css';

class ServerInstanceList extends Component {
    constructor(props, context) {
        super(props, context);

        this.getNewServerInstance = this.getNewServerInstance.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.state = {
        };
    }

    closeModal(event) {
        this.props.hideModal();
    }

    getNewServerInstance() {
        const newInstance = {
            "id": 0,
            "organizationId": this.props.organization.id,
            "instanceName": "",
            "serverName": "",
            "serverReleaseVersion": "",
            "port": null,
            "httpPort": null,
            "updaterUri": "",
            "serverIdentity": "system",
            "clientCredentialType": "windows",
            "isTestInstance": false,
            "state": 100
        }

        return newInstance;
    }

    render() {
        const t = this.props.t;
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <Table striped condensed hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("Name")}</th>
                                    <th className="table-fit">{t("Release")}</th>
                                    <th className="table-fit">{t("Server")}</th>
                                    <th className="table-fit">{t("Port")}</th>
                                    <th className="table-fit">{t("HTTP Port")}</th>
                                    {
                                        //<th className="table-fit">Clients</th>
                                    }
                                    <th className="table-fit">{t("Type")}</th>
                                    <th className="table-fit">{t("Status")}</th>
                                    <th className="table-fit">
                                        <span className="fa-layers fa-fw text-success float-right show-hand"
                                            onClick={() => this.props.showModal({
                                                open: true,
                                                title: 'Add New Server Instance',
                                                message: '',
                                                model: this.getNewServerInstance(),
                                                size: 'lg',
                                                closeModal: this.closeModal,
                                                saveModel: this.props.updateServerInstanceList
                                            }, 'serverInstance')}>
                                            <FontAwesomeIcon icon={faServer}  />
                                            <FontAwesomeIcon icon={faPlus} transform="shrink-8 right-12" />
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.serverInstances.map((item, i) => {
                                        return (
                                            //put mapper in here to produce columns @foreach (var user in Model.Users)
                                            <tr key={i}>
                                                <td>{item.instanceName}</td>
                                                <td className="table-fit">{item.serverReleaseVersion}</td>
                                                <td className="table-fit">{item.serverName}</td>
                                                <td className="table-fit">{item.port}</td>
                                                <td className="table-fit">{item.httpPort}</td>
                                                {
                                                    //<td className="table-fit">
                                                    //    {
                                                    //        item.registeredClients !== null ? item.registeredClients.length : 0
                                                    //    }
                                                    //</td>
                                                }
                                                <td className="table-fit">{item.isTestInstance ? t("Test") : t("Production")}</td>
                                                <td className="table-fit">{item.state === 100 ? t("Active") : t("Not Active")}</td>
                                                <td className="table-fit">
                                                    <FontAwesomeIcon icon={faPen} className="gi-1-5x  show-hand text-primary" onClick={() => this.props.showModal({
                                                        open: true,
                                                        title: t("Add New Server Instance"),
                                                        message: '',
                                                        model: item,
                                                        size: 'lg',
                                                        closeModal: this.closeModal,
                                                        saveModel: this.props.updateServerInstanceList
                                                    }, 'serverInstance')} />
                                                </td>
                                                {
                                                    //<td className="table-fit">
                                                    //    <Glyphicon glyph="remove" className="gi-1-5x text-danger" />
                                                    //</td>
                                                }
                                            </tr>
                                        );
                                    }
                                    )
                                }
                            </tbody>
                        </Table>
                        <br /><br />
                        {
                            //JSON.stringify(this.props.serverInstances)
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}
export default withTranslation()(connect(null, dispatch => bindActionCreators(modalActionCreator, dispatch))(ServerInstanceList));