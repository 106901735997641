import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Dropdown, DropdownButton, Form, InputGroup, Modal, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faSearch, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from "react-router-dom";
import {OrContext} from '../OrContext';
import {useTranslation} from 'react-i18next';
import CaseContext from '../../../controls/caseContext/CaseContext';
import RequirementsList from './RequirementsList';
import SiteSelector from "./SiteSelector";
import {ConditionalRender} from '../../../controls/formControls';
import {DataColumn, DataTable} from '../../../controls/dataTable/DataTable';

import FindItemService from '../../../services/findItemService';
import SaveButton from "./SaveButton";

const CreateQualityEvent = (props) => {
    const orContext = useContext(OrContext);
    const history = useHistory();
    const {t} = useTranslation();
    const caseSiteId = orContext.caseData.siteId;


    const [siteId, setSiteId] = useState(caseSiteId);
    const [eventType, setEventType] = useState(3);
    const [eventDetails, setEventDetails] = useState("");
    const [selectedRequirements, setSelectedRequirements] = useState([]);
    const [productSearchVisible, setProductSearchVisible] = useState(true);
    const [showProductSearchModal, setShowProductSearchModal] = useState(false);
    const [productSearchText, setProductSearchText] = useState("");
    const [returnUrl, setReturnUrl] = useState("/or/case/quality");

    // Product Search dialog 
    const [modalSearchText, setModalSearchText] = useState("");
    const [productResults, setProductResults] = useState([]);
    const [searchExecuted, setSearchExecuted] = useState(false);
    const [forwardedFromAssignedItemsScreen, setForwardedFromAssignedItemsScreen] = useState(false);

    const [selectedProductIndex, setSelectedProductIndex] = useState(null);

    useEffect(() => {
        if (props.history?.location?.state.data !== undefined) {
            let rawItem = props.history.location.state.data;

            let newItem = {
                id: rawItem.setTypeId,
                indexSelected: {key: rawItem.setIndexId, value: rawItem.indexNumber},
                name: rawItem.name
            };

            setSelectedProductIndex(newItem.indexSelected);

            if (newItem.name !== null) {
                setProductSearchText(newItem.name);
                setForwardedFromAssignedItemsScreen(true);
            }

            setReturnUrl("/or/case");
        }
    }, []);

    useEffect(() => {
        orContext.getSites();
        orContext.refreshCase();
    }, [orContext.caseData.id]);

    useEffect(() => {
        setSelectedRequirements([]);
        orContext.getRequirements(siteId, eventType);
        setProductSearchVisible((parseInt(eventType) === 3)); // Only show product search if we select product
    }, [siteId, eventType]);

    function handleSiteChange(event) {
        setSiteId(event.target.value);
    }

    function isSaveDisabled() {
        return eventDetails.trim().length === 0 && selectedRequirements.length === 0;
    }

    function handleEventTypeChange(event) {
        setEventType(parseInt(event.target.value));
    }

    function handleRequirementChange(id) {
        const idx = selectedRequirements.indexOf(id);
        if (idx >= 0) {
            var remove = [...selectedRequirements];
            remove.splice(idx, 1);
            setSelectedRequirements(remove);
        } else {
            var added = [...selectedRequirements];
            added.push(id);
            setSelectedRequirements(added);
        }
    }

    function handleEventDetailsChange(event) {
        setEventDetails(event.target.value);
    }

    function handleCreation() {
        var payload = {
            createdByUserId: orContext.user.spmId,
            caseId: orContext.caseData.id,
            createdDate: new Date(),
            siteId: siteId,
            reportedBy: orContext.user.fullName,
            setIndexId: null,
            failedRequirementIds: selectedRequirements,
            eventDetails: eventDetails,
            eventType: parseInt(eventType),
            defaultEventStatus: 0,
        };

        if (eventType == 3) {
            //EventType is product
            payload.setIndexId = selectedProductIndex != null ? selectedProductIndex.key : null;
        }

        orContext.createQualityEvent(payload);
        history.push(returnUrl);
    }

    function hideProductSearch() {
        setShowProductSearchModal(false);
    }

    function search(searchTerm) {

        const params = {searchText: searchTerm, searchProducts: true, searchInstruments: false, siteId: siteId};
        let fullMatchFound = false;

        FindItemService.findItem(params)
            .then(response => {
                const resultDto = response.data;

                if (resultDto.success) {
                    const data = resultDto.payload;
                    if (data !== null && data.productSearchResults !== null) {
                        let productSearchResults = [...data.productSearchResults];

                        for (let i = 0; i < productSearchResults.length; i++) {
                            let orderedIndexes = [...productSearchResults[i].indexes];
                            orderedIndexes.sort((a, b) => {
                                return (a.value > b.value) - (a.value < b.value);
                            });

                            productSearchResults[i].indexes = orderedIndexes;
                            productSearchResults[i].indexSelected = orderedIndexes[0];
                        }

                        if (productSearchResults.length == 1) {

                            const splitSearchText = params.searchText.split("-");
                            if (splitSearchText.length == 2) {
                                //assume valid barcode submission with id and index
                                const searchIndex = splitSearchText[1];
                                const product = productSearchResults[0];

                                const matchingIndex = product.indexes.find(x => x.value == searchIndex);

                                if (matchingIndex !== undefined) {
                                    fullMatchFound = true;

                                    setSelectedProductIndex(matchingIndex);
                                    setSearchExecuted(true);

                                    setProductSearchText(product.name);
                                }
                            }
                        }

                        if (!fullMatchFound) {
                            setShowProductSearchModal(true);
                            setProductResults(productSearchResults);
                        }
                    }
                }
            });
    }

    function performSearch() {
        if (searchExecuted || forwardedFromAssignedItemsScreen) {
            setProductSearchText("");
            setSearchExecuted(false);
            setForwardedFromAssignedItemsScreen(false);
        } else {

            if (productSearchText.length >= 3) {
                setModalSearchText(productSearchText);

                search(productSearchText);
            }
        }
    }

    function handleModalSearchTextChange(event) {
        var st = event.target.value;
        setModalSearchText(st);

        if (st.length >= 3) {
            search(st);
        }
    }

    function handleProductSearchTextChange(event) {
        setProductSearchText(event.target.value);
    }

    function chooseProductIndex(index, productIndex) {
        let updatedProductResults = [...productResults];
        let updatedProduct = {...updatedProductResults[index]};
        updatedProduct.indexSelected = productIndex;

        updatedProductResults[index] = updatedProduct;
        setProductResults(updatedProductResults);
    }

    function updateSelectedProduct(productIndex) {
        var newSelectedProduct = {...productResults[productIndex]};
        var newSelectedIndex = newSelectedProduct.indexSelected;

        setShowProductSearchModal(false);
        setModalSearchText("");
        setProductSearchText(newSelectedProduct.name);
        setSearchExecuted(true);

        setSelectedProductIndex(newSelectedIndex);
    }

    return (<>
        <Modal bsSize="small" show={showProductSearchModal}>
            <Modal.Header>
                <Modal.Title>
                    Product Search
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="searchText">{t("Search Text")}</label>
                    <InputGroup>
                        <Form.Control type="text" name="searchText" value={modalSearchText}
                                      onChange={handleModalSearchTextChange}/>
                    </InputGroup>
                </div>
                <div className="form-group" style={{overflowY: "scroll", resize: "none", height: "50vh"}}>
                    <DataTable striped hover responsive size="small" dataSource={productResults}>
                        <DataColumn sortable dataField="name">{t('Name')}</DataColumn>
                        <DataColumn dataField="indexes"
                                    formattedColumn={(row, i) => {
                                        return (<DropdownButton key={`index-dropdown-${i}`} id={`index-dropdown-${i}`}
                                                                title={row.indexSelected.value} variant="secondary">
                                            {row.indexes.map((index, j) => {
                                                return (<Dropdown.Item key={j}
                                                                       onSelect={() => chooseProductIndex(i, index)}>{index.value}</Dropdown.Item>)
                                            })}
                                        </DropdownButton>)
                                    }}
                        >{t('Index')}</DataColumn>
                        <DataColumn dataField="productGroup"
                                    formattedColumn={(row, i) => {
                                        return (<Button variant="primary" size="sm"
                                                        onClick={() => updateSelectedProduct(i)}>
                                            <FontAwesomeIcon icon={faCheck}/>
                                        </Button>)
                                    }}
                        >{t('Action')}</DataColumn>
                    </DataTable>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => hideProductSearch()} variant="warning" type="button">Cancel</Button>
            </Modal.Footer>
        </Modal>
        <CaseContext caseInfo={orContext.caseData} className="context-container-or-portal bg-light"/>
        <Card className="mt-30">
            <Card.Header className="d-flex align-items-center">
                <div className="mr-auto card-button-group-item">{t('Create Quality Event')}</div>
                <div className="card-btn-group form-inline">
                    <SaveButton
                        disabled={isSaveDisabled()}
                        onClick={() => handleCreation()}
                    />
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col lg={3} md={6}>
                        <SiteSelector onChange={handleSiteChange} sites={orContext.sites}
                                      caseSiteId={caseSiteId}/>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group">
                            <label htmlFor="reportedBy">Reported By</label>
                            <input className="form-control" type="text" id="reportedBy" name="reportedBy"
                                   value={orContext.user.fullName} readOnly/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} md={6}>
                        <div className="form-group">
                            <label htmlFor="eventType">Event Type</label>
                            <select className="form-control" id="eventType" name="eventType" value={eventType}
                                    onChange={handleEventTypeChange}>
                                <option value="1">Case cart</option>
                                <option value="2">Loaner order</option>
                                <option value="3" selected>Product</option>
                                <option value="4">Sterilizer load</option>
                                <option value="5">Task</option>
                                <option value="6">Washer load</option>
                                <option value="0">Other</option>
                            </select>
                        </div>
                    </Col>
                    <ConditionalRender visible={productSearchVisible}>
                        <Col lg={3} md={6}>
                            <div className="form-group">
                                <label htmlFor="identifier" id="identifier">Product</label>
                                <InputGroup>
                                    <input className="form-control"
                                           readOnly={(searchExecuted || forwardedFromAssignedItemsScreen)}
                                           type="text" id="productSearchText" value={productSearchText}
                                           onChange={handleProductSearchTextChange} name="productSearchText"
                                           placeholder="Search by barcode or name"/>
                                    <InputGroup.Append>
                                        <Button variant="primary" size="sm" onClick={() => performSearch()}>
                                            <FontAwesomeIcon
                                                icon={(searchExecuted || forwardedFromAssignedItemsScreen) ? faTimesCircle : faSearch}/>
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        </Col>
                    </ConditionalRender>
                </Row>
                <hr/>
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <h5 htmlFor="eventDetails">Event details{isSaveDisabled() &&
                                <span style={{color: "red"}}> (Required)</ span>}:</h5>
                            <textarea className="form-control" name="eventDetails" id="eventDetails" rows="5"
                                      style={{overflowY: "scroll", height: "100px", resize: "none"}}
                                      placeholder="Enter event details"
                                      onChange={handleEventDetailsChange}></textarea>
                        </div>
                    </Col>
                </Row>
                <h5>Requirements{isSaveDisabled() && <span style={{color: "red"}}> (Required)</ span>}:
                </h5>
                <RequirementsList requirements={orContext.requirements} selectedRequirements={selectedRequirements}
                                  handleRequirementChange={handleRequirementChange}/>
            </Card.Body>
        </Card>
    </>)
}
export default CreateQualityEvent