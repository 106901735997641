import axios from "axios";

const FindItemService = {
    async findItem(searchParams, config = {}) {
        const response = await axios.post(`/api/finditem/`, searchParams, config);
        return response;
    },

    async setIndexSearch(searchParams, config = {}) {
        const response = await axios.post(`/api/finditem/setindex`, searchParams, config);
        return response;
    },

    async getProductsForInstrument(searchParams, config = {}) {
        const response = await axios.post(`/api/finditem/getproductsforinstrument`, searchParams, config);
        return response;
    },
}

export default FindItemService; 
