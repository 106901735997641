import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import DelivererSelector from '../../components/loaner/DeliverTrays/DelivererSelector';

const DelivererSelectorModal = ({ model, closeModal, beforeCloseModal, onCancel }) => {

    const { t } = useTranslation();
    const [deliverers, setDeliverers] = useState(model.deliverers);
    const [selectedDeliverer, setSelectedDeliverer] = useState(model.selectedDeliverer);
    const [siteVendorDeliverers, setSiteVendorDeliverers] = useState(model.siteVendorDeliverers);
    const [canSaveAndClose, setCanSaveAndClose] = useState(false);

    const updateDelivererList = (data = null) => {
        const deliverersLocal = model.selectedVendor && model.selectedSites && deliverers ?
            deliverers
                .filter(d => d.vendorId === model.selectedVendor && model.selectedSites.includes(d.siteId))
                .map(d => d.name)
                .filter((item, i, ar) => ar.indexOf(item) === i)    //get unique names
                .sort() : [];


        let selectedDelivererLocal = data ? data : selectedDeliverer;
        if (!deliverersLocal.includes(selectedDelivererLocal)) {
            selectedDelivererLocal = '';
        }

        setSiteVendorDeliverers(deliverersLocal);
        setSelectedDeliverer(selectedDelivererLocal);
    }

    useEffect(() => {
        updateDelivererList();
    }, [deliverers]);

    useEffect(() => {
        setCanSaveAndClose(!!selectedDeliverer);
    }, [selectedDeliverer]);

    const handleDelivererChange = (event) => {
        const newValue = event.target.selectedIndex > 0 ? event.target.value : '';

        setSelectedDeliverer(newValue);
    }

    const handleNewDeliverer = (name) => {
        const existingDeliverer = siteVendorDeliverers.find(d => d.toLowerCase() === name.toLowerCase());

        if (existingDeliverer) {
            setSelectedDeliverer(existingDeliverer);
            setCanSaveAndClose(true);
        }
        else {
            setSelectedDeliverer(name);

            setDeliverers([...deliverers,
            {
                siteId: model.selectedSites[0],
                vendorId: model.selectedVendor,
                name: name
            }]);
        }
    }

    const handleSaveAndClose = () => {
        beforeCloseModal(selectedDeliverer);
        closeModal();
    }

    const handleCancel = () => {
        onCancel();
        closeModal();
    }

    return (
        <div>
            <Modal.Header>
                <h4>{t('Select Delivery Person')}</h4>
            </Modal.Header>
            <Modal.Body>
                <DelivererSelector
                    selectedSites={model.selectedSites}
                    selectedVendor={model.selectedVendor}
                    selectedDeliverer={selectedDeliverer}
                    siteVendorDeliverers={siteVendorDeliverers}
                    handleDelivererChange={handleDelivererChange}
                    handleNewDeliverer={handleNewDeliverer}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="default" variant="warning" onClick={handleCancel}>{t('Cancel')}</Button>
                <Button bsStyle="success" disabled={!canSaveAndClose} onClick={handleSaveAndClose}>{t('Save & Close')}</Button>
            </Modal.Footer>
        </div>

    );
};

export default DelivererSelectorModal