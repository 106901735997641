import React, { Component } from 'react';
import { registerClientResultEnum } from '../../utilities/enumerations';
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Fingerprint from 'fingerprintjs2';
import IdleTimer from 'react-idle-timer';
import AuthService from '../../services/authService';
import { roleIds } from '../../utilities/authConstants';

const idleTimeout = process.env.REACT_APP_API_IDLE_LOGOUT || 600000; //10 minute timeout.

class AssureContext extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            createClient: { name: "", primarySiteId: 0, fingerprint: '', components: '' },
            registerClientResult: { confirmedClient: {}, potentialClients: [], result: null, message: "" },
            selectedClientId: 0,
            returnUrl: '',
            fingerprint: '',
            sites: []
        };

        this.assureUserContext = this.assureUserContext.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.assureClientContext = this.assureClientContext.bind(this);
        this.checkRegisteredClients = this.checkRegisteredClients.bind(this);
    }

    async componentDidMount() {

        let location = this.props.location;
        let returnUrl = location.pathname;
        if (location.search !== '') {
            returnUrl = returnUrl + encodeURIComponent(location.search);
        }
        this.setState({ returnUrl: returnUrl }, async () => await this.assureContext());
    }

    async assureContext() {
        await this.assureUserContext();
        if (!this.props.ignoreClientContext) {
            // likely need to figure this out - because
            // it would be nicer to have/make sure the spm client context
            // is working even for admin areas.
            // I added this in for admin
            await this.assureClientContext();
        }
    }

    async assureUserContext() {
        if (this.props.applicationContext.user === null) {
            await this.getCurrentUser();
        }
    }

    async getCurrentUser() {
        const url = `api/auth`;

        await fetch(url)
            .then(response => {
                if (response.redirected === true) {
                    this.props.history.push("/auth/login");
                }
                if (response.ok) {
                    return response;
                }
                else if (response.status === 401) {
                    // redirect to authentication
                    this.props.history.push("/auth/login");
                }
                else if (response.status === 403) {
                    // redirect to unauthorized
                    this.props.history.push("../auth/unauthorized");
                }
                else if (response.status === 409) {
                    // redirect to unauthorized
                    this.props.history.push("/auth/invalidtenant");
                }
                else {
                    throw new Error(response.status + ": " + response.statusText);
                }
            })
            .then(response => {
                return response.json();
            })
            .then(result => {
                this.props.updateCurrentUser(result);
                return result;
            })
            .catch(error => {
                const tenant = this.props?.applicationContext?.tenant ?? null;
                const path = this.props?.location?.pathname ?? null;

                if (tenant !== null && path !== null) {
                    if (!tenant.isDatabaseCreated && path == "/loaners") {
                        this.props.history.push("/auth/tenantdbmissing");
                    }
                }

                this.setState({ returnError: error.message });
            });
    }

    async assureClientContext() {
        if (this.props.applicationContext.client.id === 0) {
            const localThis = this;
            Fingerprint.get(function (components) {
                var murmur = Fingerprint.x64hash128(components.map(function (pair) { return pair.value }).join(), 31);
                localThis.setState({ fingerprint: murmur }, async () => await localThis.checkRegisteredClients());
            });
        }
    }

    async checkRegisteredClients() {
        const fingerprint = this.state.fingerprint;
        const url = `api/client/register/fingerprint/${fingerprint}`;
        await fetch(url)
            .then(response => {
                if (response.redirected === true) {
                    this.props.history.push("/auth/login");
                }
                if (response.ok) {
                    return response;
                }
                else if (response.status === 401) {
                    // redirect to authentication
                    this.props.history.push("/auth/login");
                }
                else if (response.status === 403) {
                    // redirect to unauthorized
                    this.props.history.push("/auth/unauthorized");
                }
                else if (response.status === 409) {
                    // redirect to unauthorized
                    this.props.history.push("/auth/invalidtenant");
                }
                else {
                    throw new Error(response.status + ": " + response.statusText);
                }
            })
            .then(response => {
                return response.json();
            })
            .then(clientResult => {
                if (clientResult.result === registerClientResultEnum.Success) {
                    clientResult.confirmedClient.settings = clientResult.confirmedClient.settings.map(cs => {
                        return {
                            id: cs.id,
                            settingType: cs.settingType,
                            value: cs.value.startsWith("[") || cs.value.startsWith("{") ? JSON.parse(cs.value) : cs.value
                        }
                    });
                    // set client in redux
                    this.props.updateCurrentClient(clientResult.confirmedClient);
                }
                else {
                    // send to the register client page
                    let returnUrl = this.state.returnUrl;
                    this.props.history.push('/registerclient?returnUrl=' + returnUrl);
                }
            })
            .catch(error => {
                this.setState({ returnError: error.message });
            });
    }


    isLoanerKioskUser = () => {
        const { user } = this.props.applicationContext;
        // Normally we want to use permissions instead of roles.
        // In this case there is no permission that is specific to only the Loaner Kiosk User role.
        if (user?.roles) {
            var loanerKioskUser = user.roles.find(p => p.id === roleIds.loanerKioskUser);
            if (loanerKioskUser) {
                return true;
            }
        }
        return false;
    }

    isEpicLogin = () => {
        const { user } = this.props.applicationContext;

        if (user?.isEpicUser !== undefined) {
            return true;
        }

        return false;
    }

    handleIdle = () => {
        if (!this.isLoanerKioskUser() && !this.isEpicLogin()) {
            AuthService.logout().then(() => {
                this.props.updateCurrentUser(null);
                this.props.history.push('/auth/login');
            });
        }
    }

    render() {
        return (
            <>
                <IdleTimer
                    onIdle={this.handleIdle}
                    timeout={idleTimeout}
                />
                {
                    //If we have a user and if we haven't ignored the context and actually have a context
                    //Then render out the UI otherwise don't render the UI
                    this.props.applicationContext.user !== null
                        ? (!this.props.ignoreClientContext && this.props.applicationContext !== null)
                            || this.props.ignoreClientContext
                            ? this.props.children
                            : null
                        : null
                }
            </>
        );
    }
}

export default compose(withRouter, connect(
    state => state.applicationContext, dispatch => bindActionCreators({ ...applicationContextActionCreator }, dispatch)))(AssureContext);