import React, { Component } from 'react';
import { Modal,Button } from 'react-bootstrap';
import image from './temp_tray.jpg';

class ImageViewModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }
    render() {
        return (
            <>
                <Modal.Header closeButton />
                <Modal.Body>
                    <img src={image} />
                </Modal.Body>
            </>
        );
    }
}


export default ImageViewModal;