import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router-dom';

import { LinkContainer } from 'react-router-bootstrap';
import { Breadcrumb } from 'react-bootstrap';

const getPaths = pathname => {
    const paths = ['/'];
    if (pathname === '/') return paths;
    pathname.split('/').reduce((prev, curr) => {
        const currPath = `${prev}/${curr}`;
        paths.push(currPath);
        return currPath;
    });
    return paths;
};

const BreadcrumbRouteItem = ({ name, currPath }, fullCurrPath) => {
    if (currPath === fullCurrPath) {
        return <li key={currPath} className="sub-nav-breadcrumb-item breadcrumb-item" active="true">{name}</li>;
    }
    else if (currPath === '/') {
        return <li key={currPath} className="sub-nav-breadcrumb-item breadcrumb-item">{name}</li>;
    }
    else {        
        return <li key={currPath} className="sub-nav-breadcrumb-item breadcrumb-item">
                    <LinkContainer to={currPath}>
                        <a>
                            {name}
                        </a>
                    </LinkContainer>
                </li>;
    }
};

class SpmBreadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = { items: [] };
        this.refreshItems = this.refreshItems.bind(this);
    }
    
    refreshItems(pathname) {
        let items = null;
        if (this.props.routes) {
            const currPath = pathname;
            const paths = getPaths(currPath);
            const currRoutes = paths.map(currPath => {
                const route = this.props.routes.find(route => matchPath(currPath, {
                    path: route.path,
                    exact: route.exact
                }));
                return { ...route, currPath };
            }).filter(route => route);
            items = currRoutes.map(route => {
                return BreadcrumbRouteItem(route, currPath);
            });
        }

        return items;
    }

    render() {
        
        return (
            <Breadcrumb className="sub-nav-breadcrumb">
                {this.refreshItems(this.props.location.pathname)}
            </Breadcrumb>
        );
    }
}

export default withRouter(SpmBreadcrumb);

