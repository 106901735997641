import moment from 'moment';
import settings from './enums/siteSettingsEnum';
import { dateDisplayFormat, dateTimeFormat } from "./dateTime";

const labelFormatter = {
    transformCaseOrderContent(caseOrder) {
        return [
            {
                name: 'CaseNotes',
                value: caseOrder.caseNotes !== null && caseOrder.caseNotes !== undefined ? caseOrder.caseNotes : ''
            },
            {
                name: 'CaseNumber', value: caseOrder.caseNumber
            },
            {
                name: 'CaseWhenStart',
                value: caseOrder.caseStartTime !== null && caseOrder.caseStartTime !== undefined
                    ? moment(caseOrder.caseStartTime).format(dateTimeFormat)
                    : ''
            },
            {
                name: 'NumberOfLoaners', value: caseOrder.loaners.length
            },
            {
                name: 'OperatingRoomName',
                value: caseOrder.operatingRoomName !== null && caseOrder.operatingRoomName !== undefined ? caseOrder.operatingRoomName : ''
            },
            {
                name: 'PhysicianName',
                value: caseOrder.physicianName !== null && caseOrder.physicianName !== undefined ? caseOrder.physicianName : ''
            },
            {
                name: 'VendorName',
                value: caseOrder.vendorName !== null && caseOrder.vendorName !== undefined ? caseOrder.vendorName : ''
            },
            {
                name: 'VendorRepName',
                value: caseOrder.vendorRepName !== null && caseOrder.vendorRepName !== undefined ? caseOrder.vendorRepName : ''
            },
            {
                name: 'VendorRepPhone',
                value: caseOrder.vendorRepCell !== null && caseOrder.vendorRepCell !== undefined ? caseOrder.vendorRepCell : ''
            }
        ];
    },

    transformLoanerContent(loaner, caseOrder) {
        return [
            {
                name: 'Barcode',
                value: loaner.barcode
            },
            {
                name: 'DateDeliveredDateOnly',
                value: loaner.delivered !== null && loaner.delivered !== undefined ? moment(loaner.delivered).format(dateDisplayFormat) : ''
            },
            {
                name: 'CaseSiteShortName', value: caseOrder.siteName
            },
            {
                name: 'TrayName', value: loaner.trayName
            },
            {
                name: 'VendorName',
                value: caseOrder.vendorName !== null && caseOrder.vendorName !== undefined ? caseOrder.vendorName : ''
            },
            {
                name: 'VendorRepName',
                value: caseOrder.vendorRepName !== null && caseOrder.vendorRepName !== undefined ? caseOrder.vendorRepName : ''
            },
            {
                name: 'VendorRepPhone',
                value: caseOrder.vendorRepCell !== null && caseOrder.vendorRepCell !== undefined ? caseOrder.vendorRepCell : ''
            }
        ];
    },

    injectLabelData(zpl, data) {
        let zplCopy = zpl;
        data.forEach(p => {
            const propName = "${" + p.name + "}";
            zplCopy = zplCopy.replace(propName, p.value);
        });

        return zplCopy;
    },

    formatLoanerZpl(loaner, siteSettings, caseOrder) {
        const zpl = siteSettings.find(s => s.settingType === settings.loanerTrayLabelZpl.value)?.value;
        const content = this.transformLoanerContent(loaner, caseOrder);
        return this.injectLabelData(zpl, content);
    },

    formatCaseOrderInfoZpl(siteSettings, caseOrder) {
        const zpl = siteSettings.find(s => s.settingType === settings.caseOrderLabelZpl.value)?.value;
        const content = this.transformCaseOrderContent(caseOrder);
        return this.injectLabelData(zpl, content);
    }

}

export default labelFormatter;