import * as actionTypes from '../actions/actionTypes';

const initialState = {
    visible: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_SPINNER:
            return {
                visible: true
            };
        case actionTypes.HIDE_SPINNER:
            return initialState;
        default:
            return state;
    }
};