import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const CaseNotFound = (props) => {

    const { t, i18n } = useTranslation();
    return (
        <div class="container-fluid">
            <div class="py-5">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-2 text-center">
                            <p><FontAwesomeIcon icon={faExclamationTriangle} className="fa-5x text-warning" /></p>
                        </div>
                        <div class="col-md-10">
                            <h3>{t('SPM OR Portal has lost context of this visit')}</h3>
                            <p>{t('Sorry, the SPM OR Portal has been refreshed and has lost the context of the visit. Please click elsewhere in Epic and return to this page to restore your session.')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaseNotFound;