import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import Login from './Login';
import { withTranslation } from 'react-i18next';
import logo from '../../assets/img/brand/spm.png';
import { AuthenticationModeEnum } from '../../utilities/enumerations';

const defaultInstructions = "Please log in with your SPM Web credentials.";

class MainLogin extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            instructions: defaultInstructions
        }

        this.updateInstructions = this.updateInstructions.bind(this);
    }

    updateInstructions(inst) {
        let newInstructions = "";
        switch (inst) {
        case AuthenticationModeEnum.Web:
            newInstructions = defaultInstructions;
            break;
        case AuthenticationModeEnum.SPM:
            newInstructions = "Please log in with your SPM credentials.";
            break;
        case AuthenticationModeEnum.SynergyTrak:
            newInstructions = "Please log in with your SynergyTrak credentials.";
            break;
        default:
            newInstructions = defaultInstructions;
            break;
        }

        this.setState({ instructions: newInstructions });
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="app flex-row align-items-center" style={{ "borderLeft": "2px solid darkgray", "borderRight": "2px solid darkgray" }}>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <img
                                    src={logo}
                                    width="66"
                                    height="66"
                                    className="d-inline-block float-left"
                                    alt="SPM Logo"
                                    style={{ "margin-right": "10px" }}
                                />
                                <span>
                                    <h3>SPM Web Login</h3>
                                    {t(this.state.instructions)}
                                </span>
                            </Col>
                        </Row>
                        <Login history={this.props.history} callback={this.updateInstructions} remember />
                    </Container>
                </div>
            </div>
        );
    }
}


export default withTranslation() (MainLogin)
