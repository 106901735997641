import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const spinnerSize = (size) => {
    switch (size) {
        case 'lg':
            return '2.2rem';
        case 'sm':
            return '1.2rem';
        default:
            return size;
    }
};

const LoadingButton = ({
    loading,
    children,
    disabled,
    indicatorSize = 'sm',
    variant = "primary",
    icon = faSpinner,
    ...otherProps
}) => {
    return (
        <Button
            variant={variant}
            disabled={loading || disabled}
            {...otherProps}
        >
            <div className="position-relative">
                <div
                    className={`loading-container ${
                        loading ? 'loading-container-active' : ''
                    }`}
                >
                    <FontAwesomeIcon
                        style={{ fontSize: spinnerSize(indicatorSize) }}
                        icon={icon}
                        spin
                    />
                </div>
                <div className={loading ? 'loading-button-active' : ''}>
                    {children}
                </div>
            </div>
        </Button>
    );
};

export default LoadingButton;
