import React from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

const siteMultiSelect = (props) => {
    const { t } = props;
    const placeholder = t("Add sites...");
    const styles = { container: base => ({ ...base, minWidth: '200px', zIndex: 2 }) };
    const siteOptions = props.sites.map((site) => {
        return {
            value: site.id,
            label: site.shortName
        };
    });

    const selectedValues = siteOptions.filter(o => props.selectedSites.indexOf(o.value) >= 0);

    return (
        <Select
            isDisabled={props.disabled}
            isMulti={true}
            isClearable={false}
            placeholder={placeholder}
            name="sites"
            styles={styles}
            onChange={props.sitesChangedHandler}
            options={siteOptions}
            value={selectedValues}
        />
    );
};

export default withTranslation()(siteMultiSelect);