import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Alert, Modal, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import ServerInstanceService from '../../services/serverInstanceService';

class ServerInstanceModal extends Component {
    constructor(props, context) {
        super(props, context);

        const instanceValidation = {
            "instanceName": true,
            "serverName": true,
            "serverReleaseVersion": true,
            "port": true,
            "updaterUri": true,
            "serverIdentity": true
        }

        this.state = { serverInstance: this.props.model, validation: instanceValidation, validated: true, errorMessage: "", saveSuccess: true };

        this.handleServerNameInput = this.handleServerNameInput.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handlePortInput = this.handlePortInput.bind(this);
        this.handleRadioInput = this.handleRadioInput.bind(this);
        this.handleSaveServerInstance = this.handleSaveServerInstance.bind(this);
        //this.validateInstance = this.validateInstance.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    //validateInstance() {
    //    var val = this.state.instanceValidation;
    //    var instance = this.state.serverInstance;
    //    val.instanceName = instance.instangeName.length > 0;
    //    val.serverName = instance.serverName.length > 0;
    //    val.serverReleaseVersion = instance.serverReleaseVersion.length > 0;
    //    val.port = instance.port.length > 0;
    //    val.updateUri = instance.updaterUri.length > 0;
    //    val.serverIdentity = instance.serverIdentity.length > 0;
    //    this.setState({ validation: val });
    //}

    handleServerNameInput(event) {
        if (event.key === " ") {
            event.preventDefault();
        }
    }

    handlePortInput(event) {
        if (event.charCode > 57 || event.charCode < 48) {
            event.preventDefault();
        }
    }

    handleUserInput(event) {
        // taken straight from the official React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var partialState = this.state.serverInstance;
        partialState[name] = value;
        this.setState({ serverInstance: partialState });
    }

    handleRadioInput(name, event) {
        var partialState = this.state.serverInstance;
        partialState[name] = event;
        this.setState({ serverInstance: partialState });
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        const validated = form.checkValidity();

        if (validated === true) {
            this.handleSaveServerInstance();
        }

        this.setState({ validated: validated });
    }

    handleSaveServerInstance() {
        var obj = {
            ...this.state.serverInstance,
            port : parseInt(this.state.serverInstance.port),
            httpPort: parseInt(this.state.serverInstance.httpPort)
        };
        ServerInstanceService.saveServerInstance(obj)
            .then(response => {
                //this.generateMessage("success", "Success", "Save was successful!");
                if (response.data.success === true) {
                    // update the state
                    this.setState({ serverInstance: response.data.payload });
                    this.props.saveModel(response.data.payload);
                    this.props.closeModal();
                }
                else {
                    // error condition
                    this.setState({ errorMessage: response.data.message, saveSuccess: response.data.success });
                }
            })
            .catch(error => {
                //this.generateMessage("danger", "Error", error.message + " - Error saving site.");
                this.setState({ errorMessage: error.message, saveSuccess: false });
            });
    }

    render() {
        const { t } = this.props;
        return (
            <Form validated={this.state.validated} onSubmit={this.handleSubmit}>
                <Modal.Header>
                    <h4>
                        {
                            this.state.serverInstance.instanceName.length > 0 ? this.state.serverInstance.instanceName : t("New Server Instance")
                        }
                    </h4><br />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6}>
                            <div className="form-group">
                                <label htmlFor="instanceName">{t('Instance Name')}</label>
                                <input className="form-control" type="text" id="instanceName" name="instanceName" value={this.state.serverInstance.instanceName} onChange={this.handleUserInput} asp-for="instanceName" aria-describedby="instanceNameHelp" required />
                                <small id="instanceNameHelp" className="form-text text-muted">{t('Please enter the name of this instance.')}</small>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="form-group">
                                <label htmlFor="serverName">{t('Server Name')}</label>
                                <input className="form-control" type="text" id="serverName" name="serverName" asp-for="serverName" value={this.state.serverInstance.serverName} onChange={this.handleUserInput} onKeyPress={this.handleServerNameInput} aria-describedby="serverNameHelp" required />
                                <small id="serverNameHelp" className="form-text text-muted">{t('Please enter the name of the server that hosts your SPM App Service.')}</small>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div className="form-group">
                                <label htmlFor="serverReleaseVersion">{t('Release Version')}</label>
                                <input className="form-control" type="text" id="serverReleaseVersion" name="serverReleaseVersion" value={this.state.serverInstance.serverReleaseVersion} onChange={this.handleUserInput} asp-for="serverReleaseVersion" aria-describedby="serverReleaseVersionHelp" required />
                                <small id="serverReleaseVersionHelp" className="form-text text-muted">{t('Please enter the release version of this instance.')}</small>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-group">
                                <label htmlFor="port">{t('Port')}</label>
                                <input className="form-control" type="text" id="port" name="port" asp-for="port" value={this.state.serverInstance.port} onChange={this.handleUserInput} onKeyPress={this.handlePortInput} aria-describedby="portHelp" required maxlength="5"/>
                                <small id="portHelp" className="form-text text-muted">{t('Please enter the port number of this instance.')}</small>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-group">
                                <label htmlFor="httpPort">{t('HTTP Port')}</label>
                                <input className="form-control" type="text" id="httpPort" name="httpPort" asp-for="httpPort" value={this.state.serverInstance.httpPort} onChange={this.handleUserInput} onKeyPress={this.handlePortInput} aria-describedby="httpPortHelp" required maxlength="5"/>
                                <small id="httpPortHelp" className="form-text text-muted">{t('Please enter the HTTP port number of this instance.')}</small>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="form-group">
                                <label htmlFor="updaterUri">{t('Client Update Url')}</label>
                                <input className="form-control" type="text" id="updaterUri" name="updaterUri" value={this.state.serverInstance.updaterUri} onChange={this.handleUserInput} asp-for="updaterUri" aria-describedby="updaterUriHelp" required />
                                <small id="updaterUriHelp" className="form-text text-muted">{t('Please enter the url for the client updater.')}</small>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="form-group">
                                <label htmlFor="serverIdentity">{t('Server Identity')}</label>
                                <input className="form-control" type="text" id="serverIdentity" name="serverIdentity" value={this.state.serverInstance.serverIdentity} onChange={this.handleUserInput} asp-for="serverIdentity" aria-describedby="serverIdentityHelp" required />
                                <small id="serverIdentityHelp" className="form-text text-muted">{t('Please enter the server identity.')}</small>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div className="form-group">
                                <label htmlFor="clientCredentialType">{t('Client Credential Type')}</label>
                                <ButtonToolbar>
                                    <ToggleButtonGroup type="radio" name="clientCredentialType" id="clientCredentialType" defaultValue={"windows"} value={this.state.serverInstance.clientCredentialType} onChange={(e) => this.handleRadioInput("clientCredentialType", e)}>
                                        <ToggleButton value={"windows"}>{t('Windows')}</ToggleButton>
                                        <ToggleButton value={"none"}>{t('None')}</ToggleButton>
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="form-group">
                                <label htmlFor="isTestInstance">{t('Instance Type')}</label>
                                <ButtonToolbar>
                                    <ToggleButtonGroup type="radio" name="isTestInstance" id="isTestInstance" defaultValue={false} value={this.state.serverInstance.isTestInstance} onChange={(e) => this.handleRadioInput("isTestInstance", e)}>
                                        <ToggleButton value={false}>{t('Production')}</ToggleButton>
                                        <ToggleButton value={true}>{t('Test')}</ToggleButton>
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="form-group">
                                <label htmlFor="state">{t('Status')}</label>
                                <ButtonToolbar>
                                    <ToggleButtonGroup type="radio" name="state" id="state" defaultValue={100} value={this.state.serverInstance.state} onChange={(e) => this.handleRadioInput("state", e)}>
                                        <ToggleButton value={100}>{t('Active')}</ToggleButton>
                                        <ToggleButton value={150}>{t('Suspended')}</ToggleButton>
                                        <ToggleButton value={0}>{t('Deleted')}</ToggleButton>
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </div>
                        </Col>
                    </Row>
                    {
                        this.state.errorMessage.length > 0 ?
                            <Row>
                                <Col lg={12}>
                                    <Alert bsStyle="danger"><strong>{this.state.errorMessage}</strong></Alert>
                                </Col>
                            </Row>
                            :
                            null
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" variant="warning" onClick={this.props.closeModal}>{t('Cancel')}</Button>
                    <Button bsStyle="success" variant="primary" type="submit">{t('Save')}</Button>
                </Modal.Footer>
            </Form>
        );
    }
}


export default withTranslation()(ServerInstanceModal);