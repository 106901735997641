import * as actionTypes from './actionTypes';
 
export const signalrActionCreator = {
    sendSignalRMessage: (message) => dispatch => {
        dispatch({
            type: actionTypes.SEND_SIGNALR_MESSAGE,
            message: message
        });
    },
    startSignalRConnection: () => dispatch => {
        console.log('Starting SignalR connection');
        dispatch({
            type: actionTypes.START_SIGNALR_CONNECTION
        });
    },
    stopSignalRConnection: () => dispatch => {
        dispatch({
            type: actionTypes.STOP_SIGNALR_CONNECTION
        });
    },
    subscribeToTopic: (topic) => dispatch => {
        dispatch({
            type: actionTypes.SUBSCRIBE_SIGNALR_TOPIC,
            topic: topic
        });
    },
    unsubscribeFromTopic: (topic) => dispatch => {
        dispatch({
            type: actionTypes.UNSUBSCRIBE_SIGNALR_TOPIC,
            topic: topic
        });
    }
}