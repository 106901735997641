import axios from "axios";

const OrganizationService = {
    async getAllOrganizations() {
        const response = axios.get(`/api/organization/`);
        return response;
    },

    async getOrganizationDetails(organizationId) {
        const response = axios.get(`/api/organization/${organizationId}`);
        return response;
    },

    async getOrganizationSettings() {
        const response = axios.get("/api/organization/settings");
        return response;
    },

    async saveOrganization(organization) {
        const response = axios.post(`/api/organization/`, organization);
        return response;
    },

    async makeChildOrganization(organization) {
        const response = axios.post(`/api/organization/makechild`, organization);
        return response;
    },

    async changeUserPassword(passwordChangeSet) {
        const response = axios.post(`/api/organization/user/changeUserPassword`, passwordChangeSet);
        return response;
    },

    async saveClientSettings(settings) {
        const response = axios.post(`/api/organization/client/saveClientSettings`, settings);
        return response;
    },

    async saveDeliverer(deliverer) {
        const response = axios.post(`/api/organization/deliverer`, deliverer);
        return response;
    },

    async updateDelivererLastUsed(deliverer) {
        const response = axios.put(`/api/organization/deliverer/lastused`, deliverer);
        return response;
    },

    async getSitesForCurrentOrg() {
        const response = axios.get(`api/organization/sites/`);
        return response;
    },

    async organizationHasValidORPortalLicense() {
        const response = axios.get('/api/organization/validportallicense/')
        return response;
    },

    async createTenantDatabase(organizationId) {
        const response = axios.post(`/api/organization/createtenantdatabase`, organizationId);
        return response;
    },

    async getOrgSettingsForCurrentOrg() {
        const response = axios.get(`/api/organization/settings/orgsettings`);
        return response;
    }
}

export default OrganizationService; 
