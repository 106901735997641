import React, { useState } from 'react'
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EpicEncryptionService from '../../../services/epicEncryptionService';


function EpicAuthentication() {
    const { t } = useTranslation();
    const [tenantName, setTenantName] = useState('mmm');
    const [userName, setUserName] = useState('KMARNIN');
    const [caseContext, setCaseContext] = useState('10446');
    const [workstation, setWorkstation] = useState('MKM557');
    const [generatedUrl, setGeneratedUrl] = useState('');

    const generateEpicAuthUrl = () => {
        const epicValues = {
            userName: userName,
            caseContext: caseContext,
            workstation: workstation
        };     

        EpicEncryptionService.encrypt(epicValues).then(response => {
            console.log(response);
            var resultDto = response.data;

            if(resultDto.success) {
                const payload = resultDto.payload;
                const host = window.location.host;  
                const hostString = tenantName + "." + host.split(".").slice(1).join(".");
                const url = `${window.location.protocol}//${hostString}/auth/epic?data=${payload}`;

                setGeneratedUrl(url);
            }
        });
    }

    const openORPortalUrl = () => {
        if(generatedUrl.length > 0)
        {
            window.open(generatedUrl, '_blank');
        }
    }

    
    return (
        <Card>
            <Card.Header>EPIC Authentication</Card.Header>
            <Card.Body>
                <Form.Row>
                    <Form.Group as={Col} controlId="UserName">
                        <Form.Label>{t("Tenant")}</Form.Label>
                        <Form.Control 
                            name="tenantName" 
                            value={tenantName}
                            onChange={(e) => setTenantName(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="UserName">
                        <Form.Label>{t("User Name")}</Form.Label>
                        <Form.Control 
                            name="userName" 
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="CaseContext">
                        <Form.Label>{t("Case Context")}</Form.Label>
                        <Form.Control 
                            name="caseContext" 
                            value={caseContext}
                            onChange={(e) => setCaseContext(e.target.value)}
                        ></Form.Control>
                    </Form.Group>                    
                    <Form.Group as={Col} controlId="Workstation">
                        <Form.Label>{t("Workstation")}</Form.Label>
                        <Form.Control 
                            name="workstation" 
                            value={workstation}
                            onChange={(e) => setWorkstation(e.target.value)}
                        ></Form.Control>
                    </Form.Group>                                        
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="generateButton">
                        <Button onClick={() => generateEpicAuthUrl()}>{t("Generate")}</Button>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="generatedUrl">
                    <Form.Label>{t("EPIC Url")}</Form.Label>
                        <Form.Control
                            name="generatedUrl"
                            readonly
                            plaintext
                            value={generatedUrl}
                        ></Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="openButton">
                        <div className='form-inline'>
                            <Button onClick={() => openORPortalUrl()}>{t("Open")}</Button>
                        </div>
                    </Form.Group>
                </Form.Row>                
            </Card.Body>
    </Card>
    )
}

export default EpicAuthentication;