//Framework imports
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Modal, Button, ListGroup, Col } from 'react-bootstrap';
import Webcam from 'react-webcam';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCamera } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';

//App imports
import LoanerOrderService from '../../services/loanerOrderService.js';
import { applicationMessagingActionCreator } from '../../store/actions/applicationMessagingActions';
import { selectPermissionChecker } from '../../store/selectors/permissionSelectors';
import { claims, claimValues } from '../../utilities/authConstants';
import LoadingButton from '../../controls/buttons/LoadingButton';
import { dateTimeFormat } from "../../utilities/dateTime";

class WebcamCaptureModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            webcam: null,
            currentScreenshot: null,
            caseOrderLoanerFiles: [],
            isLoading: false,
        };
    }

    showDeleteImageButton = () => {
        const canVerify = this.props.hasPermission(claims.canVerifyLoanerDropOff, claimValues.isTrue);
        const isDelivered = !(this.props.model.delivConfirmed === null || this.props.model.delivConfirmed === '');
        return isDelivered ? canVerify : true;
    };

    getImageData(associatedFileId) {
        LoanerOrderService.getSavedImageData(associatedFileId).then(response => {
            if (response.data.success) {
                if (response.data.payload != null) {
                    const partialState = this.state.caseOrderLoanerFiles;
                    const associatedFile =
                        partialState.find(file => file.id === response.data.payload.associatedFileId);
                    if (associatedFile !== undefined) {
                        associatedFile.file = response.data.payload;
                    }
                    this.setState({ caseOrderLoanerFiles: [...partialState], currentScreenshot: associatedFile });
                } else {
                    this.props.createErrorMessage(response.data.message, response.data.errors[0]);
                }
            }
        });
    }

    componentDidMount() {
        let loanerId = this.props.model.id;
        LoanerOrderService.getSavedImagesWithoutData(loanerId).then(response => {
            if (response.data.success) {
                if (response.data.payload.length > 0) {
                    this.setState({ caseOrderLoanerFiles: [...response.data.payload] },
                        () => { this.getImageData(this.state.caseOrderLoanerFiles[0].id) });
                }
            } else {
                this.props.createErrorMessage(response.data.message, response.data.errors !== undefined && response.data.errors.length > 0 ? response.data.errors[0] : "");
            }
        });
    }

    //Takes a picture with the webcam and adds it to the array
    onCapture() {
        const now = moment(new Date()).format(dateTimeFormat);
        const transientId = Math.floor(Math.random() * 1000) + 1;
        const data = this.webcam.getScreenshot();
        if (data != null) {
            const caseOrderLoanerFile = {
                //Set the transient Id to something random. This is necessary for maintaining active (selected) state in the list on transient images.
                id: transientId,
                fileName: `${this.props.model.trayName} ${now}`,
                caseOrderLoanerId: this.props.model.id,
                fileType: 1, //Corresponds to AssociatedFileTypeEnum.Image
                isNew: true,
                mimeType: "image/jpeg",
                file: { id: 0, base64Data: data }
            };
            const partialState = this.state.caseOrderLoanerFiles;
            this.setState({ caseOrderLoanerFiles: [caseOrderLoanerFile, ...partialState] });
            this.onShowSelected(caseOrderLoanerFile);
        }

    }

    onShowSelected = screenshot => {
        //If we haven't pulled back any data for the image yet...
        if (screenshot.file == null) {
            if (!screenshot.isDeleting) {
                this.getImageData(screenshot.id);
            }
        } else {
            //Then this is a transient associatedFile capture that was just taken so just set it as current
            this.setState({ currentScreenshot: screenshot });
        }
    }

    //Makes a request to save the caseOrderLoanerFile and its Data (currently Base64) to the DB
    saveNewCaptures() {
        this.setState({ isLoading: true });
        const newFiles = this.state.caseOrderLoanerFiles.filter(file => file.isNew);
        LoanerOrderService.saveCapturedImages(newFiles).then(response => {
            if (response.data.success) {
                if (response.data.payload) {
                    this.props.createSuccessNotification("Success", response.data.message);
                    this.props.beforeCloseModal(this.state.caseOrderLoanerFiles.length, this.props.model.id);
                    this.props.closeModal(this);
                } else {
                    this.props.createErrorMessage(response.data.message, response.data.errors[0]);
                }
            }
        }).finally(() => this.setState({ isLoading: false }));
    }

    updateParentAndClose() {
        this.props.beforeCloseModal(this.state.caseOrderLoanerFiles.filter(i => i.isNew === false).length, this.props.model.id);
        this.props.closeModal(this);
    }

    removeFromList(index) {
        const partialState = this.state.caseOrderLoanerFiles;
        const updated = partialState.filter((item, i) => index !== i);
        this.setState({ caseOrderLoanerFiles: updated, currentScreenshot: null });
        if (this.state.caseOrderLoanerFiles === undefined || this.state.caseOrderLoanerFiles == null || updated.length === 0) {
            this.setState({ caseOrderLoanerFiles: [], currentScreenshot: null });
        }
    }

    deleteIndividual = (screenshot, index) => {
        if (screenshot.isNew === false) {
            if (screenshot.isDeleting === undefined || screenshot.isDeleting === false) {
                screenshot.isDeleting = true;
                LoanerOrderService.deleteImage(screenshot.id).then(response => {
                    if (response.data.success) {
                        if (response.data.payload) {
                            this.removeFromList(index);
                        }
                    } else {
                        screenshot.isDeleting = false;
                        this.props.createErrorMessage(response.data.message,
                            response.data.errors !== undefined && response.data.errors.length > 0
                                ? response.data.errors[0]
                                : "Unable to delete the screenshot. Please try again or contact SPM Technical Support to check the error logs.");
                    }
                });
            }
        } else {
            //This was an unsaved image so just drop from the list...
            this.removeFromList(index);
        }
    }

    resetList() {
        this.setState({ caseOrderLoanerFiles: [], currentScreenshot: null });
    }

    deleteAll() {
        const savedIds =
        this.state.caseOrderLoanerFiles.reduce((a, file) => (file.isNew === false && a.push(file.id), a), []);
        if (savedIds !== undefined && savedIds.length > 0) {
            LoanerOrderService.deleteImages(savedIds).then(response => {
                if (response.data.success) {
                    if (response.data.payload) {
                        this.resetList();
                    }
                } else {
                    this.props.createErrorMessage(response.data.message,
                        response.data.errors !== undefined && response.data.errors.length > 0
                            ? response.data.errors[0]
                            : "Unable to delete the screenshot. Please try again or contact SPM Technical Support to check the error logs.");
                }
            });
        } else {
            //This was an unsaved image so just drop from the list...
            this.resetList();
        }
    }

    //This is currently not bound to anything but should be left here to wire up so we can test or provide
    //filesystem persistence.
    downloadBase64File() {
        //A temporary impl. of saving just for testing the image quality
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);

        downloadLink.href = this.state.currentScreenshot.file.base64Data;
        downloadLink.target = '_self';
        downloadLink.download = "foo.jpg";
        downloadLink.click();
    }

    hasNewScreenshots() {
        return (this.state.caseOrderLoanerFiles !== undefined &&
            this.state.caseOrderLoanerFiles.filter(file => file.isNew).length > 0);
    }

    render() {
        const videoConstraints = {
            width: 1024,
            height: 768,
            aspectRatio: 1.777777778
        };
        const { t } = this.props;
        return (
            <div>
                <Modal.Header>
                    <h4>{t("Webcam Capture")}</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="column-centered-content">
                        <div className="row-centered-content" style={{ margin: "10px" }}>
                            <Col>
                                <div className="row-centered-content">
                                    <Webcam
                                        style={{ zIndex: "2" }}
                                        audio={false}
                                        width={320}
                                        height={180}
                                        minScreenshotWidth={1024}
                                        minScreenshotHeight={768}
                                        videoConstraints={videoConstraints}
                                        screenshotQuality={1}
                                        forceScreenshotSourceSize={true}
                                        ref={node => this.webcam = node}
                                        screenshotFormat="image/jpeg" />
                                    <div className="no-webcam-warning">
                                    {t("Please connect and choose a webcam to capture images.")}
                                    </div>
                                </div>
                                <div className="column-centered-content">
                                    <Button 
                                        variant="success"
                                        disabled={this.state.isLoading}
                                        onClick={() => { this.onCapture() }}
                                        style={{ margin: "1px" }}>
                                        <FontAwesomeIcon icon={faCamera} className="text-white gi-2x" />
                                    </Button>
                                </div>
                                <ListGroup className="images-container">
                                    {this.state.caseOrderLoanerFiles.map((screenshot, index) => {
                                        return (
                                            <ListGroup.Item
                                                key={index}
                                                action
                                                className="image-record d-flex justify-content-between"
                                                active={this.state.currentScreenshot != null ? (screenshot.id === this.state.currentScreenshot.id) : false}
                                                style={{ fontStyle: screenshot.isNew ? "italic" : "normal", fontWeight: screenshot.isNew ? "bold" : "normal" }}
                                                onClick={() => this.onShowSelected(screenshot)}>
                                                <div>{screenshot.fileName}</div>
                                                {
                                                    this.showDeleteImageButton()
                                                        ? <Button 
                                                                disabled={this.state.isLoading}
                                                                style={{ margin: "0 0 0 10px" }}
                                                                variant="danger"
                                                                onClick={() => this.deleteIndividual(screenshot, index)}>
                                                            <FontAwesomeIcon icon={faTrash} className="gi-1x" />
                                                        </Button>
                                                        : null
                                                }
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            </Col>
                            <div className="capture-placeholder">
                                {<img
                                    alt=""
                                    style={{ width: "100%", height: "auto" }}
                                    src={this.state.currentScreenshot ? this.state.currentScreenshot.file.base64Data : null} />}

                            </div>
                        </div>
                    </div >
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="default"
                        variant={
                            this.hasNewScreenshots() ? 'warning' : 'secondary'
                        }
                        disabled={this.state.isLoading}
                        onClick={() => {
                            this.updateParentAndClose();
                        }}
                    >
                        {this.hasNewScreenshots() ? t('Cancel') : t('Close')}
                    </Button>
                    <LoadingButton
                        style={{ margin: '2.5px' }}
                        variant="primary"
                        loading={this.state.isLoading}
                        disabled={!this.hasNewScreenshots()}
                        onClick={() => {
                            this.saveNewCaptures();
                        }}
                    >
                        {t('Save & Close')}
                    </LoadingButton>
                </Modal.Footer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    hasPermission: selectPermissionChecker(state)
});

export default withTranslation()(connect(mapStateToProps, dispatch => bindActionCreators({ ...applicationMessagingActionCreator }, dispatch))(WebcamCaptureModal));