import React from 'react';
import {Row, Col, Table, Popover} from 'react-bootstrap';

import PermissionItemMini from './PermissionItemMini';

export const renderRoleTooltip = (role) => (
    <Popover id="role-popover">
        <Popover.Title as="h4">{role.name}</Popover.Title>
        <Popover.Content>
        {role.description}<br />
        {
            role.permissions.map((p, i) => {
                return (
                    <PermissionItemMini key={i} permission={p} />
                )
            })
        }
        </Popover.Content>
  </Popover>
);
