import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="linebreaks">
                    {props.message}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="default" variant={props.cancelVariant || "secondary"} onClick={props.cancel}>{props.cancelText || t("Cancel")}</Button>
                <Button bsStyle="default" variant={props.confirmVariant || "primary"} onClick={props.confirm}>{props.confirmText || t("Continue")}</Button>
            </Modal.Footer>
        </>

    );
};

export default ConfirmationModal