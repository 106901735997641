import * as actionTypes from './actionTypes';
import { modalActionCreator } from './modalActions';
import i18n from './../../i18n';


export function createErrorMessageAction(title, message) {
    return {
        type: actionTypes.ERROR_MESSAGE_ARRIVED, 
        msg: {
            title: title, 
            message: message
        }
    }; 
};
  
export function createNotificationAction(type, title, message) {
    return { 
        type: actionTypes.ADD_NOTIFICATION, 
        msg: {
            id: (new Date()).getTime(),
            type: type,
            title: title, 
            message: message 
        }
    };
}

export function dismissNotificationAction(notification) {
    return {
        type: actionTypes.DISMISS_NOTIFICATION, 
        msg: notification
    };
}; 

export function createAndDispatchErrorMessage(dispatch, title, message) {


    dispatch(modalActionCreator.showModal({
        open: true,
        title: i18n.t(title),
        message: i18n.t(message),
    }, 'errorModal'));
    dispatch(createErrorMessageAction(title, message));
}

export function createAndDispatchSuccessNotification(dispatch, title, message) {
    dispatch(createNotificationAction("success", title, message));
}

export const applicationMessagingActionCreator = {
    createErrorMessage: (title, message) => async (dispatch, getState) => {
        // dispatch(createErrorMessageAction(newError));
        createAndDispatchErrorMessage(dispatch, title, message);
    },
    createSuccessNotification: (title, message) => async (dispatch, getState) => {
        dispatch(createNotificationAction("success", title, message));
    },
    dismissNotification: (id) => async (dispatch, getState) => {
        dispatch(dismissNotificationAction(id));
    }
};