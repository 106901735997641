import {Button} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

function SaveButton(props) {
    const {t} = useTranslation();

    return <Button variant="primary" size="sm" disabled={props.disabled}
                   onClick={props.onClick}>{t('Submit Event')}</Button>;
}

export default SaveButton