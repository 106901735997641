//Framework imports
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Modal, Button, Form } from 'react-bootstrap';
import { applicationMessagingActionCreator } from '../../store/actions/applicationMessagingActions';
import { applicationContextActionCreator } from '../../store/actions/applicationContextActions';
import { claims, claimValues } from '../../utilities/authConstants';
import settings from "../../utilities/enums/siteSettingsEnum";
//User imports
import SiteService from '../../services/siteService';
import { selectPermissionChecker } from '../../store/selectors/permissionSelectors';

//A filter regularly used to evaluate or find the object corresponding to the setting type
const isDropOffInstructions = setting => setting.settingType === settings.siteLoanerDropOffInstructions.value;
const isCaseOrderInfoZpl = setting => setting.settingType === settings.caseOrderLabelZpl.value;
const isLoanerTrayLabelZpl = setting => setting.settingType === settings.loanerTrayLabelZpl.value;

class SiteSettingsModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            originalSettings: [],
            settings: []
        }
        this.handleUserInput = this.handleUserInput.bind(this);
    }

    componentDidMount() {
        const settings = [];
        this.props.client.siteSettings.map(s => settings.push({ ...s }));
        this.setState({ settings: settings });

    }

    handleUserInput(event, settingName) {
        const target = event.target;
        const value = target.value;
        const partialState = this.state.settings;
        const setting = partialState.find(settingName);
        setting.value = value;
        this.setState({ settings: partialState });
    }

    save() {
        SiteService.saveSiteSettings(this.state.settings).then(response => {

            if (response.data.success) {
                if (response.data.payload != null) {
                    var client = { ...this.props.client };
                    client.siteSettings = this.state.settings;
                    this.props.updateCurrentClient(client);
                    this.props.closeModal(this);
                } else {
                    this.props.createErrorMessage(response.data.message, response.data.errors[0]);
                }
            }
        });
    }

    isFormDisabled() {
        const canEditSiteSettings = this.props.hasPermission(claims.crudSiteSettings, claimValues.createReadUpdateDelete);
        if (canEditSiteSettings) {
            return false;
        }

        return true;
    }

    render() {
        const { t } = this.props;
        const settingValueMaxLength = 2000;
        return (
            <div>
                <Modal.Header>
                    <h3>{t('Site Settings')} - {this.props.client.siteShortName}</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <br />
                        <h5>{t('These settings are for all computers at this site.')}</h5>
                        <br /><br />
                        <Form.Group style={{ width: "600px" }}>
                            <Form.Label>{t('Specify the drop off instructions for loaners.')}</Form.Label>
                            <textarea className="form-control"
                                rows="3"
                                maxLength={settingValueMaxLength}
                                style={{ overflowY: "scroll", height: "100px", resize: "none" }}
                                disabled={this.isFormDisabled()}
                                id="dropOffInstructions"
                                name="dropOffInstructions"
                                value={this.state.settings.find(isDropOffInstructions)?.value}
                                onChange={event => this.handleUserInput(event, isDropOffInstructions)} />
                        </Form.Group>

                        <Form.Group style={{ width: "600px" }}>
                            <Form.Label>{t('The Case Order Info Label ZPL')}</Form.Label>
                            <textarea className="form-control"
                                rows="3"
                                maxLength={settingValueMaxLength}
                                style={{ overflowY: "scroll", height: "100px", resize: "none" }}
                                disabled={this.isFormDisabled()}
                                id="caseOrderInfoZpl"
                                name="caseOrderInfoZpl"
                                value={this.state.settings.find(isCaseOrderInfoZpl)?.value}
                                onChange={event => this.handleUserInput(event, isCaseOrderInfoZpl)} />
                        </Form.Group>

                        <Form.Group style={{ width: "600px" }}>
                            <Form.Label>{t('The Loaner Label ZPL')}</Form.Label>
                            <textarea className="form-control"
                                rows="3"
                                maxLength={settingValueMaxLength}
                                style={{ overflowY: "scroll", height: "100px", resize: "none" }}
                                disabled={this.isFormDisabled()}
                                id="loanerTrayLabelZpl"
                                name="loanerTrayLabelZpl"
                                value={this.state.settings.find(isLoanerTrayLabelZpl)?.value}
                                onChange={event => this.handleUserInput(event, isLoanerTrayLabelZpl)} />
                        </Form.Group>
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" variant="warning" onClick={() => this.props.closeModal(this)}>Close</Button>
                    <Button style={{ margin: "2.5px" }} variant="primary" disabled={this.isFormDisabled()} onClick={() => this.save()}>Save & Close</Button>
                </Modal.Footer>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    client: state.applicationContext.applicationContext.client,
    hasPermission: selectPermissionChecker(state)
});


export default withTranslation()(connect(mapStateToProps, dispatch => bindActionCreators({ ...applicationContextActionCreator, ...applicationMessagingActionCreator }, dispatch))(SiteSettingsModal));