import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap';
import SiteMultiSelect from "./DeliverTrays/SiteMultiSelect";
import SearchResults from './CaseOrderSearchResults';

import { signalrActionCreator } from '../../store/actions/signalrActions';
import { loanerOrderActionCreator } from '../../store/actions/loanerOrderActions';
import { loanerAppSettingsActionCreator } from '../../store/actions/loanerAppSettingsActions';
import { selectCaseOrders } from '../../store/selectors/loanerOrderSelectors';
import { selectDefaultLoanerSites } from '../../store/selectors/settingsSelectors';
import * as topicTypes from '../../store/actions/topicTypes';
import { byCaseStartTimeThenByVendorName, byModifiedDateDesc } from '../../utilities/sorts';
import { toMidnightPlusDays } from '../../utilities/dateTime';

import moment from 'moment';

const onRecentlyModified = (order, threshold) => {
    // threshold will be in localtime
    // order.modifiedDate is in UTC convert to local before use
    var modifiedDate = moment.utc(order.modifiedDate).local();
    return (modifiedDate >= threshold);
};
const onCaseStartTime = (order, threshold) => {
    var caseStartTime = new Date(order.caseStartTime);
    var currentDateTime = new Date(new Date().getTime());

    // we also want to filter out any caseStartTime that are in the past
    return order.traysExpected > 0 && caseStartTime < threshold && caseStartTime >= currentDateTime;
};

class LoanerHome extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            sites: [],
            recentlyModifiedFiltered: [],
            loanersExpectedFiltered: [],
            caseOrders: [],
            selectedModifiedSites: [],
            selectedExpectedSites: [],
            isDefaultSiteSelected: false
        }
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleExpectedSitesChange = this.handleExpectedSitesChange.bind(this);
        this.handleModifiedSitesChange = this.handleModifiedSitesChange.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.filterData = this.filterData.bind(this);
    }

    componentDidMount() {
        this.props.requestLoanerOrderHome();
        this.props.subscribeToTopic(topicTypes.CASE_ORDERS);
    }

    componentWillUnmount() {
        this.props.unsubscribeFromTopic(topicTypes.CASE_ORDERS);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading) {
            var partialState = this.state;
            const hasUpdates = propName => {
                if (this.props[propName] !== prevProps[propName]) {
                    partialState[propName] = this.props[propName];
                    return true;
                }
                return false;
            }
            
            if (hasUpdates('sites') | hasUpdates('vendors') | hasUpdates('caseOrders') | hasUpdates('defaultLoanerSites')) {
                this.setState({ ...partialState }, () => {
                    this.filterData();
                    this.setDefaultSite(partialState.sites);
                });
            }
            else if(this.props.pageSettings !== prevProps.pageSettings){
                this.filterData();
            }
        } 
    }

    handleUserInput(event) {
        // taken straight from the React Docs
        // https://reactjs.org/docs/forms.html#handling-multiple-inputs
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var partialState = this.state;
        partialState[name] = value;
        this.setState({ partialState });
    }

    handleExpectedSitesChange(data) {
        const newSites = data ? data.map(d => d.value) : [];
        this.setState({ selectedExpectedSites: newSites }, this.filterData);
    }

    handleModifiedSitesChange(data) {
        const newSites = data ? data.map(d => d.value) : [];
        this.setState({ selectedModifiedSites: newSites }, this.filterData);
    }

    handleChangeState(propName, value) {
        this.props.changeLoanerAppSetting('loanerHome', propName, value);
    }

    getRecentlyModified() {
        return this.state.caseOrders.filter(onRecentlyModified);
    }

    filterData() {
        var stateData = this.state;
        if (stateData.caseOrders !== null) {

            const oneHourInMilliseconds = 60 * 60 * 1000;

            const hoursAgo = new Date(new Date().getTime() - (this.props.pageSettings.selectedRecentHours * oneHourInMilliseconds));
            const recentlyModified = stateData.caseOrders.filter((order) => onRecentlyModified(order, hoursAgo));

            const daysFromNow = toMidnightPlusDays(new Date(), this.props.pageSettings.selectedNextDays); //midnight 
            const loanersExpected = stateData.caseOrders.filter((order) => onCaseStartTime(order, daysFromNow));

            var recentlyModifiedFiltered = null;
            var loanersExpectedFiltered = null;

            if (this.state.selectedModifiedSites.length > 0) {
                recentlyModifiedFiltered = recentlyModified.filter((order) => {
                    return this.state.selectedModifiedSites.includes(order.siteId);
                });
            } else {
                recentlyModifiedFiltered = [...recentlyModified];
            }
            if (this.state.selectedExpectedSites) {
                loanersExpectedFiltered = loanersExpected.filter((order) => {
                    return this.state.selectedExpectedSites.includes(order.siteId);
                });
            } else {
                loanersExpectedFiltered = [...loanersExpected];
            }

            var partialState = stateData;
            partialState.recentlyModifiedFiltered = [...recentlyModifiedFiltered];
            partialState.loanersExpectedFiltered = [...loanersExpectedFiltered];
            this.setState({ partialState });
        }
    }

    getSiteName(id) {
        const sites = this.state.sites;
        for (var i = 0, len = sites.length; i < len; i++) {
            if (parseInt(sites[i].id) === parseInt(id)) {
                return sites[i].shortName;
            }
        }

        return 'undefined';
    }

    setDefaultSite(sites) {
        if (!this.state.isDefaultSiteSelected && sites.length > 0 && this.props.defaultLoanerSites.length > 0) {
            var defaultSites = sites.filter(s => {
                return this.props.defaultLoanerSites.indexOf(s.id) !== -1;
            }).map((item) => {
                return {
                    value: item.id,
                    label: item.shortName
                };
            });

            this.handleModifiedSitesChange(defaultSites);
            this.handleExpectedSitesChange(defaultSites);
            this.setState({ isDefaultSiteSelected: true });
        }
    }

    render() {
        return (
            <>
                <Card style={{ "height": "100%", "marginBottom": "20px" }}>
                    <Card.Header className="d-flex align-items-center">
                        <div className="mr-auto">Recently modified</div>
                        <div className="card-btn-group form-inline">
                            <span className="card-button-group-item">Sites:</span>
                            <SiteMultiSelect   sites={this.state.sites} selectedSites={this.state.selectedModifiedSites}
                                sitesChangedHandler={this.handleModifiedSitesChange} />
                            <span className="card-button-group-item show-hand">&nbsp;&nbsp;Recent hours:
                                <a onClick={() => this.handleChangeState("selectedRecentHours", 1)} className={this.props.pageSettings.selectedRecentHours === 1 ? "font-weight-bold" : null}>&nbsp;1&nbsp;</a>|
                                <a onClick={() => this.handleChangeState("selectedRecentHours", 2)} className={this.props.pageSettings.selectedRecentHours === 2 ? "font-weight-bold" : null}>&nbsp;2&nbsp;</a>|
                                <a onClick={() => this.handleChangeState("selectedRecentHours", 4)} className={this.props.pageSettings.selectedRecentHours === 4 ? "font-weight-bold" : null}>&nbsp;4&nbsp;</a>|
                                <a onClick={() => this.handleChangeState("selectedRecentHours", 8)} className={this.props.pageSettings.selectedRecentHours === 8 ? "font-weight-bold" : null}>&nbsp;8&nbsp;</a>
                            </span>
                        </div>
                    </Card.Header>
                    <Card.Body className="card-body-table">
                        <SearchResults sites={this.state.sites} results={this.state.recentlyModifiedFiltered.sort(byModifiedDateDesc)}
                            pageResults maxResultsPerPage={5}
                            site caseNumber physician caseStartTime procedure vendor traysDelivered traysExpected verified modified linkTo />
                    </Card.Body>
                </Card>

                <Card style={{ "height": "100%", "marginBottom": "20px" }}>
                    <Card.Header className="d-flex align-items-center">
                        <div className="mr-auto">Expected loaners</div>
                        <div className="card-btn-group form-inline">
                            <span className="card-button-group-item">Sites:</span>
                            <SiteMultiSelect sites={this.state.sites} selectedSites={this.state.selectedExpectedSites}
                                             sitesChangedHandler={this.handleExpectedSitesChange}/>
                            <span className="card-button-group-item show-hand">&nbsp;&nbsp;Cases in the next days:
                                <a onClick={() => this.handleChangeState("selectedNextDays", 1)} className={(this.props.pageSettings.selectedNextDays === 1 ? "font-weight-bold" : null)}>&nbsp;1&nbsp;</a>|
                                <a onClick={() => this.handleChangeState("selectedNextDays", 2)} className={(this.props.pageSettings.selectedNextDays === 2 ? "font-weight-bold" : null)}>&nbsp;2&nbsp;</a>|
                                <a onClick={() => this.handleChangeState("selectedNextDays", 3)} className={(this.props.pageSettings.selectedNextDays === 3 ? "font-weight-bold" : null)}>&nbsp;3&nbsp;</a>|
                                <a onClick={() => this.handleChangeState("selectedNextDays", 4)} className={(this.props.pageSettings.selectedNextDays === 4 ? "font-weight-bold" : null)}>&nbsp;4&nbsp;</a>
                            </span>
                        </div>
                    </Card.Header>
                    <Card.Body className="card-body-table">
                        <SearchResults sites={this.state.sites} results={this.state.loanersExpectedFiltered.sort(byCaseStartTimeThenByVendorName)}
                            pageResults maxResultsPerPage={5}
                            site caseNumber physician caseStartTime procedure vendor traysExpected linkTo />
                    </Card.Body>
                </Card>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    caseOrders: selectCaseOrders(state),
    defaultLoanerSites: selectDefaultLoanerSites(state),
    sites: state.loanerOrder.sites,
    vendors: state.loanerOrder.vendors,
    isLoading: state.loanerOrder.isLoading,
    pageSettings: state.loanerAppSettings.loanerHome
});

export default connect(mapStateToProps, dispatch => bindActionCreators({ ...loanerOrderActionCreator, ...signalrActionCreator, ...loanerAppSettingsActionCreator }, dispatch))(LoanerHome);
