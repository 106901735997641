import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const WebSocketStatus = (props) => {

    const { t, i18n } = useTranslation();

    const { webSocketConnected } = props.applicationContext;
    var connected = "Disconnected"; //webSocketConnected ? t("Connected") : t("Disconnected");

    const connectedTooltip = <Tooltip>{t("SPM Link {{connected}}", { connected: webSocketConnected ? t("Connected") : t("Disconnected") })}</Tooltip>;
    return (
        <div>
            <OverlayTrigger overlay={connectedTooltip} delayShow={300} delayHide={150} placement="bottom">
                <FontAwesomeIcon icon={faExchangeAlt} size="lg" className={webSocketConnected ? "pointer-mouseover text-success" : "pointer-mouseover text-danger"} /> 
            </OverlayTrigger>
        </div>
    );
}

export default WebSocketStatus;