// Context 
export const UPDATE_CURRENT_CLIENT = 'UPDATE_CURRENT_CLIENT';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_TENANT = 'UPDATE_CURRENT_TENANT';
export const WEB_SOCKET_CONNECTED = 'WEB_SOCKET_CONNECTED';
export const WEB_SOCKET_DISCONNECTED = 'WEB_SOCKET_DISCONNECTED';
export const UPDATE_LOGIN_MODE = 'UPDATE_LOGIN_MODE';
export const USER_LOG_OUT = 'USER_LOG_OUT';

// OR Case 
export const REQUEST_OR_CASE_INFO = 'REQUEST_OR_CASE_INFO';
export const RECEIVE_OR_CASE_INFO = 'RECEIVE_OR_CASE_INFO';
export const UPDATE_CASE_INFO = 'UPDATE_CASE_INFO';
export const ADD_ITEM_TO_CASE = 'ADD_ITEM_TO_CASE';

// Loaner Order 
export const REQUEST_LOANER_ORDER_HOME = 'REQUEST_LOANER_ORDER_HOME';
export const RECEIVE_LOANER_ORDER_HOME = 'RECEIVE_LOANER_ORDER_HOME';
export const REQUEST_CASE_ORDER = 'REQUEST_CASE_ORDER';
export const RECEIVE_CASE_ORDER = 'RECEIVE_CASE_ORDER';
export const UPDATE_CASE = 'UPDATE_CASE';
export const VERIFY_CASE_ORDER = 'VERIFY_CASE_ORDER';
export const UPDATE_DELIVERERS = 'UPDATE_DELIVERERS';

//Loaner App Settings
export const CHANGE_LOANER_APP_SETTING = 'CHANGE_LOANER_APP_SETTING';
export const PATIENT_DATA_SETTING = 'PATIENT_DATA_SETTING';

// Modals 
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// Messaging
export const ERROR_MESSAGE_ARRIVED = 'ERROR_MESSAGE_ARRIVED';

// Notifications 
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

// Spinner
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

// SignalR
export const SEND_SIGNALR_MESSAGE = 'SEND_SIGNALR_MESSAGE';
export const RECEIVE_SIGNALR_MESSAGE = 'RECEIVE_SIGNALR_MESSAGE';

export const START_SIGNALR_CONNECTION = 'START_SIGNALR_CONNECTION';
export const STOP_SIGNALR_CONNECTION = 'STOP_SIGNALR_CONNECTION';

export const SUBSCRIBE_SIGNALR_TOPIC = 'SUBSCRIBE_SIGNALR_TOPIC';
export const UNSUBSCRIBE_SIGNALR_TOPIC = 'UNSUBSCRIBE_SIGNALR_TOPIC';

export const LOANER_UPDATE = 'LOANER_UPDATE'; // Remove this, not needed anymore 

// OR Portal 
export const OR_REQUEST_UPDATED = 'OR_REQUEST_UPDATED';
export const CLEAR_OR_REQUEST_UPDATES = 'CLEAR_OR_REQUEST_UPDATES';