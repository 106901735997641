import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const SpmNotConnected = (props) => {

    const { t, i18n } = useTranslation();
    return (
        <div class="py-5">
            <div class="container py-5">
                <div class="row">
                    <div class="col-md-2 text-center">
                        <p><FontAwesomeIcon icon={faExclamationTriangle} className="fa-5x text-danger" /><br />{t('Status Code')}: 503</p>
                    </div>
                    <div class="col-md-10">
                        <h3><b>503</b> {t('SPM Not Connected')}</h3>
                        <p>{t('We are currently unable to connect to SPM. Please try again later or contact an administrator.')}</p>
                        < a class="btn btn-primary" href="javascript:history.back()" > {t('Try again')}</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SpmNotConnected;