
export const filterCases = (orders, fromDate, toDate) => {
    // TODO: let's use moment.js for date operations
    if (fromDate && toDate && orders) {
        const [fromYear, fromMonth, fromDay] = fromDate.split("-").map(datePart => parseInt(datePart));
        const dateFrom = new Date(fromYear, fromMonth - 1, fromDay);
        const [toYear, toMonth, toDay] = toDate.split("-").map(datePart => parseInt(datePart));
        const dateTo = new Date(toYear, toMonth - 1, toDay + 1);

        return orders.filter(o => {
            var date = new Date(o.caseStartTime);
            // this is ugly and gross
            var dateStr = date.getFullYear() +
                "-" +
                (date.getMonth() + 1) +
                "-" +
                date.getDate() +
                " " +
                date.getHours() +
                ":" +
                date.getMinutes() +
                ":" +
                date.getSeconds();
            var parsedDate = Date.parse(dateStr);
            return parsedDate >= dateFrom && parsedDate <= dateTo;
        });
    }
};

export const filterCasesFrom = (orders, fromDate) => {
    // TODO: let's use moment.js for date operations
    if (fromDate) {
        const [fromYear, fromMonth, fromDay] = fromDate.split("-").map(datePart => parseInt(datePart));
        const dateFrom = new Date(fromYear, fromMonth - 1, fromDay);

        return orders.filter(o => {
            var date = new Date(o.caseStartTime);
            // this is ugly and gross
            var dateStr = date.getFullYear() +
                "-" +
                (date.getMonth() + 1) +
                "-" +
                date.getDate() +
                " " +
                date.getHours() +
                ":" +
                date.getMinutes() +
                ":" +
                date.getSeconds();
            var parsedDate = Date.parse(dateStr);
            return parsedDate >= dateFrom;
        });
    }
};