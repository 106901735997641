import i18n from '../i18n';

export const organizationTypes = [
    { id: 1, text: i18n.t('Organization') },
    { id: 2, text: i18n.t('Parent Organization') },
    { id: 3, text: i18n.t('Child Organization') }
]

export const organizationTypeEnum = {
    Organization: 1,
    ParentOrganization: 2,
    ChildOrganization: 3,
    Steris: 4
};

export const userAuthenticationResultEnum = {
    Success: 1,
    Failed: 2,
    LockedOut: 3,
    NotAllowed: 4,
    TwoFactorRequired: 5,
    PendingAuthorization: 6,
    SpmAccountLinkRequired: 7
};

export const userStateEnum = {
    Deleted: 0,
    Active: 100,
    Disabled: 150,
    Pending: 300,
    UpdateNeeded: 301
};

export const registerClientResultEnum = {
    CreateClientFailed: 0,
    NoActiveSocketConnection: 50,
    Success: 100,
    NoMatchingClient: 200,
    MatchedFingerprint: 300,
    MatchedName: 400
};

// TODO: These are wrong
export const apiKeyTypesEnum = {
    Casechek: "IsCasechekApiClient",
    Support: "IsSupportApiClient",
    DynamicConfig: "IsDynamicConfigClient",
    Messaging: "IsMessagingApiClient"
};

// TODO: These are wrong
export const apiKeyUserRolesEnum = {
    Casechek: "Casechek Api Client",
    Support: "Support Api Client",
    DynamicConfig: "Dynamic Config Client",
    Messaging: "Messaging ApiClient",
    SynergyTrak: "SynergyTrak Api Client"
};

export const caseOrderSourceEnum = {
    Adhoc: 0,
    Torq: 1
};

export const AuthenticationModeEnum = {
    Unset: 0,
    Web: 1, 
    SPM: 2, 
    SynergyTrak: 3
};