import { useState, useEffect } from 'react';

import LoanerOrderService from '../../../services/loanerOrderService';
import { toggleSpinner } from "../../../utilities/configureAxios";

const useLoanerTrayQuery = (
    vendorId,
    nameText,
    productCodeText,
    shouldSearch) => {

    const [searchResults, setSearchResults] = useState([]);
    const [performedSearch, setPerformedSearch] = useState(false);
    const [message, setMessage] = useState(null);

    const query = () => {
        var results = [];
        setPerformedSearch(false);
        if (shouldSearch && vendorId) {
            toggleSpinner(false);
            LoanerOrderService.searchMasterLoanerSets({
                masterLoanerCompanyId: vendorId,
                nameText: nameText,
                productCodeText: productCodeText
            }).then(response => {
                results = response.data.payload ? response.data.payload : [];
                setSearchResults(results);
                setPerformedSearch(true);
                if (vendorId && (!results || !results.some(Boolean))) {
                    setMessage("Search returned no results");
                }
            }).finally(() => {
                toggleSpinner(true);
            });
        }
    };

    useEffect(() => {
        query();
    }, [shouldSearch]);

    return { searchResults, performedSearch, message };
}
export default useLoanerTrayQuery;